import React from "react";
import { $, jQuery } from "jquery";

function EaFluidControlValveTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr>
            <th colspan="2">SIZE</th>
            <th colspan="2">BODY OD</th>
            <th colspan="2">NOGO OD</th>
            <th colspan="2">FISHING NECK OD</th>
            <th colspan="2">PULLING TOOL</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>in.</td>
            <td>mm</td>
            <td>in.</td>
            <td>mm</td>
            <td>in.</td>
            <td>mm</td>
            <td>in.</td>
            <td>mm</td>
            <td>in.</td>
            <td>mm</td>
          </tr>
          <tr>
            <td>2</td>
            <td>50.8</td>
            <td>1.750</td>
            <td>44.5</td>
            <td>1.875</td>
            <td>47.6</td>
            <td>1.375</td>
            <td>34.9</td>
            <td>2" Camco JDC</td>
            <td>2" Otis RB</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default EaFluidControlValveTable;
