import React, { Component } from "react";
import PreloaderLogo from "../../../src/assets/logos/MaverickLogoAnimated_TransparentBlack.gif";
import $ from "jquery";
import { useState, useEffect } from 'react';



class Preloader extends Component {



  // setTimeout(function() {
  //   $('.preloader-container').addClass('preloader-hidden');
  // }, 2000);

  render() {
    return (
      <div>
        <div className="preloader-container" id="preloader">
          <img src={PreloaderLogo} alt="Maverick Logo" />
        </div>
      </div>
    );
  }
}

export default Preloader;
