import React from "react";
import { $, jQuery } from "jquery";

function HydrosetIIAPackerTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c165">
            <td class="c699" colspan="2" rowspan="1">
              <p class="c0">
                <span class="c3">Casing</span>
              </p>
            </td>
            <td class="c375" colspan="1" rowspan="2">
              <p class="c384">
                <span class="c3">Recommended Hole Size </span>
              </p>
              <p class="c0">
                <span class="c3">(inches)</span>
              </p>
            </td>
            <td class="c153" colspan="1" rowspan="2">
              <p class="c0">
                <span class="c3">Tool OD </span>
              </p>
              <p class="c0">
                <span class="c3">(inches)</span>
              </p>
            </td>
            <td class="c88" colspan="2" rowspan="1">
              <p class="c0">
                <span class="c3">Long String </span>
              </p>
            </td>
            <td class="c623" colspan="2" rowspan="1">
              <p class="c0">
                <span class="c3">Short String</span>
              </p>
            </td>
            <td class="c804" colspan="1" rowspan="2">
              <p class="c0">
                <span class="c3">Part </span>
              </p>
              <p class="c0">
                <span class="c3">Number</span>
              </p>
            </td>
          </tr>
          <tr class="c351">
            <td class="c67" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c3">Size </span>
              </p>
              <p class="c0">
                <span class="c3">(inches)</span>
              </p>
            </td>
            <td class="c36" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c3">Weight</span>
              </p>
              <p class="c0">
                <span class="c3">(lbs/ft) </span>
              </p>
            </td>
            <td class="c236" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c3">Connections </span>
              </p>
            </td>
            <td class="c5" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c3">Min ID </span>
              </p>
              <p class="c0">
                <span class="c3">(inches) </span>
              </p>
            </td>
            <td class="c136" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c3">Connections </span>
              </p>
            </td>
            <td class="c686" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c3">Min ID </span>
              </p>
              <p class="c0">
                <span class="c3">(inches)</span>
              </p>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="c25">
            <td class="c67" colspan="1" rowspan="1">
              7
            </td>
            <td class="c36" colspan="1" rowspan="1">
              26.0 - 32.0
            </td>
            <td class="c375" colspan="1" rowspan="1">
              6.094 - 6.276
            </td>
            <td class="c153" colspan="1" rowspan="1">
              5.938
            </td>
            <td class="c236" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c5" colspan="1" rowspan="1">
              2.39
            </td>
            <td class="c136" colspan="1" rowspan="1">
              1.900 NUE
            </td>
            <td class="c686" colspan="1" rowspan="1">
              1.50
            </td>
            <td class="c804" colspan="1" rowspan="1">
              94570-BAC-BBA
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default HydrosetIIAPackerTable;
