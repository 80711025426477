import React from "react";
import { $, jQuery } from "jquery";

function HydrosetIVTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c165">
            <td class="c22" colspan="2" rowspan="1">
              Casing
            </td>
            <td class="c103" colspan="1" rowspan="2">
              Recommended Hole Size (inches)
            </td>
            <td class="c89" colspan="1" rowspan="2">
              Tool OD (inches)
            </td>
            <td class="c319" colspan="1" rowspan="2">
              Tool ID (inches)
            </td>
            <td class="c455" colspan="1" rowspan="2">
              Thread Connection Box Up / Pin Down
            </td>
            <td class="c36" colspan="1" rowspan="2">
              Part &nbsp; Number
            </td>
          </tr>
          <tr class="c364">
            <td class="c113" colspan="1" rowspan="1">
              Size(inches)
            </td>
            <td class="c62" colspan="1" rowspan="1">
              Weight (lbs/ft)
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="c59">
            <td class="c113" colspan="1" rowspan="1">
              3-1/2
            </td>
            <td class="c62" colspan="1" rowspan="1">
              7.7 - 10.2
            </td>
            <td class="c103" colspan="1" rowspan="1">
              2.922 - 3.068
            </td>
            <td class="c89" colspan="1" rowspan="1">
              2.781
            </td>
            <td class="c319" colspan="1" rowspan="1">
              1.25
            </td>
            <td class="c455" colspan="1" rowspan="1">
              1.900 NUE
            </td>
            <td class="c36" colspan="1" rowspan="1">
              65435
            </td>
          </tr>
          <tr class="c59">
            <td class="c113" colspan="1" rowspan="2">
              4
            </td>
            <td class="c62" colspan="1" rowspan="1">
              9.5 - 11.0
            </td>
            <td class="c103" colspan="1" rowspan="1">
              3.476 - 3.548
            </td>
            <td class="c89" colspan="1" rowspan="1">
              3.250
            </td>
            <td class="c319" colspan="1" rowspan="1">
              1.75
            </td>
            <td class="c455" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c36" colspan="1" rowspan="1">
              65440
            </td>
          </tr>
          <tr class="c59">
            <td class="c62" colspan="1" rowspan="1">
              12.95
            </td>
            <td class="c103" colspan="1" rowspan="1">
              3.340
            </td>
            <td class="c89" colspan="1" rowspan="1">
              3.187
            </td>
            <td class="c319" colspan="1" rowspan="1">
              1.75
            </td>
            <td class="c455" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c36" colspan="1" rowspan="1">
              65441
            </td>
          </tr>
          <tr class="c59">
            <td class="c113" colspan="1" rowspan="1">
              4-1/2
            </td>
            <td class="c62" colspan="1" rowspan="1">
              9.5 - 13.5
            </td>
            <td class="c103" colspan="1" rowspan="1">
              3.920 - 4.090
            </td>
            <td class="c89" colspan="1" rowspan="1">
              3.750
            </td>
            <td class="c319" colspan="1" rowspan="1">
              2.00
            </td>
            <td class="c455" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c36" colspan="1" rowspan="1">
              65445
            </td>
          </tr>
          <tr class="c59">
            <td class="c113" colspan="1" rowspan="2">
              5-1/2
            </td>
            <td class="c62" colspan="1" rowspan="2">
              14.0 - 20.0
            </td>
            <td class="c103" colspan="1" rowspan="2">
              4.778 - 5.012
            </td>
            <td class="c89" colspan="1" rowspan="2">
              4.625
            </td>
            <td class="c319" colspan="1" rowspan="1">
              2.00
            </td>
            <td class="c455" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c36" colspan="1" rowspan="1">
              65455
            </td>
          </tr>
          <tr class="c59">
            <td class="c319" colspan="1" rowspan="1">
              2.38
            </td>
            <td class="c455" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c36" colspan="1" rowspan="1">
              65456
            </td>
          </tr>
          <tr class="c59">
            <td class="c113" colspan="1" rowspan="4">
              6-5/8
            </td>
            <td class="c62" colspan="1" rowspan="2">
              17.0 - 24.0
            </td>
            <td class="c103" colspan="1" rowspan="2">
              5.921 - 6.135
            </td>
            <td class="c89" colspan="1" rowspan="2">
              5.750
            </td>
            <td class="c319" colspan="1" rowspan="1">
              2.50
            </td>
            <td class="c455" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c36" colspan="1" rowspan="1">
              65467
            </td>
          </tr>
          <tr class="c59">
            <td class="c319" colspan="1" rowspan="1">
              3.00
            </td>
            <td class="c455" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c36" colspan="1" rowspan="1">
              65468
            </td>
          </tr>
          <tr class="c59">
            <td class="c62" colspan="1" rowspan="2">
              24.0 - 32.0
            </td>
            <td class="c103" colspan="1" rowspan="2">
              5.675 - 5.921
            </td>
            <td class="c89" colspan="1" rowspan="2">
              5.500
            </td>
            <td class="c319" colspan="1" rowspan="1">
              2.50
            </td>
            <td class="c455" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c36" colspan="1" rowspan="1">
              65465
            </td>
          </tr>
          <tr class="c59">
            <td class="c319" colspan="1" rowspan="1">
              3.00
            </td>
            <td class="c455" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c36" colspan="1" rowspan="1">
              65466
            </td>
          </tr>
          <tr class="c59">
            <td class="c113" colspan="1" rowspan="5">
              7
            </td>
            <td class="c62" colspan="1" rowspan="2">
              17.0 - 26.0
            </td>
            <td class="c103" colspan="1" rowspan="2">
              6.276 - 6.538
            </td>
            <td class="c89" colspan="1" rowspan="2">
              6.000
            </td>
            <td class="c319" colspan="1" rowspan="1">
              2.50
            </td>
            <td class="c455" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c36" colspan="1" rowspan="1">
              65472
            </td>
          </tr>
          <tr class="c59">
            <td class="c319" colspan="1" rowspan="1">
              3.00
            </td>
            <td class="c455" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c36" colspan="1" rowspan="1">
              65474
            </td>
          </tr>
          <tr class="c59">
            <td class="c62" colspan="1" rowspan="1">
              20.0 - 29.0
            </td>
            <td class="c103" colspan="1" rowspan="1">
              6.184 - 6.456
            </td>
            <td class="c89" colspan="1" rowspan="1">
              5.938
            </td>
            <td class="c319" colspan="1" rowspan="1">
              2.50
            </td>
            <td class="c455" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c36" colspan="1" rowspan="1">
              65471
            </td>
          </tr>
          <tr class="c59">
            <td class="c62" colspan="1" rowspan="2">
              26.0 - 32.0
            </td>
            <td class="c103" colspan="1" rowspan="2">
              6.094 - 6.276
            </td>
            <td class="c89" colspan="1" rowspan="2">
              5.875
            </td>
            <td class="c319" colspan="1" rowspan="1">
              2.50
            </td>
            <td class="c455" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c36" colspan="1" rowspan="1">
              65470
            </td>
          </tr>
          <tr class="c59">
            <td class="c319" colspan="1" rowspan="1">
              3.00
            </td>
            <td class="c455" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c36" colspan="1" rowspan="1">
              65473
            </td>
          </tr>
          <tr class="c59">
            <td class="c113" colspan="1" rowspan="4">
              7-5/8
            </td>
            <td class="c62" colspan="1" rowspan="2">
              24.0 - 29.7
            </td>
            <td class="c103" colspan="1" rowspan="2">
              6.875 - 7.025
            </td>
            <td class="c89" colspan="1" rowspan="2">
              6.672
            </td>
            <td class="c319" colspan="1" rowspan="1">
              2.50
            </td>
            <td class="c455" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c36" colspan="1" rowspan="1">
              65475
            </td>
          </tr>

          <tr class="c59">
            <td class="c319" colspan="1" rowspan="1">
              3.00
            </td>
            <td class="c455" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c36" colspan="1" rowspan="1">
              65478
            </td>
          </tr>
          <tr class="c59">
            <td class="c62" colspan="1" rowspan="2">
              33.7 - 39.0
            </td>
            <td class="c103" colspan="1" rowspan="2">
              6.625 - 6.765
            </td>
            <td class="c89" colspan="1" rowspan="2">
              6.453
            </td>
            <td class="c319" colspan="1" rowspan="1">
              2.50
            </td>
            <td class="c455" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c36" colspan="1" rowspan="1">
              65476
            </td>
          </tr>
          <tr class="c59">
            <td class="c319" colspan="1" rowspan="1">
              3.00
            </td>
            <td class="c455" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c36" colspan="1" rowspan="1">
              65477
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default HydrosetIVTable;
