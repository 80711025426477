import React from "react";
import { $, jQuery } from "jquery";

function Ed2() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr>
            <th colspan="2">ELEMENT OD</th>
            <th colspan="2">MIN TOOL ID</th>
            <th colspan="2">THREAD CONNECTIONS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>in.</td>
            <td>mm</td>
            <td>in.</td>
            <td>mm</td>
            <td>in.</td>
            <td>mm</td>
          </tr>
          <tr>
            <td>4.25</td>
            <td>107.95</td>
            <td>1.562</td>
            <td>39.67</td>
            <td>2 3/8 EUE</td>
            <td>60.3</td>
          </tr>
          <tr>
            <td>5.50</td>
            <td>139.70</td>
            <td>1.562</td>
            <td>39.67</td>
            <td>2 7/8 EUE</td>
            <td>73.0</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Ed2;
