import React from "react";
import ContactForm from "../../components/ContactForm/ContactForm";
import { useEffect, useState } from "react";
import { getAllProductService } from "../../services/dept";
import { getBurstDiscTitlesService } from "../../services/dept";
import GeneralBtn from "../../components/ColumnBtns/GeneralBtn";
import { Helmet } from 'react-helmet';
import GeneralHeader from "../../components/Headers/GeneralHeader";
import { useParams } from "react-router-dom";
import { pseudoencodeURI } from "./converter";


import BurstDiscSubDualHpht from '../../components/Products/BurstDiscs/BurstDiscSubDualHpht'
import InterventionlessBurstDisc from '../../components/Products/BurstDiscs/InterventionlessBurstDisc'
import BurstDiscSubDual from '../../components/Products/BurstDiscs/BurstDiscSubDual'
import MuleShoe from '../../components/Products/BurstDiscs/MuleShoe'
import MuleShoeHpht from '../../components/Products/BurstDiscs/MuleShoeHpht'
import PinDown from '../../components/Products/BurstDiscs/PinDown'
import PinDownHpht from '../../components/Products/BurstDiscs/PinDownHpht'

const ProductController = [

  {
    title: "INTERVENTIONLESS BURST DISC SUB",
    component: <InterventionlessBurstDisc title="INTERVENTIONLESS BURST DISC SUB" />,
  },
  {
    title: "BURST DISC SUB - DUAL HPHT",
    component: <BurstDiscSubDualHpht title="BURST DISC SUB - DUAL HPHT" />,
  },
  {
    title: "BURST DISC SUB - DUAL",
    component: <BurstDiscSubDual title="BURST DISC SUB - DUAL" />,
  },
  {
    title: "INTERVENTIONLESS BURST DISC SUB - MULE SHOE HPHT",
    component: <MuleShoeHpht title="INTERVENTIONLESS BURST DISC SUB - MULE SHOE HPHT" />,
  },
  {
    title: "INTERVENTIONLESS BURST DISC SUB - MULE SHOE",
    component: <MuleShoe title="INTERVENTIONLESS BURST DISC SUB - MULE SHOE" />,
  },
  {
    title: "INTERVENTIONLESS BURST DISC SUB - PIN DOWN",
    component: <PinDown title="INTERVENTIONLESS BURST DISC SUB - PIN DOWN" />,
  },
  {
    title: "INTERVENTIONLESS BURST DISC SUB - PIN DOWN HPHT",
    component: <PinDownHpht title="INTERVENTIONLESS BURST DISC SUB - PIN DOWN HPHT" />,
  },

  // --------------------
  
];

function BurstDisc() {
  const [burstDisc, setBurstDisc] = useState([]);
  const [currentProduct, setCurrentProduct] = useState("BURST DISC SUB - DUAL HPHT");
  const params = useParams(); 

  useEffect(() => {
    getBurstDiscTitlesService().then((result) => {
        setBurstDisc(result);
        setCurrentProduct(params.subLink)
        window.scrollTo(0, 0);
    });
  }, [params]);

  return (
    <div>
      <GeneralHeader title="Burst Discs" />
      <div className="product-section">
        <div className="product-btn-container">
          {burstDisc.map((burstDisc) => {
            return (
              <GeneralBtn
                onClick={(title) => setCurrentProduct(title)}
                title={burstDisc.title}
                selected={pseudoencodeURI(burstDisc.title) === currentProduct}
                baseURL={"/products/burst-discs"}
              />
            );
          })}
        </div>
        <div>
          {!currentProduct
            ? null
            : ProductController.find(
                (product) => pseudoencodeURI(product.title) === currentProduct
              )?.component}
        </div>
      </div>
      <ContactForm />
    </div>
  );
}

export default BurstDisc;
