import React from "react";
import ContactForm from "../../components/ContactForm/ContactForm";
import { useEffect, useState } from "react";
import { getPlugRetainersTitlesService } from "../../services/dept";
import PlugRetainersHeader from "../../components/Headers/PlugRetainersHeader";

import Db5BridgePlug from "../../components/Products/PlugRetainers/Db5BridgePlug";
import Db10BridgePlug from "../../components/Products/PlugRetainers/Db10BridgePlug";
import Hm1bBridgePlug from "../../components/Products/PlugRetainers/Hm1bBridgePlug";
import Hm2BridgePlug from "../../components/Products/PlugRetainers/Hm2BridgePlug";
import EcbCompBridgePlug from "../../components/Products/PlugRetainers/EcbCompBridgePlug";
import EcrCementRetainer from "../../components/Products/PlugRetainers/EcrCementRetainer";
import GeneralBtn from "../../components/ColumnBtns/GeneralBtn";
import EcrMechanicalSettingTool from "../../components/Products/PlugRetainers/EcrMechanicalSettingTool";
import EcrSnapLatch from "../../components/Products/PlugRetainers/EcrSnapLatch";
import ContactFiller from "../../components/Misc/ContactFiller";
import { useParams } from "react-router-dom";
import { pseudoencodeURI } from "./converter";

const ProductController = [
  //Deleted products
  // { title: "DB-5 BRIDGE PLUG", component: <Db5BridgePlug title="DB-5 BRIDGE PLUG" />},
  // { title: "DB10 BRIDGE PLUG", component: <Db10BridgePlug title="DB10 BRIDGE PLUG" />},
  { title: "HM-1B BRIDGE PLUG", component: <Hm1bBridgePlug title="HM-1B BRIDGE PLUG" />},
  { title: "HM-2 BRIDGE PLUG", component: <Hm2BridgePlug title="HM-2 BRIDGE PLUG" />},
  { title: "ECB COMPOSITE BRIDGE PLUG", component: <EcbCompBridgePlug title="ECB COMPOSITE BRIDGE PLUG" />},
  { title: "CR MECHANICAL SETTING TOOL", component: <EcrMechanicalSettingTool title="CR MECHANICAL SETTING TOOL" />},
  { title: "CR CEMENT RETAINER", component: <EcrCementRetainer title="CR CEMENT RETAINER" />},
  { title: "CR SNAP LATCH-J-LATCH STINGER", component: <EcrSnapLatch title="CR SNAP LATCH-J-LATCH STINGER" />},

]




function PlugRetainers() {
  const [plugRetainers, setPlugRetainers] = useState([]);
  const [currentProduct, setCurrentProduct] = useState("HM-1B BRIDGE PLUG");
  const params = useParams(); 

  useEffect(() => {
    getPlugRetainersTitlesService().then((result) => {
      setPlugRetainers(result);
      setCurrentProduct(params.subLink)
      window.scrollTo(0, 0);
    });
  }, [params]);

  return (
    <div>
      <PlugRetainersHeader />
      <div className="product-section">
        <div className="product-btn-container">
          {plugRetainers.map((plugRetainer) => {
            return (
              <GeneralBtn
                onClick={(title) => setCurrentProduct(title)}
                title={plugRetainer.title}
                selected={pseudoencodeURI(plugRetainer.title) === currentProduct}
                baseURL={"/products/plug-retainers"}
              />
            );
          })}
        </div>
        <div>

          {
            (!currentProduct) ? null : (
              ProductController.find((product) => pseudoencodeURI(product.title) === currentProduct)?.component
            )
          }

          {/* <Db5BridgePlug title="DB-5 BRIDGE PLUG" />
          <Db10BridgePlug title="DB10 BRIDGE PLUG" />
          <Hm1bBridgePlug title="HM-1B BRIDGE PLUG" />
          <Hm2BridgePlug title="HM-2 BRIDGE PLUG" />
          <EcbCompBridgePlug title="ECB COMPOSITE BRIDGE PLUG" />
          <EcrCementRetainer title="ECR CEMENT RETAINER" /> */}
        </div>
      </div>
      <ContactForm />
    </div>
  );
}

export default PlugRetainers;
