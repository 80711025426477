import React from 'react'
import {$,jQuery} from 'jquery';

function QtaAnchorTable() {
    
  return (
    <div>  
        <table id="example" class="table table-striped table-bordered" cellspacing="0" width="100%">
        <thead>
        <tr>
        <th colspan="2">CASING SIZE</th>
          <th colspan="2">CASING WEIGHT</th>
          <th colspan="2">THREAD CONNECTION</th>
          <th colspan="2">MAX OD</th>
          <th colspan="2">MIN ID</th>
        </tr>
        </thead>
 
        <tbody>
        <tr style={{backgroundColor: '#BCBEC0'}}>
        <td>in.</td>
          <td>mm</td>
          <td>lbs/ft</td>
          <td>kg/m</td>
          <td>in.</td>
          <td>mm</td>
          <td>in.</td>
          <td>mm</td>
          <td>in.</td>
          <td>mm</td>
        </tr>
        <tr>
          <td>4 <sup>1/2</sup></td>
          <td>114.3</td>
          <td>9.5-13.5</td>
          <td>14.1-20.1</td>
          <td>2 3/8 EUE</td>
          <td>60.3</td>
          <td>3.750</td>
          <td>95.25</td>
          <td>1.938</td>
          <td>49.23</td>
        </tr>
        <tr>
          <td>4 <sup>1/2</sup></td>
          <td>114.3</td>
          <td>13.5-15.1</td>
          <td>20.1-22.5</td>
          <td>2 3/8 EUE</td>
          <td>60.3</td>
          <td>3.656</td>
          <td>92.86</td>
          <td>1.938</td>
          <td>49.23</td>
        </tr>
        <tr>
          <td>5 <sup>1/2</sup></td>
          <td>139.7</td>
          <td>13.0-15.5</td>
          <td>19.2-23.1</td>
          <td>2 7/8 EUE</td>
          <td>73.0</td>
          <td>4.781</td>
          <td>121.44</td>
          <td>2.375</td>
          <td>60.30</td>
        </tr>
        <tr>
          <td>5 <sup>1/2</sup></td>
          <td>139.7</td>
          <td>15.5-20.0</td>
          <td>23.1-29.8</td>
          <td>2 7/8 EUE</td>
          <td>73.0</td>
          <td>4.625</td>
          <td>117.48</td>
          <td>2.375</td>
          <td>60.30</td>
        </tr>
        <tr>
          <td>5 <sup>1/2</sup></td>
          <td>139.7</td>
          <td>20.0-23.0</td>
          <td>29.8-34.2</td>
          <td>2 7/8 EUE</td>
          <td>73.0</td>
          <td>4.500</td>
          <td>114.3</td>
          <td>2.375</td>
          <td>60.30</td>
        </tr>
        <tr>
          <td>7</td>
          <td>177.8</td>
          <td>23.0-32.0</td>
          <td>34.2-47.6</td>
          <td>2 7/8 EUE</td>
          <td>73.0</td>
          <td>5.750</td>
          <td>146.05</td>
          <td>2.500</td>
          <td>63.50</td>
        </tr>
        <tr>
          <td>7</td>
          <td>177.8</td>
          <td>22.8-32.0</td>
          <td>34.0-47.6</td>
          <td>3 1/2 EUE</td>
          <td>88.9</td>
          <td>5.750</td>
          <td>146.05</td>
          <td>2.920</td>
          <td>74.17</td>
        </tr>
        </tbody>
        </table>
    </div>
  )
}

export default QtaAnchorTable