import "./navbar.css";
import axios from "axios";
import { useState, useEffect } from "react";
import MaverickLogoBlack from "../../assets/logos/maverick-logo-black.png";
import LinkedInWidget from "../Misc/LinkedinWidget";

import { Routes, Route, Link } from "react-router-dom";
import Clock from "../../assets/icons/clock.png";
import Phone from "../../assets/icons/phone.png";
import ProductsDropdown from "./ProductsDropdown";
import GlobalSearch from "./GlobalSearch";
import config from "../../config";
import ProductData from "../../Data.json";

function NavBar() {
  // const [ProductData, setProductData] = useState({})

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 200);
    });
  }, []);

  const [distributorvisible, setDistributorVisible] = useState(false);
  // useEffect(async () => {
  //   await axios.get(`${config.base_url}/fetchData`).then((res) => {
  //     setProductData(res)
  //   })
  // }, [])
  return (
    <header>
      <div className="nav-top">
        <div>
          <Link to="/">
            <img
              className="logo"
              src={MaverickLogoBlack}
              alt="Maverick Logo"
              title="Maverick Logo"
            />
          </Link>
        </div>
        <div>
          <div className="nav-contact">
            <img src={Clock} alt="clock" />
            <p>
              Available <br />
              <strong>24/7</strong>
            </p>
          </div>
          <div className="nav-contact">
            <img src={Phone} alt="phone" />
            <p>
              <a href="tel:5874026783">587-402-6783</a>
              <br />
              <a href="mailto:info@maverickdownhole.com?subject=Contact">
                <strong>info@maverickdownhole.com</strong>
              </a>
            </p>
          </div>
        </div>
        <div>
          <a href="#contact">
            <button className="main-btn nav-btn">Contact Us</button>
          </a>
          <i
            class="fa-solid fa-bars"
            id="hamburger"
            data-bs-toggle="offcanvas"
            href="#offcanvasExample"
            role="button"
            aria-controls="offcanvasExample"
          ></i>
        </div>
      </div>
      {/* <button id="WeirdButton2" onClick={() => {
                    // console.log("Clicked")
                    if (distributorvisible){
                      setDistributorVisible(false)
                    }
                  }}></button> */}
      <div className={scroll ? "nav-scrolled" : "just-nav"}>
        <div className="nav-bottom">
          <div className="nav-bottom-inner">
            <nav>
              <div className="nav-links-container">
                <Link to="/about">
                  <p className="nav-link">About</p>
                </Link>
                <p
                  className="nav-link"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Products
                </p>
                <div class="dropdown-menu">
                  <ProductsDropdown />
                </div>
                <Link to="/certifications">
                  <p className="nav-link">Certifications</p>
                </Link>
                {/* <p className="nav-link">Manufacturing</p> */}
                <Link to="/engineering">
                  <p className="nav-link">Engineering</p>
                </Link>
                <div className="distribotors">
                  {/* <p
                    className="nav-link"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Distributors
                  </p> */}
                  {/* <div class="dropdown-menu">
                    <div
                      className="distribotors-dropdown"
                      id="WeirdButton"
                      onClick={() => {
                        // console.log("Called")
                        setDistributorVisible(false);
                      }}
                    >
                      <Link to="alpha-oil-tools">
                        <div className="dropdown-item">
                          <h4 className="dropdown-heading">Alpha Oil Tools</h4>
                        </div>
                      </Link>
                      <Link to="d-and-l">
                        <div className="dropdown-item">
                          <h4 className="dropdown-heading">D&L Oil Tools</h4>
                        </div>
                      </Link>
                    </div>
                  </div> */}
                </div>
              </div>
              <div>
                <GlobalSearch data={ProductData} />
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

export default NavBar;
