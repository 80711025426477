import React from "react";
import { $, jQuery } from "jquery";

function GvCupPackerTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr>
            <th colSpan={2}>Casing</th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Recommended Hole Size (Inches)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Tool OD (Inches)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Tool ID (Inches)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Thread Connections Box Up / Pin Down
            </th>
            <th rowSpan={2}>Part Number</th>
          </tr>
          <tr>
            <th>Size (inches)</th>
            <th>Weight (lbs/ft)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>3-1/2</td>
            <td>9.2-10.3</td>
            <td>2.922-2.992</td>
            <td>2.810</td>
            <td>1.50</td>
            <td>1.900 EUE</td>
            <td>43935</td>
          </tr>
          <tr>
            <td rowSpan={3}>4-1/2</td>
            <td rowSpan={2}>9.5-13.5</td>
            <td rowSpan={2}>3.920-4.090</td>
            <td rowSpan={2}>3.750</td>
            <td>2.00</td>
            <td>2-3/8 EUE</td>
            <td>43945</td>
          </tr>
          <tr>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>43943946</td>
          </tr>
          <tr>
            <td>15.1</td>
            <td>3.826</td>
            <td>3.650</td>
            <td>2.00</td>
            <td>2-3/8 EUE</td>
            <td>43947</td>
          </tr>
          <tr>
            <td rowSpan={2}>5</td>
            <td>13.0-15.0</td>
            <td>4.408-4.494</td>
            <td>4.250</td>
            <td>2.00</td>
            <td>2-3/8 EUE</td>
            <td>43951</td>
          </tr>
          <tr>
            <td>18.0</td>
            <td>4.276</td>
            <td>4.130</td>
            <td>2.00</td>
            <td>2-3/8 EUE</td>
            <td>43950</td>
          </tr>
          <tr>
            <td rowSpan={4}>5-1/2</td>
            <td>13.0-20.0</td>
            <td>4.778-5.044</td>
            <td>4.625</td>
            <td>3.00</td>
            <td>3-1/2 EUE</td>
            <td>43953</td>
          </tr>
          <tr>
            <td>14.0-20.0</td>
            <td>4.778-5.012</td>
            <td>4.625</td>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>43956</td>
          </tr>
          <tr>
            <td>17.0-20.0</td>
            <td>4.778-4.892</td>
            <td>4.625</td>
            <td>2.00</td>
            <td>2-3/8 EUE</td>
            <td>43955</td>
          </tr>
          <tr>
            <td>20.0-26.0</td>
            <td>4.548-4.778</td>
            <td>4.375</td>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>43957</td>
          </tr>
          <tr>
            <td rowSpan={2}>6-5/8</td>
            <td>20.0</td>
            <td>6.049</td>
            <td>5.750</td>
            <td>3.00</td>
            <td>3-1/2 EUE</td>
            <td>43965</td>
          </tr>
          <tr>
            <td>20.0-24.0</td>
            <td>5.921-6.049</td>
            <td>5.750</td>
            <td>4.00</td>
            <td>4-1/2 EUE</td>
            <td>43966</td>
          </tr>
          <tr>
            <td rowSpan={4}>7</td>
            <td rowSpan={2}>17.0-29.0</td>
            <td rowSpan={2}>6.184-6.538</td>
            <td rowSpan={2}>6.000</td>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>43971</td>
          </tr>
          <tr>
            <td>3.00</td>
            <td>3-1/2 EUE</td>
            <td>43973</td>
          </tr>
          <tr>
            <td rowSpan={2}>20.0-29.0</td>
            <td rowSpan={2}>6.184-6.456</td>
            <td rowSpan={2}>6.000</td>
            <td>2.00</td>
            <td>2-3/8 EUE</td>
            <td>43993</td>
          </tr>
          <tr>
            <td>4.00</td>
            <td>4-1/2 LTC</td>
            <td>43974</td>
          </tr>
          <tr>
            <td rowSpan={2}>7-5/8</td>
            <td>24.0-26.4</td>
            <td>6.969-7.025</td>
            <td>6.750</td>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>43976</td>
          </tr>
          <tr>
            <td>29.7-33.7</td>
            <td>6.765-6.875</td>
            <td>6.375</td>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>43975</td>
          </tr>
          <tr>
            <td>8-5/8</td>
            <td>28.0-36.0</td>
            <td>7.825-8.017</td>
            <td>7.375</td>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>43985</td>
          </tr>
          <tr>
            <td>9-5/8</td>
            <td>40.0-47.0</td>
            <td>8.681-8.835</td>
            <td>8.450</td>
            <td>3.00</td>
            <td>3-1/2 EUE</td>
            <td>43995</td>
          </tr>
          <tr>
            <td>10-3/4</td>
            <td>40.5-55.5</td>
            <td>9.760-10.050</td>
            <td>9.710</td>
            <td>4.00</td>
            <td>4-1/2 LTC</td>
            <td>4391075</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default GvCupPackerTable;
