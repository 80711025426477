import React, { useEffect, useState } from "react";
import { getAllProductService } from "../../../services/dept";
import { Helmet } from 'react-helmet';
import JsPDF from 'jspdf';
import EsuRbpRetrievableBridgePlugImg from '../../../assets/images/product-images/service-tools/esu-bridge-plug.jpg'
import EsoRetrievablePacker  from './Tables/EsoRetrievableTable'

function EsuRetrievable(props) {

  const generatePDF = () => {

    const report = new JsPDF('portrait','pt','a4');
    report.html(document.querySelector('.product-info')).then(() => {
        report.save(`${title}.pdf`);
    });
  }

  const { title } = props;
  const [products, setProducts] = useState([]);
  const [currentProduct, setCurrentProduct] = useState({});

  useEffect(() => {
    getAllProductService().then((loadedProducts) => {
      setProducts(loadedProducts);
      setCurrentProduct(
        loadedProducts.find((product) => product.title === title)
      );
    });
  }, []);

  // console.log(currentProduct);
  return (
    <div className="product-info">
      <Helmet>‍
      <title>{`${title} | Maverick Downhole Technologies | Edmonton, AB`}</title>
      <script type="application/ld+json">
      {JSON.stringify({
      "@context": "https://schema.org/", 
      "@type": "Product", 
      "name": "ESU-RBP RETRIEVABLE BRIDGE PLUG",
      "image": {EsuRbpRetrievableBridgePlugImg},
      "description": "Effortless Connection to a Wireline Assembly. The Wireline Adapter Kit is used to attach wireline set packers or any other compatible wireline set tools to the 10/20 setting tool.",
      "brand": {
        "@type": "Brand",
        "name": "Maverick Downhole Technologies"
      }
      })}
      </script>
      </Helmet>
      <div>
        <h3 className="main-subhead">
          {currentProduct !== {} ? currentProduct.title : null}
        </h3>
        <h4 className="smaller-head">
          {currentProduct !== {} ? currentProduct.subhead : null}
        </h4>
        <div className="spacer"></div>
        <p className="body-text">
          {currentProduct !== {} ? currentProduct.description : null}
        </p>
        <button onClick={generatePDF} type="button" className="main-btn">Download PDF</button><br /><br />
        <EsoRetrievablePacker />
      </div>
      <div>
        <img
          src={EsuRbpRetrievableBridgePlugImg}
          title={currentProduct.title}
          alt={currentProduct.title}
          className="product-img"
        />
      </div>
    </div>
  );
}

export default EsuRetrievable;
