import React from 'react'
import ProductsHome from '../../components/Home/ProductsHome/ProductsHome'
import ContactForm from '../../components/ContactForm/ContactForm'
import OtherHeader from '../../components/Headers/OtherHeader'

function AllProducts() {
  return (
    <div>
        <OtherHeader title="All Products" />
        <ProductsHome />
        <ContactForm />
    </div>
  )
}

export default AllProducts