import React from "react";
import { $, jQuery } from "jquery";

function CasingPackerTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr>
            <th colSpan={2}>Casing</th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Recommended Hole Size (Inches)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Tool OD (Inches)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Tool ID (Inches)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Thread Connections Box Up / Pin Down
            </th>
            <th rowSpan={2}>Part Number</th>
          </tr>
          <tr>
            <th>Size (inches)</th>
            <th>Weight (lbs/ft)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan={2}>4-1/2</td>
            <td>9.5-13.5</td>
            <td>3.920-4.090</td>
            <td>3.750</td>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>44145</td>
          </tr>
          <tr>
            <td>15.1</td>
            <td>3.826</td>
            <td>3.750</td>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>44144</td>
          </tr>
          <tr>
            <td rowSpan={2}>5</td>
            <td>11.5-20.3</td>
            <td>4.184-4.560</td>
            <td>4.000</td>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>44150</td>
          </tr>
          <tr>
            <td>18.0-21.4</td>
            <td>4.126-4.276</td>
            <td>3.875</td>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>44151</td>
          </tr>
          <tr>
            <td rowSpan={2}>5-1/2</td>
            <td>13.0-17.0</td>
            <td>4.892-5.044</td>
            <td>4.650</td>
            <td>3.50</td>
            <td>4" NUE</td>
            <td>44156</td>
          </tr>
          <tr>
            <td>13.0-20.0</td>
            <td>4.778-5.044</td>
            <td>4.625</td>
            <td>3.00</td>
            <td>3-1/2 EUE</td>
            <td>44155</td>
          </tr>
          <tr>
            <td>5-3/4</td>
            <td>14.0-17.0</td>
            <td>5.190-5.290</td>
            <td>4.875</td>
            <td>3.50</td>
            <td>4-1/2 EUE</td>
            <td>44157</td>
          </tr>
          <tr>
            <td>6</td>
            <td>18.0-23.0</td>
            <td>5.240-5.424</td>
            <td>5.000</td>
            <td>3.50</td>
            <td>4" NUE</td>
            <td>44160</td>
          </tr>
          <tr>
            <td>6-5/8</td>
            <td>20.0-28.0</td>
            <td>5.791-6.049</td>
            <td>6.625</td>
            <td>4.00</td>
            <td>4-1/2 LTC</td>
            <td>44165</td>
          </tr>
          <tr>
            <td rowSpan={3}>7</td>
            <td>17.0-26.0</td>
            <td>6.276-6.538</td>
            <td>6.000</td>
            <td>4.75</td>
            <td>5-1/2 LTC</td>
            <td>44172</td>
          </tr>
          <tr>
            <td>17.0-38.0</td>
            <td>5.920-6.538</td>
            <td>5.750</td>
            <td>4.00</td>
            <td>4-1/2 LTC</td>
            <td>44170</td>
          </tr>
          <tr>
            <td>20.0-29.0</td>
            <td>6.184-6.456</td>
            <td>5.969</td>
            <td>4.50</td>
            <td>5" LTC</td>
            <td>44171</td>
          </tr>
          <tr>
            <td rowSpan={2}>7-5/8</td>
            <td rowSpan={2}>20.0-33.7</td>
            <td rowSpan={2}>6.765-7.125</td>
            <td rowSpan={2}>6.500</td>
            <td>4.00</td>
            <td>4-1/2 LTC</td>
            <td>44175</td>
          </tr>
          <tr>
            <td>5.00</td>
            <td>5-1/2 LTC</td>
            <td>44176</td>
          </tr>
          <tr>
            <td rowSpan={4}>8-5/8</td>
            <td rowSpan={2}>24.0-36.0</td>
            <td rowSpan={2}>7.825-8.097</td>
            <td rowSpan={2}>7.656</td>
            <td>6.00</td>
            <td>6-5/8 LTC</td>
            <td>44186</td>
          </tr>
          <tr>
            <td>6.50</td>
            <td>7" LTC</td>
            <td>44187</td>
          </tr>
          <tr>
            <td rowSpan={2}>24.0-40.0</td>
            <td rowSpan={2}>7.825-8.097</td>
            <td rowSpan={2}>7.500</td>
            <td>4.15</td>
            <td>4-1/2 LTC</td>
            <td>44184</td>
          </tr>
          <tr>
            <td>5.00</td>
            <td>5-1/2 LTC</td>
            <td>44185</td>
          </tr>
          <tr>
            <td rowSpan={2}>9-5/8</td>
            <td>32.3-43.5</td>
            <td>8.755-9.001</td>
            <td>8.500</td>
            <td>6.50</td>
            <td>7" LTC</td>
            <td>44195</td>
          </tr>
          <tr>
            <td>43.5-53.5</td>
            <td>8.535-8.755</td>
            <td>8.250</td>
            <td>6.50</td>
            <td>7" LTC</td>
            <td>44196</td>
          </tr>
          <tr>
            <td>10-3/4</td>
            <td>32.75-55.5</td>
            <td>9.760-10.192</td>
            <td>9.500</td>
            <td>6.50</td>
            <td>7" LTC</td>
            <td>44110</td>
          </tr>
          <tr>
            <td>11-3/4</td>
            <td>420-65.0</td>
            <td>10.682-11.084</td>
            <td>10.500</td>
            <td>8.00</td>
            <td>8-5/8 LTC</td>
            <td>44111</td>
          </tr>
          <tr>
            <td>13-3/8</td>
            <td>48.0-77.0</td>
            <td>12.275-12.715</td>
            <td>12.000</td>
            <td>8.75</td>
            <td>9-5/8 LTC</td>
            <td>44113</td>
          </tr>
          <tr>
            <td>18-5/8</td>
            <td>87.5-117.5</td>
            <td>17.439-17.755</td>
            <td>17.000</td>
            <td>12.50</td>
            <td>13-3/8 STC</td>
            <td>44118</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default CasingPackerTable;
