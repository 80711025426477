import React from 'react'
import {$,jQuery} from 'jquery';

function EcDragBlockTable() {
    
  return (
    <div>  
        <table id="example" class="table table-striped table-bordered" cellspacing="0" width="100%">
        <thead>
        <tr>
          <th colspan="6">CASING</th>
          <th colspan="6">ANCHOR</th>
        </tr>
        <tr>
          <th colspan="2">MAX OD</th>
          <th colspan="2">WEIGHT</th>
          <th colspan="2">MIN ID</th>
          <th colspan="2">OD</th>
          <th colspan="2">ID</th>
          <th colspan="1">THREAD CONNECTION</th>
        </tr>
        </thead>
 
        <tbody>
            <tr style={{backgroundColor: '#BCBEC0'}}>
                <td>in.</td>
                <td>mm</td>
                <td>lbs/ft</td>
                <td>kg/m</td>
                <td>in.</td>
                <td>mm</td>
                <td>in.</td>
                <td>mm</td>
                <td>in.</td>
                <td>mm</td>
                <td>in. (mm)</td>
            </tr>

            <tr>
          <td>5</td>
          <td>127.0</td>
          <td>11.5-18.0</td>
          <td>17.1-26.8</td>
          <td>4.276</td>
          <td>108.6</td>
          <td>4.12</td>
          <td>104.60</td>
          <td>1.93</td>
          <td>49.0</td>
          <td>2 3/8 (60.3 mm) EUE</td>
        </tr>
        <tr>
          <td>5 <sup>1/2</sup></td>
          <td>139.7</td>
          <td>20.0-26.0</td>
          <td>29.8-38.7</td>
          <td>4.548</td>
          <td>115.5</td>
          <td>4.50</td>
          <td>114.30</td>
          <td>1.93</td>
          <td>49.0</td>
          <td>2 3/8 (60.3 mm) EUE</td>
        </tr>
        <tr>
          <td>5 <sup>1/2</sup></td>
          <td>139.7</td>
          <td>13.0-17.0</td>
          <td>19.3-25.3</td>
          <td>4.892</td>
          <td>124.3</td>
          <td>4.62</td>
          <td>117.30</td>
          <td>1.93</td>
          <td>49.0</td>
          <td>2 3/8 (60.3 mm) EUE</td>
        </tr>
        <tr>
          <td>5 <sup>1/2</sup></td>
          <td>139.7</td>
          <td>20.0-26.0</td>
          <td>29.8-38.7</td>
          <td>4.548</td>
          <td>115.5</td>
          <td>4.50</td>
          <td>114.30</td>
          <td>2.44</td>
          <td>62.0</td>
          <td>2 7/8 (73.0 mm) EUE</td>
        </tr>
        <tr>
          <td>5 <sup>1/2</sup></td>
          <td>139.7</td>
          <td>13.0-17.0</td>
          <td>19.3-25.3</td>
          <td>4.892</td>
          <td>124.3</td>
          <td>4.62</td>
          <td>117.30</td>
          <td>2.44</td>
          <td>62.0</td>
          <td>2 7/8 (73.0 mm) EUE</td>
        </tr>
        <tr>
          <td>7</td>
          <td>177.8</td>
          <td>23.0-38.0</td>
          <td>34.2-56.6</td>
          <td>5.920</td>
          <td>150.4</td>
          <td>5.88</td>
          <td>149.40</td>
          <td>2.44</td>
          <td>62.0</td>
          <td>2 7/8 (73.0 mm) EUE</td>
        </tr>
        <tr>
          <td>7</td>
          <td>177.8</td>
          <td>17.0-20.0</td>
          <td>25.3-29.8</td>
          <td>6.456</td>
          <td>164.0</td>
          <td>6.25</td>
          <td>158.70</td>
          <td>2.99</td>
          <td>76.0</td>
          <td>3 1/2 (88.9 mm) EUE</td>
        </tr>
        <tr>
          <td>8 <sup>5/8</sup></td>
          <td>219.1</td>
          <td>24.0-49.0</td>
          <td>35.7-72.9</td>
          <td>7.511</td>
          <td>190.8</td>
          <td>7.00</td>
          <td>177.80</td>
          <td>3.00</td>
          <td>76.2</td>
          <td>3 1/2 (88.9 mm) EUE</td>
        </tr>
        <tr>
          <td>9 <sup>5/8</sup></td>
          <td>244.5</td>
          <td>32.4-47.0</td>
          <td>48.1-69.9</td>
          <td>8.681</td>
          <td>220.5</td>
          <td>8.25</td>
          <td>209.60</td>
          <td>3.00</td>
          <td>76.2</td>
          <td>3 1/2 (88.9 mm) EUE</td>
        </tr>
        </tbody>
        </table>
    </div>
  )
}

export default EcDragBlockTable