import React from 'react'
import {$,jQuery} from 'jquery';

function AsiiPackerTable() {
    
  return (
    <div>  
        <table id="example" class="table table-striped table-bordered" cellspacing="0" width="100%">
        <thead>
            <tr>
                <th colSpan={2}>Casing</th>
                <th rowSpan={2} style={{backgroundColor: 'black'}}>Recommended Hole Size (Inches)</th>
                <th rowSpan={2} style={{backgroundColor: 'black'}}>Gage OD (Inches)</th>
                <th rowSpan={2} style={{backgroundColor: 'black'}}>Max OD (Inches)</th>
                <th rowSpan={2} style={{backgroundColor: 'black'}}>Min ID (Inches)</th>
                <th rowSpan={2} style={{backgroundColor: 'black'}}>Thread Connections Box Up / Pin Down</th>
                <th rowSpan={2}>Part Number</th>
            </tr>
            <tr>
                <th>Size (inches)</th>
                <th>Weight (lbs/ft)</th>
            </tr>
        </thead>
        <tbody>
           <tr>
              <td>2-7/8</td>
              <td>6.4-6.5</td>
              <td>2.375-2.441</td>
              <td>2.250</td>
              <td>2.263</td>
              <td>0.62</td>
              <td>1.050 EUE</td>
              <td>61125</td>
           </tr>
           <tr>
              <td rowSpan={2}>3-1/2</td>
              <td rowSpan={2}>7.7-10.2</td>
              <td rowSpan={2}>2.922-3.068</td>
              <td>2.781</td>
              <td>-</td>
              <td>1.25</td>
              <td>1.900 EUE</td>
              <td>61135</td>
           </tr>
           <tr>
              <td>2.938</td>
              <td>-</td>
              <td>1.25</td>
              <td>1.900 EUE</td>
              <td>61136</td>
           </tr>
           <tr>
              <td>4</td>
              <td>9.5-11.0</td>
              <td>3.476-3.548</td>
              <td>3.250</td>
              <td>3.312<sup>1</sup></td>
              <td>1.50</td>
              <td>1.900 EUE</td>
              <td>61140</td>
           </tr>
           <tr>
              <td rowSpan={2}>4-1/2</td>
              <td>9.5-13.5</td>
              <td>3.920-4.090</td>
              <td>3.750</td>
              <td>-</td>
              <td>1.94</td>
              <td>2-3/8 EUE</td>
              <td>61145</td>
           </tr>
           <tr>
              <td>15.1</td>
              <td>3.826</td>
              <td>3.656</td>
              <td>-</td>
              <td>1.94</td>
              <td>2-3/8 EUE</td>
              <td>61144</td>
           </tr>
           <tr>
              <td rowSpan={2}>5</td>
              <td>11.5-18.0</td>
              <td>4.276-4.560</td>
              <td>4.125</td>
              <td>4.220<sup>1</sup></td>
              <td>1.94</td>
              <td>2-3/8 EUE</td>
              <td>61150</td>
           </tr>
           <tr>
              <td>18.0-21.4</td>
              <td>4.126-4.276</td>
              <td>4.000</td>
              <td>-</td>
              <td>1.94</td>
              <td>2-3/8 EUE</td>
              <td>61152</td>
           </tr>
           <tr>
              <td rowSpan={4}>5-1/2</td>
              <td>14.0-17.0</td>
              <td>4.892-5.012</td>
              <td>4.625</td>
              <td>-</td>
              <td>2.00</td>
              <td>2-3/8 EUE</td>
              <td>61155</td>
           </tr>
           <tr>
              <td>14.0-20.0</td>
              <td>4.778-5.012</td>
              <td>4.625</td>
              <td>-</td>
              <td>2.38</td>
              <td>2-7/8 EUE</td>
              <td>61156</td>
           </tr>
           <tr>
              <td rowSpan={2}>20.0-23.0</td>
              <td rowSpan={2}>4.670-4.778</td>
              <td rowSpan={2}>4.500</td>
              <td>-</td>
              <td>2.00</td>
              <td>2-3/8 EUE</td>
              <td>61157</td>
           </tr>
           <tr>
              <td>-</td>
              <td>2.38</td>
              <td>2-7/8 EUE</td>
              <td>61159</td>
           </tr>
           <tr>
              <td>5-3/4</td>
              <td>17.6-19.4</td>
              <td>4.958-5.021</td>
              <td>4.875</td>
              <td>-</td>
              <td>2.38</td>
              <td>2-7/8 EUE</td>
              <td>61158Y</td>
           </tr>
           <tr>
              <td rowSpan={2}>6-5/8</td>
              <td>17.0-24.0</td>
              <td>5.921-6.135</td>
              <td>5.750</td>
              <td>-</td>
              <td>2.50</td>
              <td>2-7/8 EUE</td>
              <td>61167</td>
           </tr>
           <tr>
              <td>24.0-32.0</td>
              <td>5.675-5.921</td>
              <td>5.500</td>
              <td>-</td>
              <td>2.50</td>
              <td>2-7/8 EUE</td>
              <td>61165</td>
           </tr>
           <tr>
              <td rowSpan={4}>7</td>
              <td>17.0-26.0</td>
              <td>6.276-6.538</td>
              <td>6.000</td>
              <td>6.062<sup>1</sup></td>
              <td>2.50</td>
              <td>2-7/8 EUE</td>
              <td>61172</td>
           </tr>
           <tr>
              <td rowSpan={2}>26.0-32.0</td>
              <td rowSpan={2}>6.094-6.276</td>
              <td rowSpan={2}>5.875</td>
              <td>-</td>
              <td>2.50</td>
              <td>2-7/8 EUE</td>
              <td>61170</td>
           </tr>
           <tr>
              <td>5.936<sup>1</sup></td>
              <td>3.00</td>
              <td>3-1/2 EUE</td>
              <td>61173</td>
           </tr>
           <tr>
              <td>29.0-35.0</td>
              <td>6.004-6.184</td>
              <td>5.812</td>
              <td>-</td>
              <td>2.50</td>
              <td>2-7/8 EUE</td>
              <td>61171</td>
           </tr>
           <tr>
              <td>7-5/8</td>
              <td>33.7-39.0</td>
              <td>6.625-6.765</td>
              <td>6.453</td>
              <td>-</td>
              <td>3.00</td>
              <td>3-1/2 EUE</td>
              <td>61177</td>
           </tr>
           <tr>
              <td rowSpan={4}>9-5/8</td>
              <td rowSpan={2}>32.3-43.5</td>
              <td rowSpan={2}>8.755-9.001</td>
              <td rowSpan={2}>8.500</td>
              <td>-</td>
              <td>3.00</td>
              <td>3-1/2 EUE</td>
              <td>61198</td>
           </tr>
           <tr>
               <td>-</td>
              <td>4.00</td>
              <td>4-1/2 EUE</td>
              <td>61196</td>
           </tr>
           <tr>
              <td rowSpan={2}>43.5-53.5</td>
              <td rowSpan={2}>8.535-8.755</td>
              <td rowSpan={2}>8.250</td>
              <td>-</td>
              <td>3.00</td>
              <td>3-1/2 EUE</td>
              <td>61197</td>
           </tr>
           <tr>
               <td>-</td>
              <td>4.00</td>
              <td>4-1/2 EUE</td>
              <td>61195</td>
           </tr>
           <tr>
            <td>13-3/8</td>
            <td>54.5-77.0</td>
            <td>12.275-12.615</td>
            <td>12.000</td>
            <td>-</td>
            <td>4.00</td>
            <td>4-1/2</td>
            <td>61113</td>
           </tr>
        </tbody>
        </table>
    </div>
  )
}

export default AsiiPackerTable