import React from "react";
import { $, jQuery } from "jquery";

function EfvFracBallValveTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr>
            <th colspan="2">TUBING SIZE</th>
            <th colspan="2">MAX OD</th>
            <th colspan="2">MIN ID</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>in.</td>
            <td>mm</td>
            <td>in.</td>
            <td>mm</td>
            <td>in.</td>
            <td>mm</td>
          </tr>
          <tr>
            <td>2 3/8 EUE</td>
            <td>60.3</td>
            <td>3.771</td>
            <td>95.78</td>
            <td>1.750</td>
            <td>44.45</td>
          </tr>
          <tr>
            <td>2 7/8 EUE</td>
            <td>73.0</td>
            <td>5.500</td>
            <td>127.00</td>
            <td>2.500</td>
            <td>25.70</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default EfvFracBallValveTable;
