import React from "react";
import { $, jQuery } from "jquery";

function DAIIUnloaderTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c150">
            <td class="c561" colspan="1" rowspan="1">
              Size (inches)
            </td>
            <td class="c434" colspan="1" rowspan="1">
              Tool OD (inches)
            </td>
            <td class="c13" colspan="1" rowspan="1">
              Tool ID (inches)
            </td>
            <td class="c629" colspan="1" rowspan="1">
              Thread Connections Box Up / Pin Down
            </td>
            <td class="c288" colspan="1" rowspan="1">
              Part Number
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="c59">
            <td class="c561" colspan="1" rowspan="1">
              4-1/2
            </td>
            <td class="c434" colspan="1" rowspan="1">
              3.750
            </td>
            <td class="c13" colspan="1" rowspan="1">
              2.00
            </td>
            <td class="c629" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c288" colspan="1" rowspan="1">
              53145
            </td>
          </tr>
          <tr class="c59">
            <td class="c561" colspan="1" rowspan="1">
              5-1/2
            </td>
            <td class="c434" colspan="1" rowspan="1">
              4.500
            </td>
            <td class="c13" colspan="1" rowspan="1">
              2.50
            </td>
            <td class="c629" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c288" colspan="1" rowspan="1">
              53155
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default DAIIUnloaderTable;
