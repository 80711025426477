import React from "react";
import { $, jQuery } from "jquery";

function PinDown() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c18">
            <td class="c39" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c11">Size</span>
              </p>
            </td>
            <td class="c39" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c11">OD</span>
              </p>
            </td>
            <td class="c41" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c11">ID</span>
              </p>
            </td>
            <td class="c20" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c11">Disc Rating Above</span>
              </p>
            </td>
            <td class="c20" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c11">Disc Rating Below</span>
              </p>
            </td>
            <td class="c40" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c11">Temperature Rating</span>
              </p>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              in <br />mm
            </td>
            <td>
              in <br />mm
            </td>
            <td>
              in <br />mm
            </td>
            <td>
              psi <br />MPa
            </td>
            <td>
              psi <br />MPa
            </td>
            <td>
            &deg;C <br />&deg;F
            </td>
          </tr>
          <tr>
            <td>2.375<br />60.3</td>
            <td>2.910<br />73.91</td>
            <td>1.995<br />50.67</td>
            <td>1,000<br />6.9</td>
            <td>10,000<br />68.9</td>
            <td>302<br />150</td>
          </tr>
          <tr>
            <td>2.875<br />73.0</td>
            <td>3.460<br />87.88</td>
            <td>2.441<br />62.00</td>
            <td>1,000<br />6.9</td>
            <td>10,000<br />68.9</td>
            <td>302<br />150</td>
          </tr>
          <tr>
            <td>3.500<br />88.9</td>
            <td>4.180<br />106.17</td>
            <td>2.992<br />76.00</td>
            <td>1,000<br />6.9</td>
            <td>10,000<br />68.9</td>
            <td>302<br />150</td>
          </tr>
          <tr>
            <td>4.500<br />114.3</td>
            <td>5.750<br />146.05</td>
            <td>3.930<br />99.82</td>
            <td>1,000<br />6.9</td>
            <td>15,000<br />104.8</td>
            <td>320<br />160</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default PinDown;
