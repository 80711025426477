import React from "react";
import { $, jQuery } from "jquery";

function CtThermalPackerTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr>
            <th colSpan={2}>Casing</th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Recommended Hole Size (Inches)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Gage OD (Inches)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Max OD (Inches)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Min ID (Inches)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Thread Connections Box Up / Pin Down
            </th>
            <th rowSpan={2}>Part Number</th>
          </tr>
          <tr>
            <th>Size (inches)</th>
            <th>Weight (lbs/ft)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan={2}>5-1/2</td>
            <td>13.0-17.0</td>
            <td>4.892-5.044</td>
            <td>4.750</td>
            <td>-</td>
            <td>2.00</td>
            <td>2-3/8 EUE</td>
            <td>74055</td>
          </tr>
          <tr>
            <td>20.0-23.0</td>
            <td>4.670-4.778</td>
            <td>4.500</td>
            <td>-</td>
            <td>2.00</td>
            <td>2-3/8 EUE</td>
            <td>74056</td>
          </tr>
          <tr>
            <td rowSpan={2}>6-5/8</td>
            <td>17.0-24.0</td>
            <td>5.921-6.135</td>
            <td>5.750</td>
            <td>-</td>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>74066</td>
          </tr>
          <tr>
            <td>24.0-32.0</td>
            <td>5.675-5.921</td>
            <td>5.500</td>
            <td>-</td>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>74065</td>
          </tr>
          <tr>
            <td rowSpan={4}>7</td>
            <td rowSpan={2}>17.0-26.0</td>
            <td rowSpan={2}>6.276-6.538</td>
            <td rowSpan={2}>6.000</td>
            <td rowSpan={2}>6.062<sup>1</sup></td>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>74072</td>
          </tr>
          <tr>
            <td>3.00</td>
            <td>3-1/2 EUE</td>
            <td>74074</td>
          </tr>
          <tr>
            <td rowSpan={2}>26.0-32.0</td>
            <td rowSpan={2}>6.094-6.276</td>
            <td rowSpan={2}>5.875</td>
            <td>-</td>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>74070</td>
          </tr>
          <tr>
            <td>-</td>
            <td>3.00</td>
            <td>3-1/2 EUE</td>
            <td>74073</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default CtThermalPackerTable;
