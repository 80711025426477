import React from "react";
import { $, jQuery } from "jquery";

function GasVentTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr>
            <th colspan="2">CASING</th>
            <th colspan="1" rowspan="2">RECOMMENDED HOLE SIZE<br />(inches)</th>
            <th colspan="1" rowspan="2">TOOL OD<br />(inches)</th>
            <th colspan="1" rowspan="2">TOOL ID<br />(inches)</th>
            <th colspan="1" rowspan="2">THREAD CONNECTION<br />Box Up/ Pin Down</th>
            <th colspan="1" rowspan="2">Part<br />Number</th>
          </tr>
          <tr>
          <th>SIZE (inches)</th>
          <th>WEIGHT (lbs/ft)</th>
        </tr>
          </thead>

        <tbody>  
        <tr>
          <td rowspan="2">4-1/2</td>
          <td>9.5-13.5</td>
          <td>3.920-4.090</td>
          <td>3.750</td>
          <td>1.94</td>
          <td>2 3/8 EUE</td>
          <td>93545</td>
        </tr>
        <tr>
          <td>15.1</td>
          <td>3.826</td>
          <td>3.656</td>
          <td>1.94</td>
          <td>2 3/8 EUE</td>
          <td>93456</td>
        </tr>
        <tr>
          <td rowspan="2">5</td>
          <td>11.5-15.0</td>
          <td>4.408-4.560</td>
          <td>4.125</td>
          <td>1.94</td>
          <td>2 3/8 EUE</td>
          <td>93550</td>
        </tr>
        <tr>
          <td>18.0-21.0</td>
          <td>4.154-4.276</td>
          <td>4.000</td>
          <td>1.94</td>
          <td>2 3/8 EUE</td>
          <td>93552</td>
        </tr>
        <tr>
          <td rowspan="5">5-1/2</td>
          <td>13.0-15.5</td>
          <td>4.950-5.044</td>
          <td>4.813</td>
          <td>2.38</td>
          <td>2 7/8 EUE</td>
          <td>93558</td>
        </tr>
        <tr>
          <td rowspan="2">14.0-20.0</td>
          <td rowspan="2">4.778-5.012</td>
          <td rowspan="2">4.626</td>
          <td>2.00</td>
          <td>2 3/8 EUE</td>
          <td>93555</td>
        </tr>
        <tr>
          <td>2.38</td>
          <td>2 7/8 EUE</td>
          <td>93556</td>
        </tr>
        <tr>
          <td rowspan="2">20.0-23.0</td>
          <td rowspan="2">4.670-4.778</td>
          <td rowspan="2">4.500</td>
          <td>2.00</td>
          <td>2 3/8 EUE</td>
          <td>93557</td>
        </tr>
        <tr>
          <td>2.38</td>
          <td>2 7/8 EUE</td>
          <td>93559</td>
        </tr>
        <tr>
          <td rowspan="2">6</td>
          <td>12.0-20.0</td>
          <td>5.352-5.620</td>
          <td>5.188</td>
          <td>2.38</td>
          <td>2 7/8 EUE</td>
          <td>93561</td>
        </tr>
        <tr>
          <td>17.0-23.0</td>
          <td>5.240-5.450</td>
          <td>5.063</td>
          <td>2.38</td>
          <td>2 7/8 EUE</td>
          <td>93560</td>
        </tr>
        <tr>
          <td rowspan="4">6-5/8</td>
          <td rowspan="2">17.0-24.0</td>
          <td rowspan="2">5.921-6.135</td>
          <td rowspan="2">5.750</td>
          <td>2.50</td>
          <td>2 7/8 EUE</td>
          <td>93567</td>
        </tr>
        <tr>
          <td>3.00</td>
          <td>3 1/2 EUE</td>
          <td>93568</td>
        </tr>
        <tr>
          <td rowspan="2">24.0-32.0</td>
          <td rowspan="2">5.675-5.921</td>
          <td rowspan="2">5.500</td>
          <td>2.50</td>
          <td>2 7/8 EUE</td>
          <td>93566</td>
        </tr>
        <tr>
          <td>3.00</td>
          <td>3 1/2 EUE</td>
          <td>93565</td>
        </tr>
        <tr>
          <td rowspan="6">7</td>
          <td rowspan="2">17.0-26.0</td>
          <td rowspan="2">6.276-7.025</td>
          <td rowspan="2">6.000</td>
          <td>2.50</td>
          <td>2 7/8 EUE</td>
          <td>93572</td>
        </tr>
        <tr>
          <td>3.00</td>
          <td>3 1/2 EUE</td>
          <td>93574</td>
        </tr>
        <tr>
          <td rowspan="2">26.0-32.0</td>
          <td rowspan="2">6.094-6.276</td>
          <td rowspan="2">5.875</td>
          <td>2.50</td>
          <td>2 7/8 EUE</td>
          <td>93570</td>
        </tr>
        <tr>
          <td>3.00</td>
          <td>3 1/2 EUE</td>
          <td>93573</td>
        </tr>
        <tr>
          <td rowspan="2">29.0-35.0</td>
          <td rowspan="2">6.004-6.184</td>
          <td rowspan="2">5.812</td>
          <td>2.50</td>
          <td>2 7/8 EUE</td>
          <td>93571</td>
        </tr>
        <tr>
          <td>3.00</td>
          <td>3 1/2 EUE</td>
          <td>93573HW</td>
        </tr>
        <tr>
          <td rowspan="5">7-5/8</td>
          <td rowspan="2">24.0-29.7</td>
          <td rowspan="2">6.875-7.025</td>
          <td rowspan="2">6.672</td>
          <td>2.50</td>
          <td>2 7/8 EUE</td>
          <td>93575</td>
        </tr>
        <tr>
          <td>3.00</td>
          <td>3 1/2 EUE</td>
          <td>93578</td>
        </tr>
        <tr>
          <td>29.7-33.7</td>
          <td>6.765-6.875</td>
          <td>6.563</td>
          <td>3.00</td>
          <td>3 1/2 EUE</td>
          <td>93578A</td>
        </tr>
        <tr>
          <td rowspan="2">33.7-39.0</td>
          <td rowspan="2">6.625-6.765</td>
          <td rowspan="2">6.453</td>
          <td>2.50</td>
          <td>2 7/8 EUE</td>
          <td>93576</td>
        </tr>
        <tr>
          <td>3.00</td>
          <td>3 1/2 EUE</td>
          <td>93577</td>
        </tr>
        <tr>
          <td>8-5/8</td>
          <td>24.0-40.0</td>
          <td>7.725-8.097</td>
          <td>7.500</td>
          <td>4.00</td>
          <td>4 1/2 EUE</td>
          <td>93585</td>
        </tr>
        <tr>
          <td rowspan="6">9-5/8</td>
          <td rowspan="3">32.3-43.5</td>
          <td rowspan="3">8.755-9.001</td>
          <td rowspan="3">8.500</td>
          <td>2.50</td>
          <td>2 7/8 EUE</td>
          <td>93596-WBAC</td>
        </tr>
        <tr>
          <td>3.00</td>
          <td>3 1/2 EUE</td>
          <td>93596-WBAE</td>
        </tr>
        <tr>
          <td>4.00</td>
          <td>4 1/2 EUE</td>
          <td>93596</td>
        </tr>
        <tr>
          <td rowspan="3">43.5-53.5</td>
          <td rowspan="3">8.535-8.755</td>
          <td rowspan="3">8.250</td>
          <td>2.50</td>
          <td>2 7/8 EUE</td>
          <td>93595-XBAC</td>
        </tr>
        <tr>
          <td>3.00</td>
          <td>3 1/2 EUE</td>
          <td>93595-WBAE</td>
        </tr>
        <tr>
          <td>4.00</td>
          <td>4 1/2 EUE</td>
          <td>93595</td>
        </tr>
        <tr>
          <td>10-3/4</td>
          <td>51.0-65.7</td>
          <td>9.560-9.850</td>
          <td>9.312</td>
          <td>4.00</td>
          <td>4 1/2 EUE</td>
          <td>93510</td>
        </tr>
        <tr>
          <td>13-3/8</td>
          <td>54.5-77.0</td>
          <td>12.275-12.615</td>
          <td>12.000</td>
          <td>5.00</td>
          <td>5 1/2 LTC</td>
          <td>93513</td>
        </tr>
        </tbody>
      </table>
    </div>
  );
}

export default GasVentTable;
