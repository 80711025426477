import React from "react";
import { $, jQuery } from "jquery";

function CasingScraperTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c61">
            <td class="c6" colspan="2" rowspan="1">
              <p class="c2">
                <span class="c4">CASING </span>
              </p>
            </td>
            <td class="c9" colspan="4" rowspan="1">
              <p class="c2">
                <span class="c4">SCRAPER </span>
              </p>
            </td>
            <td class="c9" colspan="4" rowspan="1">
              <p class="c2">
                <span class="c4">BLADE DIAMETER </span>
              </p>
            </td>
            <td class="c6" colspan="2" rowspan="1">
              <p class="c2">
                <span class="c4">CONNECTION</span>
              </p>
            </td>
          </tr>
          <tr class="c61">
            <td class="c6" colspan="2" rowspan="1">
              <p class="c2">
                <span class="c4">SIZE </span>
              </p>
            </td>
            <td class="c6" colspan="2" rowspan="1">
              <p class="c2">
                <span class="c4">BODY OD </span>
              </p>
            </td>
            <td class="c6" colspan="2" rowspan="1">
              <p class="c2">
                <span class="c4">MIN ID </span>
              </p>
            </td>
            <td class="c6" colspan="2" rowspan="1">
              <p class="c2">
                <span class="c4">COLLAPSED </span>
              </p>
            </td>
            <td class="c6" colspan="2" rowspan="1">
              <p class="c2">
                <span class="c4">EXTENDED </span>
              </p>
            </td>
            <td class="c6" colspan="2" rowspan="1">
              <p class="c2">
                <span class="c4">API REG.</span>
              </p>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="c14">
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c20">in. </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c20">mm </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c20">in. </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c20">mm. </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c20">in. </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c20">mm. </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c20">in. </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c20">mm </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c20">in. </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c20">mm </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c20">in. </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c20">mm</span>
              </p>
            </td>
          </tr>
          <tr class="c14">
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">4 1/2 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">114.30 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">3 1/16 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">77.79 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">1.00 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">25.40 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">3.795 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">96.39 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">4.280 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">108.71 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">2 3/8 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">60.3</span>
              </p>
            </td>
          </tr>
          <tr class="c14">
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">5 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">127.00 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">3 1/16 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">77.79 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">1.00 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">25.40 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">4.151 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">105.44 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">4.636 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">117.75 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">2 3/8 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">60.3</span>
              </p>
            </td>
          </tr>
          <tr class="c14">
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">5 1/2 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">139.70 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">4 3/8 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">111.13 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">1.00 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">25.40 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">4.525 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">114.94 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">5.055 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">128.40 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">2 7/8 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">73.0</span>
              </p>
            </td>
          </tr>
          <tr class="c14">
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">6 5/8 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">142.88 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">5 1/2 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">139.70 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">1.25 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">31.75 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">5.400 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">137.16 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">6.238 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">158.45 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">3 1/2 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">88.9</span>
              </p>
            </td>
          </tr>
          <tr class="c14">
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">7 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">177.80 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">5 1/2 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">139.70 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">1.25 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">31.75 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">5.795 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">147.19 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">6.538 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">166.07 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">3 1/2 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">88.9</span>
              </p>
            </td>
          </tr>
          <tr class="c14">
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">7 5/8 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">193.68 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">5 1/2 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">139.70 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">1.25 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">31.75 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">6.500 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">165.10 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">7.243 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">183.97 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">3 1/2 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">88.9</span>
              </p>
            </td>
          </tr>
          <tr class="c14">
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">8 5/8 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">219.08 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">7 1/4 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">184.15 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">2.00 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">50.80 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">7.386 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">187.60 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">8.129 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">206.47 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">4 1/2 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">114.3</span>
              </p>
            </td>
          </tr>
          <tr class="c14">
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">9 5/8 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">244.48 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">7 1/4 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">184.15 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">2.25 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">57.15 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">8.379 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">212.83 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">9.254 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">235.05 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">4 1/2 </span>
              </p>
            </td>
            <td class="c3" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">114.3</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default CasingScraperTable;
