import React from "react";
import { $, jQuery } from "jquery";

function RunningToolTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c83">
            <td class="c72" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Casing Size </span>
              </p>
              <p class="c5">
                <span class="c7">(inches)</span>
              </p>
            </td>
            <td class="c67" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Tool OD </span>
              </p>
              <p class="c5">
                <span class="c7">(inches)</span>
              </p>
            </td>
            <td class="c24" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Tool ID </span>
              </p>
              <p class="c5">
                <span class="c7">(inches)</span>
              </p>
            </td>
            <td class="c74" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Thread Connection </span>
              </p>
              <p class="c5">
                <span class="c7">Box Up</span>
              </p>
            </td>
            <td class="c24" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Part </span>
              </p>
              <p class="c5">
                <span class="c7">Number</span>
              </p>
            </td>
          </tr>
        </thead>

        <tbody>
          <tr class="c21">
            <td class="c72" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">3-1/2 </span>
              </p>
            </td>
            <td class="c67" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2.750 </span>
              </p>
            </td>
            <td class="c24" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.16 </span>
              </p>
            </td>
            <td class="c74" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.900 NUE </span>
              </p>
            </td>
            <td class="c24" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">12435RT</span>
              </p>
            </td>
          </tr>
          <tr class="c21">
            <td class="c72" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c2">4-1/2</span>
              </p>
            </td>
            <td class="c67" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">3.680 </span>
              </p>
            </td>
            <td class="c24" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.78 </span>
              </p>
            </td>
            <td class="c74" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2-3/8 EUE </span>
              </p>
            </td>
            <td class="c24" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">12444RT</span>
              </p>
            </td>
          </tr>
          <tr class="c21">
            <td class="c67" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">3.750 </span>
              </p>
            </td>
            <td class="c24" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.78 </span>
              </p>
            </td>
            <td class="c74" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2-3/8 EUE </span>
              </p>
            </td>
            <td class="c24" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">12445RT</span>
              </p>
            </td>
          </tr>
          <tr class="c21">
            <td class="c72" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c2">5-1/2 </span>
              </p>
            </td>
            <td class="c67" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c2">4.500 </span>
              </p>
            </td>
            <td class="c24" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c2">1.78</span>
              </p>
            </td>
            <td class="c74" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2-7/8 EUE </span>
              </p>
            </td>
            <td class="c24" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">12455RT</span>
              </p>
            </td>
          </tr>
          <tr class="c21">
            <td class="c74" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2-3/8 EUE </span>
              </p>
            </td>
            <td class="c24" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">12456RT</span>
              </p>
            </td>
          </tr>
          <tr class="c21">
            <td class="c72" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">7 </span>
              </p>
            </td>
            <td class="c67" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">5.880 </span>
              </p>
            </td>
            <td class="c24" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.78 </span>
              </p>
            </td>
            <td class="c74" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2-7/8 EUE </span>
              </p>
            </td>
            <td class="c24" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">12470RT</span>
              </p>
            </td>
          </tr>
          <tr class="c21">
            <td class="c72" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">10-3/4 </span>
              </p>
            </td>
            <td class="c67" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">9.700 </span>
              </p>
            </td>
            <td class="c24" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">3.00 </span>
              </p>
            </td>
            <td class="c74" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">4-1/2 EUE </span>
              </p>
            </td>
            <td class="c24" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">12401RT</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default RunningToolTable;
