import React from "react";
import { $, jQuery } from "jquery";

function TmAnchorTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr>
            <th colSpan={2}>Casing</th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Recommended Hole Size (Inches)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Tool OD (Inches)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Tool ID (Inches)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Thread Connections Box Up / Pin Down
            </th>
            <th rowSpan={2}>Part Number</th>
          </tr>
          <tr>
            <th>Size (inches)</th>
            <th>Weight (lbs/ft)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan={2}>4-1/2</td>
            <td>9.5-13.5</td>
            <td>3.920-4.090</td>
            <td>3.750</td>
            <td>2.00</td>
            <td>2-3/8 EUE</td>
            <td>32045</td>
          </tr>
          <tr>
            <td>13.5-15.1</td>
            <td>3.826-3.920</td>
            <td>3.656</td>
            <td>2.00</td>
            <td>2-3/8 EUE</td>
            <td>32044</td>
          </tr>
          <tr>
            <td rowSpan={2}>5-1/2</td>
            <td rowSpan={2}>13.0-23.0</td>
            <td rowSpan={2}>4.670-5.044</td>
            <td rowSpan={2}>4.500</td>
            <td>2.00</td>
            <td>2-3/8 EUE</td>
            <td>32055</td>
          </tr>
          <tr>
            <td>2.38</td>
            <td>2-7/8 EUE</td>
            <td>32056</td>
          </tr>
          <tr>
            <td rowSpan={2}>6-5/8</td>
            <td rowSpan={2}>20.0-32.0</td>
            <td rowSpan={2}>5.675-6.049</td>
            <td rowSpan={2}>5.500</td>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>32065</td>
          </tr>
          <tr>
            <td>3.00</td>
            <td>3-1/2 EUE</td>
            <td>32066</td>
          </tr>
          <tr>
            <td rowSpan={3}>7</td>
            <td rowSpan={3}>17.0-32.0</td>
            <td rowSpan={3}>6.094-6.538</td>
            <td rowSpan={3}>5.875</td>
            <td>2.00</td>
            <td>2-3/8 EUE</td>
            <td>32071</td>
          </tr>
          <tr>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>32070</td>
          </tr>
          <tr>
            <td>3.00</td>
            <td>3-1/2 EUE</td>
            <td>32073</td>
          </tr>
          <tr>
            <td>7-5/8</td>
            <td>24.0-39.0</td>
            <td>6.625-7.025</td>
            <td>6.400</td>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>32075</td>
          </tr>
          <tr>
            <td rowSpan={2}>9-5/8</td>
            <td rowSpan={2}>32.3-53.5</td>
            <td rowSpan={2}>8.535-9.001</td>
            <td rowSpan={2}>8.250</td>
            <td>3.00</td>
            <td>3-1/2 EUE</td>
            <td>32095-BAE</td>
          </tr>
          <tr>
            <td>4.00</td>
            <td>4-1/2 EUE</td>
            <td>32095</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default TmAnchorTable;
