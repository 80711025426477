import React from "react";
import { $, jQuery } from "jquery";

function EgwCupTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
        <tr>
          <th colspan="2">CASING SIZE</th>
          <th colspan="2">THREAD CONNECTIONS</th>
          <th colspan="2">CASING WEIGHT</th>
          <th colspan="2">MAX OD</th>
          <th colspan="2">MIN ID</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>in.</td>
          <td>mm</td>
          <td>lbs/ft</td>
          <td>kg/m</td>
          <td>in.</td>
          <td>mm</td>
          <td>in.</td>
          <td>mm</td>
          <td>in.</td>
          <td>mm</td>
        </tr>
        <tr>
          <td>4 <sub>1/2</sub></td>
          <td>114.3</td>
          <td>2 3/8 EUE</td>
          <td>60.3</td>
          <td>15.1-16.6</td>
          <td>22.5-24.7</td>
          <td>4.08</td>
          <td>103.6</td>
          <td>2.00</td>
          <td>50.8</td>
        </tr>
        <tr>
          <td>4 <sub>1/2</sub></td>
          <td>114.3</td>
          <td>2 3/8 EUE</td>
          <td>60.3</td>
          <td>9.5-11.6</td>
          <td>14.1-17.2</td>
          <td>4.15</td>
          <td>105.4</td>
          <td>2.00</td>
          <td>50.8</td>
        </tr>
        <tr>
          <td>5 </td>
          <td>127.0</td>
          <td>2 3/8 EUE</td>
          <td>60.3</td>
          <td>18.0-21.0</td>
          <td>26.8-30.2</td>
          <td>4.34</td>
          <td>110.3</td>
          <td>2.00</td>
          <td>50.8</td>
        </tr>
        <tr>
          <td>5 </td>
          <td>127.0</td>
          <td>2 3/8 EUE</td>
          <td>60.3</td>
          <td>13.0-15.0</td>
          <td>19.3-22.3</td>
          <td>4.62</td>
          <td>117.3</td>
          <td>2.00</td>
          <td>50.8</td>
        </tr>
        <tr>
          <td>5 <sub>1/2</sub></td>
          <td>139.7</td>
          <td>2 3/8 EUE</td>
          <td>60.3</td>
          <td>17.0-23.0</td>
          <td>29.8-34.2</td>
          <td>4.98</td>
          <td>126.4</td>
          <td>2.00</td>
          <td>50.8</td>
        </tr>
        <tr>
          <td>5 <sub>1/2</sub></td>
          <td>139.7</td>
          <td>2 3/8 EUE</td>
          <td>60.3</td>
          <td>15.5-17.0</td>
          <td>23.1-25.3</td>
          <td>5.12</td>
          <td>130.0</td>
          <td>2.00</td>
          <td>50.8</td>
        </tr>
        <tr>
          <td>5 <sub>1/2</sub></td>
          <td>139.7</td>
          <td>2 3/8 EUE</td>
          <td>60.3</td>
          <td>13.0-15.0</td>
          <td>19.3-22.3</td>
          <td>5.20</td>
          <td>132.0</td>
          <td>2.00</td>
          <td>50.8</td>
        </tr>
        <tr>
          <td>5 <sub>1/2</sub></td>
          <td>139.7</td>
          <td>2 7/8 EUE</td>
          <td>73.0</td>
          <td>17.0-23.0</td>
          <td>29.8-34.2</td>
          <td>4.98</td>
          <td>126.4</td>
          <td>2.50</td>
          <td>63.5</td>
        </tr>
        <tr>
          <td>5 <sub>1/2</sub></td>
          <td>139.7</td>
          <td>2 7/8 EUE</td>
          <td>73.0</td>
          <td>15.5-17.0</td>
          <td>23.1-25.3</td>
          <td>5.12</td>
          <td>130.0</td>
          <td>2.50</td>
          <td>63.5</td>
        </tr>
        <tr>
          <td>5 <sub>1/2</sub></td>
          <td>139.7</td>
          <td>2 7/8 EUE</td>
          <td>73.0</td>
          <td>13.0-15.0</td>
          <td>19.3-22.3</td>
          <td>5.20</td>
          <td>132.0</td>
          <td>2.50</td>
          <td>63.5</td>
        </tr>
        <tr>
          <td>7</td>
          <td>177.8</td>
          <td>2 7/8 EUE</td>
          <td>73.0</td>
          <td>26.0-29.0</td>
          <td>38.7-43.1</td>
          <td>6.38</td>
          <td>162.0</td>
          <td>2.50</td>
          <td>63.5</td>
        </tr>
        <tr>
          <td>7</td>
          <td>177.8</td>
          <td>2 7/8 EUE</td>
          <td>73.0</td>
          <td>22.0-24.0</td>
          <td>32.7-35.7</td>
          <td>6.59</td>
          <td>167.3</td>
          <td>2.50</td>
          <td>63.5</td>
        </tr>
        <tr>
          <td>7</td>
          <td>177.8</td>
          <td>2 7/8 EUE</td>
          <td>73.0</td>
          <td>17.0-20.0</td>
          <td>25.3-29.8</td>
          <td>6.62</td>
          <td>168.1</td>
          <td>2.50</td>
          <td>63.5</td>
        </tr>
        </tbody>
      </table>
    </div>
  );
}

export default EgwCupTable;
