import React from "react";
import { $, jQuery } from "jquery";

function SpringLoadedRetrievingTooolTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c150">
            <td class="c219" colspan="1" rowspan="1">
              Size (inches)
            </td>
            <td class="c86" colspan="1" rowspan="1">
              Tool OD (inches)
            </td>
            <td class="c225" colspan="1" rowspan="1">
              Thread Connections Box Up
            </td>
            <td class="c151" colspan="1" rowspan="1">
              Part Number
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="c46">
            <td class="c219" colspan="1" rowspan="1">
              3-1/2
            </td>
            <td class="c86" colspan="1" rowspan="1">
              2.750
            </td>
            <td class="c225" colspan="1" rowspan="1">
              1.900 EUE
            </td>
            <td class="c151" colspan="1" rowspan="1">
              57735
            </td>
          </tr>
          <tr class="c46">
            <td class="c219" colspan="1" rowspan="1">
              4
            </td>
            <td class="c86" colspan="1" rowspan="1">
              3.125
            </td>
            <td class="c225" colspan="1" rowspan="1">
              1.900 EUE
            </td>
            <td class="c151" colspan="1" rowspan="1">
              57740
            </td>
          </tr>
          <tr class="c46">
            <td class="c219" colspan="1" rowspan="3">
              4-1/2
            </td>
            <td class="c86" colspan="1" rowspan="1">
              3.625
            </td>
            <td class="c225" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c151" colspan="1" rowspan="1">
              57741
            </td>
          </tr>
          <tr class="c46">
            <td class="c86" colspan="1" rowspan="1">
              3.656
            </td>
            <td class="c225" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c151" colspan="1" rowspan="1">
              57744
            </td>
          </tr>
          <tr class="c46">
            <td class="c86" colspan="1" rowspan="1">
              3.750
            </td>
            <td class="c225" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c151" colspan="1" rowspan="1">
              57745
            </td>
          </tr>
          <tr class="c46">
            <td class="c219" colspan="1" rowspan="2">
              5
            </td>
            <td class="c86" colspan="1" rowspan="1">
              4.125
            </td>
            <td class="c225" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c151" colspan="1" rowspan="1">
              57750
            </td>
          </tr>
          <tr class="c46">
            <td class="c86" colspan="1" rowspan="1">
              4.000
            </td>
            <td class="c225" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c151" colspan="1" rowspan="1">
              57752
            </td>
          </tr>
          <tr class="c46">
            <td class="c219" colspan="1" rowspan="2">
              5-1/2
            </td>
            <td class="c86" colspan="1" rowspan="2">
              4.500
            </td>
            <td class="c225" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c151" colspan="1" rowspan="1">
              57755
            </td>
          </tr>
          <tr class="c46">
            <td class="c225" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c151" colspan="1" rowspan="1">
              57756
            </td>
          </tr>
          <tr class="c46">
            <td class="c219" colspan="1" rowspan="1">
              6
            </td>
            <td class="c86" colspan="1" rowspan="1">
              5.000
            </td>
            <td class="c225" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c151" colspan="1" rowspan="1">
              57760
            </td>
          </tr>
          <tr class="c576">
            <td class="c219" colspan="1" rowspan="1">
              6-5/8
            </td>
            <td class="c86" colspan="1" rowspan="1">
              5.437
            </td>
            <td class="c225" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c151" colspan="1" rowspan="1">
              57765
            </td>
          </tr>
          <tr class="c576">
            <td class="c219" colspan="1" rowspan="1">
              7
            </td>
            <td class="c86" colspan="1" rowspan="1">
              5.875
            </td>
            <td class="c225" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c151" colspan="1" rowspan="1">
              57770
            </td>
          </tr>
          <tr class="c46">
            <td class="c219" colspan="1" rowspan="1">
              7-5/8
            </td>
            <td class="c86" colspan="1" rowspan="1">
              6.375
            </td>
            <td class="c225" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c151" colspan="1" rowspan="1">
              57775
            </td>
          </tr>
          <tr class="c46">
            <td class="c219" colspan="1" rowspan="1">
              8-5/8
            </td>
            <td class="c86" colspan="1" rowspan="1">
              7.500
            </td>
            <td class="c225" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c151" colspan="1" rowspan="1">
              57785
            </td>
          </tr>
          <tr class="c46">
            <td class="c219" colspan="1" rowspan="2">
              9-5/8
            </td>
            <td class="c86" colspan="1" rowspan="2">
              8.250
            </td>
            <td class="c225" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c151" colspan="1" rowspan="1">
              57795
            </td>
          </tr>
          <tr class="c46">
            <td class="c225" colspan="1" rowspan="1">
              4-1/2 EUE
            </td>
            <td class="c151" colspan="1" rowspan="1">
              57796
            </td>
          </tr>
          <tr class="c46">
            <td class="c219" colspan="1" rowspan="2">
              10-3/4
            </td>
            <td class="c86" colspan="1" rowspan="2">
              9.312
            </td>
            <td class="c225" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c151" colspan="1" rowspan="1">
              57701
            </td>
          </tr>
          <tr class="c46">
            <td class="c225" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c151" colspan="1" rowspan="1">
              57710
            </td>
          </tr>
          <tr class="c46">
            <td class="c219" colspan="1" rowspan="1">
              11-3/4
            </td>
            <td class="c86" colspan="1" rowspan="1">
              10.370
            </td>
            <td class="c225" colspan="1" rowspan="1">
              4-1/2 EUE
            </td>
            <td class="c151" colspan="1" rowspan="1">
              57711
            </td>
          </tr>
          <tr class="c46">
            <td class="c219" colspan="1" rowspan="1">
              13-3/8
            </td>
            <td class="c86" colspan="1" rowspan="1">
              11.750
            </td>
            <td class="c225" colspan="1" rowspan="1">
              4-1/2 EUE
            </td>
            <td class="c151" colspan="1" rowspan="1">
              57713
            </td>
          </tr>
          <tr class="c46">
            <td class="c219" colspan="1" rowspan="1">
              14
            </td>
            <td class="c86" colspan="1" rowspan="1">
              12.000
            </td>
            <td class="c225" colspan="1" rowspan="1">
              4-1/2 IF TOOL JOINT
            </td>
            <td class="c151" colspan="1" rowspan="1">
              57714
            </td>
          </tr>
          <tr class="c46">
            <td class="c219" colspan="1" rowspan="1">
              16
            </td>
            <td class="c86" colspan="1" rowspan="1">
              14.000
            </td>
            <td class="c225" colspan="1" rowspan="1">
              4-1/2 IF TOOL JOINT
            </td>
            <td class="c151" colspan="1" rowspan="1">
              57716-XBEE
            </td>
          </tr>
          <tr class="c46">
            <td class="c219" colspan="1" rowspan="1">
              18-5/8
            </td>
            <td class="c86" colspan="1" rowspan="1">
              16.750
            </td>
            <td class="c225" colspan="1" rowspan="1">
              4-1/2 IF TOOL JOINT
            </td>
            <td class="c151" colspan="1" rowspan="1">
              57718-XBEE
            </td>
          </tr>
          <tr class="c46">
            <td class="c219" colspan="1" rowspan="1">
              20
            </td>
            <td class="c86" colspan="1" rowspan="1">
              17.500
            </td>
            <td class="c225" colspan="1" rowspan="1">
              4-1/2 IF TOOL JOINT
            </td>
            <td class="c151" colspan="1" rowspan="1">
              57720-XBEE
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default SpringLoadedRetrievingTooolTable;
