let DEVMODE = false

let config = {
    // base_url: DEVMODE ? 'http://localhost:3012' : 'https://www.alpacasys.com'
    base_url: DEVMODE ? 'http://localhost:3012' : 'https://site-api.maverickdownhole.com'

}


export default config
