import React from "react";
import { $, jQuery } from "jquery";

function CupTypeSelectiveTreatingAssemblyTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr>
            <th colspan="2">CASING SIZE</th>
            <th colspan="2">CASING WEIGHT</th>
            <th colspan="2">THIMBLE OD</th>
            <th colspan="2">MIN ID</th>
            <th colspan="2">THREAD</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>in.</td>
            <td>mm</td>
            <td>lbs/ft</td>
            <td>kg/m</td>
            <td>in.</td>
            <td>mm</td>
            <td>in.</td>
            <td>mm</td>
            <td>in.</td>
            <td>mm</td>
          </tr>
          <tr>
            <td>
              4<sup>1/2</sup>
            </td>
            <td>114.3</td>
            <td>9.5-13.5</td>
            <td>14.1-20.1</td>
            <td>3.750</td>
            <td>95.25</td>
            <td>1.25</td>
            <td>31.8</td>
            <td>2 3/8 EUE</td>
            <td>60.3</td>
          </tr>
          <tr>
            <td>5</td>
            <td>127.0</td>
            <td>18.0-21.0</td>
            <td>26.8-31.2</td>
            <td>4.000</td>
            <td>101.6</td>
            <td>1.25</td>
            <td>31.8</td>
            <td>2 3/8 EUE</td>
            <td>60.3</td>
          </tr>
          <tr>
            <td>5</td>
            <td>127.0</td>
            <td>11.5-15.0</td>
            <td>17.1-22.3</td>
            <td>4.125</td>
            <td>103.8</td>
            <td>1.25</td>
            <td>31.8</td>
            <td>2 3/8 EUE</td>
            <td>60.3</td>
          </tr>
          <tr>
            <td>
              5<sup>1/2</sup>
            </td>
            <td>139.7</td>
            <td>20.0-23.0</td>
            <td>29.8-34.2</td>
            <td>4.500</td>
            <td>114.3</td>
            <td>1.75</td>
            <td>44.5</td>
            <td>2 3/8 EUE</td>
            <td>60.3</td>
          </tr>
          <tr>
            <td>
              5<sup>1/2</sup>
            </td>
            <td>139.7</td>
            <td>15.5-17.0</td>
            <td>23.1-25.3</td>
            <td>4.625</td>
            <td>117.5</td>
            <td>1.75</td>
            <td>44.5</td>
            <td>2 3/8 EUE</td>
            <td>60.3</td>
          </tr>
          <tr>
            <td>
              5<sup>1/2</sup>
            </td>
            <td>139.7</td>
            <td>13.0-15.5</td>
            <td>19.3-23.1</td>
            <td>4.781</td>
            <td>121.4</td>
            <td>1.75</td>
            <td>44.5</td>
            <td>2 3/8 EUE</td>
            <td>60.3</td>
          </tr>
          <tr>
            <td>7</td>
            <td>177.8</td>
            <td>28.0-35.0</td>
            <td>41.7-52.1</td>
            <td>5.780</td>
            <td>146.8</td>
            <td>1.75</td>
            <td>44.5</td>
            <td>2 7/8 EUE</td>
            <td>73.0</td>
          </tr>
          <tr>
            <td>7</td>
            <td>177.8</td>
            <td>23.0-26.0</td>
            <td>34.2-38.7</td>
            <td>5.940</td>
            <td>150.9</td>
            <td>1.75</td>
            <td>44.5</td>
            <td>2 7/8 EUE</td>
            <td>73.0</td>
          </tr>
          <tr>
            <td>7</td>
            <td>177.8</td>
            <td>17.0-20.0</td>
            <td>25.3-29.8</td>
            <td>6.210</td>
            <td>157.7</td>
            <td>1.75</td>
            <td>44.5</td>
            <td>2 7/8 EUE</td>
            <td>73.0</td>
          </tr>
          <tr>
            <td>
              7<sup>5/8</sup>
            </td>
            <td>193.7</td>
            <td>33.7-39.0</td>
            <td>50.2-58.0</td>
            <td>7.260</td>
            <td>184.4</td>
            <td>2.25</td>
            <td>57.2</td>
            <td>2 7/8 EUE</td>
            <td>73.0</td>
          </tr>
          <tr>
            <td>
              8<sup>5/8</sup>
            </td>
            <td>219.1</td>
            <td>40.0-49.0</td>
            <td>59.5-72.9</td>
            <td>7.250</td>
            <td>188.2</td>
            <td>2.25</td>
            <td>57.2</td>
            <td>2 7/8 EUE</td>
            <td>73.0</td>
          </tr>
          <tr>
            <td>
              9<sup>5/8</sup>
            </td>
            <td>244.5</td>
            <td>47.0-53.5</td>
            <td>69.9-79.6</td>
            <td>8.250</td>
            <td>209.6</td>
            <td>2.25</td>
            <td>57.2</td>
            <td>2 7/8 EUE</td>
            <td>73.0</td>
          </tr>
          <tr>
            <td>
              10<sup>3/4</sup>
            </td>
            <td>273.1</td>
            <td>32.3-43.5</td>
            <td>48.1-64.7</td>
            <td>8.500</td>
            <td>215.9</td>
            <td>2.25</td>
            <td>57.2</td>
            <td>2 7/8 EUE</td>
            <td>73.0</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default CupTypeSelectiveTreatingAssemblyTable;
