import React from "react";
import { $, jQuery } from "jquery";

function EpIndexValveTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr>
            <th colspan="2">CASING SIZE</th>
            <th colspan="2">CONNECTION SIZE</th>
            <th colspan="2">MAX OD</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>in.</td>
            <td>mm</td>
            <td>in.</td>
            <td>mm</td>
            <td>in.</td>
            <td>mm</td>
          </tr>
          <tr>
            <td>
              4<sup>1/2</sup>
            </td>
            <td>114.3</td>
            <td>2 3/8 EUE</td>
            <td>60.3</td>
            <td>3.656</td>
            <td>92.86</td>
          </tr>
          <tr>
            <td>
              4<sup>1/2</sup>
            </td>
            <td>114.3</td>
            <td>2 3/8 EUE</td>
            <td>60.3</td>
            <td>3.750</td>
            <td>95.25</td>
          </tr>
          <tr>
            <td>5</td>
            <td>127.0</td>
            <td>2 3/8 EUE</td>
            <td>60.3</td>
            <td>3.750</td>
            <td>95.25</td>
          </tr>
          <tr>
            <td>
              5<sup>1/2</sup>
            </td>
            <td>139.7</td>
            <td>2 3/8 EUE</td>
            <td>60.3</td>
            <td>4.500</td>
            <td>144.30</td>
          </tr>
          <tr>
            <td>7</td>
            <td>177.8</td>
            <td>2 7/8 EUE</td>
            <td>73.0</td>
            <td>5.750</td>
            <td>146.05</td>
          </tr>
          <tr>
            <td>
              7<sup>5/8</sup>
            </td>
            <td>193.7</td>
            <td>2 7/8 EUE</td>
            <td>73.0</td>
            <td>6.25</td>
            <td>158.75</td>
          </tr>
          <tr>
            <td>
              8<sup>5/8</sup>
            </td>
            <td>219.1</td>
            <td>2 7/8 EUE</td>
            <td>73.0</td>
            <td>7.12</td>
            <td>180.85</td>
          </tr>
          <tr>
            <td>
              9<sup>5/8</sup>
            </td>
            <td>244.5</td>
            <td>2 7/8 EUE</td>
            <td>73.0</td>
            <td>8.00</td>
            <td>203.20</td>
          </tr>
          <tr>
            <td>
              9<sup>5/8</sup>
            </td>
            <td>244.5</td>
            <td>3 1/2 EUE</td>
            <td>88.9</td>
            <td>8.00</td>
            <td>203.20</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default EpIndexValveTable;
