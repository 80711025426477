import logo from "./logo.svg";
import "./App.css";
import { Routes, Route, Link, useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import ProductData from "./Data.json";
import "./components/Misc/print.css";
import LinkedinWidget from "./components/Misc/LinkedinWidget";

import Home from "../src/pages/Home";
import About from "../src/pages/About";
import Engineering from "./pages/Engineering";
// _______________
import Accessories from "./pages/Products/Accessories";
import MechanicalPackers from "../src/pages/Products/MechanicalPackers";
import FlowControl from "../src/pages/Products/FlowControl";
import PlugRetainers from "../src/pages/Products/PlugRetainers";
import TubingAnchors from "../src/pages/Products/TubingAnchors";
import HydraulicPackers from "./pages/Products/HydraulicPackers";
import PermanentPackers from "./pages/Products/PermanentPackers";
import RetrievablePackers from "./pages/Products/RetrievablePackers";
import NotFoundPage from "./pages/Utility/NotFoundPage";
import ServiceTools from "./pages/Products/ServiceTools";
import SealBorePackers from "./pages/Products/SealBorePackers";
import BurstDisc from "./pages/Products/BurstDisc";
import Certifications from "./pages/Certifications";
import Alpha from "./pages/Misc/Alpha";
import Dl from "./pages/Misc/Dl";
import PrivacyPolicy from "./pages/Utility/PrivacyPolicy";
import AllProducts from "./pages/Products/AllProducts";
import CookieConsent from "./components/Utility/CookieConsent";
import { useEffect } from "react";

function App() {
  let { subLink } = useParams();
  return (
    <>
      <CookieConsent />
      <LinkedinWidget />

      <Routes>
        {/* <Switch> */}
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="products" element={<AllProducts />} />
        <Route path="engineering" element={<Engineering />} />
        <Route path="certifications" element={<Certifications />} />
        <Route path="alpha-oil-tools" element={<Alpha />} />
        <Route path="d-and-l" element={<Dl />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        {/* PRODUCT PAGES */}

        <Route
          exact
          path="/products/mechanical-packers/:subLink"
          element={<MechanicalPackers />}
        />

        <Route
          exact
          path="products/accessories/:subLink"
          element={<Accessories />}
        />

        <Route
          exact
          path="products/hydraulic-packers/:subLink"
          element={<HydraulicPackers />}
        />
        <Route
          exact
          path="products/permanent-packers/:subLink"
          element={<PermanentPackers />}
        />
        <Route
          path="products/retrievable-packers"
          element={<RetrievablePackers />}
        />
        <Route
          exact
          path="products/flow-control/:subLink"
          element={<FlowControl />}
        />
        <Route
          exact
          path="products/plug-retainers/:subLink"
          element={<PlugRetainers />}
        />
        <Route
          exact
          path="products/service-tools/:subLink"
          element={<ServiceTools />}
        />
        <Route
          exact
          path="products/tubing-anchors/:subLink"
          element={<TubingAnchors />}
        />
        {/* <Route
          path="products/seal-bore-packers"
          element={<SealBorePackers />}
        /> */}
        <Route
          exact
          path="products/burst-discs/:subLink"
          element={<BurstDisc />}
        />
        <Route path="*" element={<NotFoundPage />} />
        {/* </Switch> */}
      </Routes>
    </>
  );
}

export default App;
