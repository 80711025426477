import React from "react";
import ContactForm from "../../components/ContactForm/ContactForm";
import { useEffect, useState } from "react";
import { getAllProductService } from "../../services/dept";
import { getTubingAnchorTitlesService } from "../../services/dept";
import TubingAnchorHeader from '../../components/Headers/TubingAnchorHeader'
import GeneralBtn from "../../components/ColumnBtns/GeneralBtn";
import { Helmet } from 'react-helmet';
import EcDragBlock from "../../components/Products/TubingAnchors/EcDragBlock";
import HcAnchorCatcher from "../../components/Products/TubingAnchors/HcAnchorCatcher";
import QtaAnchorCatcher from "../../components/Products/TubingAnchors/QtaAnchorCatcher";
import TmAnchor from '../../components/Products/MechanicalPackers/TmAnchor'
import AsiXAnchor from '../../components/Products/MechanicalPackers/AsiXAnchor'
import HydraulicTubingAnchor from '../../components/Products/HydraulicPackers/HydraulicTubingAnchor'
import { useParams } from "react-router-dom";
import { pseudoencodeURI } from "./converter";

const ProductController = [
  {
    title: "EC-1 DRAG BLOCK TUBING ANCHOR/CATCHER",
    component: <EcDragBlock title="EC-1 DRAG BLOCK TUBING ANCHOR/CATCHER" />,
  },
  {
    title: "HC ANCHOR CATCHER",
    component: <HcAnchorCatcher title="HC ANCHOR CATCHER" />,
  },
  {
    title: "QTA ANCHOR/CATCHER",
    component: <QtaAnchorCatcher title="QTA ANCHOR/CATCHER" />,
  },
  {
    title: "TM ANCHOR-CATCHER",
    component: <TmAnchor title="TM ANCHOR-CATCHER" />,
  },
  {
    title: "ASI-X ANCHOR",
    component: <AsiXAnchor title="ASI-X ANCHOR" />,
  },
  {
    title: "HYDRAULIC TUBING ANCHOR",
    component: <HydraulicTubingAnchor title="HYDRAULIC TUBING ANCHOR" />,
  },
];

function TubingAnchor() {
  const [tubingAnchor, setTubingAnchor] = useState([]);
  const [currentProduct, setCurrentProduct] = useState("EC-1 DRAG BLOCK TUBING ANCHOR/CATCHER");
  const params = useParams(); 

  useEffect(() => {
    getTubingAnchorTitlesService().then((result) => {
        setTubingAnchor(result);
        setCurrentProduct(params.subLink)
        window.scrollTo(0, 0);

    });
  }, [params]);

  return (
    <div>
    <Helmet>‍
        <title>Tubing Anchors | Maverick Downhole Technologies | Edmonton, AB</title>‍ 
      </Helmet>
      <TubingAnchorHeader />
      <div className="product-section">
        <div className="product-btn-container">
          {tubingAnchor.map((tubingAnchor) => {
            return (
              <GeneralBtn
                onClick={(title) => setCurrentProduct(title)}
                title={tubingAnchor.title}
                selected={pseudoencodeURI(tubingAnchor.title) === currentProduct}
                baseURL={"/products/tubing-anchors"}
              />
            );
          })}
        </div>
        <div>
          {!currentProduct
            ? null
            : ProductController.find(
                (product) => pseudoencodeURI(product.title) === currentProduct
              )?.component}
        </div>
      </div>
      <ContactForm />
    </div>
  );
}

export default TubingAnchor;
