import React from "react";
import { $, jQuery } from "jquery";

function VIIIUnloaderTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
        <tr class="c59">
      <td class="c362" colspan="1" rowspan="1">
          Size 
          (inches)
      </td>
      <td class="c379" colspan="1" rowspan="1">
          Tool OD 
          (inches)
      </td>
      <td class="c15" colspan="1" rowspan="1">
          Tool ID 
          (inches)
      </td>
      <td class="c477" colspan="1" rowspan="1">
          Thread Connections 
          Box Up / Pin Down
      </td>
      <td class="c447" colspan="1" rowspan="1">
          Part 
          Number
      </td>
  </tr>
        </thead>
        <tbody>
        <tr class="c31">
      <td class="c362" colspan="1" rowspan="1">
          3-1/2 
      </td>
      <td class="c379" colspan="1" rowspan="1">
          5.500 
      </td>
      <td class="c15" colspan="1" rowspan="1">
          2.69 
      </td>
      <td class="c477" colspan="1" rowspan="1">
          3-1/2 IF TOOL JOINT 
      </td>
      <td class="c447" colspan="1" rowspan="1">
          52735
      </td>
  </tr>
  <tr class="c31">
      <td class="c362" colspan="1" rowspan="1">
          4-1/2 
      </td>
      <td class="c379" colspan="1" rowspan="1">
          7.250 
      </td>
      <td class="c15" colspan="1" rowspan="1">
          3.75 
      </td>
      <td class="c477" colspan="1" rowspan="1">
          4-1/2 IF TOOL JOINT 
      </td>
      <td class="c447" colspan="1" rowspan="1">
          52745
      </td>
  </tr>
        </tbody>
      </table>
    </div>
  );
}

export default VIIIUnloaderTable;
