import React from "react";
import styled, { css } from 'styled-components'

//const linkedinContainer = document.getElementById(LinkedinContainer)

const LinkedinContainer = styled.div`
  position: fixed;
  right: 0;
  top: 50%;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(134, 137, 136, 0.4);
  @media (max-width: 768px) {
    display: none;
  }
`;

function LinkedinWidget() {
  return (
    <div>
      <LinkedinContainer id="linkedContainer">
        <h4 style={{fontSize: '14pt'}}>Follow us on</h4>
        <a href="https://www.linkedin.com/company/maverick-downhole-technologies/?originalSubdomain=ca" target="_blank">
        <i class="fa-brands fa-linkedin" style={{fontSize: '30pt', color: '#0072b1'}}></i>
        </a>
      </LinkedinContainer>
    </div>
  );
}

export default LinkedinWidget;
