import React from 'react'
import { Link } from "react-router-dom";

//ICONS
import MechanicalPacker from '../../assets/icons/nav/MechanicalPacker.svg'
import PermanentPacker from '../../assets/icons/nav/PermanentPackers.svg'
import RPackers from '../../assets/icons/nav/RPackers.svg'
import SealBore from '../../assets/icons/nav/SealBore.svg'
import Service from '../../assets/icons/nav/Service.svg'
import TubingAnchors from '../../assets/icons/nav/TubingAnchors.svg'
import Accessories from '../../assets/icons/nav/Accessories.svg'
import FlowControl from '../../assets/icons/nav/FlowControl.svg'
import HydraulicPackers from '../../assets/icons/nav/HydraulicPackers.svg'
import PlugRetainers from '../../assets/icons/nav/PlugRetainers.svg'
import productLinks from './ProductLinks';




function ProductsDropdown() {
    return (
        <div className="product-dropdown">
            <Link to="/products/mechanical-packers/TYPE%20DL-IB%20TANDEM%20TENSION%20PACKER">
                <div className="dropdown-item">
                    <img src={MechanicalPacker} alt="Mechanical Packer"/>
                    <div>
                        <h4 className="dropdown-heading">
                            Mechanical Packers
                        </h4>
                        {/* <p className="dropdown-small-text">
                            lorem20
                        </p> */}
                    </div>
                </div>
            </Link>
            <Link to="/products/hydraulic-packers/DLH%20PACKER">
            <div className="dropdown-item">
                <img src={HydraulicPackers} alt="Mechanical Packer"/>
                <div>
                    <h4 className="dropdown-heading">
                        Hydraulic Packers
                    </h4>
                    {/* <p className="dropdown-small-text">
                        lorem20
                    </p> */}
                </div>
            </div>
            </Link>
            <Link to="/products/permanent-packers/PERMAPAK%20ACCESSORIES">
                <div className="dropdown-item">
                    <img src={PermanentPacker} alt="Mechanical Packer"/>
                    <div>
                        <h4 className="dropdown-heading">
                            Permanent Packer
                        </h4>
                        {/* <p className="dropdown-small-text">
                            lorem20
                        </p> */}
                    </div>
                </div>
            </Link>
            {/* <Link to="/products/retrievable-packers">
                <div className="dropdown-item">
                    <img src={RPackers} alt="Mechanical Packer"/>
                    <div>
                        <h4 className="dropdown-heading">
                            Retrievable Packers
                        </h4>
                       
                    </div>
                </div>
            </Link> */}
            <Link to="/products/plug-retainers/HM-1B%20BRIDGE%20PLUG">
            <div className="dropdown-item">
                <img src={PlugRetainers} alt="Mechanical Packer"/>
                <div>
                    <h4 className="dropdown-heading">
                        Plug/Cement Retainer
                    </h4>
                    {/* <p className="dropdown-small-text">
                        lorem20
                    </p> */}
                </div>
            </div>
            </Link>
            {/* <Link to="/products/seal-bore-packers">
            <div className="dropdown-item">
                <img src={SealBore} alt="Mechanical Packer"/>
                <div>
                    <h4 className="dropdown-heading">
                        Seal Bore Packers
                    </h4>
                  
                </div>
            </div>
            </Link> */}
            <Link to="products/tubing-anchors/EC-1%20DRAG%20BLOCK%20TUBING%20ANCHOR-CATCHER">
            <div className="dropdown-item">
                <img src={TubingAnchors} alt="Mechanical Packer"/>
                <div>
                    <h4 className="dropdown-heading">
                        Tubing Anchors
                    </h4>
                    {/* <p className="dropdown-small-text">
                        lorem20
                    </p> */}
                </div>
            </div>
            </Link>
            <Link to="/products/flow-control/X-XN%20LANDING%20NIPPLES">
            <div className="dropdown-item">
                <img src={FlowControl} alt="Mechanical Packer"/>
                <div>
                    <h4 className="dropdown-heading">
                        Flow Control
                    </h4>
                    {/* <p className="dropdown-small-text">
                        lorem20
                    </p> */}
                </div>
            </div>
            </Link>
            <Link to="/products/service-tools/TUBING%20SWIVEL">
                <div className="dropdown-item">
                    <img src={Service} alt="Mechanical Packer"/>
                    <div>
                        <h4 className="dropdown-heading">
                            Service Tools
                        </h4>
                        {/* <p className="dropdown-small-text">
                            lorem20
                        </p> */}
                    </div>
                </div>
            </Link>
            <Link to="/products/accessories/ROTATIONAL%20SHEAR%20SAFETY%20JOINT">
                <div className="dropdown-item">
                    <img src={Accessories} alt="Mechanical Packer"/>
                    <div>
                        <h4 className="dropdown-heading">
                            Accessories
                        </h4>
                        {/* <p className="dropdown-small-text">
                            lorem20
                        </p> */}
                    </div>
                </div>
            </Link>
            <Link to={productLinks.burstDiscs}>
                <div className="dropdown-item">
                    <img src={Accessories} alt="Mechanical Packer"/>
                    <div>
                        <h4 className="dropdown-heading">
                            Burst Discs
                        </h4>
                        {/* <p className="dropdown-small-text">
                            lorem20
                        </p> */}
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default ProductsDropdown
