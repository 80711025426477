import React from "react";
import DLLogo from "../../assets/logos/DL-Oil-logo1.webp";

function DlPage() {
  return (
    <div className="alpha">
      <img src={DLLogo} alt="DL Logo" />
      <br />
      <h4 className="smaller-head centre">
        Canada’s Exclusive Stocking Distributor for
      </h4>
      <h2 className="main-subhead centre">
        <span class="bold-head">D&L Oil Tools</span>
      </h2>
      <p className="body-text centre">
        Canada’s Exclusive D&L Distributor As Canada’s Largest D&L Stocking
        Distributor, Maverick is committed to supplying the best quality
        equipment, at market competitive prices and with a depth of inventory
        that allows us to help these Service Company clients across the Western
        Canada Basin and the region. <br /> <br />​ Maverick’s team in Edmonton
        has successfully designed and built innovative tools for highly
        specialized completions. With a focus on State-of-the-Art, Patented Plug
        & Perf tools,n Innovative Multi-Zone Frac Liners, Ball Drop Pumping,
        Coiled-Tubing.
      </p>
      <br />
      <a href="mailto:sales@maverickdownhole.com">
        <button className="main-btn">Contact Sales</button>
      </a>
      <br />
    </div>
  );
}

export default DlPage;
