import React from "react";
import { $, jQuery } from "jquery";

function ExpansionJointTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c83">
            <td class="c59" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Tubing Size </span>
              </p>
              <p class="c5">
                <span class="c7">(inches)</span>
              </p>
            </td>
          </tr>
        </thead>

        <tbody>
          <tr class="c13">
            <td class="c59" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.900</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c59" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2-3/8</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c59" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2-7/8</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c59" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">3-1/2</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c59" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">4</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c59" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">4-1/2</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c59" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">5</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c59" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">5-1/2</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c59" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">7</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c59" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">9-5/8</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default ExpansionJointTable;
