import React from "react";
import ContactForm from "../../components/ContactForm/ContactForm";
import { useEffect, useState } from "react";
import { getAllProductService } from "../../services/dept";
import { getHydraulicPackerTitlesService } from "../../services/dept";
import GeneralBtn from "../../components/ColumnBtns/GeneralBtn";
import { Helmet } from 'react-helmet';
import HydraulicPackersHeader from '../../components/Headers/HydraulicPackerHeader'
import DlhPacker from "../../components/Products/HydraulicPackers/DlhPacker";
import HydrosetIIPacker from '../../components/Products/HydraulicPackers/HydrosetIIPacker'
import HydrosetIIAPacker from '../../components/Products/HydraulicPackers/HydrosetIIAPacker'
import DlespPacker from '../../components/Products/HydraulicPackers/DlespPacker'
import HydrosetIVPacker from '../../components/Products/HydraulicPackers/HydrosetIVPacker'
import HydraulicOpenHolePacker from '../../components/Products/HydraulicPackers/HydraulicOpenHolePacker'
import PermanentHydraulicIsolationPacker from '../../components/Products/HydraulicPackers/PermanentHydraulicIsolationPacker'
import HydraulicTubingAnchor from '../../components/Products/HydraulicPackers/HydraulicTubingAnchor'
import { useParams } from "react-router-dom";
import { pseudoencodeURI } from "./converter";

const ProductController = [
  {
    title: "DLH PACKER",
    component: <DlhPacker title="DLH PACKER" />,
  },
  {
    title: "HYDROSET II PACKER",
    component: <HydrosetIIPacker title="HYDROSET II PACKER" />,
  },
  {
    title: "HYDROSET II-A PACKER",
    component: <HydrosetIIAPacker title="HYDROSET II-A PACKER" />,
  },
  {
    title: "DLESP PACKER",
    component: <DlespPacker title="DLESP PACKER" />,
  },
  {
    title: "HYDROSET IV PACKER",
    component: <HydrosetIVPacker title="HYDROSET IV PACKER" />,
  },
  {
    title: "HYDRAULIC OPEN HOLE PACKER",
    component: <HydraulicOpenHolePacker title="HYDRAULIC OPEN HOLE PACKER" />,
  },
  {
    title: "PERMANENT HYDRAULIC ISOLATION PACKER",
    component: <PermanentHydraulicIsolationPacker title="PERMANENT HYDRAULIC ISOLATION PACKER" />,
  },
  {
    title: "HYDRAULIC TUBING ANCHOR",
    component: <HydraulicTubingAnchor title="HYDRAULIC TUBING ANCHOR" />,
  },
];

function HydraulicPackers() {
  const [hydraulicPacker, setHydraulicPacker] = useState([]);
  const [currentProduct, setCurrentProduct] = useState("DLH PACKER");
  const params = useParams(); 

  useEffect(() => {
    getHydraulicPackerTitlesService().then((result) => {
        setHydraulicPacker(result);
        setCurrentProduct(params.subLink)
        window.scrollTo(0, 0);
    });
  }, [params]);

  return (
    <div>
    <Helmet>‍
        <title>Hydraulic Packers | Maverick Downhole Technologies | Edmonton, AB</title>‍
        
      </Helmet>
      <HydraulicPackersHeader />
      <div className="product-section">
        <div className="product-btn-container">
          {hydraulicPacker.map((hydraulicPacker) => {
            return (
              <GeneralBtn
                onClick={(title) => setCurrentProduct(title)}
                title={hydraulicPacker.title}
                selected={pseudoencodeURI (hydraulicPacker.title)=== currentProduct}
                baseURL={"/products/hydraulic-packers"}
              />
            );
          })}
        </div>
        <div>
          {!currentProduct
            ? null
            : ProductController.find(
                (product) => pseudoencodeURI(product.title) === currentProduct
              )?.component}
        </div>
      </div>
      <ContactForm />
    </div>
  );
}

export default HydraulicPackers;
