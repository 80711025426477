import React from "react";
import { $, jQuery } from "jquery";

function HdRetrievablePackerTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c165">
            <td class="c43" colspan="2" rowspan="1">
              Casing
            </td>
            <td class="c243" colspan="1" rowspan="2">
              Recommended Hole Size (inches)
            </td>
            <td class="c89" colspan="1" rowspan="2">
              Gage OD (inches)
            </td>
            <td class="c89" colspan="1" rowspan="2">
              Max OD (inches)
            </td>
            <td class="c34" colspan="1" rowspan="2">
              Tool ID (inches)
            </td>
            <td class="c281" colspan="1" rowspan="2">
              Thread Connections Box Up / Pin Down
            </td>
            <td class="c284" colspan="1" rowspan="2">
              Part Number
            </td>
          </tr>
          <tr class="c150">
            <td class="c99" colspan="1" rowspan="1">
              (inches)
            </td>
            <td class="c236" colspan="1" rowspan="1">
              Weight (lbs/ft)
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="c59">
            <td class="c99" colspan="1" rowspan="2">
              4-1/2
            </td>
            <td class="c236" colspan="1" rowspan="1">
              9.5 - 13.5
            </td>
            <td class="c243" colspan="1" rowspan="1">
              3.920 - 4.090
            </td>
            <td class="c89" colspan="1" rowspan="1">
              3.750
            </td>
            <td class="c89" colspan="1" rowspan="1">
              -
            </td>
            <td class="c34" colspan="1" rowspan="1">
              1.88
            </td>
            <td class="c281" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c284" colspan="1" rowspan="1">
              61345RS
            </td>
          </tr>
          <tr class="c59">
            <td class="c236" colspan="1" rowspan="1">
              11.6 - 15.1
            </td>
            <td class="c243" colspan="1" rowspan="1">
              3.826 - 4.000
            </td>
            <td class="c89" colspan="1" rowspan="1">
              3.656
            </td>
            <td class="c89" colspan="1" rowspan="1">
              -
            </td>
            <td class="c34" colspan="1" rowspan="1">
              1.75
            </td>
            <td class="c281" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c284" colspan="1" rowspan="1">
              61346RS
            </td>
          </tr>
          <tr class="c59">
            <td class="c99" colspan="1" rowspan="2">
              5
            </td>
            <td class="c236" colspan="1" rowspan="1">
              11.5 - 15.0
            </td>
            <td class="c243" colspan="1" rowspan="1">
              4.408 - 4.560
            </td>
            <td class="c89" colspan="1" rowspan="1">
              4.125
            </td>
            <td class="c89" colspan="1" rowspan="1">
              -
            </td>
            <td class="c34" colspan="1" rowspan="1">
              1.88
            </td>
            <td class="c281" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c284" colspan="1" rowspan="1">
              61350RS
            </td>
          </tr>
          <tr class="c59">
            <td class="c236" colspan="1" rowspan="1">
              18.0 - 21.0
            </td>
            <td class="c243" colspan="1" rowspan="1">
              4.154 - 4.276
            </td>
            <td class="c89" colspan="1" rowspan="1">
              3.969
            </td>
            <td class="c89" colspan="1" rowspan="1">
              -
            </td>
            <td class="c34" colspan="1" rowspan="1">
              1.88
            </td>
            <td class="c281" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c284" colspan="1" rowspan="1">
              61352RS
            </td>
          </tr>
          <tr class="c59">
            <td class="c99" colspan="1" rowspan="2">
              5-1/2
            </td>
            <td class="c236" colspan="1" rowspan="1">
              14.0 - 20.0
            </td>
            <td class="c243" colspan="1" rowspan="1">
              4.778 - 5.012
            </td>
            <td class="c89" colspan="1" rowspan="1">
              4.625
            </td>
            <td class="c89" colspan="1" rowspan="1">
              -
            </td>
            <td class="c34" colspan="1" rowspan="1">
              2.00
            </td>
            <td class="c281" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c284" colspan="1" rowspan="1">
              61357RS
            </td>
          </tr>
          <tr class="c59">
            <td class="c236" colspan="1" rowspan="1">
              20.0 - 23.0
            </td>
            <td class="c243" colspan="1" rowspan="1">
              4.670 - 4.778
            </td>
            <td class="c89" colspan="1" rowspan="1">
              4.500
            </td>
            <td class="c89" colspan="1" rowspan="1">
              -
            </td>
            <td class="c34" colspan="1" rowspan="1">
              2.00
            </td>
            <td class="c281" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c284" colspan="1" rowspan="1">
              61356RS
            </td>
          </tr>
          <tr class="c59">
            <td class="c99" colspan="1" rowspan="3">
              5-3/4
            </td>
            <td class="c236" colspan="1" rowspan="1">
              15.0 - 16.1
            </td>
            <td class="c243" colspan="1" rowspan="1">
              5.076 - 5.115
            </td>
            <td class="c89" colspan="1" rowspan="1">
              5.000
            </td>
            <td class="c89" colspan="1" rowspan="1">
              -
            </td>
            <td class="c34" colspan="1" rowspan="1">
              2.00
            </td>
            <td class="c281" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c284" colspan="1" rowspan="1">
              61357XRS
            </td>
          </tr>
          <tr class="c59">
            <td class="c236" colspan="1" rowspan="1">
              17.6 - 19.4
            </td>
            <td class="c243" colspan="1" rowspan="1">
              4.958 - 5.021
            </td>
            <td class="c89" colspan="1" rowspan="1">
              4.875
            </td>
            <td class="c89" colspan="1" rowspan="1">
              -
            </td>
            <td class="c34" colspan="1" rowspan="1">
              2.00
            </td>
            <td class="c281" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c284" colspan="1" rowspan="1">
              61357YRS
            </td>
          </tr>
          <tr class="c59">
            <td class="c236" colspan="1" rowspan="1">
              21.5 - 24.0
            </td>
            <td class="c243" colspan="1" rowspan="1">
              4.784 - 4.879
            </td>
            <td class="c89" colspan="1" rowspan="1">
              4.720
            </td>
            <td class="c89" colspan="1" rowspan="1">
              -
            </td>
            <td class="c34" colspan="1" rowspan="1">
              2.00
            </td>
            <td class="c281" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c284" colspan="1" rowspan="1">
              61357ZRS
            </td>
          </tr>
          <tr class="c59">
            <td class="c99" colspan="1" rowspan="1">
              6
            </td>
            <td class="c236" colspan="1" rowspan="1">
              17.0 - 20.0
            </td>
            <td class="c243" colspan="1" rowspan="1">
              5.352 - 5.450
            </td>
            <td class="c89" colspan="1" rowspan="1">
              5.188
            </td>
            <td class="c89" colspan="1" rowspan="1">
              -
            </td>
            <td class="c34" colspan="1" rowspan="1">
              2.00
            </td>
            <td class="c281" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c284" colspan="1" rowspan="1">
              61360RS
            </td>
          </tr>
          <tr class="c70">
            <td class="c99" colspan="1" rowspan="4">
              6-5/8
            </td>
            <td class="c365" colspan="1" rowspan="1">
              20.0 - 24.0
            </td>
            <td class="c243" colspan="1" rowspan="1">
              5.921 - 6.049
            </td>
            <td class="c89" colspan="1" rowspan="1">
              5.750
            </td>
            <td class="c117" colspan="1" rowspan="1">
              -
            </td>
            <td class="c34" colspan="1" rowspan="1">
              2.50
            </td>
            <td class="c281" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c275" colspan="1" rowspan="1">
              61367RS
            </td>
          </tr>
          <tr class="c70">
            <td class="c365" colspan="1" rowspan="1">
              24.0 - 32.0
            </td>
            <td class="c243" colspan="1" rowspan="1">
              5.675 - 5.921
            </td>
            <td class="c89" colspan="1" rowspan="1">
              5.500
            </td>
            <td class="c117" colspan="1" rowspan="1">
              -
            </td>
            <td class="c34" colspan="1" rowspan="1">
              2.50
            </td>
            <td class="c281" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c275" colspan="1" rowspan="1">
              61365RS
            </td>
          </tr>
          <tr class="c70">
            <td class="c365" colspan="1" rowspan="1">
              32.0 - 35.0
            </td>
            <td class="c243" colspan="1" rowspan="1">
              5.575 - 5.675
            </td>
            <td class="c89" colspan="1" rowspan="1">
              5.375
            </td>
            <td class="c117" colspan="1" rowspan="1">
              -
            </td>
            <td class="c34" colspan="1" rowspan="1">
              2.00
            </td>
            <td class="c281" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c275" colspan="1" rowspan="1">
              61366RS
            </td>
          </tr>
          <tr class="c70">
            <td class="c365" colspan="1" rowspan="1">
              36.7 - 40.2
            </td>
            <td class="c243" colspan="1" rowspan="1">
              5.375 - 5.501
            </td>
            <td class="c89" colspan="1" rowspan="1">
              5.188
            </td>
            <td class="c117" colspan="1" rowspan="1">
              -
            </td>
            <td class="c34" colspan="1" rowspan="1">
              2.00
            </td>
            <td class="c281" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c275" colspan="1" rowspan="1">
              61360RS
            </td>
          </tr>
          <tr class="c70">
            <td class="c99" colspan="1" rowspan="3">
              7
            </td>
            <td class="c365" colspan="1" rowspan="1">
              17.0 - 26.0
            </td>
            <td class="c243" colspan="1" rowspan="1">
              6.276 - 6.538
            </td>
            <td class="c89" colspan="1" rowspan="1">
              6.000
            </td>
            <td class="c117" colspan="1" rowspan="1">
              6.0621
            </td>
            <td class="c34" colspan="1" rowspan="1">
              2.50
            </td>
            <td class="c281" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c275" colspan="1" rowspan="1">
              61372RS
            </td>
          </tr>
          <tr class="c70">
            <td class="c365" colspan="1" rowspan="1">
              26.0 - 32.0
            </td>
            <td class="c243" colspan="1" rowspan="1">
              6.094 - 6.276
            </td>
            <td class="c89" colspan="1" rowspan="1">
              5.875
            </td>
            <td class="c117" colspan="1" rowspan="1">
              -
            </td>
            <td class="c34" colspan="1" rowspan="1">
              2.50
            </td>
            <td class="c281" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c275" colspan="1" rowspan="1">
              61370RS
            </td>
          </tr>
          <tr class="c70">
            <td class="c365" colspan="1" rowspan="1">
              29.0 - 35.0
            </td>
            <td class="c243" colspan="1" rowspan="1">
              6.004 - 6.184
            </td>
            <td class="c89" colspan="1" rowspan="1">
              5.812
            </td>
            <td class="c117" colspan="1" rowspan="1">
              5.8741
            </td>
            <td class="c34" colspan="1" rowspan="1">
              2.50
            </td>
            <td class="c281" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c275" colspan="1" rowspan="1">
              61371RS
            </td>
          </tr>
          <tr class="c70">
            <td class="c99" colspan="1" rowspan="2">
              7-5/8
            </td>
            <td class="c365" colspan="1" rowspan="1">
              24.0 - 29.7
            </td>
            <td class="c243" colspan="1" rowspan="1">
              6.875 - 7.025
            </td>
            <td class="c89" colspan="1" rowspan="1">
              6.672
            </td>
            <td class="c117" colspan="1" rowspan="1">
              -
            </td>
            <td class="c34" colspan="1" rowspan="1">
              2.50
            </td>
            <td class="c281" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c275" colspan="1" rowspan="1">
              61375RS
            </td>
          </tr>
          <tr class="c70">
            <td class="c365" colspan="1" rowspan="1">
              33.7 - 39.0
            </td>
            <td class="c243" colspan="1" rowspan="1">
              6.625 - 6.765
            </td>
            <td class="c89" colspan="1" rowspan="1">
              6.453
            </td>
            <td class="c117" colspan="1" rowspan="1">
              6.4991
            </td>
            <td class="c34" colspan="1" rowspan="1">
              2.50
            </td>
            <td class="c281" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c275" colspan="1" rowspan="1">
              61376RS
            </td>
          </tr>
          <tr class="c70">
            <td class="c99" colspan="1" rowspan="2">
              8-5/8
            </td>
            <td class="c365" colspan="1" rowspan="1">
              20.0 - 28.0
            </td>
            <td class="c243" colspan="1" rowspan="1">
              8.017 - 8.191
            </td>
            <td class="c89" colspan="1" rowspan="1">
              7.750
            </td>
            <td class="c117" colspan="1" rowspan="1">
              -
            </td>
            <td class="c34" colspan="1" rowspan="1">
              3.00
            </td>
            <td class="c281" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c275" colspan="1" rowspan="1">
              61385RS
            </td>
          </tr>
          <tr class="c70">
            <td class="c365" colspan="1" rowspan="1">
              24.0 - 40.0
            </td>
            <td class="c243" colspan="1" rowspan="1">
              7.725 - 8.097
            </td>
            <td class="c89" colspan="1" rowspan="1">
              7.500
            </td>
            <td class="c117" colspan="1" rowspan="1">
              7.5041
            </td>
            <td class="c34" colspan="1" rowspan="1">
              3.00
            </td>
            <td class="c281" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c275" colspan="1" rowspan="1">
              61387RS
            </td>
          </tr>
          <tr class="c70">
            <td class="c99" colspan="1" rowspan="2">
              9-5/8
            </td>
            <td class="c365" colspan="1" rowspan="1">
              32.3 - 43.5
            </td>
            <td class="c243" colspan="1" rowspan="1">
              8.755 - 9.001
            </td>
            <td class="c89" colspan="1" rowspan="1">
              8.500
            </td>
            <td class="c117" colspan="1" rowspan="1">
              -
            </td>
            <td class="c34" colspan="1" rowspan="1">
              4.00
            </td>
            <td class="c281" colspan="1" rowspan="1">
              4-1/2 EUE
            </td>
            <td class="c275" colspan="1" rowspan="1">
              61396RS
            </td>
          </tr>
          <tr class="c70">
            <td class="c365" colspan="1" rowspan="1">
              43.5 - 53.5
            </td>
            <td class="c243" colspan="1" rowspan="1">
              8.535 - 8.755
            </td>
            <td class="c89" colspan="1" rowspan="1">
              8.250
            </td>
            <td class="c117" colspan="1" rowspan="1">
              -
            </td>
            <td class="c34" colspan="1" rowspan="1">
              4.00
            </td>
            <td class="c281" colspan="1" rowspan="1">
              4-1/2 EUE
            </td>
            <td class="c275" colspan="1" rowspan="1">
              61395RS
            </td>
          </tr>
          <tr class="c70">
            <td class="c99" colspan="1" rowspan="3">
              10-3/4
            </td>
            <td class="c365" colspan="1" rowspan="1">
              32.75 - 45.5
            </td>
            <td class="c243" colspan="1" rowspan="1">
              9.950 - 10.192
            </td>
            <td class="c89" colspan="1" rowspan="1">
              9.700
            </td>
            <td class="c117" colspan="1" rowspan="1">
              -
            </td>
            <td class="c34" colspan="1" rowspan="1">
              4.00
            </td>
            <td class="c281" colspan="1" rowspan="1">
              4-1/2 EUE
            </td>
            <td class="c275" colspan="1" rowspan="1">
              61301RS
            </td>
          </tr>
          <tr class="c70">
            <td class="c365" colspan="1" rowspan="1">
              51.0 - 65.7
            </td>
            <td class="c243" colspan="1" rowspan="1">
              9.560 - 9.850
            </td>
            <td class="c89" colspan="1" rowspan="1">
              9.312
            </td>
            <td class="c117" colspan="1" rowspan="1">
              9.3751
            </td>
            <td class="c34" colspan="1" rowspan="1">
              4.00
            </td>
            <td class="c281" colspan="1" rowspan="1">
              4-1/2 EUE
            </td>
            <td class="c275" colspan="1" rowspan="1">
              61310RS
            </td>
          </tr>
          <tr class="c70">
            <td class="c365" colspan="1" rowspan="1">
              65.7 - 91.2
            </td>
            <td class="c243" colspan="1" rowspan="1">
              9.032 - 9.560
            </td>
            <td class="c89" colspan="1" rowspan="1">
              8.813
            </td>
            <td class="c117" colspan="1" rowspan="1">
              -
            </td>
            <td class="c34" colspan="1" rowspan="1">
              4.00
            </td>
            <td class="c281" colspan="1" rowspan="1">
              4-1/2 EUE
            </td>
            <td class="c275" colspan="1" rowspan="1">
              61399RS
            </td>
          </tr>
          <tr class="c70">
            <td class="c99" colspan="1" rowspan="1">
              11-3/4
            </td>
            <td class="c365" colspan="1" rowspan="1">
              42.0 - 65.0
            </td>
            <td class="c243" colspan="1" rowspan="1">
              10.682 - 11.084
            </td>
            <td class="c89" colspan="1" rowspan="1">
              10.438
            </td>
            <td class="c117" colspan="1" rowspan="1">
              10.5001
            </td>
            <td class="c34" colspan="1" rowspan="1">
              4.00
            </td>
            <td class="c281" colspan="1" rowspan="1">
              4-1/2 EUE
            </td>
            <td class="c275" colspan="1" rowspan="1">
              61311RS
            </td>
          </tr>
          <tr class="c70">
            <td class="c99" colspan="1" rowspan="1">
              13-3/8
            </td>
            <td class="c365" colspan="1" rowspan="1">
              54.5 - 77.0
            </td>
            <td class="c243" colspan="1" rowspan="1">
              12.275 - 12.615
            </td>
            <td class="c89" colspan="1" rowspan="1">
              12.000
            </td>
            <td class="c117" colspan="1" rowspan="1">
              -
            </td>
            <td class="c34" colspan="1" rowspan="1">
              4.00
            </td>
            <td class="c281" colspan="1" rowspan="1">
              4-1/2 EUE
            </td>
            <td class="c275" colspan="1" rowspan="1">
              61313RS
            </td>
          </tr>
          <tr class="c200">
            <td class="c99" colspan="1" rowspan="1">
              16
            </td>
            <td class="c365" colspan="1" rowspan="1">
              65.0 - 109.0
            </td>
            <td class="c243" colspan="1" rowspan="1">
              14.688 - 15.250
            </td>
            <td class="c89" colspan="1" rowspan="1">
              14.438
            </td>
            <td class="c117" colspan="1" rowspan="1">
              -
            </td>
            <td class="c34" colspan="1" rowspan="1">
              5.00
            </td>
            <td class="c281" colspan="1" rowspan="1">
              6-5/8 API FULL &nbsp; HOLE TOOL JOINT
            </td>
            <td class="c275" colspan="1" rowspan="1">
              61316
            </td>
          </tr>
          <tr class="c200">
            <td class="c99" colspan="1" rowspan="1">
              18
            </td>
            <td class="c365" colspan="1" rowspan="1">
              78.0 - 96.5
            </td>
            <td class="c243" colspan="1" rowspan="1">
              16.986 - 17.194
            </td>
            <td class="c89" colspan="1" rowspan="1">
              16.750
            </td>
            <td class="c117" colspan="1" rowspan="1">
              -
            </td>
            <td class="c34" colspan="1" rowspan="1">
              5.00
            </td>
            <td class="c281" colspan="1" rowspan="1">
              6-5/8 API FULL HOLE TOOL JOINT
            </td>
            <td class="c275" colspan="1" rowspan="1">
              61318X
            </td>
          </tr>
          <tr class="c200">
            <td class="c99" colspan="1" rowspan="1">
              18-5/8
            </td>
            <td class="c365" colspan="1" rowspan="1">
              87.5 - 117.5
            </td>
            <td class="c243" colspan="1" rowspan="1">
              17.439 - 17.755
            </td>
            <td class="c89" colspan="1" rowspan="1">
              17.000
            </td>
            <td class="c117" colspan="1" rowspan="1">
              -
            </td>
            <td class="c34" colspan="1" rowspan="1">
              5.00
            </td>
            <td class="c281" colspan="1" rowspan="1">
              6-5/8 API FULL &nbsp; HOLE TOOL JOINT
            </td>
            <td class="c275" colspan="1" rowspan="1">
              61318
            </td>
          </tr>
          <tr class="c200">
            <td class="c99" colspan="1" rowspan="1">
              20
            </td>
            <td class="c365" colspan="1" rowspan="1">
              133.0 - 169.0
            </td>
            <td class="c243" colspan="1" rowspan="1">
              18.376 - 18.730
            </td>
            <td class="c89" colspan="1" rowspan="1">
              18.000
            </td>
            <td class="c117" colspan="1" rowspan="1">
              -
            </td>
            <td class="c34" colspan="1" rowspan="1">
              5.00
            </td>
            <td class="c281" colspan="1" rowspan="1">
              6-5/8 API FULL &nbsp; HOLE TOOL JOINT
            </td>
            <td class="c275" colspan="1" rowspan="1">
              61320
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default HdRetrievablePackerTable;
