import React from 'react'

function CertificationsHeader() {
  return (
    <div className='engineering-header'>
        <h3 className='main-subhead white'>
            Certifications
        </h3>
    </div>
  )
}

export default CertificationsHeader