import React from "react";
import { $, jQuery } from "jquery";

function AsRetrievablePackerTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c165">
            <td class="c529" colspan="2" rowspan="1">
              Casing
            </td>
            <td class="c230" colspan="1" rowspan="2">
              Recommended Hole Size (inches)
            </td>
            <td class="c320" colspan="1" rowspan="2">
              Gage OD (inches)
            </td>
            <td class="c249" colspan="1" rowspan="2">
              Max OD (inches)
            </td>
            <td class="c180" colspan="1" rowspan="2">
              Thread Connection Pin Down
            </td>
            <td class="c830" colspan="2" rowspan="1">
              Part Number
            </td>
          </tr>
          <tr class="c150">
            <td class="c251" colspan="1" rowspan="1">
              Size (inches)
            </td>
            <td class="c16" colspan="1" rowspan="1">
              Weight (lbs/ft)
            </td>
            <td class="c278" colspan="1" rowspan="1">
              Std
            </td>
            <td class="c339" colspan="1" rowspan="1">
              Wireline Set
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="c25">
            <td class="c251" colspan="1" rowspan="1">
              3-1/2
            </td>
            <td class="c16" colspan="1" rowspan="1">
              7.7 - 10.2
            </td>
            <td class="c230" colspan="1" rowspan="1">
              2.922 - 3.068
            </td>
            <td class="c320" colspan="1" rowspan="1">
              2.781
            </td>
            <td class="c249" colspan="1" rowspan="1">
              -
            </td>
            <td class="c180" colspan="1" rowspan="1">
              1.900 EUE
            </td>
            <td class="c278" colspan="1" rowspan="1">
              72535RR
            </td>
            <td class="c339" colspan="1" rowspan="1">
              72435RR
            </td>
          </tr>
          <tr class="c25">
            <td class="c251" colspan="1" rowspan="1">
              4
            </td>
            <td class="c16" colspan="1" rowspan="1">
              9.5 - 11.0
            </td>
            <td class="c230" colspan="1" rowspan="1">
              3.476 - 3.548
            </td>
            <td class="c320" colspan="1" rowspan="1">
              3.250
            </td>
            <td class="c249" colspan="1" rowspan="1">
              -
            </td>
            <td class="c180" colspan="1" rowspan="1">
              1.900 EUE
            </td>
            <td class="c278" colspan="1" rowspan="1">
              72540RR
            </td>
            <td class="c339" colspan="1" rowspan="1">
              72440RR
            </td>
          </tr>
          <tr class="c25">
            <td class="c251" colspan="1" rowspan="3">
              4-1/2
            </td>
            <td class="c16" colspan="1" rowspan="1">
              9.5 - 13.5
            </td>
            <td class="c230" colspan="1" rowspan="1">
              3.920 - 4.090
            </td>
            <td class="c320" colspan="1" rowspan="1">
              3.750
            </td>
            <td class="c249" colspan="1" rowspan="1">
              -
            </td>
            <td class="c180" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c278" colspan="1" rowspan="1">
              72545RR
            </td>
            <td class="c339" colspan="1" rowspan="1">
              72445RR
            </td>
          </tr>
          <tr class="c25">
            <td class="c16" colspan="1" rowspan="1">
              15.1
            </td>
            <td class="c230" colspan="1" rowspan="1">
              3.826
            </td>
            <td class="c320" colspan="1" rowspan="1">
              3.656
            </td>
            <td class="c249" colspan="1" rowspan="1">
              -
            </td>
            <td class="c180" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c278" colspan="1" rowspan="1">
              72544RR
            </td>
            <td class="c339" colspan="1" rowspan="1">
              72444RR
            </td>
          </tr>
          <tr class="c25">
            <td class="c16" colspan="1" rowspan="1">
              15.1 - 16.6
            </td>
            <td class="c230" colspan="1" rowspan="1">
              3.754 - 3.826
            </td>
            <td class="c320" colspan="1" rowspan="1">
              3.625
            </td>
            <td class="c249" colspan="1" rowspan="1">
              -
            </td>
            <td class="c180" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c278" colspan="1" rowspan="1">
              72541RR
            </td>
            <td class="c339" colspan="1" rowspan="1">
              72441RR
            </td>
          </tr>
          <tr class="c25">
            <td class="c251" colspan="1" rowspan="2">
              5
            </td>
            <td class="c16" colspan="1" rowspan="1">
              11.5 - 15.0
            </td>
            <td class="c230" colspan="1" rowspan="1">
              4.408 - 4.560
            </td>
            <td class="c320" colspan="1" rowspan="1">
              4.125
            </td>
            <td class="c249" colspan="1" rowspan="1">
              4.2151
            </td>
            <td class="c180" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c278" colspan="1" rowspan="1">
              72550RR
            </td>
            <td class="c27" colspan="1" rowspan="1">
              72450RR
            </td>
          </tr>
          <tr class="c25">
            <td class="c16" colspan="1" rowspan="1">
              18.0 - 21.0
            </td>
            <td class="c230" colspan="1" rowspan="1">
              4.154 - 4.276
            </td>
            <td class="c320" colspan="1" rowspan="1">
              3.969
            </td>
            <td class="c249" colspan="1" rowspan="1">
              4.0101
            </td>
            <td class="c180" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c278" colspan="1" rowspan="1">
              72552RR
            </td>
            <td class="c27" colspan="1" rowspan="1">
              72452RR
            </td>
          </tr>
          <tr class="c25">
            <td class="c251" colspan="1" rowspan="3">
              5-1/2
            </td>
            <td class="c16" colspan="1" rowspan="1">
              9.0 - 13.0
            </td>
            <td class="c230" colspan="1" rowspan="1">
              5.044 - 5.192
            </td>
            <td class="c320" colspan="1" rowspan="1">
              4.812
            </td>
            <td class="c249" colspan="1" rowspan="1">
              -
            </td>
            <td class="c180" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c278" colspan="1" rowspan="1">
              72554RR
            </td>
            <td class="c27" colspan="1" rowspan="1">
              72454RR
            </td>
          </tr>
          <tr class="c25">
            <td class="c16" colspan="1" rowspan="1">
              13.0 - 20.0
            </td>
            <td class="c230" colspan="1" rowspan="1">
              4.778 - 5.044
            </td>
            <td class="c320" colspan="1" rowspan="1">
              4.625
            </td>
            <td class="c249" colspan="1" rowspan="1">
              -
            </td>
            <td class="c180" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c278" colspan="1" rowspan="1">
              72555RR
            </td>
            <td class="c27" colspan="1" rowspan="1">
              72455RR
            </td>
          </tr>
          <tr class="c25">
            <td class="c16" colspan="1" rowspan="1">
              20.0 - 23.0
            </td>
            <td class="c230" colspan="1" rowspan="1">
              4.670 - 4.778
            </td>
            <td class="c320" colspan="1" rowspan="1">
              4.500
            </td>
            <td class="c249" colspan="1" rowspan="1">
              -
            </td>
            <td class="c180" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c278" colspan="1" rowspan="1">
              72557RR
            </td>
            <td class="c27" colspan="1" rowspan="1">
              72457RR
            </td>
          </tr>
          <tr class="c25">
            <td class="c251" colspan="1" rowspan="1">
              6
            </td>
            <td class="c16" colspan="1" rowspan="1">
              17.0 - 20.0
            </td>
            <td class="c230" colspan="1" rowspan="1">
              5.352 - 5.450
            </td>
            <td class="c320" colspan="1" rowspan="1">
              5.188
            </td>
            <td class="c249" colspan="1" rowspan="1">
              -
            </td>
            <td class="c180" colspan="1" rowspan="1">
              2-3/8 NUE
            </td>
            <td class="c278" colspan="1" rowspan="1">
              72560RR
            </td>
            <td class="c27" colspan="1" rowspan="1">
              72460RR
            </td>
          </tr>
          <tr class="c25">
            <td class="c251" colspan="1" rowspan="3">
              6-5/8
            </td>
            <td class="c16" colspan="1" rowspan="1">
              17.0 - 24.0
            </td>
            <td class="c230" colspan="1" rowspan="1">
              5.921 - 6.135
            </td>
            <td class="c320" colspan="1" rowspan="1">
              5.750
            </td>
            <td class="c249" colspan="1" rowspan="1">
              -
            </td>
            <td class="c180" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c278" colspan="1" rowspan="1">
              72567RR
            </td>
            <td class="c27" colspan="1" rowspan="1">
              72467RR
            </td>
          </tr>
          <tr class="c25">
            <td class="c16" colspan="1" rowspan="1">
              24.0 - 32.0
            </td>
            <td class="c230" colspan="1" rowspan="1">
              5.675 - 5.921
            </td>
            <td class="c320" colspan="1" rowspan="1">
              5.500
            </td>
            <td class="c249" colspan="1" rowspan="1">
              -
            </td>
            <td class="c180" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c278" colspan="1" rowspan="1">
              72565RR
            </td>
            <td class="c27" colspan="1" rowspan="1">
              72465RR
            </td>
          </tr>
          <tr class="c25">
            <td class="c16" colspan="1" rowspan="1">
              32.0 - 35.0
            </td>
            <td class="c230" colspan="1" rowspan="1">
              5.575 - 5.675
            </td>
            <td class="c320" colspan="1" rowspan="1">
              5.375
            </td>
            <td class="c249" colspan="1" rowspan="1">
              -
            </td>
            <td class="c180" colspan="1" rowspan="1">
              2-3/8 NUE
            </td>
            <td class="c278" colspan="1" rowspan="1">
              72566RR
            </td>
            <td class="c27" colspan="1" rowspan="1">
              72466RR
            </td>
          </tr>
          <tr class="c25">
            <td class="c251" colspan="1" rowspan="2">
              7
            </td>
            <td class="c16" colspan="1" rowspan="1">
              17.0 - 26.0
            </td>
            <td class="c230" colspan="1" rowspan="1">
              6.276 - 6.538
            </td>
            <td class="c320" colspan="1" rowspan="1">
              5.969
            </td>
            <td class="c249" colspan="1" rowspan="1">
              6.0621
            </td>
            <td class="c180" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c278" colspan="1" rowspan="1">
              72570RR
            </td>
            <td class="c27" colspan="1" rowspan="1">
              72470RR
            </td>
          </tr>
          <tr class="c25">
            <td class="c16" colspan="1" rowspan="1">
              26.0 - 32.0
            </td>
            <td class="c230" colspan="1" rowspan="1">
              6.094 - 6.276
            </td>
            <td class="c320" colspan="1" rowspan="1">
              5.875
            </td>
            <td class="c249" colspan="1" rowspan="1">
              -
            </td>
            <td class="c180" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c278" colspan="1" rowspan="1">
              72571RR
            </td>
            <td class="c27" colspan="1" rowspan="1">
              72471RR
            </td>
          </tr>
          <tr class="c25">
            <td class="c251" colspan="1" rowspan="3">
              7-5/8
            </td>
            <td class="c16" colspan="1" rowspan="1">
              24.0 - 29.7
            </td>
            <td class="c230" colspan="1" rowspan="1">
              6.875 - 7.025
            </td>
            <td class="c320" colspan="1" rowspan="1">
              6.672
            </td>
            <td class="c249" colspan="1" rowspan="1">
              6.6871
            </td>
            <td class="c180" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c278" colspan="1" rowspan="1">
              72575RR
            </td>
            <td class="c27" colspan="1" rowspan="1">
              72475RR
            </td>
          </tr>
          <tr class="c25">
            <td class="c16" colspan="1" rowspan="1">
              29.7 - 33.7
            </td>
            <td class="c230" colspan="1" rowspan="1">
              6.765 - 6.875
            </td>
            <td class="c320" colspan="1" rowspan="1">
              6.527
            </td>
            <td class="c249" colspan="1" rowspan="1">
              -
            </td>
            <td class="c180" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c278" colspan="1" rowspan="1">
              72576RR
            </td>
            <td class="c27" colspan="1" rowspan="1">
              72476RR
            </td>
          </tr>
          <tr class="c25">
            <td class="c16" colspan="1" rowspan="1">
              33.7 - 39.0
            </td>
            <td class="c230" colspan="1" rowspan="1">
              6.625 - 6.765
            </td>
            <td class="c320" colspan="1" rowspan="1">
              6.453
            </td>
            <td class="c249" colspan="1" rowspan="1">
              6.4991
            </td>
            <td class="c180" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c278" colspan="1" rowspan="1">
              72577RR
            </td>
            <td class="c27" colspan="1" rowspan="1">
              72477RR
            </td>
          </tr>
          <tr class="c25">
            <td class="c251" colspan="1" rowspan="2">
              8-5/8
            </td>
            <td class="c16" colspan="1" rowspan="1">
              20.0 - 28.0
            </td>
            <td class="c230" colspan="1" rowspan="1">
              8.017 - 8.191
            </td>
            <td class="c320" colspan="1" rowspan="1">
              7.750
            </td>
            <td class="c249" colspan="1" rowspan="1">
              -
            </td>
            <td class="c180" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c278" colspan="1" rowspan="1">
              72581RR
            </td>
            <td class="c27" colspan="1" rowspan="1">
              72481RR
            </td>
          </tr>
          <tr class="c25">
            <td class="c16" colspan="1" rowspan="1">
              24.0 - 40.0
            </td>
            <td class="c230" colspan="1" rowspan="1">
              7.725 - 8.097
            </td>
            <td class="c320" colspan="1" rowspan="1">
              7.500
            </td>
            <td class="c249" colspan="1" rowspan="1">
              -
            </td>
            <td class="c180" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c278" colspan="1" rowspan="1">
              72585RR
            </td>
            <td class="c27" colspan="1" rowspan="1">
              72485RR
            </td>
          </tr>
          <tr class="c25">
            <td class="c251" colspan="1" rowspan="2">
              9-5/8
            </td>
            <td class="c16" colspan="1" rowspan="1">
              32.3 - 43.5
            </td>
            <td class="c230" colspan="1" rowspan="1">
              8.755 - 9.001
            </td>
            <td class="c320" colspan="1" rowspan="1">
              8.500
            </td>
            <td class="c249" colspan="1" rowspan="1">
              -
            </td>
            <td class="c180" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c278" colspan="1" rowspan="1">
              72596RR
            </td>
            <td class="c27" colspan="1" rowspan="1">
              72496RR
            </td>
          </tr>
          <tr class="c25">
            <td class="c16" colspan="1" rowspan="1">
              43.5 - 53.5
            </td>
            <td class="c230" colspan="1" rowspan="1">
              8.535 - 8.755
            </td>
            <td class="c320" colspan="1" rowspan="1">
              8.250
            </td>
            <td class="c249" colspan="1" rowspan="1">
              -
            </td>
            <td class="c180" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c278" colspan="1" rowspan="1">
              72595RR
            </td>
            <td class="c27" colspan="1" rowspan="1">
              72495RR
            </td>
          </tr>
          <tr class="c25">
            <td class="c251" colspan="1" rowspan="2">
              10-3/4
            </td>
            <td class="c16" colspan="1" rowspan="1">
              32.75 - 51.0
            </td>
            <td class="c230" colspan="1" rowspan="1">
              9.850 - 10.192
            </td>
            <td class="c320" colspan="1" rowspan="1">
              9.625
            </td>
            <td class="c249" colspan="1" rowspan="1">
              -
            </td>
            <td class="c180" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c278" colspan="1" rowspan="1">
              72501RR
            </td>
            <td class="c27" colspan="1" rowspan="1">
              72401RR
            </td>
          </tr>
          <tr class="c25">
            <td class="c16" colspan="1" rowspan="1">
              51.0 - 65.7
            </td>
            <td class="c230" colspan="1" rowspan="1">
              9.560 - 9.850
            </td>
            <td class="c320" colspan="1" rowspan="1">
              9.312
            </td>
            <td class="c249" colspan="1" rowspan="1">
              9.3741
            </td>
            <td class="c180" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c278" colspan="1" rowspan="1">
              72510RR
            </td>
            <td class="c27" colspan="1" rowspan="1">
              72410RR
            </td>
          </tr>
          <tr class="c25">
            <td class="c251" colspan="1" rowspan="1">
              11-3/4
            </td>
            <td class="c16" colspan="1" rowspan="1">
              42.0 - 71.0
            </td>
            <td class="c230" colspan="1" rowspan="1">
              10.586 - 11.084
            </td>
            <td class="c320" colspan="1" rowspan="1">
              10.375
            </td>
            <td class="c249" colspan="1" rowspan="1">
              10.5001
            </td>
            <td class="c180" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c278" colspan="1" rowspan="1">
              72511RR
            </td>
            <td class="c27" colspan="1" rowspan="1">
              72411RR
            </td>
          </tr>
          <tr class="c25">
            <td class="c251" colspan="1" rowspan="1">
              13-3/8
            </td>
            <td class="c16" colspan="1" rowspan="1">
              54.5 - 77.0
            </td>
            <td class="c230" colspan="1" rowspan="1">
              12.275 - 12.615
            </td>
            <td class="c320" colspan="1" rowspan="1">
              12.000
            </td>
            <td class="c249" colspan="1" rowspan="1">
              -
            </td>
            <td class="c180" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c278" colspan="1" rowspan="1">
              72513RR
            </td>
            <td class="c27" colspan="1" rowspan="1">
              72413RR
            </td>
          </tr>
          <tr class="c25">
            <td class="c251" colspan="1" rowspan="1">
              14
            </td>
            <td class="c16" colspan="1" rowspan="1">
              82.5 - 101.5
            </td>
            <td class="c230" colspan="1" rowspan="1">
              12.600 - 12.876
            </td>
            <td class="c320" colspan="1" rowspan="1">
              12.438
            </td>
            <td class="c249" colspan="1" rowspan="1">
              12.5001
            </td>
            <td class="c180" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c278" colspan="1" rowspan="1">
              72514RR
            </td>
            <td class="c27" colspan="1" rowspan="1">
              72414RR
            </td>
          </tr>
          <tr class="c25">
            <td class="c251" colspan="1" rowspan="1">
              16
            </td>
            <td class="c16" colspan="1" rowspan="1">
              65.0 - 109.0
            </td>
            <td class="c230" colspan="1" rowspan="1">
              14.688 - 15.250
            </td>
            <td class="c320" colspan="1" rowspan="1">
              14.438
            </td>
            <td class="c249" colspan="1" rowspan="1">
              -
            </td>
            <td class="c180" colspan="1" rowspan="1">
              4-1/2 IF TOOL JOINT
            </td>
            <td class="c278" colspan="1" rowspan="1">
              72516RR
            </td>
            <td class="c27" colspan="1" rowspan="1">
              -
            </td>
          </tr>
          <tr class="c446">
            <td class="c251" colspan="1" rowspan="1">
              18-5/8
            </td>
            <td class="c16" colspan="1" rowspan="1">
              87.5 - 117.5
            </td>
            <td class="c230" colspan="1" rowspan="1">
              17.439 - 17.755
            </td>
            <td class="c320" colspan="1" rowspan="1">
              17.000
            </td>
            <td class="c249" colspan="1" rowspan="1">
              -
            </td>
            <td class="c180" colspan="1" rowspan="1">
              4-1/2 IF TOOL JOINT
            </td>
            <td class="c278" colspan="1" rowspan="1">
              72518RR-X BEE
            </td>
            <td class="c27" colspan="1" rowspan="1">
              -
            </td>
          </tr>
          <tr class="c446">
            <td class="c251" colspan="1" rowspan="1">
              20
            </td>
            <td class="c16" colspan="1" rowspan="1">
              133.0 - 169.0
            </td>
            <td class="c230" colspan="1" rowspan="1">
              18.376 - 18.730
            </td>
            <td class="c320" colspan="1" rowspan="1">
              18.000
            </td>
            <td class="c249" colspan="1" rowspan="1">
              -
            </td>
            <td class="c180" colspan="1" rowspan="1">
              4-1/2 IF TOOL JOINT
            </td>
            <td class="c278" colspan="1" rowspan="1">
              72520RR-X BEE
            </td>
            <td class="c27" colspan="1" rowspan="1">
              -
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default AsRetrievablePackerTable;
