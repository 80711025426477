import React from "react";
import OtherHeader from "../../components/Headers/OtherHeader";
import Privacy from "../../components/Utility/Privacy";

function PrivacyPolicy() {
  return (
    <div className="privacy-policy-container">
      <OtherHeader title="Privacy Policy" />
      <div className="privacy-text">
        <Privacy />
      </div>
    </div>
  );
}

export default PrivacyPolicy;
