import React from "react";
import { $, jQuery } from "jquery";

function ErBypassValveTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr>
            <th colspan="2">CASING OD</th>
            <th colspan="2">VALVE OD</th>
            <th colspan="2">VALVE ID</th>
            <th colspan="2">THREAD CONNECTION</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>in.</td>
            <td>mm</td>
            <td>in.</td>
            <td>mm</td>
            <td>in.</td>
            <td>mm</td>
            <td>in.</td>
            <td>mm</td>
          </tr>
          <tr>
            <td>
              4<sup>1/2</sup>
            </td>
            <td>114.3</td>
            <td>3.75</td>
            <td>95.2</td>
            <td>1.93</td>
            <td>49.0</td>
            <td>2 3/8 EUE</td>
            <td>60.3</td>
          </tr>
          <tr>
            <td>5</td>
            <td>127.0</td>
            <td>3.75</td>
            <td>95.2</td>
            <td>1.93</td>
            <td>49.0</td>
            <td>2 3/8 EUE</td>
            <td>60.3</td>
          </tr>
          <tr>
            <td>
              5<sup>1/2</sup>
            </td>
            <td>139.7</td>
            <td>3.75</td>
            <td>95.2</td>
            <td>1.93</td>
            <td>49.0</td>
            <td>2 3/8 EUE</td>
            <td>60.3</td>
          </tr>
          <tr>
            <td>
              6<sup>5/8</sup>
            </td>
            <td>168.3</td>
            <td>3.75</td>
            <td>95.2</td>
            <td>1.93</td>
            <td>49.0</td>
            <td>2 3/8 EUE</td>
            <td>60.3</td>
          </tr>
          <tr>
            <td>7</td>
            <td>177.8</td>
            <td>5.00</td>
            <td>127.0</td>
            <td>2.44</td>
            <td>62.0</td>
            <td>2 7/8 EUE</td>
            <td>73.0</td>
          </tr>
          <tr>
            <td>
              8<sup>5/8</sup>
            </td>
            <td>219.1</td>
            <td>5.00</td>
            <td>127.0</td>
            <td>2.44</td>
            <td>62.0</td>
            <td>2 7/8 EUE</td>
            <td>73.0</td>
          </tr>
          <tr>
            <td>
              9<sup>5/8</sup>
            </td>
            <td>244.5</td>
            <td>5.00</td>
            <td>127.0</td>
            <td>2.44</td>
            <td>62.0</td>
            <td>2 7/8 EUE</td>
            <td>73.0</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default ErBypassValveTable;
