import React from "react";
import { $, jQuery } from "jquery";

function AnnulusTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c83">
            <td class="c161" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Tubing Size </span>
              </p>
              <p class="c5">
                <span class="c7">(inches)</span>
              </p>
            </td>
            <td class="c100" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Tool OD </span>
              </p>
              <p class="c5">
                <span class="c7">(inches)</span>
              </p>
            </td>
            <td class="c163" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Tool ID </span>
              </p>
              <p class="c5">
                <span class="c7">(inches)</span>
              </p>
            </td>
            <td class="c85" colspan="1" rowspan="1">
              <p class="c148">
                <span class="c7">
                  Thread Connections Box &nbsp;Up / Pin Down
                </span>
              </p>
            </td>
            <td class="c67" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Part </span>
              </p>
              <p class="c5">
                <span class="c7">Number</span>
              </p>
            </td>
          </tr>
        </thead>

        <tbody>
          <tr class="c21">
            <td class="c161" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2-7/8 </span>
              </p>
            </td>
            <td class="c100" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">3.750 </span>
              </p>
            </td>
            <td class="c163" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2.50 </span>
              </p>
            </td>
            <td class="c85" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2-7/8 EUE </span>
              </p>
            </td>
            <td class="c67" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">55025</span>
              </p>
            </td>
          </tr>
          <tr class="c21">
            <td class="c161" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">3-1/2 </span>
              </p>
            </td>
            <td class="c100" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">4.750 </span>
              </p>
            </td>
            <td class="c163" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">3.00 </span>
              </p>
            </td>
            <td class="c85" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">3-1/2 EUE </span>
              </p>
            </td>
            <td class="c67" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">55035</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default AnnulusTable;
