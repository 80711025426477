import React from 'react'

function EngineeringHeader() {
  return (
    <div className='engineering-header'>
        <h3 className='main-subhead white'>
            Engineering
        </h3>
    </div>
  )
}

export default EngineeringHeader