import React from "react";
import { $, jQuery } from "jquery";

function AsiXAnchorTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr>
            <th colSpan={2}>Casing</th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Recommended Hole Size (Inches)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Tool OD (Inches)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Tool ID (Inches)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Thread Connections Box Up / Pin Down
            </th>
            <th rowSpan={2}>Part Number</th>
          </tr>
          <tr>
            <th>Size (inches)</th>
            <th>Weight (lbs/ft)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>3-1/2</td>
            <td>7.7-10.2</td>
            <td>2.922-3.068</td>
            <td>2.781</td>
            <td>1.25</td>
            <td>1.900 NUE</td>
            <td>32335</td>
          </tr>
          <tr>
            <td rowSpan={2}>4-1/2</td>
            <td>9.5-13.5</td>
            <td>3.920-4.090</td>
            <td>3.750</td>
            <td>1.94</td>
            <td>2-3/8 EUE</td>
            <td>32345</td>
          </tr>
          <tr>
            <td>13.5-15.1</td>
            <td>3.826-3.920</td>
            <td>3.650</td>
            <td>1.94</td>
            <td>2-3/8 EUE</td>
            <td>32344</td>
          </tr>
          <tr>
            <td>5</td>
            <td>18.0-20.8</td>
            <td>4.156-4.276</td>
            <td>4.00</td>
            <td>1.94</td>
            <td>2-3/8 EUE</td>
            <td>32352</td>
          </tr>
          <tr>
            <td rowSpan={3}>5-1/2</td>
            <td>14.0-20.0</td>
            <td>4.778-5.012</td>
            <td>4.625</td>
            <td>2.38</td>
            <td>2-7/8 EUE</td>
            <td>32356</td>
          </tr>
          <tr>
            <td>20.0-23.0</td>
            <td>4.670-4.788</td>
            <td>4.500</td>
            <td>2.38</td>
            <td>2-7/8 EUE</td>
            <td>32359</td>
          </tr>
          <tr>
            <td>23.0-26.0</td>
            <td>4.548-4.670</td>
            <td>4.406</td>
            <td>2.38</td>
            <td>2-7/8 EUE</td>
            <td>32351</td>
          </tr>
          <tr>
            <td rowSpan={3}>7</td>
            <td rowSpan={2}>17.0-26.0</td>
            <td rowSpan={2}>6.276-6.538</td>
            <td rowSpan={2}>6.000</td>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>32372</td>
          </tr>
          <tr>
            <td>3.00</td>
            <td>3-1/2 EUE</td>
            <td>32374</td>
          </tr>
          <tr>
            <td>26.0-32.0</td>
            <td>6.094-6.276</td>
            <td>5.875</td>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>32370</td>
          </tr>
          <tr>
            <td>7-5/8</td>
            <td>24.0-29.7</td>
            <td>6.875-7.025</td>
            <td>6.453</td>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>32375</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default AsiXAnchorTable;
