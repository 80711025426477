import HomeHero from "../components/Home/HomeHero/HomeHero";
import ContactForm from "../components/ContactForm/ContactForm";
import AboutUs from "../components/Home/AboutUs/AboutUs";
import ReliableToolSupply from "../components/Home/ReliableToolSupply/ReliableToolSupply";
import ProductsHome from "../components/Home/ProductsHome/ProductsHome";
import Preloader from "../components/Preloader/preloader";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import MetaImg from "../assets/images/meta-img.jpg";
import MaverickLogoBlack from "../assets/logos/maverick-logo-black.png";

function Home() {
  const metaDescription =
    "Maverick’s mission is to act as the preferred supply partner of downhole tools to the world’s most important service companies.";
  const title =
    "Maverick Downhole Technologies | Oilfield Supplier | Edmonton, Alberta";

  return (
    <div>
      <Helmet>
        <meta
          name="image"
          content="https://i.postimg.cc/hG1nXVgR/meta-img.jpg"
          href="https://i.postimg.cc/hG1nXVgR/meta-img.jpg"
        />
        <meta property="description" content={metaDescription} />
        {/* Facebook */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content="https://i.postimg.cc/hG1nXVgR/meta-img.jpg" />‍
        <meta property="og:url" content="https:www.maverickdownhole.com" />
        {/* Twitter */}
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={metaDescription} />
        <meta
          property="twitter:image"
          content="https://i.postimg.cc/hG1nXVgR/meta-img.jpg"
        />
        ‍
        <meta property="twitter:url" content="https:www.maverickdownhole.com" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Corporation",
            name: "Maverick Downhole Technologies",
            alternateName: "Maverick",
            url: "https://www.maverickdownhole.com",
            logo: { MaverickLogoBlack },
          })}
        </script>
      </Helmet>
      <Preloader />
      <HomeHero />
      <AboutUs />
      <ReliableToolSupply />
      <ProductsHome />
      <ContactForm />
    </div>
  );
}

export default Home;
