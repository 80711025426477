import React, { useEffect, useState } from "react";
import { getAllProductService } from "../../../services/dept";
import Hm1bBridgePlugTable from "./Tables/hm1bBridgePlugTable";
import { Helmet } from "react-helmet";

function Hm1bBridgePlug(props) {
  const { title } = props;
  const [products, setProducts] = useState([]);
  const [currentProduct, setCurrentProduct] = useState({});

  useEffect(() => {
    getAllProductService().then((loadedProducts) => {
      setProducts(loadedProducts);
      setCurrentProduct(
        loadedProducts.find((product) => product.title === title)
      );
    });
  }, []);

  // console.log(currentProduct);
  return (
    <div className="product-info">
      <Helmet>‍
      <meta
          name="image"
          content="https://i.postimg.cc/hG1nXVgR/meta-img.jpg"
          href="https://i.postimg.cc/hG1nXVgR/meta-img.jpg"
        />
        <meta property="description" content={currentProduct.description} />
        {/* Facebook */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={currentProduct.description} />
        <meta property="og:image" content="https://i.postimg.cc/hG1nXVgR/meta-img.jpg" />‍
        <meta property="og:url" content="https:www.maverickdownhole.com" />
        {/* Twitter */}
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={currentProduct.description} />
        <meta
          property="twitter:image"
          content="https://i.postimg.cc/hG1nXVgR/meta-img.jpg"
        />
        ‍
        <meta property="twitter:url" content="https:www.maverickdownhole.com" />
      <title>{`${title} | Maverick Downhole Technologies | Edmonton, AB`}</title>
      <script type="application/ld+json">
      {JSON.stringify({
      "@context": "https://schema.org/", 
      "@type": "Product", 
      "name": title,
      "image": "",
      "description":  currentProduct.description,
      "brand": {
        "@type": "Brand",
        "name": "Maverick Downhole Technologies"
      }
      })}
      </script>
      </Helmet>
      <div>
        <h3 className="main-subhead">
          {currentProduct !== {} ? currentProduct.title : null}
        </h3>
        <h4 className="smaller-head">
          {currentProduct !== {} ? currentProduct.subhead : null}
        </h4>
        <div className="spacer"></div>
        <p className="body-text">
          {currentProduct !== {} ? currentProduct.description : null}
        </p>
        <Hm1bBridgePlugTable />
      </div>
      <div>
        {/* <img
          src={Db10BridgePlugImg}
          title={currentProduct.title}
          alt={currentProduct.title}
          className="product-img"
        /> */}
      </div>
    </div>
  );
}

export default Hm1bBridgePlug;
