import React from "react";
import { $, jQuery } from "jquery";

function PermapakSingleTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c165">
            <td class="c755" colspan="2" rowspan="1">
              Casing
            </td>
            <td class="c64" colspan="1" rowspan="2">
              Recommended Hole Size (inches)
            </td>
            <td class="c142" colspan="1" rowspan="2">
              Tool OD (inches)
            </td>
            <td class="c344" colspan="1" rowspan="2">
              Packer Bore (inches)
            </td>
            <td class="c97" colspan="1" rowspan="2">
              Min ID Thru Seals (inches)
            </td>
            <td class="c414" colspan="1" rowspan="2">
              Part &nbsp; Number
            </td>
          </tr>
          <tr class="c364">
            <td class="c240" colspan="1" rowspan="1">
              Size (inches)
            </td>
            <td class="c308" colspan="1" rowspan="1">
              Weight (lbs/ft)
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="c25">
            <td class="c240" colspan="1" rowspan="1">
              3-1/2
            </td>
            <td class="c308" colspan="1" rowspan="1">
              7.7 - 10.2
            </td>
            <td class="c64" colspan="1" rowspan="1">
              2.922 - 3.068
            </td>
            <td class="c142" colspan="1" rowspan="1">
              2.750
            </td>
            <td class="c344" colspan="1" rowspan="1">
              1.750
            </td>
            <td class="c97" colspan="1" rowspan="1">
              1.187
            </td>
            <td class="c414" colspan="1" rowspan="1">
              67235
            </td>
          </tr>
          <tr class="c25">
            <td class="c240" colspan="1" rowspan="2">
              4
            </td>
            <td class="c308" colspan="1" rowspan="1">
              9.5 - 11.6
            </td>
            <td class="c64" colspan="1" rowspan="1">
              3.428 - 3.548
            </td>
            <td class="c142" colspan="1" rowspan="1">
              3.281
            </td>
            <td class="c344" colspan="1" rowspan="1">
              1.750
            </td>
            <td class="c97" colspan="1" rowspan="1">
              1.187
            </td>
            <td class="c414" colspan="1" rowspan="1">
              67240
            </td>
          </tr>
          <tr class="c25">
            <td class="c308" colspan="1" rowspan="1">
              11.6 - 14.8
            </td>
            <td class="c64" colspan="1" rowspan="1">
              3.240 - 3.428
            </td>
            <td class="c142" colspan="1" rowspan="1">
              3.090
            </td>
            <td class="c344" colspan="1" rowspan="1">
              1.750
            </td>
            <td class="c97" colspan="1" rowspan="1">
              1.187
            </td>
            <td class="c414" colspan="1" rowspan="1">
              67241
            </td>
          </tr>
          <tr class="c25">
            <td class="c240" colspan="1" rowspan="3">
              4-1/2
            </td>
            <td class="c308" colspan="1" rowspan="2">
              9.5 - 13.5
            </td>
            <td class="c64" colspan="1" rowspan="2">
              3.920 - 4.090
            </td>
            <td class="c142" colspan="1" rowspan="2">
              3.750
            </td>
            <td class="c344" colspan="1" rowspan="1">
              2.500
            </td>
            <td class="c97" colspan="1" rowspan="1">
              1.910
            </td>
            <td class="c414" colspan="1" rowspan="1">
              67045
            </td>
          </tr>
          <tr class="c25">
            <td class="c344" colspan="1" rowspan="1">
              2.688
            </td>
            <td class="c97" colspan="1" rowspan="1">
              1.938
            </td>
            <td class="c414" colspan="1" rowspan="1">
              67245
            </td>
          </tr>
          <tr class="c25">
            <td class="c308" colspan="1" rowspan="1">
              15.1
            </td>
            <td class="c64" colspan="1" rowspan="1">
              3.826
            </td>
            <td class="c142" colspan="1" rowspan="1">
              3.600
            </td>
            <td class="c344" colspan="1" rowspan="1">
              2.500
            </td>
            <td class="c97" colspan="1" rowspan="1">
              1.910
            </td>
            <td class="c414" colspan="1" rowspan="1">
              67246
            </td>
          </tr>
          <tr class="c25">
            <td class="c240" colspan="1" rowspan="2">
              5
            </td>
            <td class="c308" colspan="1" rowspan="2">
              15.0 - 21.4
            </td>
            <td class="c64" colspan="1" rowspan="2">
              4.126 - 4.408
            </td>
            <td class="c142" colspan="1" rowspan="2">
              3.968
            </td>
            <td class="c344" colspan="1" rowspan="1">
              2.500
            </td>
            <td class="c97" colspan="1" rowspan="1">
              1.910
            </td>
            <td class="c414" colspan="1" rowspan="1">
              67050
            </td>
          </tr>
          <tr class="c25">
            <td class="c344" colspan="1" rowspan="1">
              2.688
            </td>
            <td class="c97" colspan="1" rowspan="1">
              1.938
            </td>
            <td class="c414" colspan="1" rowspan="1">
              67250
            </td>
          </tr>
          <tr class="c25">
            <td class="c240" colspan="1" rowspan="5">
              5-1/2
            </td>
            <td class="c308" colspan="1" rowspan="2">
              &nbsp;13.0 - 17.0
            </td>
            <td class="c64" colspan="1" rowspan="2">
              4.892 - 5.044
            </td>
            <td class="c142" colspan="1" rowspan="2">
              4.532
            </td>
            <td class="c344" colspan="1" rowspan="1">
              2.688
            </td>
            <td class="c97" colspan="1" rowspan="1">
              1.938
            </td>
            <td class="c414" colspan="1" rowspan="1">
              67055
            </td>
          </tr>
          <tr class="c25">
            <td class="c344" colspan="1" rowspan="1">
              3.000
            </td>
            <td class="c97" colspan="1" rowspan="1">
              2.375
            </td>
            <td class="c414" colspan="1" rowspan="1">
              67255
            </td>
          </tr>
          <tr class="c25">
            <td class="c308" colspan="1" rowspan="2">
              17.0 - 23.0
            </td>
            <td class="c64" colspan="1" rowspan="2">
              4.670 - 4.892
            </td>
            <td class="c142" colspan="1" rowspan="2">
              4.438
            </td>
            <td class="c344" colspan="1" rowspan="1">
              2.688
            </td>
            <td class="c97" colspan="1" rowspan="1">
              1.938
            </td>
            <td class="c414" colspan="1" rowspan="1">
              67056
            </td>
          </tr>
          <tr class="c25">
            <td class="c344" colspan="1" rowspan="1">
              3.000
            </td>
            <td class="c97" colspan="1" rowspan="1">
              2.375
            </td>
            <td class="c414" colspan="1" rowspan="1">
              67256
            </td>
          </tr>
          <tr class="c25">
            <td class="c308" colspan="1" rowspan="1">
              23.0 - 26.0
            </td>
            <td class="c64" colspan="1" rowspan="1">
              4.548 - 4.670
            </td>
            <td class="c142" colspan="1" rowspan="1">
              4.250
            </td>
            <td class="c344" colspan="1" rowspan="1">
              2.688
            </td>
            <td class="c97" colspan="1" rowspan="1">
              1.938
            </td>
            <td class="c414" colspan="1" rowspan="1">
              67057
            </td>
          </tr>
          <tr class="c25">
            <td class="c90" colspan="1" rowspan="1">
              6
            </td>
            <td class="c181" colspan="1" rowspan="1">
              18.0 - 26.0
            </td>
            <td class="c309" colspan="1" rowspan="1">
              5.132 - 5.424
            </td>
            <td class="c8" colspan="1" rowspan="1">
              4.875
            </td>
            <td class="c73" colspan="1" rowspan="1">
              3.000
            </td>
            <td class="c12" colspan="1" rowspan="1">
              2.375
            </td>
            <td class="c137" colspan="1" rowspan="1">
              67260
            </td>
          </tr>
          <tr class="c25">
            <td class="c90" colspan="1" rowspan="1">
              6-5/8
            </td>
            <td class="c181" colspan="1" rowspan="1">
              17.0 - 32.0
            </td>
            <td class="c309" colspan="1" rowspan="1">
              5.675 - 6.135
            </td>
            <td class="c8" colspan="1" rowspan="1">
              5.468
            </td>
            <td class="c73" colspan="1" rowspan="1">
              3.250
            </td>
            <td class="c12" colspan="1" rowspan="1">
              2.416
            </td>
            <td class="c137" colspan="1" rowspan="1">
              67065
            </td>
          </tr>
          <tr class="c25">
            <td class="c90" colspan="1" rowspan="6">
              7
            </td>
            <td class="c181" colspan="1" rowspan="2">
              17.0 - 23.0
            </td>
            <td class="c309" colspan="1" rowspan="2">
              6.366 - 6.538
            </td>
            <td class="c8" colspan="1" rowspan="2">
              6.000
            </td>
            <td class="c73" colspan="1" rowspan="1">
              3.250
            </td>
            <td class="c12" colspan="1" rowspan="1">
              2.406
            </td>
            <td class="c137" colspan="1" rowspan="1">
              67070
            </td>
          </tr>
          <tr class="c25">
            <td class="c73" colspan="1" rowspan="1">
              4.000
            </td>
            <td class="c12" colspan="1" rowspan="1">
              3.000
            </td>
            <td class="c137" colspan="1" rowspan="1">
              67270
            </td>
          </tr>
          <tr class="c25">
            <td class="c181" colspan="1" rowspan="2">
              23.0 - 32.0
            </td>
            <td class="c309" colspan="1" rowspan="2">
              6.094 - 6.366
            </td>
            <td class="c8" colspan="1" rowspan="2">
              5.813
            </td>
            <td class="c73" colspan="1" rowspan="1">
              3.250
            </td>
            <td class="c12" colspan="1" rowspan="1">
              2.406
            </td>
            <td class="c137" colspan="1" rowspan="1">
              67071
            </td>
          </tr>
          <tr class="c25">
            <td class="c73" colspan="1" rowspan="1">
              4.000
            </td>
            <td class="c12" colspan="1" rowspan="1">
              3.000
            </td>
            <td class="c137" colspan="1" rowspan="1">
              67271
            </td>
          </tr>
          <tr class="c25">
            <td class="c181" colspan="1" rowspan="2">
              32.0 - 38.0
            </td>
            <td class="c309" colspan="1" rowspan="2">
              5.920 - 6.094
            </td>
            <td class="c8" colspan="1" rowspan="2">
              5.625
            </td>
            <td class="c73" colspan="1" rowspan="1">
              3.250
            </td>
            <td class="c12" colspan="1" rowspan="1">
              2.406
            </td>
            <td class="c137" colspan="1" rowspan="1">
              67072
            </td>
          </tr>
          <tr class="c25">
            <td class="c73" colspan="1" rowspan="1">
              4.000
            </td>
            <td class="c12" colspan="1" rowspan="1">
              3.000
            </td>
            <td class="c137" colspan="1" rowspan="1">
              67272
            </td>
          </tr>
          <tr class="c25">
            <td class="c90" colspan="1" rowspan="2">
              7-5/8
            </td>
            <td class="c181" colspan="1" rowspan="2">
              24.0 - 39.0
            </td>
            <td class="c309" colspan="1" rowspan="2">
              6.625 - 7.025
            </td>
            <td class="c8" colspan="1" rowspan="2">
              6.250
            </td>
            <td class="c73" colspan="1" rowspan="1">
              3.250
            </td>
            <td class="c12" colspan="1" rowspan="1">
              2.416
            </td>
            <td class="c137" colspan="1" rowspan="1">
              67075
            </td>
          </tr>
          <tr class="c25">
            <td class="c73" colspan="1" rowspan="1">
              4.000
            </td>
            <td class="c12" colspan="1" rowspan="1">
              3.000
            </td>
            <td class="c137" colspan="1" rowspan="1">
              67275
            </td>
          </tr>
          <tr class="c25">
            <td class="c90" colspan="1" rowspan="2">
              8-5/8
            </td>
            <td class="c181" colspan="1" rowspan="2">
              24.0 - 32.0
            </td>
            <td class="c309" colspan="1" rowspan="2">
              7.921 - 8.097
            </td>
            <td class="c8" colspan="1" rowspan="2">
              7.625
            </td>
            <td class="c73" colspan="1" rowspan="1">
              4.000
            </td>
            <td class="c12" colspan="1" rowspan="1">
              3.000
            </td>
            <td class="c137" colspan="1" rowspan="1">
              67085
            </td>
          </tr>
          <tr class="c25">
            <td class="c73" colspan="1" rowspan="1">
              5.250
            </td>
            <td class="c12" colspan="1" rowspan="1">
              4.250
            </td>
            <td class="c137" colspan="1" rowspan="1">
              67286
            </td>
          </tr>
          <tr class="c25">
            <td class="c90" colspan="1" rowspan="4">
              9-5/8
            </td>
            <td class="c181" colspan="1" rowspan="4">
              36.0 - 53.5
            </td>
            <td class="c309" colspan="1" rowspan="4">
              8.535 - 8.921
            </td>
            <td class="c8" colspan="1" rowspan="4">
              8.125
            </td>
            <td class="c73" colspan="1" rowspan="1">
              3.250
            </td>
            <td class="c12" colspan="1" rowspan="1">
              2.416
            </td>
            <td class="c137" colspan="1" rowspan="1">
              67093
            </td>
          </tr>
          <tr class="c25">
            <td class="c73" colspan="1" rowspan="1">
              4.000
            </td>
            <td class="c12" colspan="1" rowspan="1">
              3.000
            </td>
            <td class="c137" colspan="1" rowspan="1">
              67094
            </td>
          </tr>
          <tr class="c25">
            <td class="c73" colspan="1" rowspan="1">
              4.750
            </td>
            <td class="c12" colspan="1" rowspan="1">
              3.500
            </td>
            <td class="c137" colspan="1" rowspan="1">
              67095
            </td>
          </tr>
          <tr class="c25">
            <td class="c73" colspan="1" rowspan="1">
              6.000
            </td>
            <td class="c12" colspan="1" rowspan="1">
              4.750
            </td>
            <td class="c137" colspan="1" rowspan="1">
              67295
            </td>
          </tr>
          <tr class="c25">
            <td class="c90" colspan="1" rowspan="3">
              10-3/4
            </td>
            <td class="c181" colspan="1" rowspan="2">
              40.5 - 55.5
            </td>
            <td class="c309" colspan="1" rowspan="2">
              9.760 - 10.050
            </td>
            <td class="c8" colspan="1" rowspan="1">
              9.496
            </td>
            <td class="c73" colspan="1" rowspan="1">
              4.000
            </td>
            <td class="c12" colspan="1" rowspan="1">
              3.000
            </td>
            <td class="c137" colspan="1" rowspan="1">
              67010
            </td>
          </tr>
          <tr class="c25">
            <td class="c8" colspan="1" rowspan="1">
              9.300
            </td>
            <td class="c73" colspan="1" rowspan="1">
              6.000
            </td>
            <td class="c12" colspan="1" rowspan="1">
              4.750
            </td>
            <td class="c137" colspan="1" rowspan="1">
              67210
            </td>
          </tr>
          <tr class="c25">
            <td class="c181" colspan="1" rowspan="1">
              45.5 - 60.7
            </td>
            <td class="c309" colspan="1" rowspan="1">
              9.660 - 9.950
            </td>
            <td class="c8" colspan="1" rowspan="1">
              9.300
            </td>
            <td class="c73" colspan="1" rowspan="1">
              4.000
            </td>
            <td class="c12" colspan="1" rowspan="1">
              3.000
            </td>
            <td class="c137" colspan="1" rowspan="1">
              67010-9.3
            </td>
          </tr>
          <tr class="c25">
            <td class="c90" colspan="1" rowspan="1">
              11-3/4
            </td>
            <td class="c181" colspan="1" rowspan="1">
              42.0 - 65.0
            </td>
            <td class="c309" colspan="1" rowspan="1">
              10.682 - 11.084
            </td>
            <td class="c8" colspan="1" rowspan="1">
              10.430
            </td>
            <td class="c73" colspan="1" rowspan="1">
              6.000
            </td>
            <td class="c12" colspan="1" rowspan="1">
              4.750
            </td>
            <td class="c137" colspan="1" rowspan="1">
              67211
            </td>
          </tr>
          <tr class="c25">
            <td class="c90" colspan="1" rowspan="1">
              13-3/8
            </td>
            <td class="c181" colspan="1" rowspan="1">
              54.5 - 77.0
            </td>
            <td class="c309" colspan="1" rowspan="1">
              12.275 - 12.615
            </td>
            <td class="c8" colspan="1" rowspan="1">
              12.000
            </td>
            <td class="c73" colspan="1" rowspan="1">
              9.000
            </td>
            <td class="c12" colspan="1" rowspan="1">
              8.000
            </td>
            <td class="c137" colspan="1" rowspan="1">
              67213
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default PermapakSingleTable;
