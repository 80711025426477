import React from "react";
import { $, jQuery } from "jquery";

function TypeDl() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c165">
            <td class="c295" colspan="2" rowspan="1">
              Casing
            </td>
            <td class="c10" colspan="1" rowspan="2">
              Recommended Hole Size (inches)
            </td>
            <td class="c117" colspan="1" rowspan="2">
              Tool OD (inches)
            </td>
            <td class="c142" colspan="1" rowspan="2">
              Tool ID (inches)
            </td>
            <td class="c22" colspan="1" rowspan="2">
              Thread Connections Box Up / Pin Down
            </td>
            <td class="c23" colspan="1" rowspan="2">
              Part Number
            </td>
          </tr>
          <tr class="c150">
            <td class="c240" colspan="1" rowspan="1">
              Size (inches)
            </td>
            <td class="c62" colspan="1" rowspan="1">
              Weight (lbs/ft)
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="c46">
            <td class="c240" colspan="1" rowspan="1">
              3-1/2
            </td>
            <td class="c62" colspan="1" rowspan="1">
              7.7 - 10.3
            </td>
            <td class="c10" colspan="1" rowspan="1">
              2.922 - 3.068
            </td>
            <td class="c117" colspan="1" rowspan="1">
              2.781
            </td>
            <td class="c142" colspan="1" rowspan="1">
              1.53
            </td>
            <td class="c22" colspan="1" rowspan="1">
              1.900 EUE
            </td>
            <td class="c23" colspan="1" rowspan="1">
              43635
            </td>
          </tr>
          <tr class="c46">
            <td class="c240" colspan="1" rowspan="5">
              4-1/2
            </td>
            <td class="c62" colspan="1" rowspan="2">
              9.5 - 11.6
            </td>
            <td class="c10" colspan="1" rowspan="2">
              4.000 - 4.090
            </td>
            <td class="c117" colspan="1" rowspan="2">
              3.844
            </td>
            <td class="c142" colspan="1" rowspan="1">
              1.94
            </td>
            <td class="c22" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c23" colspan="1" rowspan="1">
              43644
            </td>
          </tr>
          <tr class="c46">
            <td class="c142" colspan="1" rowspan="1">
              2.38
            </td>
            <td class="c22" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c23" colspan="1" rowspan="1">
              43646
            </td>
          </tr>
          <tr class="c46">
            <td class="c62" colspan="1" rowspan="2">
              9.5 - 13.5
            </td>
            <td class="c10" colspan="1" rowspan="2">
              3.920 - 4.090
            </td>
            <td class="c117" colspan="1" rowspan="2">
              3.750
            </td>
            <td class="c142" colspan="1" rowspan="1">
              1.50
            </td>
            <td class="c22" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c23" colspan="1" rowspan="1">
              43647
            </td>
          </tr>
          <tr class="c46">
            <td class="c142" colspan="1" rowspan="1">
              1.94
            </td>
            <td class="c22" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c23" colspan="1" rowspan="1">
              43645
            </td>
          </tr>
          <tr class="c46">
            <td class="c62" colspan="1" rowspan="1">
              15.1
            </td>
            <td class="c10" colspan="1" rowspan="1">
              3.826
            </td>
            <td class="c117" colspan="1" rowspan="1">
              3.650
            </td>
            <td class="c142" colspan="1" rowspan="1">
              1.94
            </td>
            <td class="c22" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c23" colspan="1" rowspan="1">
              43643
            </td>
          </tr>
          <tr class="c46">
            <td class="c240" colspan="1" rowspan="3">
              5
            </td>
            <td class="c62" colspan="1" rowspan="2">
              15.0 - 18.0
            </td>
            <td class="c10" colspan="1" rowspan="2">
              4.276 - 4.408
            </td>
            <td class="c117" colspan="1" rowspan="2">
              4.125
            </td>
            <td class="c142" colspan="1" rowspan="1">
              1.94
            </td>
            <td class="c22" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c23" colspan="1" rowspan="1">
              43651
            </td>
          </tr>
          <tr class="c46">
            <td class="c142" colspan="1" rowspan="1">
              2.38
            </td>
            <td class="c22" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c23" colspan="1" rowspan="1">
              43650
            </td>
          </tr>
          <tr class="c46">
            <td class="c62" colspan="1" rowspan="1">
              18.0 - 20.8
            </td>
            <td class="c10" colspan="1" rowspan="1">
              4.156 - 4.276
            </td>
            <td class="c117" colspan="1" rowspan="1">
              4.000
            </td>
            <td class="c142" colspan="1" rowspan="1">
              1.94
            </td>
            <td class="c22" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c23" colspan="1" rowspan="1">
              43652
            </td>
          </tr>
          <tr class="c46">
            <td class="c240" colspan="1" rowspan="6">
              5-1/2
            </td>
            <td class="c62" colspan="1" rowspan="2">
              13.0 - 15.5
            </td>
            <td class="c10" colspan="1" rowspan="2">
              4.950 - 5.044
            </td>
            <td class="c117" colspan="1" rowspan="2">
              4.781
            </td>
            <td class="c142" colspan="1" rowspan="1">
              2.00
            </td>
            <td class="c22" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c23" colspan="1" rowspan="1">
              43655
            </td>
          </tr>
          <tr class="c46">
            <td class="c142" colspan="1" rowspan="1">
              2.44
            </td>
            <td class="c22" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c23" colspan="1" rowspan="1">
              43656
            </td>
          </tr>
          <tr class="c46">
            <td class="c62" colspan="1" rowspan="1">
              13.0 - 20.0
            </td>
            <td class="c10" colspan="1" rowspan="1">
              4.778 - 5.044
            </td>
            <td class="c117" colspan="1" rowspan="1">
              4.750
            </td>
            <td class="c142" colspan="1" rowspan="1">
              3.00
            </td>
            <td class="c22" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c23" colspan="1" rowspan="1">
              43653
            </td>
          </tr>
          <tr class="c46">
            <td class="c62" colspan="1" rowspan="2">
              15.5 - 20.0
            </td>
            <td class="c10" colspan="1" rowspan="2">
              4.778 - 4.950
            </td>
            <td class="c117" colspan="1" rowspan="2">
              4.641
            </td>
            <td class="c142" colspan="1" rowspan="1">
              2.00
            </td>
            <td class="c22" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c23" colspan="1" rowspan="1">
              43657
            </td>
          </tr>
          <tr class="c46">
            <td class="c142" colspan="1" rowspan="1">
              2.44
            </td>
            <td class="c22" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c23" colspan="1" rowspan="1">
              43658
            </td>
          </tr>
          <tr class="c46">
            <td class="c62" colspan="1" rowspan="1">
              15.5 - 23.0
            </td>
            <td class="c10" colspan="1" rowspan="1">
              4.670 - 4.950
            </td>
            <td class="c117" colspan="1" rowspan="1">
              4.500
            </td>
            <td class="c142" colspan="1" rowspan="1">
              2.00
            </td>
            <td class="c22" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c23" colspan="1" rowspan="1">
              43654
            </td>
          </tr>
          <tr class="c46">
            <td class="c240" colspan="1" rowspan="3">
              7
            </td>
            <td class="c62" colspan="1" rowspan="2">
              17.0 - 23.0
            </td>
            <td class="c10" colspan="1" rowspan="2">
              6.366 - 6.538
            </td>
            <td class="c117" colspan="1" rowspan="2">
              6.125
            </td>
            <td class="c142" colspan="1" rowspan="1">
              2.00
            </td>
            <td class="c22" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c23" colspan="1" rowspan="1">
              43671
            </td>
          </tr>
          <tr class="c46">
            <td class="c142" colspan="1" rowspan="1">
              2.44
            </td>
            <td class="c22" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c23" colspan="1" rowspan="1">
              43670
            </td>
          </tr>
          <tr class="c46">
            <td class="c62" colspan="1" rowspan="1">
              17.0 - 26.0
            </td>
            <td class="c10" colspan="1" rowspan="1">
              6.276 - 6.538
            </td>
            <td class="c117" colspan="1" rowspan="1">
              6.000
            </td>
            <td class="c142" colspan="1" rowspan="1">
              3.00
            </td>
            <td class="c22" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c23" colspan="1" rowspan="1">
              43673
            </td>
          </tr>
          <tr class="c46">
            <td class="c240" colspan="1" rowspan="1">
              7-5/8
            </td>
            <td class="c62" colspan="1" rowspan="1">
              24.0 - 29.7
            </td>
            <td class="c10" colspan="1" rowspan="1">
              6.875 - 7.025
            </td>
            <td class="c117" colspan="1" rowspan="1">
              6.672
            </td>
            <td class="c142" colspan="1" rowspan="1">
              2.44
            </td>
            <td class="c22" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c23" colspan="1" rowspan="1">
              43675
            </td>
          </tr>
          <tr class="c46">
            <td class="c240" colspan="1" rowspan="2">
              9-5/8
            </td>
            <td class="c62" colspan="1" rowspan="1">
              32.3 - 43.5
            </td>
            <td class="c10" colspan="1" rowspan="1">
              8.755 - 9.001
            </td>
            <td class="c117" colspan="1" rowspan="1">
              8.500
            </td>
            <td class="c142" colspan="1" rowspan="1">
              3.00
            </td>
            <td class="c22" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c23" colspan="1" rowspan="1">
              43696
            </td>
          </tr>
          <tr class="c46">
            <td class="c62" colspan="1" rowspan="1">
              43.5 - 53.5
            </td>
            <td class="c10" colspan="1" rowspan="1">
              8.535 - 8.755
            </td>
            <td class="c117" colspan="1" rowspan="1">
              8.250
            </td>
            <td class="c142" colspan="1" rowspan="1">
              2.50
            </td>
            <td class="c22" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c23" colspan="1" rowspan="1">
              43695
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default TypeDl;
