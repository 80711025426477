import React from "react";
import SeaharvestLogo from '../../assets/logos/SeharvestLogo.jpg'

function AgentsSection(props) {
  return (
    <>
      <section className="agent-section">
        <h3 className="main-subhead centre">
          Exclusive<span className="bold"> Agents</span>
        </h3>
        <div className="agent-container">
            <img src={SeaharvestLogo} alt="Seaharvest" className="seaharvet-logo"/>
            <p className="agent-headline">Exclusive Agent for Egypt</p>
        </div>
      </section>
    </>
  );
}

export default AgentsSection;
