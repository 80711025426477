import React from "react";
import { $, jQuery } from "jquery";

function TypeTSqueezePackerTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c165">
            <td class="c481" colspan="2" rowspan="1">
              Casing
            </td>
            <td class="c279" colspan="1" rowspan="2">
              Recommended Hole Size (inches)
            </td>
            <td class="c77" colspan="1" rowspan="2">
              Gage OD (inches)
            </td>
            <td class="c77" colspan="1" rowspan="2">
              Max OD (inches)
            </td>
            <td class="c136" colspan="1" rowspan="2">
              Tool ID (inches)
            </td>
            <td class="c297" colspan="1" rowspan="2">
              Thread Connections Box Up / Pin Down
            </td>
            <td class="c66" colspan="1" rowspan="2">
              Part Number
            </td>
          </tr>
          <tr class="c150">
            <td class="c53" colspan="1" rowspan="1">
              (inches)
            </td>
            <td class="c77" colspan="1" rowspan="1">
              Weight (lbs/ft)
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="c59">
            <td class="c53" colspan="1" rowspan="1">
              2-7/8
            </td>
            <td class="c77" colspan="1" rowspan="1">
              6.4 - 6.5
            </td>
            <td class="c279" colspan="1" rowspan="1">
              2.375 - 2.441
            </td>
            <td class="c77" colspan="1" rowspan="1">
              2.250
            </td>
            <td class="c77" colspan="1" rowspan="1">
              -
            </td>
            <td class="c136" colspan="1" rowspan="1">
              0.75
            </td>
            <td class="c297" colspan="1" rowspan="1">
              1.660 EUE
            </td>
            <td class="c66" colspan="1" rowspan="1">
              62125RM
            </td>
          </tr>
          <tr class="c59">
            <td class="c53" colspan="1" rowspan="1">
              4
            </td>
            <td class="c77" colspan="1" rowspan="1">
              9.5 - 11.0
            </td>
            <td class="c279" colspan="1" rowspan="1">
              3.476 - 3.548
            </td>
            <td class="c77" colspan="1" rowspan="1">
              3.250
            </td>
            <td class="c77" colspan="1" rowspan="1">
              -
            </td>
            <td class="c136" colspan="1" rowspan="1">
              1.63
            </td>
            <td class="c297" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c66" colspan="1" rowspan="1">
              62140RM
            </td>
          </tr>
          <tr class="c59">
            <td class="c53" colspan="1" rowspan="2">
              4-1/2
            </td>
            <td class="c77" colspan="1" rowspan="1">
              9.5 - 13.5
            </td>
            <td class="c279" colspan="1" rowspan="1">
              3.920 - 4.090
            </td>
            <td class="c77" colspan="1" rowspan="1">
              3.750
            </td>
            <td class="c77" colspan="1" rowspan="1">
              -
            </td>
            <td class="c136" colspan="1" rowspan="1">
              2.00
            </td>
            <td class="c297" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c66" colspan="1" rowspan="1">
              62145RM
            </td>
          </tr>
          <tr class="c59">
            <td class="c77" colspan="1" rowspan="1">
              15.1 - 16.9
            </td>
            <td class="c279" colspan="1" rowspan="1">
              3.740 - 3.826
            </td>
            <td class="c77" colspan="1" rowspan="1">
              3.594
            </td>
            <td class="c77" colspan="1" rowspan="1">
              -
            </td>
            <td class="c136" colspan="1" rowspan="1">
              1.63
            </td>
            <td class="c297" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c66" colspan="1" rowspan="1">
              62143RM
            </td>
          </tr>
          <tr class="c59">
            <td class="c53" colspan="1" rowspan="2">
              5
            </td>
            <td class="c77" colspan="1" rowspan="1">
              13.0 - 18.0
            </td>
            <td class="c279" colspan="1" rowspan="1">
              4.276 - 4.494
            </td>
            <td class="c77" colspan="1" rowspan="1">
              4.125
            </td>
            <td class="c77" colspan="1" rowspan="1">
              -
            </td>
            <td class="c136" colspan="1" rowspan="1">
              2.00
            </td>
            <td class="c297" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c66" colspan="1" rowspan="1">
              62150RM
            </td>
          </tr>
          <tr class="c59">
            <td class="c77" colspan="1" rowspan="1">
              18.0 - 20.8
            </td>
            <td class="c279" colspan="1" rowspan="1">
              4.156 - 4.276
            </td>
            <td class="c77" colspan="1" rowspan="1">
              4.000
            </td>
            <td class="c77" colspan="1" rowspan="1">
              -
            </td>
            <td class="c136" colspan="1" rowspan="1">
              2.00
            </td>
            <td class="c297" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c66" colspan="1" rowspan="1">
              62152RM
            </td>
          </tr>
          <tr class="c59">
            <td class="c53" colspan="1" rowspan="2">
              5-1/2
            </td>
            <td class="c77" colspan="1" rowspan="1">
              14.0 - 20.0
            </td>
            <td class="c279" colspan="1" rowspan="1">
              4.778 - 5.012
            </td>
            <td class="c77" colspan="1" rowspan="1">
              4.625
            </td>
            <td class="c77" colspan="1" rowspan="1">
              -
            </td>
            <td class="c136" colspan="1" rowspan="1">
              2.00
            </td>
            <td class="c297" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c66" colspan="1" rowspan="1">
              62155RM
            </td>
          </tr>
          <tr class="c59">
            <td class="c77" colspan="1" rowspan="1">
              20.0 - 23.0
            </td>
            <td class="c279" colspan="1" rowspan="1">
              4.670 - 4.778
            </td>
            <td class="c77" colspan="1" rowspan="1">
              4.500
            </td>
            <td class="c77" colspan="1" rowspan="1">
              -
            </td>
            <td class="c136" colspan="1" rowspan="1">
              2.00
            </td>
            <td class="c297" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c66" colspan="1" rowspan="1">
              62157RM
            </td>
          </tr>
          <tr class="c59">
            <td class="c53" colspan="1" rowspan="1">
              6
            </td>
            <td class="c77" colspan="1" rowspan="1">
              14.0 - 20.0
            </td>
            <td class="c279" colspan="1" rowspan="1">
              5.352 - 5.552
            </td>
            <td class="c77" colspan="1" rowspan="1">
              5.188
            </td>
            <td class="c77" colspan="1" rowspan="1">
              -
            </td>
            <td class="c136" colspan="1" rowspan="1">
              2.00
            </td>
            <td class="c297" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c66" colspan="1" rowspan="1">
              62160RM
            </td>
          </tr>
          <tr class="c59">
            <td class="c53" colspan="1" rowspan="1">
              6-5/8
            </td>
            <td class="c77" colspan="1" rowspan="1">
              24.0 - 28.0
            </td>
            <td class="c279" colspan="1" rowspan="1">
              5.791 - 5.921
            </td>
            <td class="c77" colspan="1" rowspan="1">
              5.625
            </td>
            <td class="c77" colspan="1" rowspan="1">
              -
            </td>
            <td class="c136" colspan="1" rowspan="1">
              2.50
            </td>
            <td class="c297" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c66" colspan="1" rowspan="1">
              62165RM
            </td>
          </tr>
          <tr class="c59">
            <td class="c53" colspan="1" rowspan="2">
              7
            </td>
            <td class="c77" colspan="1" rowspan="1">
              17.0 - 26.0
            </td>
            <td class="c279" colspan="1" rowspan="1">
              6.276 - 6.538
            </td>
            <td class="c77" colspan="1" rowspan="1">
              6.000
            </td>
            <td class="c77" colspan="1" rowspan="1">
              6.0571
            </td>
            <td class="c136" colspan="1" rowspan="1">
              2.50
            </td>
            <td class="c297" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c66" colspan="1" rowspan="1">
              62171RM
            </td>
          </tr>
          <tr class="c59">
            <td class="c77" colspan="1" rowspan="1">
              26.0 - 32.0
            </td>
            <td class="c279" colspan="1" rowspan="1">
              6.094 - 6.276
            </td>
            <td class="c77" colspan="1" rowspan="1">
              5.875
            </td>
            <td class="c77" colspan="1" rowspan="1">
              -
            </td>
            <td class="c136" colspan="1" rowspan="1">
              2.50
            </td>
            <td class="c297" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c66" colspan="1" rowspan="1">
              62170RM
            </td>
          </tr>
          <tr class="c59">
            <td class="c53" colspan="1" rowspan="2">
              7-5/8
            </td>
            <td class="c77" colspan="1" rowspan="1">
              24.0 - 29.7
            </td>
            <td class="c279" colspan="1" rowspan="1">
              6.875 - 7.025
            </td>
            <td class="c77" colspan="1" rowspan="1">
              6.672
            </td>
            <td class="c77" colspan="1" rowspan="1">
              -
            </td>
            <td class="c136" colspan="1" rowspan="1">
              2.50
            </td>
            <td class="c297" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c66" colspan="1" rowspan="1">
              62175RM
            </td>
          </tr>
          <tr class="c59">
            <td class="c77" colspan="1" rowspan="1">
              33.7 - 39.0
            </td>
            <td class="c279" colspan="1" rowspan="1">
              6.625 - 6.765
            </td>
            <td class="c77" colspan="1" rowspan="1">
              6.453
            </td>
            <td class="c77" colspan="1" rowspan="1">
              -
            </td>
            <td class="c136" colspan="1" rowspan="1">
              2.50
            </td>
            <td class="c297" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c66" colspan="1" rowspan="1">
              62177RM
            </td>
          </tr>
          <tr class="c59">
            <td class="c53" colspan="1" rowspan="2">
              8-5/8
            </td>
            <td class="c77" colspan="1" rowspan="1">
              20.0 - 28.0
            </td>
            <td class="c279" colspan="1" rowspan="1">
              8.017 - 8.191
            </td>
            <td class="c77" colspan="1" rowspan="1">
              7.750
            </td>
            <td class="c77" colspan="1" rowspan="1">
              -
            </td>
            <td class="c136" colspan="1" rowspan="1">
              3.00
            </td>
            <td class="c297" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c66" colspan="1" rowspan="1">
              62186RM
            </td>
          </tr>
          <tr class="c59">
            <td class="c77" colspan="1" rowspan="1">
              28.0 - 40.0
            </td>
            <td class="c279" colspan="1" rowspan="1">
              7.725 - 8.097
            </td>
            <td class="c77" colspan="1" rowspan="1">
              7.500
            </td>
            <td class="c77" colspan="1" rowspan="1">
              -
            </td>
            <td class="c136" colspan="1" rowspan="1">
              3.00
            </td>
            <td class="c297" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c66" colspan="1" rowspan="1">
              62185RM
            </td>
          </tr>
          <tr class="c59">
            <td class="c53" colspan="1" rowspan="2">
              9-5/8
            </td>
            <td class="c77" colspan="1" rowspan="1">
              32.3 - 43.5
            </td>
            <td class="c279" colspan="1" rowspan="1">
              8.755 - 9.001
            </td>
            <td class="c77" colspan="1" rowspan="1">
              8.500
            </td>
            <td class="c77" colspan="1" rowspan="1">
              -
            </td>
            <td class="c136" colspan="1" rowspan="1">
              3.00
            </td>
            <td class="c297" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c66" colspan="1" rowspan="1">
              62195RM
            </td>
          </tr>
          <tr class="c59">
            <td class="c77" colspan="1" rowspan="1">
              43.5 - 53.5
            </td>
            <td class="c279" colspan="1" rowspan="1">
              8.535 - 8.755
            </td>
            <td class="c77" colspan="1" rowspan="1">
              8.250
            </td>
            <td class="c77" colspan="1" rowspan="1">
              -
            </td>
            <td class="c136" colspan="1" rowspan="1">
              3.00
            </td>
            <td class="c297" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c66" colspan="1" rowspan="1">
              62196RM
            </td>
          </tr>
          <tr class="c59">
            <td class="c53" colspan="1" rowspan="2">
              10-3/4
            </td>
            <td class="c77" colspan="1" rowspan="1">
              32.75 - 55.5
            </td>
            <td class="c279" colspan="1" rowspan="1">
              9.760 - 10.192
            </td>
            <td class="c77" colspan="1" rowspan="1">
              9.500
            </td>
            <td class="c77" colspan="1" rowspan="1">
              -
            </td>
            <td class="c136" colspan="1" rowspan="1">
              4.00
            </td>
            <td class="c297" colspan="1" rowspan="1">
              4-1/2 EUE
            </td>
            <td class="c66" colspan="1" rowspan="1">
              62110RM
            </td>
          </tr>
          <tr class="c59">
            <td class="c77" colspan="1" rowspan="1">
              60.7 - 85.3
            </td>
            <td class="c279" colspan="1" rowspan="1">
              9.156 - 9.660
            </td>
            <td class="c77" colspan="1" rowspan="1">
              9.000
            </td>
            <td class="c77" colspan="1" rowspan="1">
              -
            </td>
            <td class="c136" colspan="1" rowspan="1">
              4.00
            </td>
            <td class="c297" colspan="1" rowspan="1">
              4-1/2 EUE
            </td>
            <td class="c66" colspan="1" rowspan="1">
              62110XRM
            </td>
          </tr>
          <tr class="c59">
            <td class="c53" colspan="1" rowspan="1">
              13-3/8
            </td>
            <td class="c77" colspan="1" rowspan="1">
              48.0 - 77.0
            </td>
            <td class="c279" colspan="1" rowspan="1">
              12.275 - 12.715
            </td>
            <td class="c77" colspan="1" rowspan="1">
              12.000
            </td>
            <td class="c77" colspan="1" rowspan="1">
              -
            </td>
            <td class="c136" colspan="1" rowspan="1">
              4.00
            </td>
            <td class="c297" colspan="1" rowspan="1">
              4-1/2 EUE
            </td>
            <td class="c66" colspan="1" rowspan="1">
              62113RM
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default TypeTSqueezePackerTable;
