import React from "react";
import ContactForm from "../../components/ContactForm/ContactForm";
import { useEffect, useState } from "react";
import { getAllProductService } from "../../services/dept";
import { getPermanentPackerTitlesService } from "../../services/dept";
import GeneralBtn from "../../components/ColumnBtns/GeneralBtn";
import GeneralHeader from '../../components/Headers/GeneralHeader'
import { Helmet } from 'react-helmet';
import WLAK from '../../components/Products/PermanentPackers/WLAK'
import PermapakSingle from '../../components/Products/PermanentPackers/PermapakSingle'
import PermapakDual from '../../components/Products/PermanentPackers/PermapakDual'
import HydraulicPermapak from '../../components/Products/PermanentPackers/HydraulicPermapak'
import PermapakAccessories from '../../components/Products/PermanentPackers/PermapakAccessories'
import { useParams } from "react-router-dom";
import { pseudoencodeURI } from "./converter";

const ProductController = [
  {
    title: "PERMAPAK ACCESSORIES",
    component: <PermapakAccessories title="PERMAPAK ACCESSORIES" />,
  },
  {
    title: "WIRELINE ADAPTER KIT",
    component: <WLAK title="WIRELINE ADAPTER KIT" />,
  },
  {
    title: "PERMAPAK PACKER - SINGLE BORE",
    component: <PermapakSingle title="PERMAPAK PACKER - SINGLE BORE" />,
  },
  {
    title: "PERMAPAK PACKER - DUAL BORE",
    component: <PermapakDual title="PERMAPAK PACKER - DUAL BORE" />,
  },
  {
    title: "HYDRAULIC PERMAPAK PACKER",
    component: <HydraulicPermapak title="HYDRAULIC PERMAPAK PACKER" />,
  },
];

function PermanentPacker() {
  const [permanentPacker, setPermanentPacker] = useState([]);
  const [currentProduct, setCurrentProduct] = useState("PERMAPAK ACCESSORIES");
  const params = useParams(); 

  useEffect(() => {
    getPermanentPackerTitlesService().then((result) => {
        setPermanentPacker(result);
        setCurrentProduct(params.subLink)
        window.scrollTo(0, 0);

    });
  }, [params]);

  return (
    <div>
    <Helmet>‍
        <title>Permanent Packers | Maverick Downhole Technologies | Edmonton, AB</title>‍
        
      </Helmet>
      <GeneralHeader title="Permanent Packers" />
      <div className="product-section">
        <div className="product-btn-container">
          {permanentPacker.map((permanentPacker) => {
            return (
              <GeneralBtn
                onClick={(title) => setCurrentProduct(title)}
                title={permanentPacker.title}
                selected={pseudoencodeURI(permanentPacker.title) === currentProduct}
                baseURL={"/products/permanent-packers"}
              />
            );
          })}
        </div>
        <div>
          {!currentProduct
            ? null
            : ProductController.find(
                (product) => pseudoencodeURI(product.title) === currentProduct
              )?.component}
        </div>
      </div>
      <ContactForm />
    </div>
  );
}

export default PermanentPacker;
