import React from 'react'
import {$,jQuery} from 'jquery';

function Hm2BridgePlugTable() {
    
  return (
    <div>  
        <table id="example" class="table table-striped table-bordered" cellspacing="0" width="100%">
        <thead>
            <tr>
                <th colSpan={2}>Casing OD</th>
                <th colSpan={2}>Casing Weight</th>
                <th colSpan={2}>Tool OD</th>
                <th colSpan={2}>Overall Lenth</th>
            </tr>
        </thead>
 
        <tbody>
            <tr style={{backgroundColor: '#BCBEC0'}}>
                <td>in.</td>
                <td>mm</td>
                <td>lbs/ft</td>
                <td>kg/m</td>
                <td>in.</td>
                <td>mm</td>
                <td>in.</td>
                <td>mm</td>
            </tr>
            <tr>
                <td>2 <sup>3/8</sup></td>
                <td>60.3</td>
                <td>4.70</td>
                <td>7.0</td>
                <td>1.750</td>
                <td>44.45</td>
                <td>16.38</td>
                <td>416.1</td>
            </tr>
            <tr>
                <td>2 <sup>7/8</sup></td>
                <td>73.0</td>
                <td>6.40-7.90</td>
                <td>9.5-11.8</td>
                <td>2.150</td>
                <td>54.61</td>
                <td>17.50</td>
                <td>444.5</td>
            </tr>
            <tr>
                <td>3 <sup>1/2</sup></td>
                <td>88.9</td>
                <td>7.70</td>
                <td>11.4</td>
                <td>2.825</td>
                <td>71.25</td>
                <td>17.50</td>
                <td>444.5</td>
            </tr>
            <tr>
                <td>3 <sup>1/2</sup></td>
                <td>88.9</td>
                <td>9.30-10.20</td>
                <td>13.8-15.2</td>
                <td>2.725</td>
                <td>69.22</td>
                <td>17.50</td>
                <td>444.5</td>
            </tr>
            <tr>
                <td>3 <sup>1/2</sup></td>
                <td>88.9</td>
                <td>12.95</td>
                <td>19.3</td>
                <td>2.500</td>
                <td>63.50</td>
                <td>17.50</td>
                <td>444.5</td>
            </tr>
            <tr>
                <td>4</td>
                <td>101.6</td>
                <td>9.50-11.00</td>
                <td>14.1-16.4</td>
                <td>3.188</td>
                <td>80.98</td>
                <td>23.88</td>
                <td>606.6</td>
            </tr>
            <tr>
                <td>4 <sup>1/2</sup></td>
                <td>114.3</td>
                <td>9.50-13.50</td>
                <td>14.1-20.1</td>
                <td>3.570</td>
                <td>90.67</td>
                <td>23.88</td>
                <td>606.6</td>
            </tr>
            <tr>
                <td>4 <sup>1/2</sup></td>
                <td>114.3</td>
                <td>15.10-17.10</td>
                <td>22.5-25.4</td>
                <td>3.440</td>
                <td>87.38</td>
                <td>23.88</td>
                <td>606.6</td>
            </tr>
            <tr>
                <td>4 <sup>1/2</sup></td>
                <td>114.3</td>
                <td>18.80-20.00</td>
                <td>28.0-29.8</td>
                <td>3.375</td>
                <td>85.73</td>
                <td>23.88</td>
                <td>606.6</td>
            </tr>
            <tr>
                <td>5</td>
                <td>127.0</td>
                <td>11.50-18.00</td>
                <td>17.1-26.8</td>
                <td>3.920</td>
                <td>99.57</td>
                <td>23.88</td>
                <td>606.6</td>
            </tr>
            <tr>
                <td>5 <sup>1/2</sup></td>
                <td>139.7</td>
                <td>14.00</td>
                <td>20.8</td>
                <td>4.600</td>
                <td>116.80</td>
                <td>23.80</td>
                <td>604.5</td>
            </tr>
            <tr>
                <td>5 <sup>1/2</sup></td>
                <td>139.7</td>
                <td>15.50-23.00</td>
                <td>23.1-34.2</td>
                <td>4.300</td>
                <td>109.22</td>
                <td>23.80</td>
                <td>604.5</td>
            </tr>
            <tr>
                <td>7</td>
                <td>177.8</td>
                <td>17.00-20.00</td>
                <td>25.3-29.8</td>
                <td>5.950</td>
                <td>151.10</td>
                <td>24.00</td>
                <td>609.6</td>
            </tr>
            <tr>
                <td>7</td>
                <td>177.8</td>
                <td>23.00-32.00</td>
                <td>34.2-47.6</td>
                <td>5.750</td>
                <td>146.05</td>
                <td>24.00</td>
                <td>609.6</td>
            </tr>
            <tr>
                <td>7 <sup>5/8</sup></td>
                <td>193.7</td>
                <td>24.00-33.70</td>
                <td>35.7-50.2</td>
                <td>6.250</td>
                <td>158.75</td>
                <td>24.00</td>
                <td>609.6</td>
            </tr>
        </tbody>
        </table>
    </div>
  )
}

export default Hm2BridgePlugTable