import React from "react";
import { $, jQuery } from "jquery";

function GasVentTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr>
            <th colSpan={2}>Casing</th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Liner Size (Inches)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Tubing Size (Inches)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Tool OD (Inches)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
             Tool ID
            </th>
            <th rowSpan={2}>Part Number</th>
          </tr>
          <tr>
            <th>Size (inches)</th>
            <th>Weight (lbs/ft)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>4-1/2</td>
            <td>9.5-13.5</td>
            <td>3" Line Pipe</td>
            <td>2-3/8</td>
            <td>3.813</td>
            <td>2.69</td>
            <td>42145</td>
          </tr>
          <tr>
            <td rowSpan={2}>5-1/2</td>
            <td rowSpan={2}>13.0-20.0</td>
            <td>3" Line Pipe</td>
            <td>2-3/8</td>
            <td>4.000</td>
            <td>2.75</td>
            <td>42155</td>
          </tr>
          <tr>
            <td>3-1/2" Line Pipe</td>
            <td>2-7/8</td>
            <td>4.630</td>
            <td>3.13</td>
            <td>42156</td>
          </tr>
          <tr>
            <td rowSpan={2}>7</td>
            <td rowSpan={2}>20.0-29.0</td>
            <td rowSpan={2}>4-1/2" LTC</td>
            <td>2-3/8</td>
            <td>5.000</td>
            <td>2.75</td>
            <td>42171</td>
          </tr>
          <tr>
            <td>2-7/8</td>
            <td>5.000</td>
            <td>3.13</td>
            <td>42170</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default GasVentTable;
