
 export const pseudoencodeURI = (title) => {
    let test = title.split('/')
    let result = test[0]
    for (let len = 1; len < test.length; ++len){
      result = result + "-" + test[len]
      
    }
    // navigate(`${baseURL}/${result}`, {replace: true})
    return result; 
  }

  