import React from 'react'
import CertificationsHeader from '../components/Certifications/CertificationsHeader'
import CertificationsMain from '../components/Certifications/CertificationsMain'
import ContacForm from '../components/ContactForm/ContactForm'

function Certifications() {
  return (
    <div>
      <CertificationsHeader />  
      <CertificationsMain />
      <ContacForm />
    </div>
  )
}

export default Certifications