import React from "react";
import { $, jQuery } from "jquery";

function DlespTable() {
  return (
    <div>
      <table
        id="dlsesp"
        class="table table-striped table-bordered dlesp-table"
        cellspacing="0"
      >
        <thead>
          <tr>
            <th colspan="2">CASING</th>
            <th colspan="1" rowspan="2">
              RECOMMENDED HOLE SIZE
              <br />
              (inches)
            </th>
            <th colspan="1" rowspan="2">
              TOOL OD
              <br />
              (inches)
            </th>
            <th colspan="2">LONG STRING</th>
            <th colspan="2">SHORT STRING</th>
            <th  className="wider" colspan="1" rowspan="2">
              Feed Thru
              Holes ID
              (inches)
            </th>
            <th colspan="1" rowspan="2">
              Part
              <br />
              Number
            </th>
          </tr>
          <tr>
            <th colspan="1">SIZE (inches)</th>
            <th colspan="1">WEIGHT (lbs/ft)</th>
            <th colspan="1">CONNECTIONS</th>
            <th colspan="1">MIN ID (inches)</th>
            <th colspan="1">CONNECTIONS</th>
            <th colspan="1">MIN ID (inches)</th>
          </tr>
        </thead>
        <tbody>
        <tr>
      <td>4-1/2</td>
      <td>9.5-13.5</td>
      <td>3.920-4.090</td>
      <td>3.781</td>
      <td>1.900 EUE</td>
      <td>1.50</td>
      <td>-</td>
      <td>-</td>
      <td className="wider">3/8 NPT X<br />1/4NPT =<br />0.42</td>
      <td>94845-BAA-2</td>
    </tr>
    <tr>
      <td>5-1/2</td>
      <td>14.0-20.0</td>
      <td>4.778-5.012</td>
      <td>4.625</td>
      <td>1.900 EUE</td>
      <td>1.00</td>
      <td>-</td>
      <td>-</td>
      <td>(QTY 4)<br />3/8 NPT =<br />0.50</td>
      <td>94855V-BBA-5</td>
    </tr>
    <tr>
      <td>6-5/8</td>
      <td>20.0-28.0</td>
      <td>5.791-6.049</td>
      <td>5.640</td>
      <td>2 7/8 EUE</td>
      <td>2.39</td>
      <td>-</td>
      <td>-</td>
      <td>(QTY 5)<br />1/2 NPT =<br />0.62</td>
      <td>94866-BAC-5</td>
    </tr>
    <tr>
      <td rowspan="2">7</td>
      <td rowspan="2">26.0-32.0</td>
      <td rowspan="2">6.094-6.276</td>
      <td rowspan="2">5.938</td>
      <td rowspan="2">2 7/8 EUE</td>
      <td rowspan="2">2.39</td>
      <td rowspan="2">1.900 NUE</td>
      <td rowspan="2">1.50</td>
      <td>3/8 NPT =<br />0.50 AND<br />1/2 NPT =<br />0.50</td>
      <td>98470-BAC-BBA-2</td>
    </tr>
    <tr>
      <td>3/8 NPT =<br />0.50 AND<br />3/4 NPT =<br />0.82</td>
      <td>94870-BAC-BBA-3</td>
    </tr>
    <tr>
      <td>7-5/8</td>
      <td>24.0-29.7</td>
      <td>6.875-7.025</td>
      <td>6.672</td>
      <td>3 1/2 EUE</td>
      <td>2.94</td>
      <td>1.900 EUE/<br />1.900 NUE</td>
      <td>1.50</td>
      <td>3/4 NPT =<br />0.82 AND<br />3/8 NPT =<br />0.50</td>
      <td>94875-BAE-BBA-3</td>
    </tr>
        </tbody>
      </table>
    </div>
  );
}

export default DlespTable;
