import React from 'react'
import {$,jQuery} from 'jquery';

function ElOnOffToolTable() {
    
  return (
    <div>  
        <table id="example" class="table table-striped table-bordered" cellspacing="0" width="100%">
        <thead>
            <tr>
                <th colSpan={2}>Tubing Size</th>
                <th colSpan={2}>Length</th>
                <th colSpan={2}>Seal Bore</th>
                <th colSpan={2}>Tool OD</th>
            </tr>
        </thead>
 
        <tbody>
            <tr style={{backgroundColor: '#BCBEC0'}}>
                <td>in.</td>
                <td>mm</td>
               <td>in.</td>
               <td>mm</td>
               <td>in.</td>
               <td>mm</td>
               <td>in.</td>
               <td>mm</td>
            </tr>
            <tr>
                <td>2 <sup>3/8</sup></td>
                <td>60.33</td>
               <td>31.97</td>
               <td>812.04</td>
               <td>1.781</td>
               <td>45.23</td>
               <td>2.910</td>
               <td>73.91</td>
            </tr>
            <tr>
                <td>2 <sup>3/8</sup></td>
                <td>60.33</td>
               <td>31.97</td>
               <td>812.04</td>
               <td>1.812</td>
               <td>46.02</td>
               <td>2.910</td>
               <td>73.91</td>
            </tr>
            <tr>
                <td>2 <sup>3/8</sup></td>
                <td>60.33</td>
               <td>31.97</td>
               <td>812.04</td>
               <td>1.875</td>
               <td>47.63</td>
               <td>2.910</td>
               <td>73.91</td>
            </tr>
            <tr>
                <td>2 <sup>7/8</sup></td>
                <td>73.03</td>
               <td>35.28</td>
               <td>896.11</td>
               <td>2.250</td>
               <td>57.15</td>
               <td>3.410</td>
               <td>86.61</td>
            </tr>
            <tr>
                <td>2 <sup>7/8</sup></td>
                <td>73.03</td>
               <td>35.28</td>
               <td>896.11</td>
               <td>2.312</td>
               <td>58.72</td>
               <td>3.410</td>
               <td>86.61</td>
            </tr>
            <tr>
                <td>3 <sup>1/2</sup></td>
                <td>88.90</td>
               <td>37.66</td>
               <td>956.56</td>
               <td>2.750</td>
               <td>69.85</td>
               <td>4.500</td>
               <td>114.30</td>
            </tr>
            <tr>
                <td>3 <sup>1/2</sup></td>
                <td>88.90</td>
               <td>37.66</td>
               <td>956.56</td>
               <td>2.812</td>
               <td>71.42</td>
               <td>4.500</td>
               <td>114.30</td>
            </tr>
            <tr>
                <td>4 <sup>1/2</sup></td>
                <td>114.30</td>
               <td>46.70</td>
               <td>1189.18</td>
               <td>3.688</td>
               <td>93.68</td>
               <td>5.562</td>
               <td>141.27</td>
            </tr>
            <tr>
                <td>4 <sup>1/2</sup></td>
                <td>114.30</td>
               <td>46.70</td>
               <td>1189.18</td>
               <td>3.812</td>
               <td>96.82</td>
               <td>5.562</td>
               <td>141.27</td>
            </tr>
        </tbody>
        </table>
    </div>
  )
}

export default ElOnOffToolTable