import React from 'react'
import {$,jQuery} from 'jquery';

function ElOnOffToolTable() {
    
  return (
    <div>  
        <table id="example" class="table table-striped table-bordered" cellspacing="0" width="100%">
        <thead>
            <tr>
                <th colSpan={2}>Max Tool OD</th>
                <th colSpan={2}>Min Tool ID</th>
                <th colSpan={2}>Thread Connection</th>
                <th>Max # Pins</th>
            </tr>
        </thead>
 
        <tbody>
            <tr style={{backgroundColor: '#BCBEC0'}}>
                <td>in.</td>
                <td>mm</td>
               <td>in.</td>
               <td>mm</td>
               <td>in.</td>
               <td>mm</td>
               <td>#</td>
            </tr>
            <tr>
                <td>1.660</td>
                <td>42.2</td>
               <td>2.220</td>
               <td>56.4</td>
               <td>1.375</td>
               <td>34.9</td>
               <td>8</td>
            </tr>
    
        </tbody>
        </table>
    </div>
  )
}

export default ElOnOffToolTable