import React from "react";
import ContactForm from "../../components/ContactForm/ContactForm";
import { useEffect, useState } from "react";
import { getAllProductService } from "../../services/dept";
import { getMechanicalPackerTitlesService } from "../../services/dept";
import GeneralBtn from "../../components/ColumnBtns/GeneralBtn";
import MechanicalPackerHeader from '../../components/Headers/MechanicalPackerHeader'
import { Helmet } from 'react-helmet';
import TandemTensionPacker from "../../components/Products/MechanicalPackers/TandemTensionPacker";
import AsiXPacker from "../../components/Products/MechanicalPackers/AsiXPacker";
import WirelineAdapterKitVsi from "../../components/Products/MechanicalPackers/WirelineAdapterKitVsi";
import JettsetPacker from '../../components/Products/MechanicalPackers/JettsetPacker'
import ASIIPacker from '../../components/Products/MechanicalPackers/ASIIPacker'
import ASIIIPacker from '../../components/Products/MechanicalPackers/ASIIIPacker'
import AS5Packer from '../../components/Products/MechanicalPackers/AS5Packer'
import SnapsetIPacker from '../../components/Products/MechanicalPackers/SnapsetIPacker'
import SnapsetIIPacker from '../../components/Products/MechanicalPackers/SnapsetIIPacker'
import CtThermalPacker from '../../components/Products/MechanicalPackers/CtThermalPacker'
import IeThermalPacker from '../../components/Products/MechanicalPackers/IeThermalPacker'
import TmAnchor from '../../components/Products/MechanicalPackers/TmAnchor'
import AsiXAnchor from '../../components/Products/MechanicalPackers/AsiXAnchor'
import CasingPacker from '../../components/Products/MechanicalPackers/CasingPacker'
import GvCupPacker from '../../components/Products/MechanicalPackers/GvCupPacker'
import GasVentPumpingPacker from '../../components/Products/MechanicalPackers/GasVentPumpingPacker'
import RetrievableSealBorePackerDual from '../../components/Products/MechanicalPackers/RSBPDual'
import RetrievableSealBorePackerSingle from '../../components/Products/MechanicalPackers/RSBPSingle'
import RsbRetrievingTool from '../../components/Products/MechanicalPackers/RsbRetrievingTool'
import SetDownIsolationPacker from '../../components/Products/MechanicalPackers/SetDownIsolationPacker'
import MechanicalBallValve from '../../components/Products/MechanicalPackers/MechanicalBallValve'
import EHCompressionPacker from '../../components/Products/RetrievablePackers/EHCompressionPacker'
import TensionPacker from '../../components/Products/RetrievablePackers/TensionPacker'
import El1InflatableSingle from '../../components/Products/RetrievablePackers/El1InflatableSingle'
import El2InflatableMulti from '../../components/Products/RetrievablePackers/El2InflatableMulti'
import HydroCTPacker from '../../components/Products/RetrievablePackers/HydroCTPacker'
import { useParams } from "react-router-dom";
import { pseudoencodeURI } from "./converter";

const ProductController = [
  {
    title: "TYPE DL-IB TANDEM TENSION PACKER",
    component: <TandemTensionPacker title="TYPE DL-IB TANDEM TENSION PACKER" />,
  },
  {
    title: "ASI-X PACKER",
    component: <AsiXPacker title="ASI-X PACKER" />,
  },
  {
    title: "WIRELINE ADAPTER KIT - FOR VSI-X PACKER",
    component: <WirelineAdapterKitVsi title="WIRELINE ADAPTER KIT - FOR VSI-X PACKER" />,
  },
  {
    title: "JETTSET PACKER",
    component: <JettsetPacker title="JETTSET PACKER" />,
  },
  {
    title: "AS-III PACKER",
    component: <ASIIIPacker title="AS-III PACKER" />,
  },
  {
    title: "AS-II PACKER",
    component: <ASIIPacker title="AS-II PACKER" />,
  },
  {
    title: "AS-5 PACKER",
    component: <AS5Packer title="AS-5 PACKER" />,
  },
  {
    title: "SNAPSET I PACKER",
    component: <SnapsetIPacker title="SNAPSET I PACKER" />,
  },
  {
    title: "SNAPSET II PACKER",
    component: <SnapsetIIPacker title="SNAPSET II PACKER" />,
  },
  {
    title: "CT THERMAL PACKER",
    component: <CtThermalPacker title="CT THERMAL PACKER" />,
  },
  {
    title: "IE THERMAL PACKER",
    component: <IeThermalPacker title="IE THERMAL PACKER" />,
  },
  {
    title: "TM ANCHOR/CATCHER",
    component: <TmAnchor title="TM ANCHOR/CATCHER" />,
  },
  {
    title: "ASI-X ANCHOR",
    component: <AsiXAnchor title="ASI-X ANCHOR" />,
  },
  {
    title: "CASING PACKER",
    component: <CasingPacker title="CASING PACKER" />,
  },
  {
    title: "GV CUP PACKER",
    component: <GvCupPacker title="GV CUP PACKER" />,
  },
  {
    title: "GAS VENT PUMPING PACKER",
    component: <GasVentPumpingPacker title="GAS VENT PUMPING PACKER" />,
  },
  {
    title: "RETRIEVABLE SEAL BORE PACKER - DUAL",
    component: <RetrievableSealBorePackerDual title="RETRIEVABLE SEAL BORE PACKER - DUAL" />,
  },
  {
    title: "RETRIEVABLE SEAL BORE PACKER - SINGLE",
    component: <RetrievableSealBorePackerSingle title="RETRIEVABLE SEAL BORE PACKER - SINGLE" />,
  },
  {
    title: "RSB RETRIEVING TOOL",
    component: <RsbRetrievingTool title="RSB RETRIEVING TOOL" />,
  },
  {
    title: "SET DOWN ISOLATION PACKER",
    component: <SetDownIsolationPacker title="SET DOWN ISOLATION PACKER" />,
  },
  {
    title: "MECHANICAL BALL VALVE",
    component: <MechanicalBallValve title="MECHANICAL BALL VALVE" />,
  },
  {
    title: "OH COMPRESSION PACKER",
    component: <EHCompressionPacker title="OH COMPRESSION PACKER" />,
  },
  {
    title: "TENSION PACKER",
    component: <TensionPacker title="TENSION PACKER" />,
  },
  {
    title: "HYDRO-CT PACKER",
    component: <HydroCTPacker title="HYDRO-CT PACKER" />,
  },
  {
    title: "INFLATABLE SINGLE SET PACKER",
    component: <El1InflatableSingle title="INFLATABLE SINGLE SET PACKER" />,
  },
  {
    title: "INFLATABLE MULTI-SET PACKER",
    component: <El2InflatableMulti title="INFLATABLE MULTI-SET PACKER" />,
  },
];

function MechanicalPacker(props) {
  const [mechanicalPacker, setMechanicalPacker] = useState([]);
  const [currentProduct, setCurrentProduct] = useState("TYPE DL-IB TANDEM TENSION PACKER");

  const params = useParams(); 


  useEffect(() => {
    getMechanicalPackerTitlesService().then((result) => {
        setMechanicalPacker(result);
        console.log(params)
        setCurrentProduct(params.subLink)
        // setCurrentProduct(props.name)
        window.scrollTo(0, 0);

    });
  }, [params]);

  return (
    <div>
      {/* <h1> We are here</h1> */}
    <Helmet>‍
        <title>Mechanical Packers | Maverick Downhole Technologies | Edmonton, AB</title>‍
        
      </Helmet>
      <MechanicalPackerHeader />
      <div className="product-section">
        <div className="product-btn-container">
          {mechanicalPacker.map((mechanicalPacker) => {
            return (
              <GeneralBtn
                onClick={(title) => setCurrentProduct(title)}

                title={mechanicalPacker.title}
                selected={pseudoencodeURI (mechanicalPacker.title) === currentProduct}
                baseURL={"/products/mechanical-packers"}
              />
            );
          })}
        </div>
        <div>
          {!currentProduct
            ? null
            : ProductController.find(
                (product) => pseudoencodeURI (product.title) === currentProduct
              )?.component}
        </div>
      </div>
      <ContactForm />
    </div>
  );
}

export default MechanicalPacker;
