import React from 'react'
import {$,jQuery} from 'jquery';

function JettSetPackerTable() {
    
  return (
    <div>  
        <table id="example" class="table table-striped table-bordered" cellspacing="0" width="100%">
        <thead>
            <tr>
                <th colSpan={2}>Casing</th>
                <th rowSpan={2} style={{backgroundColor: 'black'}}>Recommended Hole Size (Inches)</th>
                <th rowSpan={2} style={{backgroundColor: 'black'}}>Gage OD (Inches)</th>
                <th rowSpan={2} style={{backgroundColor: 'black'}}>Max OD (Inches)</th>
                <th rowSpan={2} style={{backgroundColor: 'black'}}>Tool OD (Inches)</th>
                <th rowSpan={2} style={{backgroundColor: 'black'}}>Thread Connections Box Up / Pin Down</th>
                <th rowSpan={2}>Part Number</th>
            </tr>
            <tr>
                <th>Size (inches)</th>
                <th>Weight (lbs/ft)</th>
            </tr>
        </thead>
        <tbody>
           <tr>
            <td>4-1/2</td>
            <td>9.5-13.5</td>
            <td>3.920-4.090</td>
            <td>3.750</td>
            <td>-</td>
            <td>1.94</td>
            <td>2-3/8 EUE</td>
            <td>12045</td>
           </tr>
           <tr>
            <td>7</td>
            <td>17.0-26.0</td>
            <td>6.276-6.538</td>
            <td>6.000</td>
            <td>6.062<sup>1</sup></td>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>12072</td>
           </tr>
        </tbody>
        </table>
    </div>
  )
}

export default JettSetPackerTable