import React from 'react'

function AboutHeader() {
  return (
    <div className='about-header'>
        <div>
            <h2 className='large-headline white'>
                <span className="bold">About</span> Us
            </h2>
        </div>
    </div>
  )
}

export default AboutHeader