import React, { useEffect, useState } from "react";
import { getAllProductService } from "../../../services/dept";
import TmAnchor from '../../../assets/images/product-images/mechanical-packers/tm-anchor.jpg'
import { Helmet } from 'react-helmet';
import JsPDF from 'jspdf';
import TmAnchorTable from './Tables/TmAnchorTable'

function WirelineAdapterKitVsi(props) {
  const { title } = props;
  const [products, setProducts] = useState([]);
  const [currentProduct, setCurrentProduct] = useState({});

  const generatePDF = () => {

    const report = new JsPDF('portrait','pt','a4');
    report.html(document.querySelector('.product-info')).then(() => {
        report.save(`${title}.pdf`);
    });
  }

  useEffect(() => {
    getAllProductService().then((loadedProducts) => {
      setProducts(loadedProducts);
      setCurrentProduct(
        loadedProducts.find((product) => product.title === title)
      );
    });
  }, []);

  // console.log(currentProduct);
  return (
    <div className="product-info">
        <Helmet>‍
        <title>{`${title} | Maverick Downhole Technologies | Edmonton, AB`}</title>
        <script type="application/ld+json">
        {JSON.stringify({
        
        })}
        </script>
        </Helmet>
      <div>
        <h3 className="main-subhead">
          {currentProduct !== {} ? currentProduct.title : null}
        </h3>
        <h4 className="smaller-head">
          {currentProduct !== {} ? currentProduct.subhead : null}
        </h4>
        <div className="spacer"></div>
        <p className="body-text">
          {currentProduct !== {} ? currentProduct.description : null}
        </p>
        {/* <button onClick={generatePDF} type="button" className="main-btn">Download PDF</button><br /><br /> */}
        <TmAnchorTable />
      </div>
      <div>
        <img
          src={TmAnchor}
          title={currentProduct.title}
          alt={currentProduct.title}
          className="product-img"
        />
      </div>
    </div>
  );
}

export default WirelineAdapterKitVsi;
