import React, { useState, useEffect} from "react";
import config from "../../config";
import axios from 'axios'


function GlobalSearch({ data }) {
  const [filteredData, setFilteredData] = useState([]);
  const [globalDataBase, setGlobalDataBase] = useState([])
  const [categories, setCategories] = useState([])
  const handleFilter = (event) => {
    const searchWord = event.target.value;
    const newFilter = globalDataBase.filter((value) => {
        return value.title.toLowerCase().includes(searchWord.toLowerCase());
    });
    setFilteredData(newFilter);
  };


  useEffect(async () => {
    await axios.get(`${config.base_url}/dept/getAllCategories`).then((res) => {
      setCategories(res.data)
    })
    await axios.get(`${config.base_url}/dept/getAllProducts`).then((res) => {
      setGlobalDataBase(res.data)
    })

  }, [])
  

 const generateLink = (value) => {
    let productId = value.product_category_id; 
    let filteredCategories = categories.filter((elem) => {
      return elem.product_category_id === productId
    })

    if (filteredCategories.length !== 0){
      let result = filteredCategories[0].product_category
      return `/products/${result}/${value.title}`
    }
    return "Not found "

  }

  return (
    <div>
      <input
        className="search"
        type="text"
        placeholder="search..."
        id="globalSearch"
        onChange={handleFilter}
      ></input>
      {filteredData.length != 0 && (
      <div className="dataResult">
        {filteredData.slice(0, 15).map((value, key) => {
          return (
            <div className="dataItem">
              <a href={generateLink(value)} target="_blank">
                <p className="body-text">{value.title}</p>
              </a>
            </div>
          );
        })}
      </div>
      )}
    </div>
  );
}

export default GlobalSearch;
