import React from "react";
import ContactForm from "../../components/ContactForm/ContactForm";
import { useEffect, useState } from "react";
import { getAllProductService } from "../../services/dept";
import { getSealBorePackerTitlesService } from "../../services/dept";
import GeneralBtn from "../../components/ColumnBtns/GeneralBtn";
import { Helmet } from 'react-helmet';
import GeneralHeader from "../../components/Headers/GeneralHeader";

import PermanentSealBorePacker from "../../components/Products/SealBorePackers/PermanentSealBorePacker";
import SealAssemblies from "../../components/Products/SealBorePackers/SealAssemblies";
import SealBoreAccessories from "../../components/Products/SealBorePackers/SealBoreAccessories";
import RetrievableSealBorePacker from "../../components/Products/SealBorePackers/RetrievableSealBorePacker";

const ProductController = [
  {
    title: "PERMANENT SEAL BORE PACKER",
    component: <PermanentSealBorePacker title="PERMANENT SEAL BORE PACKER" />,
  },
  {
    title: "SEAL ASSEMBLIES",
    component: <SealAssemblies title="SEAL ASSEMBLIES" />,
  },
  {
    title: "SEAL BORE ACCESSORIES",
    component: <SealBoreAccessories title="SEAL BORE ACCESSORIES" />,
  },
  {
    title: "RETRIEVABLE SEAL BORE PACKER",
    component: <RetrievableSealBorePacker title="RETRIEVABLE SEAL BORE PACKER" />,
  },
];

function SealBorePackers() {
  const [sealBorePacker, setSealBorePacker] = useState([]);
  const [currentProduct, setCurrentProduct] = useState("PERMANENT SEAL BORE PACKER");

  useEffect(() => {
    getSealBorePackerTitlesService().then((result) => {
        setSealBorePacker(result);
    });
  }, []);

  return (
    <div>

      <GeneralHeader title="Seal Bore Packers" />
      <div className="product-section">
        <div className="product-btn-container">
          {sealBorePacker.map((sealBorePacker) => {
            return (
              <GeneralBtn
                onClick={(title) => setCurrentProduct(title)}
                title={sealBorePacker.title}
                selected={sealBorePacker.title === currentProduct}
              />
            );
          })}
        </div>
        <div>
          {!currentProduct
            ? null
            : ProductController.find(
                (product) => product.title === currentProduct
              )?.component}
        </div>
      </div>
      <ContactForm />
    </div>
  );
}

export default SealBorePackers;
