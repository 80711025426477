import React from "react";
import { $, jQuery } from "jquery";

function PumpOutCementingSleeveTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c126">
            <td class="c131" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Size </span>
              </p>
              <p class="c5">
                <span class="c7">(inches)</span>
              </p>
            </td>
            <td class="c103" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Tool OD </span>
              </p>
              <p class="c5">
                <span class="c7">(inches)</span>
              </p>
            </td>
            <td class="c114" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Ball Seat ID </span>
              </p>
              <p class="c5">
                <span class="c7">(inches)</span>
              </p>
            </td>
            <td class="c57" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">ID After </span>
              </p>
              <p class="c5">
                <span class="c7">Pump Out </span>
              </p>
              <p class="c5">
                <span class="c7">(inches)</span>
              </p>
            </td>
            <td class="c137" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Thread Connections </span>
              </p>
              <p class="c5">
                <span class="c7">Box Up / Pin Down</span>
              </p>
            </td>
            <td class="c23" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Part </span>
              </p>
              <p class="c5">
                <span class="c7">Number</span>
              </p>
            </td>
          </tr>
        </thead>

        <tbody>
          <tr class="c21">
            <td class="c131" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c2">2-3/8 </span>
              </p>
            </td>
            <td class="c103" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c2">3.250 </span>
              </p>
            </td>
            <td class="c114" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c2">1.00 </span>
              </p>
            </td>
            <td class="c57" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c2">1.93 </span>
              </p>
            </td>
            <td class="c137" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c2">2-3/8 EUE</span>
              </p>
            </td>
            <td class="c23" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">22020</span>
              </p>
            </td>
          </tr>
          <tr class="c21">
            <td class="c23" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">22021</span>
              </p>
            </td>
          </tr>
          <tr class="c21">
            <td class="c131" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2-7/8 </span>
              </p>
            </td>
            <td class="c103" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">3.750 </span>
              </p>
            </td>
            <td class="c114" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.25 </span>
              </p>
            </td>
            <td class="c57" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2.40 </span>
              </p>
            </td>
            <td class="c137" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2-7/8 EUE </span>
              </p>
            </td>
            <td class="c23" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">22027</span>
              </p>
            </td>
          </tr>
          <tr class="c21">
            <td class="c131" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">3-1/2 </span>
              </p>
            </td>
            <td class="c103" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">4.410 </span>
              </p>
            </td>
            <td class="c114" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.25 </span>
              </p>
            </td>
            <td class="c57" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2.90 </span>
              </p>
            </td>
            <td class="c137" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">3-1/2 EUE </span>
              </p>
            </td>
            <td class="c23" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">22035</span>
              </p>
            </td>
          </tr>
          <tr class="c21">
            <td class="c131" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">4 </span>
              </p>
            </td>
            <td class="c103" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">4.630 </span>
              </p>
            </td>
            <td class="c114" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.25 </span>
              </p>
            </td>
            <td class="c57" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">3.45 </span>
              </p>
            </td>
            <td class="c137" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">4&rdquo; NUE </span>
              </p>
            </td>
            <td class="c23" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">22040</span>
              </p>
            </td>
          </tr>
          <tr class="c21">
            <td class="c131" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">4-1/2 </span>
              </p>
            </td>
            <td class="c103" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">5.500 </span>
              </p>
            </td>
            <td class="c114" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.25 </span>
              </p>
            </td>
            <td class="c57" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">3.97 </span>
              </p>
            </td>
            <td class="c137" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">4-1/2 LTC / 4-1/2 STC </span>
              </p>
            </td>
            <td class="c23" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">22045</span>
              </p>
            </td>
          </tr>
          <tr class="c21">
            <td class="c131" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">5-1/2 </span>
              </p>
            </td>
            <td class="c103" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">6.130 </span>
              </p>
            </td>
            <td class="c114" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.25 </span>
              </p>
            </td>
            <td class="c57" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">4.63 </span>
              </p>
            </td>
            <td class="c137" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">5-1/2 LTC </span>
              </p>
            </td>
            <td class="c23" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">22055</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default PumpOutCementingSleeveTable;
