import React from "react";
import { $, jQuery } from "jquery";

function ScTensionUnloaderTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c150">
            <td class="c278" colspan="1" rowspan="1">
              Size (inches)
            </td>
            <td class="c301" colspan="1" rowspan="1">
              Tool OD (inches)
            </td>
            <td class="c54" colspan="1" rowspan="1">
              Tool ID (inches)
            </td>
            <td class="c480" colspan="1" rowspan="1">
              Thread Connections Box Up / Pin Down
            </td>
            <td class="c234" colspan="1" rowspan="1">
              Part Number
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="c59">
            <td class="c278" colspan="1" rowspan="1">
              7/8
            </td>
            <td class="c301" colspan="1" rowspan="1">
              2.250
            </td>
            <td class="c54" colspan="1" rowspan="1">
              0.88
            </td>
            <td class="c480" colspan="1" rowspan="1">
              1.660 EUE
            </td>
            <td class="c234" colspan="1" rowspan="1">
              52504
            </td>
          </tr>
          <tr class="c59">
            <td class="c278" colspan="1" rowspan="1">
              1-5/8
            </td>
            <td class="c301" colspan="1" rowspan="1">
              3.250
            </td>
            <td class="c54" colspan="1" rowspan="1">
              1.63
            </td>
            <td class="c480" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c234" colspan="1" rowspan="1">
              52506
            </td>
          </tr>
          <tr class="c59">
            <td class="c278" colspan="1" rowspan="1">
              2-3/8
            </td>
            <td class="c301" colspan="1" rowspan="1">
              3.750
            </td>
            <td class="c54" colspan="1" rowspan="1">
              2.00
            </td>
            <td class="c480" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c234" colspan="1" rowspan="1">
              52520
            </td>
          </tr>
          <tr class="c59">
            <td class="c278" colspan="1" rowspan="1">
              2-7/8
            </td>
            <td class="c301" colspan="1" rowspan="1">
              4.500
            </td>
            <td class="c54" colspan="1" rowspan="1">
              2.50
            </td>
            <td class="c480" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c234" colspan="1" rowspan="1">
              52525
            </td>
          </tr>
          <tr class="c59">
            <td class="c278" colspan="1" rowspan="1">
              3-1/2
            </td>
            <td class="c301" colspan="1" rowspan="1">
              5.250
            </td>
            <td class="c54" colspan="1" rowspan="1">
              3.00
            </td>
            <td class="c480" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c234" colspan="1" rowspan="1">
              52535
            </td>
          </tr>
          <tr class="c59">
            <td class="c278" colspan="1" rowspan="1">
              4-1/2
            </td>
            <td class="c301" colspan="1" rowspan="1">
              6.250
            </td>
            <td class="c54" colspan="1" rowspan="1">
              4.00
            </td>
            <td class="c480" colspan="1" rowspan="1">
              4-1/2 EUE
            </td>
            <td class="c234" colspan="1" rowspan="1">
              52545
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default ScTensionUnloaderTable;
