import React from "react";
import { $, jQuery } from "jquery";

function TypeCperforationWashToolTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c165">
            <td class="c661" colspan="2" rowspan="1">
              Casing
            </td>
            <td class="c211" colspan="1" rowspan="2">
              Recommended Hole Size (inches)
            </td>
            <td class="c164" colspan="1" rowspan="2">
              Tool OD (inches)
            </td>
            <td class="c424" colspan="1" rowspan="2">
              Thread Connections Box Up / Pin Down
            </td>
            <td class="c79" colspan="1" rowspan="2">
              Part Number
            </td>
          </tr>
          <tr class="c150">
            <td class="c110" colspan="1" rowspan="1">
              Size (inches)
            </td>
            <td class="c337" colspan="1" rowspan="1">
              Weight (lbs/ft)
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="c59">
            <td class="c110" colspan="1" rowspan="1">
              3-1/2
            </td>
            <td class="c337" colspan="1" rowspan="1">
              9.2 - 10.2*
            </td>
            <td class="c211" colspan="1" rowspan="1">
              2.922 - 2.992
            </td>
            <td class="c164" colspan="1" rowspan="1">
              2.810
            </td>
            <td class="c424" colspan="1" rowspan="1">
              1.900 NUE
            </td>
            <td class="c79" colspan="1" rowspan="1">
              46035
            </td>
          </tr>
          <tr class="c59">
            <td class="c110" colspan="1" rowspan="1">
              4
            </td>
            <td class="c337" colspan="1" rowspan="1">
              9.5 - 13.4*
            </td>
            <td class="c211" colspan="1" rowspan="1">
              3.340 - 3.548
            </td>
            <td class="c164" colspan="1" rowspan="1">
              3.250
            </td>
            <td class="c424" colspan="1" rowspan="1">
              1.900 NUE
            </td>
            <td class="c79" colspan="1" rowspan="1">
              46040
            </td>
          </tr>
          <tr class="c59">
            <td class="c110" colspan="1" rowspan="1">
              4-1/2
            </td>
            <td class="c337" colspan="1" rowspan="1">
              9.5 - 11.6
            </td>
            <td class="c211" colspan="1" rowspan="1">
              4.000 - 4.090
            </td>
            <td class="c164" colspan="1" rowspan="1">
              3.690
            </td>
            <td class="c424" colspan="1" rowspan="1">
              1.900 NUE
            </td>
            <td class="c79" colspan="1" rowspan="1">
              46045
            </td>
          </tr>
          <tr class="c59">
            <td class="c110" colspan="1" rowspan="2">
              5
            </td>
            <td class="c337" colspan="1" rowspan="1">
              13.0 - 15.0
            </td>
            <td class="c211" colspan="1" rowspan="1">
              4.408 - 4.494
            </td>
            <td class="c164" colspan="1" rowspan="1">
              4.000
            </td>
            <td class="c424" colspan="1" rowspan="1">
              1.900 NUE
            </td>
            <td class="c79" colspan="1" rowspan="1">
              46050
            </td>
          </tr>
          <tr class="c59">
            <td class="c337" colspan="1" rowspan="1">
              18.0 - 20.3
            </td>
            <td class="c211" colspan="1" rowspan="1">
              4.184 - 4.276
            </td>
            <td class="c164" colspan="1" rowspan="1">
              4.000
            </td>
            <td class="c424" colspan="1" rowspan="1">
              1.900 NUE
            </td>
            <td class="c79" colspan="1" rowspan="1">
              46051
            </td>
          </tr>
          <tr class="c59">
            <td class="c110" colspan="1" rowspan="1">
              5-1/2
            </td>
            <td class="c337" colspan="1" rowspan="1">
              13.0 - 20.0
            </td>
            <td class="c211" colspan="1" rowspan="1">
              4.778 - 5.044
            </td>
            <td class="c164" colspan="1" rowspan="1">
              4.625
            </td>
            <td class="c424" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c79" colspan="1" rowspan="1">
              46055
            </td>
          </tr>
          <tr class="c59">
            <td class="c110" colspan="1" rowspan="1">
              6-5/8
            </td>
            <td class="c337" colspan="1" rowspan="1">
              20.0 - 24.0
            </td>
            <td class="c211" colspan="1" rowspan="1">
              5.921 - 6.049
            </td>
            <td class="c164" colspan="1" rowspan="1">
              5.750
            </td>
            <td class="c424" colspan="1" rowspan="1">
              3-1/2 EUE / 4-1/2 STC
            </td>
            <td class="c79" colspan="1" rowspan="1">
              46065
            </td>
          </tr>
          <tr class="c59">
            <td class="c110" colspan="1" rowspan="1">
              7
            </td>
            <td class="c337" colspan="1" rowspan="1">
              20.0 - 29.0
            </td>
            <td class="c211" colspan="1" rowspan="1">
              6.184 - 6.456
            </td>
            <td class="c164" colspan="1" rowspan="1">
              6.000
            </td>
            <td class="c424" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c79" colspan="1" rowspan="1">
              46070
            </td>
          </tr>
          <tr class="c59">
            <td class="c110" colspan="1" rowspan="1">
              9-5/8
            </td>
            <td class="c337" colspan="1" rowspan="1">
              29.3 - 53.5*
            </td>
            <td class="c211" colspan="1" rowspan="1">
              8.535 - 9.063
            </td>
            <td class="c164" colspan="1" rowspan="1">
              8.450
            </td>
            <td class="c424" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c79" colspan="1" rowspan="1">
              46095
            </td>
          </tr>
          <tr class="c59">
            <td class="c110" colspan="1" rowspan="1">
              10-3/4
            </td>
            <td class="c337" colspan="1" rowspan="1">
              40.5 - 55.5*
            </td>
            <td class="c211" colspan="1" rowspan="1">
              9.760 - 10.050
            </td>
            <td class="c164" colspan="1" rowspan="1">
              9.710
            </td>
            <td class="c424" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c79" colspan="1" rowspan="1">
              46010
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default TypeCperforationWashToolTable;
