import React from "react";
import { $, jQuery } from "jquery";

function TypeEValveTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c83">
            <td class="c54" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Size </span>
              </p>
              <p class="c5">
                <span class="c7">(inches)</span>
              </p>
            </td>
            <td class="c69" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Tool OD </span>
              </p>
              <p class="c5">
                <span class="c7">(inches)</span>
              </p>
            </td>
            <td class="c89" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Thread Connection </span>
              </p>
              <p class="c5">
                <span class="c7">Pin Down</span>
              </p>
            </td>
            <td class="c78" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Part </span>
              </p>
              <p class="c5">
                <span class="c7">Number</span>
              </p>
            </td>
          </tr>
        </thead>

        <tbody>
          <tr class="c21">
            <td class="c54" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.900 </span>
              </p>
            </td>
            <td class="c69" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2.130 </span>
              </p>
            </td>
            <td class="c89" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.900 NUE </span>
              </p>
            </td>
            <td class="c78" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">12435</span>
              </p>
            </td>
          </tr>
          <tr class="c21">
            <td class="c54" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2-3/8 </span>
              </p>
            </td>
            <td class="c69" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">3.094 </span>
              </p>
            </td>
            <td class="c89" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2-3/8 EUE </span>
              </p>
            </td>
            <td class="c78" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">12445</span>
              </p>
            </td>
          </tr>
          <tr class="c21">
            <td class="c54" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2-7/8 </span>
              </p>
            </td>
            <td class="c69" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">3.094 </span>
              </p>
            </td>
            <td class="c89" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2-7/8 EUE </span>
              </p>
            </td>
            <td class="c78" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">12455</span>
              </p>
            </td>
          </tr>
          <tr class="c21">
            <td class="c54" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">3-1/2 </span>
              </p>
            </td>
            <td class="c69" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">4.344 </span>
              </p>
            </td>
            <td class="c89" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">3-1/2 EUE </span>
              </p>
            </td>
            <td class="c78" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">12473</span>
              </p>
            </td>
          </tr>
          <tr class="c21">
            <td class="c54" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">4-1/2 </span>
              </p>
            </td>
            <td class="c69" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">4.750 </span>
              </p>
            </td>
            <td class="c89" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">4-1/2 EUE </span>
              </p>
            </td>
            <td class="c78" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">12495</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default TypeEValveTable;
