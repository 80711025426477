import React from "react";
import { $, jQuery } from "jquery";

function MechanicalBallValveTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c150">
            <td class="c508" colspan="1" rowspan="1">
              Size (inches)
            </td>
            <td class="c415" colspan="1" rowspan="1">
              Tool OD (inches)
            </td>
            <td class="c309" colspan="1" rowspan="1">
              Tool ID (inches)
            </td>
            <td class="c389" colspan="1" rowspan="1">
              Thread Connections Box Up / Pin Down
            </td>
            <td class="c327" colspan="1" rowspan="1">
              Part Number
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="c59">
            <td class="c508" colspan="1" rowspan="1">
              4-1/2
            </td>
            <td class="c415" colspan="1" rowspan="1">
              5.875
            </td>
            <td class="c309" colspan="1" rowspan="1">
              3.25
            </td>
            <td class="c389" colspan="1" rowspan="1">
              4-1/2 VAM TOP
            </td>
            <td class="c327" colspan="1" rowspan="1">
              54945
            </td>
          </tr>
          <tr class="c59">
            <td class="c508" colspan="1" rowspan="1">
              5-1/2
            </td>
            <td class="c415" colspan="1" rowspan="1">
              8.190
            </td>
            <td class="c309" colspan="1" rowspan="1">
              4.30
            </td>
            <td class="c389" colspan="1" rowspan="1">
              4-1/2 VAM TOP
            </td>
            <td class="c327" colspan="1" rowspan="1">
              54955
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default MechanicalBallValveTable;
