import React from "react";

function Privacy() {
  return (
    <div>
      <h3 className="main-subhead centre">Notice of Our Privacy Policy</h3>
      <p className="body-text">
        Maverick Technical Services, S. de R.L. of C.V. (hereinafter “MST”),
        Variable Capital Limited Liability Company, organized under Mexican law;
        with address to hear and receive notifications located at the address of
        Calle San Remo, Manzana 2, Lote 1, Warehouse 2, Colonia Parque Logístico
        Industrial Tabasco, Anacleto Canabal 4th Section, Zip Code 86287,
        Centro, Tabasco, Mexico, strictly compliance with the provisions of the
        Federal Law on Protection of Personal Data Held by Private Parties
        (hereinafter the "Law"), the Regulations of the Federal Law on
        Protection of Personal Data Held by Private Parties (hereinafter the
        "Regulation" ) and the Guidelines of the Privacy Notice (hereinafter the
        "Guidelines"), informs you that the requested personal and/or property
        data and information, including sensitive ones, that currently or in the
        future are in our databases , will be used exclusively for carrying out
        activities and fulfilling the obligations arising from the contractual
        and/or commercial relationship that currently relates to it or him/her,
        thus complying with MST's internal policies.
        <br />
        <br />
        All data and information provided by customers and/or suppliers will
        have a legitimate, controlled, confidential and informed treatment based
        on principles of legality, consent, information, quality, purpose,
        loyalty, proportionality and responsibility in order to strictly
        guarantee privacy. thereof.
        <br />
        <br />
        MST protects data and/or information against damage, loss, alteration,
        destruction, unauthorized access and disclosure by establishing and
        maintaining appropriate physical, technological and administrative
        procedures as security measures to protect all the information it
        collects.
        <br />
        <br />
        In accordance with article 70 of the Regulations and with the internal
        policies of MST, the latter may use and disclose, without prior notice
        to the owner, the information to its affiliated and/or subsidiary
        companies; with the sole purpose of complying with those obligations
        that derive from the existing legal relationship with the owner of the
        data and/or information and MST; In the event that MST requires
        disclosing the information and/or data, obtained by the owner, to those
        third parties who, due to the nature of their jobs or functions, have
        the need to know the information provided, MST will give prior notice to
        the owner so that they holders expressly express their consent, in terms
        of the provisions of the Law.
        <br />
        <br />
        The holders of the data and/or information may request the revocation of
        the consent granted by signing this notice or, where appropriate,
        exercise the rights of access, rectification, cancellation or opposition
        conferred by the Law, by means of a written request to the which must be
        delivered to the address of MST and must be addressed to the address of
        Calle San Remo, Manzana 2, Lote 1, Bodega 2, Colonia Parque Logístico
        Industrial Tabasco, Anacleto Canabal 4th Section, Zip Code 86287,
        Centro, Tabasco, Mexico; Said request must comply with each and every
        one of the following requirements established in article 29 of the Law:
        <br />
        <br />
        <div style={{padding: '40px', backgroundColor: '#cccccc'}}>
        <ul className="body-text">
          <li className="body-text">
            The name of the owner and address or other means to communicate the
            response to your request;
          </li>
          <li className="body-text">
            The documents that prove the identity or, where appropriate, the
            legal representation of the owner;
          </li>
          <li className="body-text">
            The clear and precise description of the personal data with respect
            to which one seeks to exercise any of the aforementioned rights, and
          </li>
          <li className="body-text">
            Any other element or document that facilitates the location of
            personal data.
          </li>
        </ul>
        </div>
        <br />
        <br />
        Once the corresponding request is received, MST will have a period of 20
        business days to respond to the request made, notifying what corresponds
        to its right at the address that would have been provided in the request
        through which the owner exercises his rights of access, rectification,
        cancellation or opposition. If it is appropriate, it will be effective
        within 15 days following the date on which the response is communicated.
        If a security breach occurs at any stage of data processing that
        significantly affects the patrimonial or moral rights of the owners, the
        owner of the data and/or information will be immediately informed in
        writing by MST, so that the owner can take the corresponding measures to
        defend their rights.
        <br />
        <br />
        Likewise, once the contractual or commercial relationship between MST
        and the owner of the data and/or information has ended, MST will proceed
        to cancel the data in its possession after blocking them, for their
        subsequent deletion.
        <br />
        <br />
        MST reserves the right to change, modify and/or supplement this notice,
        at any time, in which case it will be made known through any of the
        means established.
      </p>
    </div>
  );
}

export default Privacy;
