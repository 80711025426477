import React from "react";
import { $, jQuery } from "jquery";

function SnapsetIPackerTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr>
            <th colSpan={2}>Casing</th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Recommended Hole Size (Inches)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Tool OD (Inches)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Tool ID (Inches)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Thread Connections Box Up / Pin Down
            </th>
            <th rowSpan={2}>Part Number</th>
          </tr>
          <tr>
            <th>Size (inches)</th>
            <th>Weight (lbs/ft)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>3-1/2</td>
            <td>7.7-10.2</td>
            <td>2.922-3.068</td>
            <td>2.781</td>
            <td>1.25</td>
            <td>1.900 EUE</td>
            <td>63535</td>
          </tr>
          <tr>
            <td>4-1/2</td>
            <td>9.5-13.5</td>
            <td>3.920-4.090</td>
            <td>3.750</td>
            <td>1.94</td>
            <td>2-3/8 EUE</td>
            <td>63545</td>
          </tr>
          <tr>
            <td rowSpan={4}>5-1/2</td>
            <td rowSpan={2}>14.0-20.0</td>
            <td rowSpan={2}>4.778-5.012</td>
            <td rowSpan={2}>4.625</td>
            <td>2.00</td>
            <td>2-3/8 EUE</td>
            <td>63555</td>
          </tr>
          <tr>
            <td>2.38</td>
            <td>2-7/8 EUE</td>
            <td>63556</td>
          </tr>
          <tr>
            <td rowSpan={2}>20.0-23.0</td>
            <td rowSpan={2}>4.670-4.778</td>
            <td rowSpan={2}>4.500</td>
            <td>2.00</td>
            <td>2-3/8 EUE</td>
            <td>63557</td>
          </tr>
          <tr>
            <td>2.38</td>
            <td>2-7/8 EUE</td>
            <td>63558</td>
          </tr>
          <tr>
            <td rowSpan={2}>7</td>
            <td>17.0-26.0</td>
            <td>6.276-6.538</td>
            <td>6.000</td>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>63572</td>
          </tr>
          <tr>
            <td>26.0-32.0</td>
            <td>6.094-6.276</td>
            <td>5.875</td>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>63570</td>
          </tr>
          <tr>
            <td>7-5/8</td>
            <td>24.0-29.7</td>
            <td>6.875-7.025</td>
            <td>6.672</td>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>63575</td>
          </tr>
          <tr>
            <td>9-5/8</td>
            <td>43.5-53.5</td>
            <td>8.535-8.755</td>
            <td>8.250</td>
            <td>3.00</td>
            <td>3-1/2 EUE</td>
            <td>63595-BAE</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default SnapsetIPackerTable;
