import React from "react";
import { $, jQuery } from "jquery";

function HydraulicOpenHolePackerTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c667">
            <td class="c182" colspan="1" rowspan="1">
              Packer Size (inches)
            </td>
            <td class="c73" colspan="1" rowspan="1">
              Recommended Hole Size (inches)
            </td>
            <td class="c396" colspan="1" rowspan="1">
              Tool OD (inches)
            </td>
            <td class="c133" colspan="1" rowspan="1">
              Tool ID (inches)
            </td>
            <td class="c155" colspan="1" rowspan="1">
              Thread Connections Box Up / Pin Down
            </td>
            <td class="c93" colspan="1" rowspan="1">
              Part &nbsp; Number
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="c25">
            <td class="c182" colspan="1" rowspan="1">
              7
            </td>
            <td class="c73" colspan="1" rowspan="1">
              5.875 - 6.600
            </td>
            <td class="c396" colspan="1" rowspan="1">
              5.750
            </td>
            <td class="c133" colspan="1" rowspan="1">
              3.92
            </td>
            <td class="c155" colspan="1" rowspan="1">
              4-1/2 LTC
            </td>
            <td class="c93" colspan="1" rowspan="1">
              33570-1
            </td>
          </tr>
          <tr class="c25">
            <td class="c182" colspan="1" rowspan="1">
              7-1/2
            </td>
            <td class="c73" colspan="1" rowspan="1">
              7.500 - 8.250
            </td>
            <td class="c396" colspan="1" rowspan="1">
              7.350
            </td>
            <td class="c133" colspan="1" rowspan="1">
              4.75
            </td>
            <td class="c155" colspan="1" rowspan="1">
              5-1/2 BTC
            </td>
            <td class="c93" colspan="1" rowspan="1">
              33575-1
            </td>
          </tr>
          <tr class="c25">
            <td class="c182" colspan="1" rowspan="2">
              8-1/2
            </td>
            <td class="c73" colspan="1" rowspan="1">
              8.000 - 9.000
            </td>
            <td class="c396" colspan="1" rowspan="1">
              7.780
            </td>
            <td class="c133" colspan="1" rowspan="1">
              4.75
            </td>
            <td class="c155" colspan="1" rowspan="1">
              5-1/2 BTC
            </td>
            <td class="c93" colspan="1" rowspan="1">
              33580-1
            </td>
          </tr>
          <tr class="c25">
            <td class="c73" colspan="1" rowspan="1">
              8.500 - 8.750
            </td>
            <td class="c396" colspan="1" rowspan="1">
              8.000
            </td>
            <td class="c133" colspan="1" rowspan="1">
              4.75
            </td>
            <td class="c155" colspan="1" rowspan="1">
              5-1/2 BTC
            </td>
            <td class="c93" colspan="1" rowspan="1">
              33581-1
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default HydraulicOpenHolePackerTable;
