import React, { useEffect } from "react";
import styled from "styled-components";
import Helmet from "helmet";
import { Link } from "react-router-dom";
import '../../Misc/misc'
import ProductLinks from '../../NavBar/ProductLinks'

// IMAGES 
import Mechanical from '../../../assets/images/home-images/mechanical.png'
import Hydraulic from '../../../assets/images/home-images/hydraulic packer.png'
import Accessories from '../../../assets/images/accessories.jpg'
import ServiceTools from '../../../assets/images/home-images/service-tools.png'
import Permanent from '../../../assets/images/home-images/permanent-packer.png'
import Placeholder2 from '../../../assets/images/placeholder2.jpg'
import TubingAnchors from '../../../assets/images/home-images/tubing-anchors.png'
import CementRetainers from '../../../assets/images/home-images/cement-retainers.png'
import BurstDisks from '../../../assets/images/home-images/burst-disk.png'
import BridgePlugs from '../../../assets/images/home-images/bridge-plugs.png'
import FlowControl from '../../../assets/images/home-images/flow control.png'
import RetrievablePackers from '../../../assets/images/home-images/retrievable packer.png'

const ProductCard = styled.div`
  position: relative;
  box-shadow: 0px 0px 5px rgba(134, 137, 136, 0.4);
  display: grid; 
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 20px;
`;

function ProductsHome() {

  useEffect(() => {

  })

  const products = [
    {
      id: 1,
      number: "1",
      title: "Mechanical Set Packers",
      description:
        "Mechanical packers are set with the help of mechanical forces with or without rotations applied. ",
      img: Mechanical,
      link: "/products/mechanical-packers/TYPE%20DL-IB%20TANDEM%20TENSION%20PACKER",
    },
    {
      id: 2,
      number: "2",
      title: "Hydraulic Set Packers",
      description:
        "Hydraulic Set Packers have a bidirectional slip system that us actuated by a predetermined amount of hydraulic pressure.",
      img: Hydraulic,
      link: "/products/hydraulic-packers/DLH%20PACKER",
    },
    {
      id: 3,
      number: "3",
      title: "Permanent Packers",
      description:
        "A permanent packer is a significant tool in the casing string",
      img: Permanent,
      link: "/products/permanent-packers/PERMAPAK%20ACCESSORIES",
    },
    // {
    //   id: 4,
    //   number: "4",
    //   title: "Retrievable Packers",
    //   description:
    //     "Retrievable packers are a type of packer that is run and retrieved on a running or production string",
    //   img:  RetrievablePackers,
    //   link: "/products/retrievable-packers",
    // },
    // {
    //   id: 5,
    //   number: "5",
    //   title: "Seal Bore Packers",
    //   description:
    //     "A type of production packer that incorporates a sealbore that accepts a seal assembly fitted to the bottom of the production tubing",
    //   img:  Placeholder2,
    //   link: "/products/seal-bore-packers",
    // },
    {
        id: 6,
        number: "6",
        title: "Tubing Anchors",
        description:
          "The Hydraulic Tubing Anchor is a hydraulically-activated retrievable anchor designed to hold the tubing string in tension or compression.",
        img:  TubingAnchors,
        link: ProductLinks.tubingAnchors
      },
      {
        id: 7,
        number: "7",
        title: "Flow Control",
        description:
          "Flow control tools are used to move water, gas and oil through piping and downstream.",
        img:  FlowControl,
        link: "/products/flow-control/X-XN%20LANDING%20NIPPLES",
      },
      {
        id: 8,
        number: "8",
        title: "Plug/Cement Retainers",
        description:
          "An isolation tool set in the casing or liner that enables treatments to be applied to a lower interval while providing isolation from the annulus above.",
        img:  CementRetainers,
        link: "/products/plug-retainers/HM-1B%20BRIDGE%20PLUG",
      },
      {
        id: 9,
        number: "9",
        title: "Service Tools",
        description:
          "Downhole Service Tools to help get the job done with ease.",
        img: ServiceTools,
        link: ProductLinks.serviceTools,
      },
      {
        id: 10,
        number: "10",
        title: "Accessories",
        description:
          "Accessories to help you maximize production while minimizing risk.",
        img: Accessories,
        link: "/products/accessories/ROTATIONAL%20SHEAR%20SAFETY%20JOINT",
      },
      {
        id: 11,
        number: "11",
        title: "Burst Discs",
        description:
          "Burst Discs, also known as rupture discs or pressure safety discs are non-reclosing pressure relief system. In most cases, they protect a pressure vessel from overpressurization.",
        img: BurstDisks,
        link: "/products/burst-discs/INTERVENTIONLESS%20BURST%20DISC%20SUB%20–%20MULE%20SHOE",
      },
      // {
      //   id: 12,
      //   number: "12",
      //   title: "Bridge Plugs",
      //   description:
      //     "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores magni facere rerum. Placeat provident minus porro",
      //   img: BridgePlugs,
      //   link: "/products/burst-disks",
      // },
  ];
  return (
    <div className="products-home">
      <div className="inner-container-products-home">
        <h3 className="main-subhead">
          Our<span className="bold"> Products</span>
        </h3>
        <p className="body-text">
          More Than 130 Different Products Available In All Sizes
        </p>
        <Helmet>
          <tite>Test</tite>
        </Helmet>
        {/* <div className="product-grid"> */}
          <div className="product-grid">
            {products.map(({ number, title, img, description, link }) => {
              return (
                <>
                <div>
                  <div className="product-card-top">
                    <div className="product-img-container">
                        <img src={`${img}`} alt={title} title={title} className="product-img-home"/>
                    </div>
                    <p>{number}</p>
                    <h4 className="product-card-title">{title}</h4>
                  </div>
                  <div className="product-card-bottom">
                    <p className="body-text">{description}</p>
                    <Link to={`${link}`}>
                      <button className="main-btn">View Products</button>
                    </Link>
                  </div>
                  </div>
                </>
              );
            })}
          </div>
        {/* </div> */}
      </div>
    </div>
  );
}

export default ProductsHome;
