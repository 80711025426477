import React from "react";
import { $, jQuery } from "jquery";

function BitReleaseJointTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c180">
            <td class="c228" colspan="2" rowspan="1">
              <p class="c5">
                <span class="c7">Thread Connections </span>
              </p>
            </td>
            <td class="c86" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c7">Tool OD </span>
              </p>
              <p class="c5">
                <span class="c7">(inches)</span>
              </p>
            </td>
            <td class="c69" colspan="1" rowspan="2">
              <p class="c108">
                <span class="c7">Part &nbsp;</span>
              </p>
              <p class="c5">
                <span class="c7">Number </span>
              </p>
            </td>
          </tr>
          <tr class="c180">
            <td class="c157" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Top </span>
              </p>
            </td>
            <td class="c107" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Bottom</span>
              </p>
            </td>
          </tr>
        </thead>

        <tbody>
          <tr class="c21">
            <td class="c157" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2-3/8 EUE </span>
              </p>
            </td>
            <td class="c107" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2-3/8 REGULAR TOOL JOINT </span>
              </p>
            </td>
            <td class="c86" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">3.500 </span>
              </p>
            </td>
            <td class="c69" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">80120</span>
              </p>
            </td>
          </tr>
          <tr class="c21">
            <td class="c157" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2-7/8 EUE </span>
              </p>
            </td>
            <td class="c107" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2-7/8 REGULAR TOOL JOINT </span>
              </p>
            </td>
            <td class="c86" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">3.750 </span>
              </p>
            </td>
            <td class="c69" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">80125</span>
              </p>
            </td>
          </tr>
          <tr class="c21">
            <td class="c157" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">4&rdquo; IF TOOL JOINT </span>
              </p>
            </td>
            <td class="c107" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">4-1/2 REGULAR TOOL JOINT </span>
              </p>
            </td>
            <td class="c86" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">6.500 </span>
              </p>
            </td>
            <td class="c69" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">80145</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default BitReleaseJointTable;
