import React, { useEffect, useState } from "react";
import { getAllProductService } from "../../../services/dept";
import TypeDLImg from "../../../assets/images/product-images/mechanical-packers/type-dl.jpg";
import { Helmet } from "react-helmet";
import JsPDF from "jspdf";
import TypeDl from "./Tables/TypeDl";

function TandemTensionPacker(props) {
  const { title } = props;
  const [products, setProducts] = useState([]);
  const [currentProduct, setCurrentProduct] = useState({});

  const generatePDF = () => {
    const report = new JsPDF("portrait", "pt", "a4");
    report.html(document.querySelector(".product-info")).then(() => {
      report.save(`${title}.pdf`);
    });
  };

  // useEffect(() => {
  //   getAllProductService().then((loadedProducts) => {
  //     setProducts(loadedProducts);
  //     setCurrentProduct(
  //       loadedProducts.find((product) => product.title === title)
  //     );
  //   });
  // }, []);

  useEffect(() => {
    getAllProductService().then((loadedProducts) => {
      setProducts(loadedProducts);

      let formatLoaded = loadedProducts.map((elem) => {
        if (elem.title === title) {
          return {
            ...elem,

            // description: elem.description.replace(/[\\n\\n]/gm, "n"),
            description: elem.description.split("\\n"),
          };
        } else {
          return elem;
        }
      });

      setCurrentProduct(
        formatLoaded.find((product) => product.title === title)
      );
      //currentProduct.description = currentProduct.description.replace(/[\\n\\n]/gm, "n");
      currentProduct.description = currentProduct.description.split("\\n");
    });
  }, []);

  // console.log(currentProduct);
  return (
    <div className="product-info">
      <Helmet>
        ‍
        <title>{`${title} | Maverick Downhole Technologies | Edmonton, AB`}</title>
        <meta
          property="og:title"
          content={`${title} | Maverick Downhole Technologies | Edmonton, AB`}
        />
        <meta
          property="og:description"
          content="The Type DL-IB Tandem Tension Packer is an economical, compact, low-pressure, tension set, isolation packer used in casing or perforations and multi-zone injection installations."
        />
        <meta property="og:image" content={TypeDLImg} />
        <meta name="keywords" content="tandem tension packer," />
        <meta name="robots" content="index, follow" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Product",
            name: "TYPE DL-IB TANDEM TENSION PACKER",
            image: { TypeDLImg },
            description:
              "The Type DL-IB Tandem Tension Packer is an economical, compact, low-pressure, tension set, isolation packer used in casing or perforations and multi-zone injection installations.",
            brand: {
              "@type": "Brand",
              name: "Maverick Downhole Technologies",
            },
          })}
        </script>
      </Helmet>
      <div>
        <h3 className="main-subhead">
          {currentProduct !== {} ? currentProduct.title : null}
        </h3>
        <h4 className="smaller-head">
          {currentProduct !== {} ? currentProduct.subhead : null}
        </h4>
        <div className="spacer"></div>
        <p className="body-text">
          {currentProduct !== {} ? currentProduct.description : null}
        </p>
        {/* <button onClick={generatePDF} type="button" className="main-btn">Download PDF</button><br /><br /> */}
        <TypeDl />
      </div>
      <div>
        <img
          src={TypeDLImg}
          title={currentProduct.title}
          alt={currentProduct.title}
          className="product-img"
        />
      </div>
    </div>
  );
}

export default TandemTensionPacker;
