import HomeHero from './HomeHero.css'
import { useEffect } from 'react';
import anime from 'animejs';
import { Link } from 'react-router-dom'

function HomeHeroNew() {

    useEffect(() => {
        // Wrap every letter in a span
var textWrapper = document.querySelector('.large-headline');
textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

anime.timeline({loop: true})
  .add({
    targets: '.large-headline .letter',
    translateX: [40,0],
    translateZ: 0,
    opacity: [0,1],
    easing: "easeOutExpo",
    duration: 1200,
    delay: (el, i) => 500 + 30 * i
  }).add({
    targets: '.large-headline .letter',
    translateX: [0,-30],
    opacity: [1,0],
    easing: "easeInExpo",
    duration: 1100,
    delay: (el, i) => 100000 + 30 * i
  });

          // Wrap every letter in a span
var textWrapper = document.querySelector('.two');
textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

anime.timeline({loop: true})
  .add({
    targets: '.two .letter',
    translateX: [30,0],
    translateZ: 0,
    opacity: [0,1],
    fontWeight: ["bold"],
    easing: "easeOutExpo",
    duration: 1200,
    delay: (el, i) => 500 + 30 * i
  }).add({
    targets: '.two .letter',
    translateX: [0,-30],
    opacity: [1,0],
    easing: "easeInExpo",
    duration: 1100,
    delay: (el, i) => 100000 + 30 * i
  });
      }, []);

    return(
        <div className="home-hero-container">
            <div className="home-hero-text">
                <h1 className="large-headline white">
                    Complete <br /> 
                </h1>
                <h1 className="two large-headline white bold">
                    <span className="bold">Confidence</span>
                </h1>
                <Link to="products">
                <button className="main-btn">
                    View Products
                </button>
                </Link>
            </div>
        </div>
    );
}

export default HomeHeroNew; 