import React from "react";
import EngineeringHeader from "../components/Engineering/EngineeringHeader";
import ApegaLogo from "../assets/logos/apega.png";

function Engineering() {
  return (
    <div>
      <EngineeringHeader />
      {/* <div className='frame-container'>
            <iframe src="https://www-maverickdownhole-com.filesusr.com/html/8d26df_b48631c8d445d619a3e3d1b93941ff0e.html"></iframe>
        </div> */}

      {/* 
      <div className="eng-page-description">
      <p className="body-text centre">
      Maverick’s experienced team of industry experts design and develop solutions to meet your completion needs. By meshing together its experience with conventional completion equipment and new cutting-edge technologies, Maverick provides innovative solutions to the energy industry.
      </p>
      <img src={ApegaLogo} alt="" className="apega-logo" />
      </div> */}
    </div>
  );
}

export default Engineering;
