import React from "react";
import { $, jQuery } from "jquery";

function WrBridgePlugTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c30">
            <td class="c73" colspan="6" rowspan="1">
              <p class="c2">
                <span class="c4">CASING</span>
              </p>
            </td>
            <td class="c10" colspan="2" rowspan="2">
              <p class="c2">
                <span class="c4">GAUGE RING OD</span>
              </p>
            </td>
          </tr>
          <tr class="c70">
            <td class="c10" colspan="2" rowspan="1">
              <p class="c2">
                <span class="c4">OD </span>
              </p>
            </td>
            <td class="c10" colspan="2" rowspan="1">
              <p class="c2">
                <span class="c4">WEIGHT </span>
              </p>
            </td>
            <td class="c10" colspan="2" rowspan="1">
              <p class="c2">
                <span class="c4">ID</span>
              </p>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="c14">
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c20">in. </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c20">mm </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c20">lbs/ft </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c20">kg/m </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c20">in. </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c20">mm </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c20">in. </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c20">mm</span>
              </p>
            </td>
          </tr>
          <tr class="c14">
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">4 1/2 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">114.3 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">15.1 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">22.5 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">3.701-3.826 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">94.0-97.9 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">3.695 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">93.8</span>
              </p>
            </td>
          </tr>
          <tr class="c14">
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">4 1/2 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">114.3 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">9.5-13.5 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">14.1-20.1 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">3.920-4.090 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">99.6-103.9 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">3.800 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">96.5</span>
              </p>
            </td>
          </tr>
          <tr class="c14">
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">5 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">127.0 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">21.4 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">31.8 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">4.001-4.126 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">101.6-104.8 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">3.975 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">101.6</span>
              </p>
            </td>
          </tr>
          <tr class="c14">
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">5 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">127.0 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">20.8 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">30.9 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">4.031-4.156 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">102.4-105.6 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">4.000 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">101.6</span>
              </p>
            </td>
          </tr>
          <tr class="c14">
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">5 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">127.0 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">15.0-18.0 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">22.3-26.8 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">4.276-4.408 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">108.6-112.0 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">4.125 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">104.8</span>
              </p>
            </td>
          </tr>
          <tr class="c14">
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">5 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">127.0 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">11.5-15.0 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">17.1-22.3 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">4.408-4.560 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">112.0-115.8 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">4.250 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">108.0</span>
              </p>
            </td>
          </tr>
          <tr class="c14">
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">5 1/2 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">139.7 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">26.8 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">39.9 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">4.375-4.500 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">111.1-114.3 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">4.250 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">108.0</span>
              </p>
            </td>
          </tr>
          <tr class="c14">
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">5 1/2 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">139.7 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">20.0-23.0 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">29.8-34.2 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">4.670-4.778 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">118.6-121.4 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">4.500 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">114.3</span>
              </p>
            </td>
          </tr>
          <tr class="c14">
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">5 1/2 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">139.7 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">15.5-20.0 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">23.1-29.8 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">4.778-4.950 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">121.4-125.7 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">4.641 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">117.9</span>
              </p>
            </td>
          </tr>
          <tr class="c14">
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">7 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">177.8 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">32.0-35.0 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">47.6-52.1 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">6.004-6.094 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">152.5-154.8 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">5.812 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">147.6</span>
              </p>
            </td>
          </tr>
          <tr class="c14">
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">7 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">177.8 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">26.0-29.0 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">38.7-43.2 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">6.184-6.276 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">157.1-159.4 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">5.875 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">149.2</span>
              </p>
            </td>
          </tr>
          <tr class="c14">
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">7 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">177.8 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">20.0-26.0 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">29.8-38.7 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">6.276-6.456 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">159.4-164.0 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">6.023 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">153.0</span>
              </p>
            </td>
          </tr>
          <tr class="c14">
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">7 5/8 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">193.7 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">33.7-39.0 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">50.1-58.0 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">6.625-6.765 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">168.3-171.8 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">6.375 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">161.9</span>
              </p>
            </td>
          </tr>
          <tr class="c14">
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">7 5/8 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">193.7 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">24.0-29.7 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">35.7-44.2 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">6.875-7.025 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">174.6-178.4 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">6.625 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">168.3</span>
              </p>
            </td>
          </tr>
          <tr class="c14">
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">8 5/8 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">219.1 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">32.0-40.4 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">47.6-59.5 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">7.725-7.921 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">196.2-201.2 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">7.531 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">191.3</span>
              </p>
            </td>
          </tr>
          <tr class="c14">
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">8 5/8 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">219.1 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">20.0-28.0 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">29.8-41.7 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">8.017-8.191 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">203.6-205.7 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">7.781 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">197.6</span>
              </p>
            </td>
          </tr>
          <tr class="c14">
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">9 5/8 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">244.5 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">47.0-53.5 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">69.9-79.6 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">8.535-8.681 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">216.8-220.5 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">8.285 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">210.4</span>
              </p>
            </td>
          </tr>
          <tr class="c14">
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">9 5/8 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">244.5 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">32.3-43.5 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">48.1-64.7 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">8.755-9.001 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">222.4-228.6 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">8.500 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">215.9</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default WrBridgePlugTable;
