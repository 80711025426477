import React from 'react'
import {$,jQuery} from 'jquery';

function AsiiiPackerTable() {
    
  return (
    <div>  
        <table id="example" class="table table-striped table-bordered" cellspacing="0" width="100%">
        <thead>
            <tr>
                <th colSpan={2}>Casing</th>
                <th rowSpan={2} style={{backgroundColor: 'black'}}>Recommended Hole Size (Inches)</th>
                <th rowSpan={2} style={{backgroundColor: 'black'}}>Gage OD (Inches)</th>
                <th rowSpan={2} style={{backgroundColor: 'black'}}>Max OD (Inches)</th>
                <th rowSpan={2} style={{backgroundColor: 'black'}}>Min ID (Inches)</th>
                <th rowSpan={2} style={{backgroundColor: 'black'}}>Thread Connections Box Up / Pin Down</th>
                <th rowSpan={2}>Part Number</th>
            </tr>
            <tr>
                <th>Size (inches)</th>
                <th>Weight (lbs/ft)</th>
            </tr>
        </thead>
        <tbody>
           <tr>
              <td>3-1/2</td>
              <td>7.7-10.2</td>
              <td>2.922-3.068</td>
              <td>2.781</td>
              <td>-</td>
              <td>1.25</td>
              <td>1.900 EUE</td>
              <td>61135RS</td>
           </tr>
           <tr>
              <td rowSpan={2}>4-1/2</td>
              <td>9.5-13.5</td>
              <td>3.920-4.090</td>
              <td>3.750</td>
              <td>-</td>
              <td>1.94</td>
              <td>2-3/8 EUE</td>
              <td>61145RS</td>
           </tr>
           <tr>
              <td>13.5-15.1</td>
              <td>3.826-3.920</td>
              <td>3.650</td>
              <td>-</td>
              <td>1.94</td>
              <td>2-3/8 EUE</td>
              <td>61144RS</td>
           </tr>
           <tr>
              <td>5</td>
              <td>11.5-15.0</td>
              <td>4.408-4.560</td>
              <td>4.125</td>
              <td>4.220<sup>1</sup></td>
              <td>1.94</td>
              <td>2-3/8 EUE</td>
              <td>61150RS</td>
           </tr>
          <tr>
            <td rowSpan={4}>5-1/2</td>
            <td rowSpan={2}>14.0-20.0</td>
            <td rowSpan={2}>4.778-5.012</td>
            <td rowSpan={2}>4.625</td>
            <td>-</td>
            <td>1.94</td>
            <td>2-3/8 EUE</td>
            <td>63155RS</td>
          </tr>
          <tr>
            <td>-</td>
            <td>2.38</td>
            <td>2-7/8 EUE</td>
            <td>63156RS</td>
          </tr>
          <tr>
            <td rowSpan={2}>20.0-23.0</td>
            <td rowSpan={2}>4.670-4.778</td>
            <td rowSpan={2}>4.500</td>
            <td rowSpan={2}>-</td>
            <td>2.00</td>
            <td>2-3/8 EUE</td>
            <td>63157RS</td>
          </tr>
          <tr>
            <td>2.38</td>
            <td>2-7/8 EUE</td>
            <td>63159RS</td>
          </tr>
          <tr>
            <td>5-3/4</td>
            <td>21.5-24.0</td>
            <td>4.784-4.879</td>
            <td>4.720</td>
            <td>-</td>
            <td>2.38</td>
            <td>2-7/8 EUE</td>
            <td>63158Z</td>
          </tr>
          <tr>
            <td rowSpan={2}>6-5/8</td>
            <td>17.0-24.0</td>
            <td>5.921-6.135</td>
            <td>5.750</td>
            <td>-</td>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>63167RS</td>
          </tr>
          <tr>
            <td>24.0-32.0</td>
            <td>5.675-5.921</td>
            <td>5.500</td>
            <td>-</td>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>63165RS</td>
          </tr>
          <tr>
            <td rowSpan={4}>7</td>
            <td rowSpan={2}>17.0-26.0</td>
            <td rowSpan={2}>6.276-6.538</td>
            <td>6.000</td>
            <td>6.062<sup>1</sup></td>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>63172RS</td>
          </tr>
          <tr>
            <td>6.000</td>
            <td>6.125<sup>1</sup></td>
            <td>3.00</td>
            <td>3-1/2 EUE</td>
            <td>63174RS</td>
          </tr>
          <tr>
            <td rowSpan={2}>26.0-32.0</td>
            <td rowSpan={2}>6.094-6.276</td>
            <td rowSpan={2}>5.875</td>
            <td>-</td>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>63170RS</td>
          </tr>
          <tr>
            <td>5.936<sup>1</sup></td>
            <td>3.00</td>
            <td>3-1/2 EUE</td>
            <td>63173RS</td>
          </tr>
          <tr>
            <td>7-5/8</td>
            <td>24.0-29.7</td>
            <td>6.875-7.025</td>
            <td>6.672</td>
            <td>-</td>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>63175RS</td>
          </tr>
          <tr>
            <td>8-5/8</td>
            <td>28.0-40.0</td>
            <td>7.725-8.017</td>
            <td>7.500</td>
            <td>-</td>
            <td>3.00</td>
            <td>3-1/2 EUE</td>
            <td>63182RS</td>
          </tr>
          <tr>
            <td rowSpan={3}>9-5/8</td>
            <td>32.3-43.5</td>
            <td>8.755-9.001</td>
            <td>8.500</td>
            <td>-</td>
            <td>3.00</td>
            <td>3-1/2 EUE</td>
            <td>63196RS</td>
          </tr>
          <tr>
            <td rowSpan={2}>43.5-53.5</td>
            <td rowSpan={2}>8.535-8.755</td>
            <td rowSpan={2}>8.250</td>
            <td>-</td>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>63196RS</td>
          </tr>
          <tr>
            <td>-</td>
            <td>4.00</td>
            <td>4-1/2 EUE</td>
            <td>63195</td>
          </tr>
        </tbody>
        </table>
    </div>
  )
}

export default AsiiiPackerTable