import Footer from "../Footer/Footer.css";
import MaverickLogoWhite from "../../assets/logos/maverick-logo-white.png";
import CanadaFlag from "../../assets/icons/CanadaFlag.png";
import MexicoFlag from "../../assets/icons/MexicoFlag.png";
import EgyptFlag from "../../assets/icons/egypt.png";
import AmericanFLag from "../../assets/icons/AmericanFlag.png";

import SeaharvestLogo from "../../assets/logos/seaharvest.png";
import { Routes, Route, Link } from "react-router-dom";

function FooterMaverick() {
  return (
    <footer>
      <div className="footer-grid-container">
        <div className="footer-item">
          <img
            src={MaverickLogoWhite}
            className="footer-logo"
            alt="Maverick Logo"
            title="Maverick Logo"
            loading="lazy"
          />
          <p className="footer-text">
            Maverick’s mission is to be the preferred supply partner of downhole
            tools to the world’s most important service companies.
          </p>

          <div className="footer-item">
            <p class="footer-heading">Links</p>
            <div className="footer-link-container">
              <ul>
                <Link to="/">
                  <li className="footer-link">Home</li>
                </Link>
                <Link to="products">
                  <li className="footer-link">Products</li>
                </Link>
                <Link to="engineering">
                  <li className="footer-link">Engineering</li>
                </Link>
              </ul>
            </div>
          </div>
        </div>

        <div className="footer-item">
          <p class="footer-heading">
            Get in <span className="bold">Touch</span>
          </p>
          <div className="contact-info">
            <div>
              <img src={CanadaFlag} alt={CanadaFlag} className="flag" />
              <a href="tel:5874026783">
                <p className="footer-text">587-402-6783</p>
              </a>
              <a href="mailto:info@maverickdownhole.com">
                <p className="footer-text">info@maverickdownhole.com</p>
              </a>
              <a
                href="https://www.google.ca/maps/dir//maverick+downhole/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x53a018cc6f43d895:0x27ad2a1e9de3c98?sa=X&ved=2ahUKEwjlvfKG4b_7AhUROH0KHRnqCQEQ9Rd6BAhKEAQ"
                target="_blank"
              >
                <address className="footer-text">
                  5744 67th Ave <br />
                  Edmonton, AB T6B 3P8
                </address>
              </a>
            </div>

            <div>
              <img src={AmericanFLag} alt={AmericanFLag} className="flag" />
              <a href="tel:17015776783">
                <p className="footer-text">701-577-6783</p>
              </a>
              <a href="mailto:info@maverickdownhole.com">
                <p className="footer-text">info@maverickdownhole.com</p>
              </a>

              <address className="footer-text">
                1910-50th St W<br /> Williston, ND, USA 58801
              </address>

              <div
                style={{
                  backgroundColor: "white",
                  width: "100%",
                  height: "1px",
                  marginBottom: "20px",
                }}
              ></div>

              <a href="tel:14326060399">
                <p className="footer-text">432.606.0399</p>
              </a>
              <address className="footer-text">
                3107 S County Road
                <br /> Midland Texas, USA 79706
              </address>
            </div>

            <div>
              <img src={MexicoFlag} alt={MexicoFlag} className="flag" />
              <a href="tel:529933170005">
                <p className="footer-text">52-993-317-0005</p>
              </a>
              <a href="mailto:info@maverickdownhole.com">
                <p className="footer-text">info@maverickdownhole.com</p>
              </a>
              <address className="footer-text">
                Parque Logistico Industrial Tabasco <br />
                (PLIT) San Remo, Mz. 2, Lt. 1, Bodega 2 <br />
                CP.86287. Villahermosa
              </address>
            </div>
            <div>
              <img src={EgyptFlag} alt="Egypt" className="flag egypt" />
              <a href="tel:20227324446">
                <p className="footer-text">+202 27324446</p>
              </a>
              <a href="mailto:m.shehata@seaharvest.org">
                <p className="footer-text">Email Us</p>
              </a>
              <p className="footer-text">Seaharvest Freezone</p>
              <address className="footer-text">
                Plot 70, Industrial Zone, Zahra El Maadi <br />
                11728, Cairo, Egypt
              </address>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterMaverick;
