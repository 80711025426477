import React from "react";
import Derrick from "../../assets/images/derrick.jpg"
import { gsap } from 'gsap/all';
import ScrollTrigger from 'gsap/ScrollTrigger'


function AboutContent() {

  gsap.registerPlugin(ScrollTrigger);

  let revealContainers = document.querySelectorAll(".reveal");
  
  revealContainers.forEach((container) => {
    let image = container.querySelector(".about-top");
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: container,
        toggleActions: "restart none none reset"
      }
    });
  
    tl.set(container, { autoAlpha: 1 });
    tl.from(container, 1.5, {
      xPercent: -100,
      // ease: Power2.out
    });
    tl.from(image, 1.5, {
      xPercent: 100,
      scale: 1.3,
      delay: -1.5,
      // ease: Power2.out
    });
  });

  return (
    <div>
      <div className="about-top">
        <h3 className="main-subhead centre">
          It starts with an<span className="bold"> idea and engineering</span>
        </h3>
        <p className="body-text centre">
          At Maverick Downhole Technologies, some of the best minds in the
          industry consistently push the envelope of innovation in designing
          downhole tools that are stronger, easier, and smarter than ever
          before. So, whether its an off-the shelf Downhole product, or a
          sophisticated solution to a customer’s specifc Downhole problem,
          Maverick Downhole Technologies products are engineered to do the job,
          and stand the test of time, in the world’s most challenging work
          environments.
        </p>
      </div>
      <div className="about-bottom">
        <div className="about-bottom-inner">
          <div>
            <img src={Derrick} alt="Derrick" className="derrick" />
          </div>
          <div className="column-right-about">
            <div className="spacer mb40"></div>
            <p className="body-text">
              Maverick Downhole Technologies offers downhole tool supervision in
              Western Canada as well as internationally. Field level supervision
              specializing in Multi-Zone Frac Liners, Ball Drop Pumping
              Operations, Coil Tubing and Standard Downhole Completions.
              (Hydraulic/Mechanical Packers, Bridge Plugs, Plug & Perf, Acid
              Wash Tools Etc.)
              <br />
              <br />
              Maverick Downhole Technologies is experienced throughout the
              Western Canada Basin including winter projects in the Arctic
              (Mackenzie Delta). Internationally The Maverick Team has extensive
              experience in project management in Asia Including a 200+
              Horizontal Multi-Zone Liner Well Project for Sinopec in Southern
              China.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutContent;
