import React from "react";
import { Link } from "react-router-dom";

function ErrorPage() {
  return (
    <div>
      <div className="error-page">
        <h4 className="main-subhead white">This page cannot be found</h4>
        <Link to="/">
          <button className="main-btn">Return Home</button>
        </Link>
      </div>
    </div>
  );
}

export default ErrorPage;
