import React from "react";
import { $, jQuery } from "jquery";

function DltRetrievablePackerTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c38">
            <td class="c328" colspan="2" rowspan="1">
              Casing
            </td>
            <td class="c83" colspan="1" rowspan="2">
              Recommended Hole Size (inches)
            </td>
            <td class="c6" colspan="1" rowspan="2">
              Gage OD (inches)
            </td>
            <td class="c42" colspan="1" rowspan="2">
              Max OD (inches)
            </td>
            <td class="c178" colspan="1" rowspan="2">
              Tool ID (inches)
            </td>
            <td class="c168" colspan="1" rowspan="2">
              Thread Connections Box Up / Pin Down
            </td>
            <td class="c40" colspan="1" rowspan="2">
              Part Number
            </td>
          </tr>
          <tr class="c59">
            <td class="c121" colspan="1" rowspan="1">
              Size (inches)
            </td>
            <td class="c160" colspan="1" rowspan="1">
              Weight (lbs/ft)
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="c21">
            <td class="c121" colspan="1" rowspan="4">
              7
            </td>
            <td class="c160" colspan="1" rowspan="1">
              17.0 - 23.0
            </td>
            <td class="c83" colspan="1" rowspan="1">
              6.366 - 6.538
            </td>
            <td class="c6" colspan="1" rowspan="1">
              6.188
            </td>
            <td class="c42" colspan="1" rowspan="1">
              6.2001
            </td>
            <td class="c178" colspan="1" rowspan="1">
              2.69
            </td>
            <td class="c168" colspan="1" rowspan="1">
              3-1/2 IF TOOL JOINT
            </td>
            <td class="c40" colspan="1" rowspan="1">
              68873A
            </td>
          </tr>
          <tr class="c21">
            <td class="c160" colspan="1" rowspan="1">
              26.0 - 32.0
            </td>
            <td class="c83" colspan="1" rowspan="1">
              6.094 - 6.276
            </td>
            <td class="c6" colspan="1" rowspan="1">
              5.954
            </td>
            <td class="c42" colspan="1" rowspan="1">
              -
            </td>
            <td class="c178" colspan="1" rowspan="1">
              2.69
            </td>
            <td class="c168" colspan="1" rowspan="1">
              3-1/2 IF TOOL JOINT
            </td>
            <td class="c40" colspan="1" rowspan="1">
              68873B
            </td>
          </tr>
          <tr class="c21">
            <td class="c160" colspan="1" rowspan="1">
              32.0 - 38.0
            </td>
            <td class="c83" colspan="1" rowspan="1">
              5.920 - 6.094
            </td>
            <td class="c6" colspan="1" rowspan="1">
              5.781
            </td>
            <td class="c42" colspan="1" rowspan="1">
              -
            </td>
            <td class="c178" colspan="1" rowspan="1">
              2.69
            </td>
            <td class="c168" colspan="1" rowspan="1">
              3-1/2 IF TOOL JOINT
            </td>
            <td class="c40" colspan="1" rowspan="1">
              68873C
            </td>
          </tr>
          <tr class="c21">
            <td class="c160" colspan="1" rowspan="1">
              38.0 - 46.4
            </td>
            <td class="c83" colspan="1" rowspan="1">
              5.626 - 5.920
            </td>
            <td class="c6" colspan="1" rowspan="1">
              5.525
            </td>
            <td class="c42" colspan="1" rowspan="1">
              -
            </td>
            <td class="c178" colspan="1" rowspan="1">
              2.69
            </td>
            <td class="c168" colspan="1" rowspan="1">
              3-1/2 IF TOOL JOINT
            </td>
            <td class="c40" colspan="1" rowspan="1">
              68873D
            </td>
          </tr>
          <tr class="c21">
            <td class="c121" colspan="1" rowspan="2">
              7-5/8
            </td>
            <td class="c160" colspan="1" rowspan="1">
              24.0 - 29.7
            </td>
            <td class="c83" colspan="1" rowspan="1">
              6.875 - 7.025
            </td>
            <td class="c6" colspan="1" rowspan="1">
              6.672
            </td>
            <td class="c42" colspan="1" rowspan="1">
              -
            </td>
            <td class="c178" colspan="1" rowspan="1">
              2.69
            </td>
            <td class="c168" colspan="1" rowspan="1">
              3-1/2 IF TOOL JOINT
            </td>
            <td class="c40" colspan="1" rowspan="1">
              68875A
            </td>
          </tr>
          <tr class="c21">
            <td class="c160" colspan="1" rowspan="1">
              33.7 - 39.0
            </td>
            <td class="c83" colspan="1" rowspan="1">
              6.625 - 6.765
            </td>
            <td class="c6" colspan="1" rowspan="1">
              6.453
            </td>
            <td class="c42" colspan="1" rowspan="1">
              -
            </td>
            <td class="c178" colspan="1" rowspan="1">
              2.69
            </td>
            <td class="c168" colspan="1" rowspan="1">
              3-1/2 IF TOOL JOINT
            </td>
            <td class="c40" colspan="1" rowspan="1">
              68875B
            </td>
          </tr>
          <tr class="c21">
            <td class="c121" colspan="1" rowspan="3">
              9-5/8
            </td>
            <td class="c160" colspan="1" rowspan="1">
              32.3 - 43.5
            </td>
            <td class="c83" colspan="1" rowspan="1">
              8.755 - 9.001
            </td>
            <td class="c6" colspan="1" rowspan="1">
              8.584
            </td>
            <td class="c42" colspan="1" rowspan="1">
              -
            </td>
            <td class="c178" colspan="1" rowspan="1">
              3.75
            </td>
            <td class="c168" colspan="1" rowspan="1">
              4-1/2 IF TOOL JOINT
            </td>
            <td class="c40" colspan="1" rowspan="1">
              68896
            </td>
          </tr>
          <tr class="c21">
            <td class="c160" colspan="1" rowspan="1">
              43.5 - 53.5
            </td>
            <td class="c83" colspan="1" rowspan="1">
              8.535 - 8.755
            </td>
            <td class="c6" colspan="1" rowspan="1">
              8.365
            </td>
            <td class="c42" colspan="1" rowspan="1">
              -
            </td>
            <td class="c178" colspan="1" rowspan="1">
              3.75
            </td>
            <td class="c168" colspan="1" rowspan="1">
              4-1/2 IF TOOL JOINT
            </td>
            <td class="c40" colspan="1" rowspan="1">
              68895
            </td>
          </tr>
          <tr class="c21">
            <td class="c160" colspan="1" rowspan="1">
              58.4 - 59.4
            </td>
            <td class="c83" colspan="1" rowspan="1">
              8.407 - 8.435
            </td>
            <td class="c6" colspan="1" rowspan="1">
              8.250
            </td>
            <td class="c42" colspan="1" rowspan="1">
              -
            </td>
            <td class="c178" colspan="1" rowspan="1">
              3.75
            </td>
            <td class="c168" colspan="1" rowspan="1">
              4-1/2 IF TOOL JOINT
            </td>
            <td class="c40" colspan="1" rowspan="1">
              68894
            </td>
          </tr>
          <tr class="c21">
            <td class="c121" colspan="1" rowspan="3">
              10-3/4
            </td>
            <td class="c160" colspan="1" rowspan="1">
              51.0 - 55.5
            </td>
            <td class="c83" colspan="1" rowspan="1">
              9.760 - 9.850
            </td>
            <td class="c6" colspan="1" rowspan="1">
              9.500
            </td>
            <td class="c42" colspan="1" rowspan="1">
              -
            </td>
            <td class="c178" colspan="1" rowspan="1">
              3.75
            </td>
            <td class="c168" colspan="1" rowspan="1">
              4-1/2 IF TOOL JOINT
            </td>
            <td class="c40" colspan="1" rowspan="1">
              68810A
            </td>
          </tr>
          <tr class="c21">
            <td class="c160" colspan="1" rowspan="1">
              60.7 - 65.7
            </td>
            <td class="c83" colspan="1" rowspan="1">
              9.560 - 9.660
            </td>
            <td class="c6" colspan="1" rowspan="1">
              9.375
            </td>
            <td class="c42" colspan="1" rowspan="1">
              -
            </td>
            <td class="c178" colspan="1" rowspan="1">
              3.75
            </td>
            <td class="c168" colspan="1" rowspan="1">
              4-1/2 IF TOOL JOINT
            </td>
            <td class="c40" colspan="1" rowspan="1">
              68810B
            </td>
          </tr>
          <tr class="c21">
            <td class="c160" colspan="1" rowspan="1">
              71.1 - 73.2
            </td>
            <td class="c83" colspan="1" rowspan="1">
              9.406 - 9.450
            </td>
            <td class="c6" colspan="1" rowspan="1">
              9.125
            </td>
            <td class="c42" colspan="1" rowspan="1">
              -
            </td>
            <td class="c178" colspan="1" rowspan="1">
              3.75
            </td>
            <td class="c168" colspan="1" rowspan="1">
              4-1/2 IF TOOL JOINT
            </td>
            <td class="c40" colspan="1" rowspan="1">
              68810C
            </td>
          </tr>
          <tr class="c21">
            <td class="c121" colspan="1" rowspan="2">
              11-3/4
            </td>
            <td class="c160" colspan="1" rowspan="1">
              42.0 - 54.0
            </td>
            <td class="c83" colspan="1" rowspan="1">
              10.880 - 11.084
            </td>
            <td class="c6" colspan="1" rowspan="1">
              10.625
            </td>
            <td class="c42" colspan="1" rowspan="1">
              -
            </td>
            <td class="c178" colspan="1" rowspan="1">
              3.75
            </td>
            <td class="c168" colspan="1" rowspan="1">
              4-1/2 IF TOOL JOINT
            </td>
            <td class="c40" colspan="1" rowspan="1">
              68811A
            </td>
          </tr>
          <tr class="c21">
            <td class="c160" colspan="1" rowspan="1">
              60.0 - 71.0
            </td>
            <td class="c83" colspan="1" rowspan="1">
              10.586 - 10.772
            </td>
            <td class="c6" colspan="1" rowspan="1">
              10.406
            </td>
            <td class="c42" colspan="1" rowspan="1">
              -
            </td>
            <td class="c178" colspan="1" rowspan="1">
              3.75
            </td>
            <td class="c168" colspan="1" rowspan="1">
              4-1/2 IF TOOL JOINT
            </td>
            <td class="c40" colspan="1" rowspan="1">
              68811B
            </td>
          </tr>
          <tr class="c21">
            <td class="c121" colspan="1" rowspan="2">
              13-3/8
            </td>
            <td class="c160" colspan="1" rowspan="1">
              48.0 - 77.0
            </td>
            <td class="c83" colspan="1" rowspan="1">
              12.275 - 12.715
            </td>
            <td class="c6" colspan="1" rowspan="1">
              12.000
            </td>
            <td class="c42" colspan="1" rowspan="1">
              -
            </td>
            <td class="c178" colspan="1" rowspan="1">
              3.75
            </td>
            <td class="c168" colspan="1" rowspan="1">
              4-1/2 IF TOOL JOINT
            </td>
            <td class="c40" colspan="1" rowspan="1">
              68813
            </td>
          </tr>
          <tr class="c21">
            <td class="c160" colspan="1" rowspan="1">
              80.7 - 92.0
            </td>
            <td class="c83" colspan="1" rowspan="1">
              12.031 - 12.215
            </td>
            <td class="c6" colspan="1" rowspan="1">
              11.750
            </td>
            <td class="c42" colspan="1" rowspan="1">
              -
            </td>
            <td class="c178" colspan="1" rowspan="1">
              3.75
            </td>
            <td class="c168" colspan="1" rowspan="1">
              4-1/2 IF TOOL JOINT
            </td>
            <td class="c40" colspan="1" rowspan="1">
              68813B
            </td>
          </tr>
          <tr class="c93">
            <td class="c121" colspan="1" rowspan="1">
              14
            </td>
            <td class="c160" colspan="1" rowspan="1">
              82.5 - 92.68
            </td>
            <td class="c83" colspan="1" rowspan="1">
              12.700 - 12.876
            </td>
            <td class="c6" colspan="1" rowspan="1">
              12.500
            </td>
            <td class="c42" colspan="1" rowspan="1">
              -
            </td>
            <td class="c178" colspan="1" rowspan="1">
              3.75
            </td>
            <td class="c168" colspan="1" rowspan="1">
              4-1/2 IF TOOL JOINT
            </td>
            <td class="c40" colspan="1" rowspan="1">
              68814A
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default DltRetrievablePackerTable;
