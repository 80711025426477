import React from 'react'
import {$,jQuery} from 'jquery';

function AsiXPacker() {
    
  return (
    <div>  
        <table id="example" class="table table-striped table-bordered" cellspacing="0" width="100%">
        <thead>
            <tr>
                <th colSpan={2}>Casing</th>
                <th rowSpan={2} style={{backgroundColor: 'black'}}>Recommended Hole Size (Inches)</th>
                <th rowSpan={2} style={{backgroundColor: 'black'}}>Gage OD (Inches)</th>
                <th rowSpan={2} style={{backgroundColor: 'black'}}>Max OD (Inches)</th>
                <th rowSpan={2} style={{backgroundColor: 'black'}}>Tool OD (Inches)</th>
                <th rowSpan={2} style={{backgroundColor: 'black'}}>Thread Connections Box Up / Pin Down</th>
                <th colSpan={2}>Part Number</th>
            </tr>
            <tr>
                <th>Size (inches)</th>
                <th>Weight (lbs/ft)</th>
                <th>Std</th>
                <th>HT</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td rowSpan={2}>2-7/8</td>
                <td>6.4-6.5</td>
                <td>2.375-2.441</td>
                <td>2.250</td>
                <td>2.263</td>
                <td>0.63</td>
                <td>1.050 EUE</td>
                <td>60325-3E*</td>
                <td>-</td>
            </tr>
            <tr>
                <td>8.6</td>
                <td>2.259</td>
                <td>2.125</td>
                <td>2.152</td>
                <td>0.63</td>
                <td>1.050 EUE</td>
                <td>60324-3E*</td>
                <td>-</td>
            </tr>
            <tr>
                <td rowSpan={3} align="center">3-12</td>
                <td>7.5-7.7</td>
                <td>3.068-3.250</td>
                <td>2.938</td>
                <td>-</td>
                <td>1.25</td>
                <td>1.900 EUE</td>
                <td>60336*</td>
                <td>-</td>
            </tr>
            <tr>
                <td>7.7-10.2</td>
                <td>2.922-3.068</td>
                <td>2.781</td>
                <td>-</td>
                <td>1.25</td>
                <td>1.900 EUE</td>
                <td>60335*</td>
                <td>-</td>
            </tr>
            <tr>
                <td>12.95</td>
                <td>2.750</td>
                <td>2.562</td>
                <td>-</td>
                <td>1.00</td>
                <td>1.315/1.660 EUE</td>
                <td>60337*</td>
                <td>-</td>
            </tr>
            <tr>
                <td rowSpan={2}>4</td>
                <td>9.5-11.0</td>
                <td>3.476-3.548</td>
                <td>3.250</td>
                <td>3.312</td>
                <td>1.50</td>
                <td>1.900 EUE</td>
                <td>60340*</td>
                <td>-</td>
            </tr>
            <tr>
                <td>10.46-12.95</td>
                <td>3.340-3.476</td>
                <td>3.187</td>
                <td>-</td>
                <td>1.50</td>
                <td>1.900 EUE</td>
                <td>60341*</td>
                <td>-</td>
            </tr>
            <tr>
                <td rowSpan={5}>4-1/2</td>
                <td>9.5-13.5</td>
                <td>3.920-4.090</td>
                <td>3.750</td>
                <td>-</td>
                <td>1.94</td>
                <td>2-3/8 EUE</td>
                <td>60345<sup>2</sup></td>
                <td>60345HT<sup>2</sup></td>
            </tr>
            <tr>
                <td>13.5-15.1</td>
                <td>3.826-3.920</td>
                <td>3.650</td>
                <td>-</td>
                <td>1.94</td>
                <td>2-3/8 EUE</td>
                <td>60344<sup>2</sup></td>
                <td>60345HT<sup>2</sup></td>
            </tr>
            <tr>
                <td>15.1</td>
                <td>3.826</td>
                <td>3.641</td>
                <td>-</td>
                <td>1.94</td>
                <td>2-3/8 EUE</td>
                <td>60346</td>
                <td>-</td>
            </tr>
            <tr>
                <td>15.1-16.6</td>
                <td>3.754-3.826</td>
                <td>3.594</td>
                <td>-</td>
                <td>1.50</td>
                <td>1.900 EUE</td>
                <td>60343</td>
                <td>-</td>
            </tr>
            <tr>
                <td>18.8</td>
                <td>3.640</td>
                <td>3.437</td>
                <td>-</td>
                <td>1.50</td>
                <td>1.900 EUE</td>
                <td>60342</td>
                <td>-</td>
            </tr>
            <tr>
                <td rowSpan={4}>5</td>
                <td>11.5-15.0</td>
                <td>4.408-4.560</td>
                <td>4.125</td>
                <td>4.220<sup>1</sup></td>
                <td>1.94</td>
                <td>2-3/8 EUE</td>
                <td>60350</td>
                <td>60350HT</td>
            </tr>
            <tr>
                <td>15.0-18.0</td>
                <td>4.276-4.408</td>
                <td>4.063</td>
                <td>-</td>
                <td>1.94</td>
                <td>2-3/8 EUE</td>
                <td>60349</td>
                <td>-</td>
            </tr>
            <tr>
                <td>18.0-20.8</td>
                <td>4.156-4.276</td>
                <td>4.000</td>
                <td>4.010<sup>1</sup></td>
                <td>1.94</td>
                <td>2-3/8 EUE</td>
                <td>60352</td>
                <td>60352HT</td>
            </tr>
            <tr>
                <td>21.4</td>
                <td>4.126</td>
                <td>3.938</td>
                <td>-</td>
                <td>1.94</td>
                <td>2-3/8 EUE</td>
                <td>60353</td>
                <td>60353HT</td>
            </tr>
            <tr>
                <td rowSpan={7}>5-1/2</td>
                <td>13.0-14.0</td>
                <td>5.012</td>
                <td>4.813</td>
                <td>-</td>
                <td>2.38</td>
                <td>2-7/8 EUE</td>
                <td>60358</td>
                <td>60358HT</td>
            </tr>
            <tr>
                <td rowSpan={2}>14.0-20.0</td>
                <td rowSpan={2}>4.778-5.012</td>
                <td rowSpan={2}>4.625</td>
                <td>-</td>
                <td>2.000</td>
                <td>2-3/8 EUE</td>
                <td>60355<sup>2</sup></td>
                <td>60355HT<sup>2</sup></td>
            </tr>
            <tr>
                <td>-</td>
                <td>2.38</td>
                <td>2-7/8 EUE</td>
                <td>60356<sup>2</sup></td>
                <td>60356HT<sup>2</sup></td>
            </tr>
            <tr>
                <td rowSpan={2}>20.0-23.0</td>
                <td rowSpan={2}>4.670-4.778</td>
                <td rowSpan={2}>4.500</td>
                <td>-</td>
                <td>2.000</td>
                <td>2-3/8 EUE</td>
                <td>60357<sup>2</sup></td>
                <td>60357HT<sup>2</sup></td>
            </tr>
            <tr>
                <td>-</td>
                <td>2.38</td>
                <td>2-7/8 EUE</td>
                <td>60359<sup>2</sup></td>
                <td>60359HT<sup>2</sup></td>
            </tr>
            <tr>
                <td rowSpan={2}>23.0-26.0</td>
                <td rowSpan={2}>4.548-4.670</td>
                <td>4.375</td>
                <td>-</td>
                <td>1.94</td>
                <td>2-3/8 EUE</td>
                <td>60354</td>
                <td>60354HT</td>
            </tr>
            <tr>
                <td>4.406</td>
                <td>-</td>
                <td>2.38</td>
                <td>2-7/8 EUE</td>
                <td>60351</td>
                <td>60351HT</td>
            </tr>
            <tr>
                <td rowSpan={6}>5-3/4</td>
                <td rowSpan={2}>15.0-16.1</td>
                <td rowSpan={2}>5.076-5.115</td>
                <td rowSpan={2}>5.000</td>
                <td>-</td>
                <td>2.00</td>
                <td>2-3/8 EUE</td>
                <td>60357X</td>
                <td>60357XHT</td>
            </tr>
            <tr>
                <td>-</td>
                <td>2.38</td>
                <td>2-7/8 EUE</td>
                <td>60358X</td>
                <td>60358XHT</td>
            </tr>
            <tr>
                <td rowSpan={2}>17.6-19.4</td>
                <td rowSpan={2}>4.958-5.021</td>
                <td rowSpan={2}>4.875</td>
                <td>-</td>
                <td>2.00</td>
                <td>2-3/8 EUE</td>
                <td>60357Y</td>
                <td>60357YHT</td>
            </tr>
            <tr>
                <td>-</td>
                <td>2.38</td>
                <td>2-7/8 EUE</td>
                <td>60358Y</td>
                <td>60358YHT</td>
            </tr>
            <tr>
                <td rowSpan={2}>21.5-24.0</td>
                <td rowSpan={2}>4.784-4.879</td>
                <td rowSpan={2}>4.720</td>
                <td>-</td>
                <td>2.00</td>
                <td>2-3/8 EUE</td>
                <td>60357Z</td>
                <td>60357ZHT</td>
            </tr>
            <tr>
                <td>-</td>
                <td>2.38</td>
                <td>2-7/8 EUE</td>
                <td>60358Z</td>
                <td>60358ZHT</td>
            </tr>
            <tr>
                <td rowSpan={2}>6</td>
                <td>10.0</td>
                <td>5.672</td>
                <td>5.375</td>
                <td>-</td>
                <td>2.50</td>
                <td>2-7/8 EUE</td>
                <td>60361</td>
                <td>-</td>
            </tr>
            <tr>
                <td>12.0-20.0</td>
                <td>5352-5.620</td>
                <td>5.188</td>
                <td>-</td>
                <td>2.38</td>
                <td>2-7/8 EUE</td>
                <td>60360</td>
                <td>60360HT</td>
            </tr>
            <tr>
                <td rowSpan={5}>6-5/8</td>
                <td>17.0-24.0</td>
                <td>5.921-6.135</td>
                <td>5.750</td>
                <td>-</td>
                <td>2.50</td>
                <td>2-7/8 EUE</td>
                <td>60367</td>
                <td>60367HT</td>
            </tr>
            <tr>
                <td>20.0-24.0</td>
                <td>5.921-6.049</td>
                <td>5.750</td>
                <td>-</td>
                <td>3.00</td>
                <td>3-1/2 EUE</td>
                <td>60368</td>
                <td>60368HT</td>
            </tr>
            <tr>
                <td rowSpan={2}>24.0-32.0</td>
                <td rowSpan={2}>5.675-5.921</td>
                <td rowSpan={2}>5.500</td>
                <td>-</td>
                <td>2.50</td>
                <td>2-7/8 EUE</td>
                <td>60365</td>
                <td>60365HT</td>
            </tr>
            <tr>
                <td>-</td>
                <td>3.00</td>
                <td>3-1/2 EUE</td>
                <td>60369</td>
                <td>60369HT</td>
            </tr>
            <tr>
                <td>32.0-34.5</td>
                <td>5.757-5.675</td>
                <td>5.312</td>
                <td>-</td>
                <td>2.50</td>
                <td>2-7/8 EUE</td>
                <td>60366</td>
                <td>60366HT</td>
            </tr>
            <tr>
                <td rowSpan={8}>7</td>
                <td rowSpan={2}>17.0-20.0</td>
                <td rowSpan={2}>6.456-6.538</td>
                <td rowSpan={2}>6.250</td>
                <td>6.281</td>
                <td>2.50</td>
                <td>2-7/8 EUE</td>
                <td>60372-625</td>
                <td>-</td>
            </tr>
            <tr>
                <td>-</td>
                <td>3.00</td>
                <td>3-1/2 EUE</td>
                <td>60372-625</td>
                <td>-</td>
            </tr>
            <tr>
                <td rowSpan={2}>17.0-26.0</td>
                <td rowSpan={2}>6.276-6.538</td>
                <td rowSpan={2}>6.000</td>
                <td>6.062<sup>1</sup></td>
                <td>2.50</td>
                <td>2-7/8 EUE</td>
                <td>60372<sup>2</sup></td>
                <td>60372HT<sup>2</sup></td>
            </tr>
            <tr>
                <td>6.125<sup>1</sup></td>
                <td>3.00</td>
                <td>3-1/2 EUE</td>
                <td>60374</td>
                <td>60374HT</td>
            </tr>
            <tr>
                <td rowSpan={2}>26.0-32.0</td>
                <td rowSpan={2}>6.094-6.276</td>
                <td rowSpan={2}>5.875</td>
                <td>-</td>
                <td>2.50</td>
                <td>2-7/8 EUE</td>
                <td>60370<sup>2</sup></td>
                <td>60370HT<sup>2</sup></td>
            </tr>
            <tr>
                <td>5.936<sup>1</sup></td>
                <td>3.00</td>
                <td>3-1/2 EUE</td>
                <td>60373</td>
                <td>60373HT</td>
            </tr>
            <tr>
                <td>29.0-35.0</td>
                <td>6.004-6.184</td>
                <td>5.812</td>
                <td>-</td>
                <td>2.50</td>
                <td>2-7/8 EUE</td>
                <td>60371<sup>2</sup></td>
                <td>60371HT<sup>2</sup></td>
            </tr>
            <tr>
                <td>35.0</td>
                <td>6.004</td>
                <td>5.812</td>
                <td>-</td>
                <td>3.00</td>
                <td>3-1/2 EUE</td>
                <td>60373-35</td>
                <td>-</td>
            </tr>
            <tr>
                <td rowSpan={4}>7-5/8</td>
                <td rowSpan={2}>24.0-29.7</td>
                <td rowSpan={2}>6.875-7.025</td>
                <td rowSpan={2}>6.672</td>
                <td>-</td>
                <td>2.50</td>
                <td>2-7/8 EUE</td>
                <td>60375</td>
                <td>60375HT</td>
            </tr>
            <tr>
                <td>-</td>
                <td>3.00</td>
                <td>3-1/2 EUE</td>
                <td>60378</td>
                <td>60378HT</td>
            </tr>
            <tr>
                <td rowSpan={2}>33.7-39.0</td>
                <td rowSpan={2}>6.625-6.765</td>
                <td rowSpan={2}>6.453</td>
                <td>-</td>
                <td>2.50</td>
                <td>2-7/8 EUE</td>
                <td>60376</td>
                <td>60376HT</td>
            </tr>
            <tr>
                <td>-</td>
                <td>3.00</td>
                <td>3-1/2 EUE</td>
                <td>60377</td>
                <td>60377HT</td>
            </tr>
            <tr>
                <td rowSpan={6}>8-5/8</td>
                <td>20.0-24.0</td>
                <td>8.097-8.191</td>
                <td>7.750</td>
                <td>-</td>
                <td>2.50</td>
                <td>2-7/8 EUE</td>
                <td>60384</td>
                <td>-</td>
            </tr>
            <tr>
                <td>20.0-28.0</td>
                <td>8.017-8.191</td>
                <td>7.750</td>
                <td>7.827</td>
                <td>4.00</td>
                <td>4-1/2 EUE</td>
                <td>60381</td>
                <td>60381HT</td>
            </tr>
            <tr>
                <td rowSpan={2}>24.0-40.0</td>
                <td rowSpan={2}>7.725-8.097</td>
                <td rowSpan={2}>7.750</td>
                <td>-</td>
                <td>2.50</td>
                <td>2-7/8 EUE</td>
                <td>60385</td>
                <td>60385HT</td>
            </tr>
            <tr>
                <td>-</td>
                <td>3.00</td>
                <td>3-1/2 EUE</td>
                <td>60387</td>
                <td>60387HT</td>
            </tr>
            <tr>
                <td>32.0-40.0</td>
                <td>7.725-7.921</td>
                <td>7.50</td>
                <td>-</td>
                <td>4.00</td>
                <td>4-1/2 EUE</td>
                <td>60382</td>
                <td>60382HT</td>
            </tr>
            <tr>
                <td>44.0-49.0</td>
                <td>7.511-7.625</td>
                <td>7.312</td>
                <td>-</td>
                <td>2.50</td>
                <td>2-7/8 EUE</td>
                <td>60386</td>
                <td>60386HT</td>
            </tr>
            <tr>
                <td rowSpan={2}>9-5/8</td>
                <td>32.3-43.5</td>
                <td>8.755-9.001</td>
                <td>8.500</td>
                <td>-</td>
                <td>4.00</td>
                <td>4-1/2 EUE</td>
                <td>60396S**</td>
                <td>60396HT</td>
            </tr>
            <tr>
                <td>43.5</td>
                <td>8.535-8.755</td>
                <td>8.250</td>
                <td>-</td>
                <td>4.00</td>
                <td>4-1/2 EUE</td>
                <td>60395S**</td>
                <td>60395HT</td>
            </tr>
            <tr>
                <td rowSpan={2}>10-3/4</td>
                <td>32.75-51.1</td>
                <td>9.850-10.192</td>
                <td>9.625</td>
                <td>-</td>
                <td>4.00</td>
                <td>4-1/2 EUE</td>
                <td>60301**</td>
                <td>-</td>
            </tr>
            <tr>
                <td>51.0-65.7</td>
                <td>9.560-9.850</td>
                <td>9.312</td>
                <td>-</td>
                <td>4.00</td>
                <td>4-1/2 EUE</td>
                <td>60310**</td>
                <td>-</td>
            </tr>
            <tr>
                <td rowSpan={2}>11-3/4</td>
                <td>42.0-65.0</td>
                <td>10.682-11.084</td>
                <td>10.438</td>
                <td>10.502<sup>1</sup></td>
                <td>4.00</td>
                <td>4-1/2 EUE</td>
                <td>60311**</td>
                <td>-</td>
            </tr>
            <tr>
                <td>66.7-80.5</td>
                <td>10.406-10.656</td>
                <td>10.200</td>
                <td>-</td>
                <td>4.00</td>
                <td>4-1/2 EUE</td>
                <td>60311Y</td>
                <td>-</td>
            </tr>
            <tr>
                <td>13-3/8</td>
                <td>54.5-77.0</td>
                <td>12.275-12.615</td>
                <td>12.000</td>
                <td>-</td>
                <td>4.00</td>
                <td>4-1/2 EUE</td>
                <td>60313**</td>
                <td>-</td>
            </tr>
            <tr>
                <td>16</td>
                <td>65.0-109.0</td>
                <td>14.688-15.250</td>
                <td>14.438</td>
                <td>-</td>
                <td>5.00</td>
                <td>7" LTC</td>
                <td>60316***</td>
                <td>-</td>
            </tr>
            <tr>
                <td>18</td>
                <td>87.5-117.5</td>
                <td>17.439-17.755</td>
                <td>17.000</td>
                <td>-</td>
                <td>5.00</td>
                <td>7" LTC</td>
                <td>60318</td>
                <td>-</td>
            </tr>
            <tr>
                <td>20</td>
                <td>133.0-169.0</td>
                <td>18.376-18.730</td>
                <td>18.000</td>
                <td>-</td>
                <td>5.00</td>
                <td>7" LTC</td>
                <td>60320</td>
                <td>-</td>
            </tr>
        </tbody>
        </table>
    </div>
  )
}

export default AsiXPacker