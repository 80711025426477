import React from 'react'
import {$,jQuery} from 'jquery';

function ElOnOffToolTable() {
    
  return (
    <div>  
        <table id="example" class="table table-striped table-bordered" cellspacing="0" width="100%">
        <thead>
            <tr>
                <th colSpan={2}>Tubing Size</th>
                <th colSpan={2}>Length</th>
                <th colSpan={2}>Nom. OD</th>
                <th colSpan={2}>Seal Bore</th>
            </tr>
        </thead>
 
        <tbody>
            <tr style={{backgroundColor: '#BCBEC0'}}>
                <td>in.</td>
                <td>mm</td>
               <td>in.</td>
               <td>mm</td>
               <td>in.</td>
               <td>mm</td>
               <td>in.</td>
               <td>mm</td>
            </tr>
            <tr>
                <td>1.90</td>
                <td>48.3</td>
               <td>30.75</td>
               <td>781.1</td>
               <td>2.375</td>
               <td>60.30</td>
               <td>1.500</td>
               <td>38.10</td>
            </tr>
            <tr>
                <td>2 <sup>1/16</sup></td>
                <td>55.0</td>
               <td>31.28</td>
               <td>794.5</td>
               <td>2.620</td>
               <td>66.50</td>
               <td>1.625</td>
               <td>41.28</td>
            </tr>
            <tr>
                <td>2 <sup>3/8</sup></td>
                <td>60.3</td>
               <td>33.32</td>
               <td>846.2</td>
               <td>3.090</td>
               <td>78.49</td>
               <td>1.875</td>
               <td>47.63</td>
            </tr>
            <tr>
                <td>2 <sup>7/8</sup></td>
                <td>73.0</td>
               <td>36.06</td>
               <td>915.8</td>
               <td>3.750</td>
               <td>95.25</td>
               <td>2.312</td>
               <td>58.73</td>
            </tr>
            <tr>
                <td>3 <sup>1/2</sup></td>
                <td>88.9</td>
               <td>44.80</td>
               <td>1137.9</td>
               <td>4.280</td>
               <td>108.71</td>
               <td>2.750</td>
               <td>69.85</td>
            </tr>
            <tr>
                <td>3 <sup>1/2</sup></td>
                <td>88.9</td>
               <td>44.80</td>
               <td>1137.9</td>
               <td>4.280</td>
               <td>108.71</td>
               <td>2.810</td>
               <td>71.37</td>
            </tr>
            <tr>
                <td>4 <sup>1/2</sup></td>
                <td>114.3</td>
               <td>51.00</td>
               <td>1295.4</td>
               <td>5.600</td>
               <td>141.22</td>
               <td>3.812</td>
               <td>96.77</td>
            </tr>
        </tbody>
        </table>
    </div>
  )
}

export default ElOnOffToolTable