import React from "react";
import { $, jQuery } from "jquery";

function RRSafetyJointTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c59">
            <td class="c206" colspan="1" rowspan="1">
              Size (inches)
            </td>
            <td class="c108" colspan="1" rowspan="1">
              Tool OD (inches)
            </td>
            <td class="c137" colspan="1" rowspan="1">
              Tool ID (inches)
            </td>
            <td class="c240" colspan="1" rowspan="1">
              Thread Connections Box Up / Pin Down
            </td>
            <td class="c30" colspan="1" rowspan="1">
              Part Number
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="c31">
            <td class="c206" colspan="1" rowspan="1">
              2-3/8
            </td>
            <td class="c108" colspan="1" rowspan="1">
              3.250
            </td>
            <td class="c137" colspan="1" rowspan="1">
              1.88
            </td>
            <td class="c240" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c30" colspan="1" rowspan="1">
              57020
            </td>
          </tr>
          <tr class="c31">
            <td class="c206" colspan="1" rowspan="1">
              2-7/8
            </td>
            <td class="c108" colspan="1" rowspan="1">
              4.500
            </td>
            <td class="c137" colspan="1" rowspan="1">
              2.50
            </td>
            <td class="c240" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c30" colspan="1" rowspan="1">
              57025
            </td>
          </tr>
          <tr class="c31">
            <td class="c206" colspan="1" rowspan="1">
              3-1/2
            </td>
            <td class="c108" colspan="1" rowspan="1">
              5.250
            </td>
            <td class="c137" colspan="1" rowspan="1">
              2.69
            </td>
            <td class="c240" colspan="1" rowspan="1">
              3-1/2 IF TOOL JOINT
            </td>
            <td class="c30" colspan="1" rowspan="1">
              57035-BEC
            </td>
          </tr>
          <tr class="c31">
            <td class="c206" colspan="1" rowspan="1">
              4-1/2
            </td>
            <td class="c108" colspan="1" rowspan="1">
              6.250
            </td>
            <td class="c137" colspan="1" rowspan="1">
              3.75
            </td>
            <td class="c240" colspan="1" rowspan="1">
              4-1/2 IF TOOL JOINT
            </td>
            <td class="c30" colspan="1" rowspan="1">
              57045-BEE
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default RRSafetyJointTable;
