import React from "react";
import { $, jQuery } from "jquery";

function HydroGripIITable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
        <tr>
          <th colspan="2">CASING SIZE</th>
          <th colspan="2">CASING WEIGHT</th>
          <th colspan="2">THREAD CONNECTION</th>
          <th colspan="2">MAX OD</th>
          <th colspan="2">MIN ID</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>in.</td>
          <td>mm</td>
          <td>lbs/ft</td>
          <td>kg/m</td>
          <td>in.</td>
          <td>mm</td>
          <td>in.</td>
          <td>mm</td>
          <td>in.</td>
          <td>mm</td>
        </tr>
        <tr>
          <td>4<sup>1/2</sup></td>
          <td>114.3</td>
          <td>9.5-13.5</td>
          <td>14.1-20.1</td>
          <td>2 3/8 EUE</td>
          <td>60.3</td>
          <td>3.750</td>
          <td>95.25</td>
          <td>1.938</td>
          <td>49.23</td>
        </tr>
        <tr>
          <td>4<sup>1/2</sup></td>
          <td>114.3</td>
          <td>13.5-15.1</td>
          <td>20.1-22.5</td>
          <td>2 3/8 EUE</td>
          <td>60.3</td>
          <td>3.656</td>
          <td>92.86</td>
          <td>1.938</td>
          <td>49.23</td>
        </tr>
        <tr>
          <td>5<sup>1/2</sup></td>
          <td>139.7</td>
          <td>20.0-23.0</td>
          <td>29.8-34.2</td>
          <td>2 3/8 EUE</td>
          <td>60.3</td>
          <td>4.500</td>
          <td>114.30</td>
          <td>2.000</td>
          <td>50.80</td>
        </tr>
        <tr>
          <td>5<sup>1/2</sup></td>
          <td>139.7</td>
          <td>14.0-20.0</td>
          <td>20.8-29.8</td>
          <td>2 3/8 EUE</td>
          <td>60.3</td>
          <td>4.625</td>
          <td>117.48</td>
          <td>2.000</td>
          <td>50.80</td>
        </tr>
        <tr>
          <td>7</td>
          <td>177.8</td>
          <td>26.0-35.0</td>
          <td>38.7-51.1</td>
          <td>2 7/8 EUE</td>
          <td>73.0</td>
          <td>5.875</td>
          <td>149.23</td>
          <td>2.500</td>
          <td>63.50</td>
        </tr>
        <tr>
          <td>7</td>
          <td>177.8</td>
          <td>17.0-26.0</td>
          <td>25.4-38.7</td>
          <td>2 7/8 EUE</td>
          <td>73.0</td>
          <td>6.000</td>
          <td>152.40</td>
          <td>2.500</td>
          <td>63.50</td>
        </tr>
        </tbody>
      </table>
    </div>
  );
}

export default HydroGripIITable;
