import React from "react";
import { $, jQuery } from "jquery";

function HydraulicTubingAnchor() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c165">
            <td class="c484" colspan="2" rowspan="1">
              Casing
            </td>
            <td class="c441" colspan="1" rowspan="2">
              Recommended Hole Size (inches)
            </td>
            <td class="c170" colspan="1" rowspan="2">
              Tool OD (inches)
            </td>
            <td class="c110" colspan="1" rowspan="2">
              Tool ID &nbsp; (inches)
            </td>
            <td class="c242" colspan="1" rowspan="2">
              Thread Connections Box Up / Pin Down
            </td>
            <td class="c93" colspan="1" rowspan="2">
              Part &nbsp; Number
            </td>
          </tr>
          <tr class="c150">
            <td class="c94" colspan="1" rowspan="1">
              Size (inches)
            </td>
            <td class="c37" colspan="1" rowspan="1">
              Weight (lbs/ft)
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="c59">
            <td class="c94" colspan="1" rowspan="1">
              4-1/2
            </td>
            <td class="c37" colspan="1" rowspan="1">
              9.5 - 13.5
            </td>
            <td class="c441" colspan="1" rowspan="1">
              3.920 - 4.090
            </td>
            <td class="c170" colspan="1" rowspan="1">
              3.750
            </td>
            <td class="c110" colspan="1" rowspan="1">
              1.94
            </td>
            <td class="c242" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c93" colspan="1" rowspan="1">
              32445
            </td>
          </tr>
          <tr class="c59">
            <td class="c94" colspan="1" rowspan="2">
              5
            </td>
            <td class="c37" colspan="1" rowspan="1">
              11.5 - 15.0
            </td>
            <td class="c441" colspan="1" rowspan="1">
              4.408 - 4.560
            </td>
            <td class="c170" colspan="1" rowspan="1">
              4.125
            </td>
            <td class="c110" colspan="1" rowspan="1">
              1.94
            </td>
            <td class="c242" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c93" colspan="1" rowspan="1">
              32452
            </td>
          </tr>
          <tr class="c59">
            <td class="c37" colspan="1" rowspan="1">
              18.0 - 21.0
            </td>
            <td class="c441" colspan="1" rowspan="1">
              4.154 - 4.276
            </td>
            <td class="c170" colspan="1" rowspan="1">
              4.000
            </td>
            <td class="c110" colspan="1" rowspan="1">
              1.94
            </td>
            <td class="c242" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c93" colspan="1" rowspan="1">
              32450
            </td>
          </tr>
          <tr class="c59">
            <td class="c94" colspan="1" rowspan="1">
              5-1/2
            </td>
            <td class="c37" colspan="1" rowspan="1">
              13.0 - 23.0
            </td>
            <td class="c441" colspan="1" rowspan="1">
              4.670 - 5.044
            </td>
            <td class="c170" colspan="1" rowspan="1">
              4.500
            </td>
            <td class="c110" colspan="1" rowspan="1">
              2.44
            </td>
            <td class="c242" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c93" colspan="1" rowspan="1">
              32455
            </td>
          </tr>
          <tr class="c59">
            <td class="c94" colspan="1" rowspan="2">
              7
            </td>
            <td class="c37" colspan="1" rowspan="2">
              17.0 - 32.0
            </td>
            <td class="c441" colspan="1" rowspan="2">
              6.094 - 6.538
            </td>
            <td class="c170" colspan="1" rowspan="2">
              5.750
            </td>
            <td class="c110" colspan="1" rowspan="1">
              3.00
            </td>
            <td class="c242" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c93" colspan="1" rowspan="1">
              32473
            </td>
          </tr>
          <tr class="c59">
            <td class="c110" colspan="1" rowspan="1">
              3.88
            </td>
            <td class="c242" colspan="1" rowspan="1">
              4-1/2 EUE
            </td>
            <td class="c93" colspan="1" rowspan="1">
              32474
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default HydraulicTubingAnchor;
