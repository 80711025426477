import React from 'react'
import {$,jQuery} from 'jquery';

function ElOnOffToolTable() {
    
  return (
    <div>  
        <table id="example" class="table table-striped table-bordered" cellspacing="0" width="100%">
        <thead>
            <tr>
                <th colSpan={2}>Casing Size</th>
                <th colSpan={2}>Max OD</th>
                <th colSpan={2}>Min ID</th>
            </tr>
        </thead>
 
        <tbody>
            <tr style={{backgroundColor: '#BCBEC0'}}>
                <td>in.</td>
                <td>mm</td>
               <td>in.</td>
               <td>mm</td>
               <td>in.</td>
               <td>mm</td>
            </tr>
            <tr>
                <td>1 <sup>1/2</sup></td>
                <td>114.3</td>
               <td>3.750</td>
               <td>95.25</td>
               <td>2 <sup>3/8</sup></td>
               <td>60.3</td>
            </tr>
            <tr>
                <td>5</td>
                <td>127.0</td>
               <td>3.750</td>
               <td>95.25</td>
               <td>2 <sup>3/8</sup></td>
               <td>60.3</td>
            </tr>
            <tr>
                <td>5 <sup>1/2</sup></td>
                <td>139.7</td>
               <td>4.500</td>
               <td>114.30</td>
               <td>2 <sup>3/8</sup></td>
               <td>60.3</td>
            </tr>
            <tr>
                <td>5 <sup>1/2</sup></td>
                <td>139.7</td>
               <td>4.500</td>
               <td>114.30</td>
               <td>2 <sup>3/8</sup></td>
               <td>73.0</td>
            </tr>
            <tr>
                <td>7</td>
                <td>177.8</td>
               <td>4.500</td>
               <td>114.30</td>
               <td>2 <sup>3/8</sup></td>
               <td>60.3</td>
            </tr>
            <tr>
                <td>7</td>
                <td>177.8</td>
               <td>4.500</td>
               <td>114.30</td>
               <td>2 <sup>7/8</sup></td>
               <td>73.0</td>
            </tr>
            <tr>
                <td>7</td>
                <td>177.8</td>
               <td>5.500</td>
               <td>139.70</td>
               <td>3 <sup>1/2</sup></td>
               <td>88.9</td>
            </tr>
           
        </tbody>
        </table>
    </div>
  )
}

export default ElOnOffToolTable