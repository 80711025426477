import React from 'react'
import {$,jQuery} from 'jquery';

function EcrCementRetainerTable() {
    
  return (
    <div>  
        <table id="example" class="table table-striped table-bordered" cellspacing="0" width="100%">
        <thead>
            <tr>
                <th colSpan={2}>Thread Connection</th>
                <th colSpan={2}>Max OD</th>
            </tr>
        </thead>
        <tbody>
            <tr style={{backgroundColor: '#BCBEC0'}}>
                <td>in.</td>
                <td>mm</td>
                <td>in.</td>
                <td>mm</td>
            </tr>
            <tr>
                <td>2 <sup>3/8</sup>EUE</td>
                <td>60.3</td>
                <td>0.785</td>
                <td>19.93</td>
            </tr>
            <tr>
                <td>2 <sup>7/8</sup>EUE</td>
                <td>73.0</td>
                <td>0.785</td>
                <td>19.93</td>
            </tr>
            <tr>
                <td>2 <sup>7/8</sup>EUE</td>
                <td>73.0</td>
                <td>1.250</td>
                <td>31.75</td>
            </tr>
        </tbody>
        </table>
    </div>
  )
}

export default EcrCementRetainerTable