import axios from 'axios';

import config from '../config';

export async function getAllProductService() {
    return await axios
        .get(`${config.base_url}/dept/getAllProducts`)
        .then((result) => result.data)
        .catch((error) => console.log(`getAllProductService - ${error}`));
}


export async function getAccessoryTitlesService() {
    return await axios
        .get(`${config.base_url}/dept/getAccessoryTitles`)
        .then((result) => result.data)
        .catch((error) => console.log(`getAccessoryTitles - ${error}`));
}


export async function getPlugRetainersTitlesService() {
    return await axios
        .get(`${config.base_url}/dept/getPlugRetainersTitles`)
        .then((result) => result.data)
        .catch((error) => console.log(`getPlugRetainersTitles - ${error}`));
}

export async function getFlowControlTitlesService() {
    return await axios
        .get(`${config.base_url}/dept/getFlowControlTitles`)
        .then((result) => result.data)
        .catch((error) => console.log(`getFlowControlTitles - ${error}`));
}

export async function getTubingAnchorTitlesService() {
    return await axios
        .get(`${config.base_url}/dept/getTubingAnchorTitles`)
        .then((result) => result.data)
        .catch((error) => console.log(`getTubingAnchorTitles - ${error}`));
}

export async function getMechanicalPackerTitlesService() {
    return await axios
        .get(`${config.base_url}/dept/getMechanicalPackerTitles`)
        .then((result) => result.data)
        .catch((error) => console.log(`getMechanicalPackerTitles - ${error}`));
}

export async function getHydraulicPackerTitlesService() {
    return await axios
        .get(`${config.base_url}/dept/getHydraulicPackerTitles`)
        .then((result) => result.data)
        .catch((error) => console.log(`getHydraulicPackerTitles - ${error}`));
}

export async function getPermanentPackerTitlesService() {
    return await axios
        .get(`${config.base_url}/dept/getPermanentPackerTitles`)
        .then((result) => result.data)
        .catch((error) => console.log(`getPermanentPackerTitles - ${error}`));
}

export async function getRetrievablePackerTitlesService() {
    return await axios
        .get(`${config.base_url}/dept/getRetrievablePackerTitles`)
        .then((result) => result.data)
        .catch((error) => console.log(`getRetrievablePackerTitles - ${error}`));
}

export async function getServiceToolTitlesService() {
    return await axios
        .get(`${config.base_url}/dept/getServiceToolTitles`)
        .then((result) => result.data)
        .catch((error) => console.log(`getServiceToolTitles - ${error}`));
}

export async function getSealBorePackerTitlesService() {
    return await axios
        .get(`${config.base_url}/dept/getSealBoreTitles`)
        .then((result) => result.data)
        .catch((error) => console.log(`getSealBoreTitles - ${error}`));
}

export async function getBurstDiscTitlesService() {
    return await axios
        .get(`${config.base_url}/dept/getBurstDiscTitles`)
        .then((result) => result.data)
        .catch((error) => console.log(`getBurstDiscTitles - ${error}`));
}