import React from "react";
import { $, jQuery } from "jquery";

function CompressionUnloaderTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c38">
            <td class="c112" colspan="1" rowspan="1">
              Casing
            </td>
            <td class="c108" colspan="1" rowspan="2">
              Tool OD (inches)
            </td>
            <td class="c94" colspan="1" rowspan="2">
              Tool ID (inches)
            </td>
            <td class="c254" colspan="1" rowspan="2">
              Thread Connections Box Up / Pin Down
            </td>
            <td class="c134" colspan="1" rowspan="2">
              Part Number
            </td>
          </tr>
          <tr class="c59">
            <td class="c112" colspan="1" rowspan="1">
              Size (inches)
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="c35">
            <td class="c112" colspan="1" rowspan="1">
              4-1/2
            </td>
            <td class="c108" colspan="1" rowspan="1">
              3.500
            </td>
            <td class="c94" colspan="1" rowspan="1">
              2.00
            </td>
            <td class="c254" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c134" colspan="1" rowspan="1">
              52845
            </td>
          </tr>
          <tr class="c35">
            <td class="c112" colspan="1" rowspan="1">
              7
            </td>
            <td class="c108" colspan="1" rowspan="1">
              5.500
            </td>
            <td class="c94" colspan="1" rowspan="1">
              2.867
            </td>
            <td class="c254" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c134" colspan="1" rowspan="1">
              52873
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default CompressionUnloaderTable;
