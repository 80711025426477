import React from "react";
import ContactForm from "../../components/ContactForm/ContactForm";
import AccessoriesBtn from "../../components/ColumnBtns/AccessoriesBtn";
import AccessoriesHeader from "../../components/Headers/AccessoriesHeader";
import { useEffect, useState } from "react";
import { getAccessoryTitlesService } from "../../services/dept";
import { Helmet } from "react-helmet";
import GeneralBtn from '../../components/ColumnBtns/GeneralBtn'
import ElOnOffTool from "../../components/Products/Accessories/ElOnOffTool";
import ErRotationalShearSafetyJoint from "../../components/Products/Accessories/ErRotationalShearSafetyJoint";
import EqTorqueShearSafetyJoint from "../../components/Products/Accessories/EqTorqueShearSafetyJoint";
import EdvAnnularDumpValve from "../../components/Products/Accessories/EdvAnnularDumpValve";
import BlastJoints from "../../components/Products/Accessories/BlastJoints";
import EsbSingleBurstDisk from "../../components/Products/Accessories/EsbSingleBurstDisk";
import EsbDualBurstDisk from "../../components/Products/Accessories/EsbDualBurstDisk";
import ExjExpansion from "../../components/Products/Accessories/ExjExpansion";
import WirelineAdapterKit from "../../components/Products/Accessories/WirelineAdapterKit";
import DtOnOff from '../../components/Products/Accessories/DtOnOff'
import ProfileNipple from '../../components/Products/Accessories/ProfileNipple'
import SafetyJoint from '../../components/Products/Accessories/SafetyJoint'
import BitReleaseJoint from '../../components/Products/Accessories/BitReleaseJoint'
import DownholeTubingCasingSwivel from '../../components/Products/Accessories/DownholeTubingCasingSwivel'
import CrossFlowAssembly from '../../components/Products/Accessories/CrossFlowAssembly'
import ExpansionJoint from '../../components/Products/Accessories/ExpansionJoint'
import PumpOutCementingSleeve from '../../components/Products/Accessories/PumpOutCementingSleeve'
import TypeEValve from '../../components/Products/Accessories/TypeEValve'
import RunningTool from '../../components/Products/Accessories/RunningTool'
import AnnulusOperatedTubingDrain from '../../components/Products/Accessories/AnnulusOperatedTubingDrain'
import PumpOutPlug from '../../components/Products/Accessories/PumpOutPlug'
import MechanicalBallValve from '../../components/Products/MechanicalPackers/MechanicalBallValve'


import { useParams } from "react-router-dom";
import { pseudoencodeURI } from "./converter";

const ProductController = [
  // {
  //   title: "EL ON/OFF TOOL",
  //   component: <ElOnOffTool title="EL ON/OFF TOOL" />,
  // },
  {
    title: "ROTATIONAL SHEAR SAFETY JOINT",
    component: (
      <ErRotationalShearSafetyJoint title="ROTATIONAL SHEAR SAFETY JOINT" />
    ),
  },
  {
    title: "TORQUE-THRU SHEAR SAFETY JOINT",
    component: (
      <EqTorqueShearSafetyJoint title="TORQUE-THRU SHEAR SAFETY JOINT" />
    ),
  },
  {
    title: "EDV ANNULAR DUMP VALVE",
    component: <EdvAnnularDumpValve title="EDV ANNULAR DUMP VALVE" />,
  },
  { title: "BLAST JOINTS", component: <BlastJoints title="BLAST JOINTS" /> },
  {
    title: "ESB SINGLE BURST DISK",
    component: <EsbSingleBurstDisk title="ESB SINGLE BURST DISK" />,
  },
  {
    title: "ESB DUAL BURST DISK",
    component: <EsbDualBurstDisk title="ESB DUAL BURST DISK" />,
  },
  { title: "EXJ EXPANSION", component: <ExjExpansion title="EXJ EXPANSION" /> },
  {
    title: "WIRELINE ADAPTER KIT",
    component: <WirelineAdapterKit title="WIRELINE ADAPTER KIT" />,
  },
  {
    title: "DT-2 ON/OFF TOOL",
    component: <DtOnOff title="DT-2 ON/OFF TOOL" />,
  },
  {
    title: "PROFILE NIPPLE",
    component: <ProfileNipple title="PROFILE NIPPLE" />,
  },
  {
    title: "BIT RELEASE JOINT",
    component: <BitReleaseJoint title="BIT RELEASE JOINT" />,
  },
  {
    title: "SAFETY JOINT",
    component: <SafetyJoint title="SAFETY JOINT" />,
  },
  {
    title: "DOWNHOLE TUBING/CASING SWIVEL",
    component: <DownholeTubingCasingSwivel title="DOWNHOLE TUBING/CASING SWIVEL" />,
  },
  {
    title: "CROSS FLOW ASSEMBLY",
    component: <CrossFlowAssembly title="CROSS FLOW ASSEMBLY" />,
  },
  {
    title: "EXPANSION JOINT",
    component: <ExpansionJoint title="EXPANSION JOINT" />,
  },
  {
    title: "PUMP-OUT CEMENTING SLEEVE",
    component: <PumpOutCementingSleeve title="PUMP-OUT CEMENTING SLEEVE" />,
  },
  {
    title: "TYPE E VALVE",
    component: <TypeEValve title="TYPE E VALVE" />,
  },
  {
    title: "TYPE E VALVE RUNNING TOOL",
    component: <RunningTool title="TYPE E VALVE RUNNING TOOL" />,
  },
  {
    title: "ANNULUS OPERATED TUBING DRAIN",
    component: <AnnulusOperatedTubingDrain title="ANNULUS OPERATED TUBING DRAIN" />,
  },
  {
    title: "PUMP OUT PLUGS",
    component: <PumpOutPlug title="PUMP OUT PLUGS" />,
  },
  {
    title: "MECHANICAL BALL VALVE",
    component: <MechanicalBallValve title="MECHANICAL BALL VALVE" />,
  },
];



function Accessories() {
  const [accessories, setAccessories] = useState([]);
  const [currentProduct, setCurrentProduct] = useState("ROTATIONAL SHEAR SAFETY JOINT");

  const params = useParams(); 


  useEffect(() => {
    getAccessoryTitlesService().then((result) => {
      setAccessories(result);
      setCurrentProduct(params.subLink)
      // console.log(params)
      window.scrollTo(0, 0);

    });
  }, [params]);


    return (
      <div>
        <Helmet>
          ‍
          <title>
            Accessories | Maverick Downhole Technologies | Edmonton, AB
          </title>
          ‍
        </Helmet>

        <AccessoriesHeader />
        <div className="product-section">
          <div className="product-btn-container">
            {accessories.map((accessory) => {
              return (
                <div>
             
                <GeneralBtn
                onClick={(title) => setCurrentProduct(title)}
                title={accessory.title}
                selected={
                  pseudoencodeURI(accessory.title) === currentProduct
                }
                baseURL={"/products/accessories"}
              />
                </div>
                // <AccessoriesBtn
                //   onClick={(title) => {

                //     // setCurrentProduct(title)}
                //   }}
                //   title={accessory.title}
                //   selected={accessory.title === currentProduct}
                // />
              );
            })}
          </div>
          <div>

            {/* {!id
              ? null
              : ProductController.find(
                  (product) => product.title === id                
                  )?.component} */}


            {!currentProduct
            ? null
            : ProductController.find(
                (product) => pseudoencodeURI(product.title) === currentProduct
              )?.component}

            {/* <EqTorqueShearSafetyJoint title="EQ TORQUE-THRU SHEAR SAFETY JOINT"/>
          <EdvAnnularDumpValve title="EDV ANNULAR DUMP VALVE"/>
          <BlastJoints title="BLAST JOINTS"/>
          <EsbSingleBurstDisk title="ESB SINGLE BURST DISK"/>
          <EsbDualBurstDisk title="ESB DUAL BURST DISK"/>
          <ExjExpansion title="EXJ EXPANSION"/>
          <WirelineAdapterKit title="WIRELINE ADAPTER KIT"/>  */}
          </div>
        </div>
        <ContactForm />
      </div>
    );
  
}

export default Accessories;
