import React from "react";
import { $, jQuery } from "jquery";

function WirelineAdapterKitTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c667">
            <td class="c126" colspan="1" rowspan="1">
              Size (inches)
            </td>
            <td class="c184" colspan="1" rowspan="1">
              Tool OD (inches)
            </td>
            <td class="c458" colspan="1" rowspan="1">
              Wireline &nbsp; Setting Tool Box Up
            </td>
            <td class="c259" colspan="1" rowspan="1">
              Part Number
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="c59">
            <td class="c126" colspan="1" rowspan="1">
              3-1/2
            </td>
            <td class="c184" colspan="1" rowspan="1">
              2.750
            </td>
            <td class="c458" colspan="1" rowspan="1">
              BAKER E-4, #05
            </td>
            <td class="c259" colspan="1" rowspan="1">
              72335
            </td>
          </tr>
          <tr class="c59">
            <td class="c126" colspan="1" rowspan="3">
              4-1/2
            </td>
            <td class="c184" colspan="1" rowspan="1">
              3.750
            </td>
            <td class="c458" colspan="1" rowspan="1">
              BAKER E-4, #10
            </td>
            <td class="c259" colspan="1" rowspan="1">
              72345
            </td>
          </tr>
          <tr class="c59">
            <td class="c184" colspan="1" rowspan="1">
              3.620
            </td>
            <td class="c458" colspan="1" rowspan="1">
              BAKER E-4, #10
            </td>
            <td class="c259" colspan="1" rowspan="1">
              72341
            </td>
          </tr>
          <tr class="c59">
            <td class="c184" colspan="1" rowspan="1">
              3.650
            </td>
            <td class="c458" colspan="1" rowspan="1">
              BAKER E-4, #10
            </td>
            <td class="c259" colspan="1" rowspan="1">
              72344
            </td>
          </tr>
          <tr class="c59">
            <td class="c126" colspan="1" rowspan="1">
              5-1/2
            </td>
            <td class="c184" colspan="1" rowspan="1">
              4.500
            </td>
            <td class="c458" colspan="1" rowspan="1">
              BAKER E-4, #20
            </td>
            <td class="c259" colspan="1" rowspan="1">
              72355WLAK
            </td>
          </tr>
          <tr class="c59">
            <td class="c126" colspan="1" rowspan="1">
              6-5/8
            </td>
            <td class="c184" colspan="1" rowspan="1">
              5.440
            </td>
            <td class="c458" colspan="1" rowspan="1">
              BAKER E-4, #20
            </td>
            <td class="c259" colspan="1" rowspan="1">
              72365
            </td>
          </tr>
          <tr class="c59">
            <td class="c126" colspan="1" rowspan="1">
              7
            </td>
            <td class="c184" colspan="1" rowspan="1">
              5.875
            </td>
            <td class="c458" colspan="1" rowspan="1">
              BAKER E-4, #20
            </td>
            <td class="c259" colspan="1" rowspan="1">
              72370
            </td>
          </tr>
          <tr class="c59">
            <td class="c126" colspan="1" rowspan="1">
              8-5/8 - 14
            </td>
            <td class="c184" colspan="1" rowspan="1">
              6.000
            </td>
            <td class="c458" colspan="1" rowspan="1">
              BAKER E-4, #20
            </td>
            <td class="c259" colspan="1" rowspan="1">
              72385
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default WirelineAdapterKitTable;
