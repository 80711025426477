import React from 'react'
import OtherHeader from '../../components/Headers/OtherHeader'
import ContactForm from '../../components/ContactForm/ContactForm'
import AlphaPage from '../../components/Misc/AlphaPage'

function Alpha() {
  return (
    <div>
        <OtherHeader title="Wholesale Suppliers" />
        <AlphaPage />
        <ContactForm />
    </div>
  )
}

export default Alpha