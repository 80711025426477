import React from "react";
import MaverickLogoWhite from "../../assets/logos/maverick-logo-white.png";
import { Routes, Route, Link } from "react-router-dom";
import GlobalSearch from "./GlobalSearch";
import config from "../../config";
import ProductData from "../../Data.json";
import productLinks from './ProductLinks';

function MobileMenu() {
  return (
    <div>
      <div
        class="offcanvas offcanvas-start"
        tabindex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="mobile-menu">
          <img
            src={MaverickLogoWhite}
            alt="Maverick Logo"
            className="mobile-logo"
          />

          <div className="mobile-links">
            <Link to="about">
              <p className="mobile-link">About</p>
            </Link>
            <p
              className="mobile-link"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="false"
              aria-controls="flush-collapseOne"
            >
              Our Products &nbsp;{" "}
              <i
                class="fa-solid fa-caret-down"
                style={{ color: "#0099cd" }}
              ></i>
            </p>
            <div
              id="flush-collapseOne"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <Link to="/products/mechanical-packers/TYPE%20DL-IB%20TANDEM%20TENSION%">
                <div>
                  <p className="mobile-dropdown">
                    <i
                      class="fa-solid fa-arrow-right"
                      aria-controls="offcanvasExample"
                      data-bs-toggle="offcanvas"
                    ></i>
                    &nbsp;Mechanical Packers
                  </p>
                </div>
              </Link>
              <Link to="/products/hydraulic-packers/DLH%20PACKER">
                <div>
                  <p className="mobile-dropdown">
                    <i class="fa-solid fa-arrow-right"></i>&nbsp;Hydraulic
                    Packers
                  </p>
                </div>
              </Link>
              <Link to="/products/permanent-packers/PERMAPAK%20ACCESSORIES">
                <div>
                  <p className="mobile-dropdown">
                    <i class="fa-solid fa-arrow-right"></i>&nbsp;Permanent
                    Packers
                  </p>
                </div>
              </Link>
              {/* <Link to="/products/retrievable-packers">
                <div>
                  <p className="mobile-dropdown">
                    <i class="fa-solid fa-arrow-right"></i>&nbsp;Retrievable
                    Packers
                  </p>
                </div>
              </Link> */}
              <Link to="/products/plug-retainers/HM-1B%20BRIDGE%20PLUG">
                <div>
                  <p className="mobile-dropdown">
                    <i class="fa-solid fa-arrow-right"></i>&nbsp;Plug/Cement
                    Retainers
                  </p>
                </div>
              </Link>
              {/* <Link to="">
                <div>
                  <p className="mobile-dropdown">
                    <i class="fa-solid fa-arrow-right"></i>&nbsp;Seal Bore
                    Packers
                  </p>
                </div>
              </Link> */}
              <Link to="/products/tubing-anchors/EC-1%20DRAG%20BLOCK%20TUBING%20ANCHOR-CATCHER">
                <div>
                  <p className="mobile-dropdown">
                    <i class="fa-solid fa-arrow-right"></i>&nbsp;Tubing Anchors
                  </p>
                </div>
              </Link>
              <Link to="/products/flow-control/X-XN%20LANDING%20NIPPLES">
                <div>
                  <p className="mobile-dropdown">
                    <i class="fa-solid fa-arrow-right"></i>&nbsp;Flow Control
                  </p>
                </div>
              </Link>
              <Link to="/products/service-tools/TUBING%20SWIVEL">
                <div>
                  <p className="mobile-dropdown">
                    <i class="fa-solid fa-arrow-right"></i>&nbsp;Service Tools
                  </p>
                </div>
              </Link>
              <Link to="/products/accessories/ROTATIONAL%20SHEAR%20SAFETY%20JOINT">
                <div>
                  <p className="mobile-dropdown">
                    <i class="fa-solid fa-arrow-right"></i>&nbsp;Accessories
                  </p>
                </div>
              </Link>
              <Link to={productLinks.burstDiscs}>
                <div>
                  <p className="mobile-dropdown">
                    <i class="fa-solid fa-arrow-right"></i>&nbsp;Burst Discs
                  </p>
                </div>
              </Link>
            </div>
            <Link to="certifications">
            <p className="mobile-link">Certifications</p>
            </Link>
            
            <Link to="engineering">
              <p className="mobile-link">Engineering</p>
            </Link>
            {/* <Link to="">
              <p className="mobile-link">Distributors</p>
            </Link> */}
            <p className="mobile-link"></p>
            <GlobalSearch data={ProductData} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileMenu;
