import React from "react";
import { $, jQuery } from "jquery";

function HydraulicTable() {
  return (
    <div>
      <p className="smaller-head">Single Bore</p>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr>
            <td colSpan={2}>Casing</td>
            <td rowSpan={2}>Recommended Hole Size (inches)</td>
            <td rowSpan={2}>Tool OD (Inches)</td>
            <td rowSpan={2}>Packer Bore (Inches)</td>
            <td rowSpan={2}>Min ID Thru Seals (Inches)</td>
            <td rowSpan={2}>Part Number</td>
          </tr>
          <tr>
            <td>Size (Inches)</td>
            <td>Weight (lbs/ft)</td>
          </tr>
        </thead>
        <tbody>
          <tr class="c38">
            <td class="c7" colspan="1" rowspan="2">
              <p class="c0">
                <span class="c13">7</span>
              </p>
            </td>
            <td class="c46" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">23.0 - 32.0 </span>
              </p>
            </td>
            <td class="c75" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">6.094 - 6.366 </span>
              </p>
            </td>
            <td class="c6" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">5.875 </span>
              </p>
            </td>
            <td class="c20" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">4.000 </span>
              </p>
            </td>
            <td class="c6" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">3.000 </span>
              </p>
            </td>
            <td class="c27" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">68371</span>
              </p>
            </td>
          </tr>
          <tr class="c38">
            <td class="c46" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">32.0 - 38.0 </span>
              </p>
            </td>
            <td class="c75" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">5.920 - 6.094 </span>
              </p>
            </td>
            <td class="c6" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">5.813 </span>
              </p>
            </td>
            <td class="c20" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">4.000 </span>
              </p>
            </td>
            <td class="c6" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">3.000 </span>
              </p>
            </td>
            <td class="c27" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">68372</span>
              </p>
            </td>
          </tr>
          <tr class="c38">
            <td class="c7" colspan="1" rowspan="2">
              <p class="c0">
                <span class="c13">9-5/8 </span>
              </p>
            </td>
            <td class="c46" colspan="1" rowspan="2">
              <p class="c0">
                <span class="c13">36.0 - 53.5 </span>
              </p>
            </td>
            <td class="c75" colspan="1" rowspan="2">
              <p class="c0">
                <span class="c13">8.535 - 8.921 </span>
              </p>
            </td>
            <td class="c6" colspan="1" rowspan="2">
              <p class="c0">
                <span class="c13">8.125</span>
              </p>
            </td>
            <td class="c20" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">4.750 </span>
              </p>
            </td>
            <td class="c6" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">3.500 </span>
              </p>
            </td>
            <td class="c27" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">68393</span>
              </p>
            </td>
          </tr>
          <tr class="c38">
            <td class="c20" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">5.000 </span>
              </p>
            </td>
            <td class="c6" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">4.750 </span>
              </p>
            </td>
            <td class="c27" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">68395</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>

      <p className="smaller-head">Dual Bore</p>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c48">
            <td class="c84" colspan="2" rowspan="1">
              <p class="c0">
                <span class="c15">Casing </span>
              </p>
            </td>
            <td class="c29" colspan="1" rowspan="2">
              <p class="c104">
                <span class="c15">Recommended Hole Size </span>
              </p>
              <p class="c0">
                <span class="c15">(inches)</span>
              </p>
            </td>
            <td class="c33" colspan="1" rowspan="2">
              <p class="c0">
                <span class="c15">Tool OD </span>
              </p>
              <p class="c0">
                <span class="c15">(inches)</span>
              </p>
            </td>
            <td class="c101" colspan="2" rowspan="1">
              <p class="c0">
                <span class="c15">Seal Bore </span>
              </p>
            </td>
            <td class="c39" colspan="1" rowspan="2">
              <p class="c0">
                <span class="c15">Min ID </span>
              </p>
              <p class="c0">
                <span class="c15">Thru Seals </span>
              </p>
              <p class="c0">
                <span class="c15">(inches)</span>
              </p>
            </td>
            <td class="c8" colspan="1" rowspan="2">
              <p class="c49">
                <span class="c15">Part &nbsp;</span>
              </p>
              <p class="c0">
                <span class="c15">Number</span>
              </p>
            </td>
          </tr>
          <tr class="c91">
            <td class="c23" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c40">Size </span>
                <span class="c15">(inches) </span>
              </p>
            </td>
            <td class="c55" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c15">Weight </span>
              </p>
              <p class="c0">
                <span class="c15">(lbs/ft) </span>
              </p>
            </td>
            <td class="c22" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c15">Upper </span>
              </p>
              <p class="c0">
                <span class="c15">(inches)</span>
              </p>
            </td>
            <td class="c42" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c15">Lower </span>
              </p>
              <p class="c0">
                <span class="c15">(inches)</span>
              </p>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="c38">
            <td class="c23" colspan="1" rowspan="2">
              <p class="c0">
                <span class="c13">7</span>
              </p>
            </td>
            <td class="c55" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">17.0 - 23.0 </span>
              </p>
            </td>
            <td class="c29" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">6.366 - 6.538 </span>
              </p>
            </td>
            <td class="c33" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">6.000 </span>
              </p>
            </td>
            <td class="c22" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">5.250 </span>
              </p>
            </td>
            <td class="c42" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">4.000 </span>
              </p>
            </td>
            <td class="c39" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">4.000 </span>
              </p>
            </td>
            <td class="c8" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">68170</span>
              </p>
            </td>
          </tr>
          <tr class="c38">
            <td class="c55" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">23.0 - 32.0 </span>
              </p>
            </td>
            <td class="c29" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">6.094 - 6.366 </span>
              </p>
            </td>
            <td class="c33" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">5.875 </span>
              </p>
            </td>
            <td class="c22" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">5.250 </span>
              </p>
            </td>
            <td class="c42" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">4.000 </span>
              </p>
            </td>
            <td class="c39" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">4.000 </span>
              </p>
            </td>
            <td class="c8" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">68171</span>
              </p>
            </td>
          </tr>
          <tr class="c38">
            <td class="c23" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">9-5/8 </span>
              </p>
            </td>
            <td class="c55" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">36.0 - 53.5 </span>
              </p>
            </td>
            <td class="c29" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">8.535 - 8.921 </span>
              </p>
            </td>
            <td class="c33" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">8.250 </span>
              </p>
            </td>
            <td class="c22" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">7.500 </span>
              </p>
            </td>
            <td class="c42" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">6.000 </span>
              </p>
            </td>
            <td class="c39" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">6.000 </span>
              </p>
            </td>
            <td class="c8" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">68195</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default HydraulicTable;
