import React from 'react'
import {$,jQuery} from 'jquery';

function ElOnOffToolTable() {
    
  return (
    <div>  
        <table id="example" class="table table-striped table-bordered" cellspacing="0" width="100%">
        <thead>
            <tr>
                <th>Thread Connection</th>
                <th colSpan={2}>Tool OD</th>
                <th colSpan={2}>Tool ID</th>
                <th colSpan={2}>Lower Disk Rating</th>
                <th colSpan={2}>Upper Disk Rating</th>
            </tr>
        </thead>
 
        <tbody>
            <tr style={{backgroundColor: '#BCBEC0'}}>
                <td>in.</td>
               <td>in.</td>
               <td>mm</td>
               <td>in.</td>
               <td>mm</td>
               <td>psi</td>
               <td>mPa</td>
               <td>psi</td>
               <td>mPa</td>
            </tr>
            <tr>
                <td>2 <sup>3/8</sup>EUE</td>
               <td>3.060</td>
               <td>77.72</td>
               <td>1.995</td>
               <td>50.67</td>
               <td>10,000</td>
               <td>70</td>
               <td>10,000</td>
               <td>70</td>
            </tr>
            <tr>
                <td>2 <sup>7/8</sup>EUE</td>
               <td>3.670</td>
               <td>93.22</td>
               <td>2.441</td>
               <td>62.00</td>
               <td>10,000</td>
               <td>70</td>
               <td>10,000</td>
               <td>70</td>
            </tr>
            <tr>
                <td>3 <sup>1/2</sup>EUE</td>
               <td>4.500</td>
               <td>114.30</td>
               <td>2.992</td>
               <td>75.99</td>
               <td>10,000</td>
               <td>70</td>
               <td>10,000</td>
               <td>70</td>
            </tr>
            <tr>
                <td>4 <sup>1/2</sup>EUE</td>
               <td>5.625</td>
               <td>142.88</td>
               <td>3.958</td>
               <td>100.53</td>
               <td>10,000</td>
               <td>70</td>
               <td>10,000</td>
               <td>70</td>
            </tr>
        </tbody>
        </table>
    </div>
  )
}

export default ElOnOffToolTable