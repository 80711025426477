import MaverickArrow from '../../../assets/images/arrow.png';

function AboutUsHome() {
  return (
    <div className="about-us-home-container">

    <div className="blue bar"></div>
    <div className="grey bar"></div>
    <div className="black bar"></div>

      <div>
        <h3 className="main-subhead">
          <span className="bold">About</span> Us
        </h3>
        <div className="spacer"></div>
        <h4 className="smaller-head mb20">Maverick Downhole Technologies</h4>
        <p className="body-text">
          Maverick’s mission is to be the preferred supply partner of
          downhole tools to the world’s most important service companies. <br /><br />
          The
          Company blends together a heritage of over 60 years of experience in
          industrial technology, precision machining, and energy services with
          local, focused and specialized knowledge of downhole tools that leads
          to exceptional performance in the field.
        </p>
      </div>
      <div>
          <img className="arrow-img" src={MaverickArrow} alt="Maverick Arrow"/>
      </div>
    </div>
  );
}

export default AboutUsHome;
