import React, { useEffect, useState } from "react";
import { getAllProductService } from "../../../services/dept";
import { Helmet } from "react-helmet";
import JsPDF from "jspdf";
import styled from "styled-components";
import PermapakAccordion from "../../Misc/PermapakAccordion";

//IMAGES ↓
import SealBoreExtensionImg from '../../../assets/images/product-images/permanent-packers/accessories/seal-bore-extension.png'
import MillOutExtensionImg from '../../../assets/images/product-images/permanent-packers/accessories/mill-out-extension.jpg'
import SealBoreMillImg from '../../../assets/images/product-images/permanent-packers/accessories/seal-bore-mill-out.jpg'
import PumpOutPlugImg from '../../../assets/images/product-images/permanent-packers/accessories/pump-out-plug.jpg'
import ConcentricBottomImg from '../../../assets/images/product-images/permanent-packers/accessories/concentric-bottom.jpg'
import MillOutImg from '../../../assets/images/product-images/permanent-packers/accessories/mill-out-bottom.jpg'
import KnockOutPlugBottomImg from '../../../assets/images/product-images/permanent-packers/accessories/knock-out-plug-bottom.jpg'
import ConcentricCouplingImg from '../../../assets/images/product-images/permanent-packers/accessories/concentric-coupling.jpg'
import KnockOutPlugImg from '../../../assets/images/product-images/permanent-packers/accessories/knock-out-plug.jpg'
import WirelineImg from '../../../assets/images/product-images/permanent-packers/accessories/wireline-reentry-bottom.jpg'
import AnchorLatchImg from '../../../assets/images/product-images/permanent-packers/accessories/anchor-latch-assembly.jpg'
import SnapLatchAssemblyImg from '../../../assets/images/product-images/permanent-packers/accessories/snap-latch-assembly.jpg'
import SealUnitImg from '../../../assets/images/product-images/permanent-packers/accessories/seal-unit.jpg'
import LocatorSealAssemblyImg from '../../../assets/images/product-images/permanent-packers/accessories/locator-seal-assembly.jpg'
import HalfMuleImg from '../../../assets/images/product-images/permanent-packers/accessories/half-mule.jpg'

function PermapakAccessories(props) {

  const accessories = [
    {
      title: 'Seal Bore Extension',
      description: 'A Seal Bore Extension is used in installations where floating seals are required due to tubing contractions. This packer accessory allows for a continuous seal bore and is available in lengths up to twenty feet.',
      img: SealBoreExtensionImg,
    },
    {
      title: 'Mill-Out Extension',
      description: 'A Mill-Out Extension is used in installations where floating seals are required due to tubing contractions. In addition, this packer accessory provides a larger ID between a packer bore and a seal bore extension allowing a packer plucker when a packer is milled out.',
      img: MillOutExtensionImg,
    },
    {
      title: 'Seal Bore to Mill-Out Coupling',
      description: 'A Seal Bore to Mill-Out Coupling is used to connect mill-out extensions to seal bore extensions.',
      img: SealBoreMillImg,
    },
    {
      title: 'Pump Out Plug',
      description: 'A Pump-Out Plug is available with a choice of threads allowing the pump-out plug to be attached to a packer, seal bore extension, mill-out extension or tailpipe and hold pressure from below after the packer is set. Once the production string is landed, pressure is applied to the tubing and the pump-out plug is removed to allow full opening.',
      img: PumpOutPlugImg,
    },
    {
      title: 'Concentric Bottom',
      description: 'A Concentric Bottom is used to connect a seal bore extension to a packer.',
      img: ConcentricBottomImg,
    },
    {
      title: 'Mill-Out Bottom',
      description: 'A Mill-Out Bottom is used to connect a mill-out extension to a packer.',
      img: MillOutImg,
    },
    {
      title: 'Knock-Out Plug Bottom',
      description: 'When a Knock-Out Bottom is run below the packer, the knock-out plug allows a packer to serve as a bridge plug holding pressure from above and below.',
      img: KnockOutPlugBottomImg,
    },
    {
      title: 'Concentric Coupling',
      description: 'A Concentric Coupling is used to connect seal bore extensions to increase length.',
      img: ConcentricCouplingImg,
    },
    {
      title: 'Knock-Out Plug',
      description: 'A Knock-Out Plug is knocked out when the production string is landed.',
      img: KnockOutPlugImg,
    },
    {
      title: 'Wireline Re-Entry Bottom',
      description: 'A Wireline Re-Entry Bottom is used as the packer bottom when an extension below a packer is not required.',
      img: WirelineImg,
    },
    {
      title: 'Anchor Latch Assembly',
      description: 'The Anchor Latch Seal Assembly is used when floating seals are not required. This assembly allows the tubing string to be in tension or compression. Upon set down the anchor latches into the packer and can be removed with eight to ten right-hand turns.',
      img: AnchorLatchImg,
    },
    {
      title: 'Snap Latch Assembly',
      description: 'The Snap Latch Seal Assembly latches into the packer upon set down (like our Anchor Latch Seal Assembly). It can be removed with straight pull of 10,000 to 12,000 lbs. above tubing weight. The Snap Latch Seal Assembly is used where a mechanical indication is required to verify the seal assembly is properly positioned in the packer bore.',
      img: SnapLatchAssemblyImg,
    },
    {
      title: 'Seal Unit',
      description: 'The standard Seal Unit i s furnished with bonded nitrile rings and a 12-inch make-up length. These units may be ordered in various lengths and with severe service seal rings for high temperature and corrosive environmental conditions.',
      img: SealUnitImg,
    },
    {
      title: 'Locator Seal Assembly',
      description: 'The Locator Seal Assembly is used in installations which require floating seals. Once landed, the seal locator prevents downward movement of the tubing while allowing the seal to move with tubing contraction.',
      img: LocatorSealAssemblyImg,
    },
    {
      title: 'Half Mule Shoe Guide',
      description: 'The Half Mule Shoe Guide is standard with the Seal Assembly to allow for easy entry into the packer bore.',
      img: HalfMuleImg,
    },
  ]


  const AccordionContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 20px;
    margin-bottom: 40px;
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  `;

  const generatePDF = () => {
    const report = new JsPDF("portrait", "pt", "a4");
    report.html(document.querySelector(".product-info")).then(() => {
      report.save(`${title}.pdf`);
    });
  };

  const { title } = props;
  const [products, setProducts] = useState([]);
  const [currentProduct, setCurrentProduct] = useState({});

  useEffect(() => {
    getAllProductService().then((loadedProducts) => {
      setProducts(loadedProducts);
      setCurrentProduct(
        loadedProducts.find((product) => product.title === title)
      );
    });
  }, []);

  // console.log(currentProduct);
  return (
    <div className="product-infoo">
      <Helmet>
        ‍
        <title>{`${title} | Maverick Downhole Technologies | Edmonton, AB`}</title>
        <script type="application/ld+json">{JSON.stringify({})}</script>
      </Helmet>
      <div>
        <h3 className="main-subhead">
          {currentProduct !== {} ? currentProduct.title : null}
        </h3>
        <div className="spacer"></div><br />
        <AccordionContainer>
          {accessories.map(({title, description, img}) => {
            return(
              <div className="accessories-card">
                <div>
                <img src={img} alt={title} title={title} />
                </div>
                <div>
                <h4 className="smaller-head">{title}</h4>
                <p className="body-text">{description}</p>
                </div>
              </div>
            )
          })}
        </AccordionContainer>
        {/* <h4 className="smaller-head">
          {currentProduct !== {} ? currentProduct.subhead : null}
        </h4>
        <p className="body-text">
          {currentProduct !== {} ? currentProduct.description : null}
        </p> */}
        {/* <button onClick={generatePDF} type="button" className="main-btn">Download PDF</button><br /><br /> */}
      </div>
    </div>
  );
}

export default PermapakAccessories;
