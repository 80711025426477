import React from "react";
import ContactForm from "../../components/ContactForm/ContactForm";
import { useEffect, useState } from "react";
import { getAllProductService } from "../../services/dept";
import { getFlowControlTitlesService } from "../../services/dept";
import FlowControlHeader from "../../components/Headers/FlowControlHeader";
import GeneralBtn from "../../components/ColumnBtns/GeneralBtn";
import { Helmet } from 'react-helmet';

import ExExnLandingNipples from "../../components/Products/FlowControl/ExExnLandingNipples";
import EorEornLandingNipples from "../../components/Products/FlowControl/EorEornLandingNipples";
import EfErLandingNipples from "../../components/Products/FlowControl/EfErLandingNipples";
import ExaExoEnpSlidingSleeves from "../../components/Products/FlowControl/ExaExoEnpSlidingSleeves";
import ElSlidingSleeve from "../../components/Products/FlowControl/ElSlidingSleeve";
import { useParams } from "react-router-dom";
import { pseudoencodeURI } from "./converter";

const ProductController = [
  {
    title: "X-XN LANDING NIPPLES",
    component: <ExExnLandingNipples title="X-XN LANDING NIPPLES" />,
  },
  {
    title: "OR-ORN LANDING NIPPLES",
    component: <EorEornLandingNipples title="OR-ORN LANDING NIPPLES" />,
  },
  {
    title: "F-R LANDING NIPPLES",
    component: <EfErLandingNipples title="F-R LANDING NIPPLES" />,
  },
  {
    title: "XA-XO-NP SLIDING SLEEVES",
    component: <ExaExoEnpSlidingSleeves title="XA-XO-NP SLIDING SLEEVES" />,
  },
  {
    title: "L SLIDING SLEEVE",
    component: <ElSlidingSleeve title="L SLIDING SLEEVE" />,
  },
];

function FlowControl() {
  const [flowControl, setFlowControl] = useState([]);
  const [currentProduct, setCurrentProduct] = useState("X-XN LANDING NIPPLES");
  const params = useParams(); 

  useEffect(() => {
    getFlowControlTitlesService().then((result) => {
      setFlowControl(result);
      setCurrentProduct(params.subLink)
      window.scrollTo(0, 0);
    });
  }, [params]);

  return (
    <div>
    <Helmet>‍
        <title>Flow Control | Maverick Downhole Technologies | Edmonton, AB</title>‍
       
      </Helmet>

      <FlowControlHeader />
      <div className="product-section">
        <div className="product-btn-container">
          {flowControl.map((flowControl) => {
            return (
              <GeneralBtn
                onClick={(title) => setCurrentProduct(title)}
                title={flowControl.title}
                selected={pseudoencodeURI(flowControl.title) === currentProduct}
                baseURL={"/products/flow-control"}
              />
            );
          })}
        </div>
        <div>
          {!currentProduct
            ? null
            : ProductController.find(
                (product) => pseudoencodeURI(product.title) === currentProduct
              )?.component}


          {/* <ExExnLandingNipples title="EX/EXN LANDING NIPPLES"/>
          <EorEornLandingNipples title="EOR/EORN LANDING NIPPLES" />
          <EfErLandingNipples title="EF/ER LANDING NIPPLES" />
          <ExaExoEnpSlidingSleeves title="EXA/EXO/ENP SLIDING SLEEVES" />
          <ElSlidingSleeve title="EL SLIDING SLEEVE" /> */}
        </div>
      </div>
      <ContactForm />
    </div>
  );
}

export default FlowControl;
