import React, { useEffect, useState } from "react";
import { getAllProductService } from "../../../services/dept";
import Img from "../../../assets/images/product-images/assessories/pump-out-plug.jpg";
import { Helmet } from "react-helmet";
import PumpOutPlugTable from './Tables/PumpOutPlugTable'

function PumpOutPlug(props) {
  const { title } = props;
  const [products, setProducts] = useState([]);
  const [currentProduct, setCurrentProduct] = useState({});

  useEffect(() => {
    getAllProductService().then((loadedProducts) => {
      setProducts(loadedProducts);
      setCurrentProduct(
        loadedProducts.find((product) => product.title === title)
      );
    });
  }, []);

  // console.log(currentProduct);
  return (
    <div className="product-info">
      <Helmet>
        <meta
          property="og:title"
          content={title}
        />
        <meta
          property="og:description"
          content={currentProduct.description}
        />
        <meta
          property="og:image"
          content={Img}
        />
        ‍
        <title>{`${title} | Maverick Downhole Technologies | Edmonton, AB`}</title>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Product",
            name: title,
            image: { Img },
            description: currentProduct.description,
            brand: {
              "@type": "Brand",
              name: "Maverick Downhole Technologies",
            },
          })}
        </script>
      </Helmet>
      <div>
        <h3 className="main-subhead">
          {currentProduct !== {} ? currentProduct.title : null}
        </h3>
        <h4 className="smaller-head">
          {currentProduct !== {} ? currentProduct.subhead : null}
        </h4>
        <div className="spacer"></div>
        <p className="body-text">
          {currentProduct !== {} ? currentProduct.description : null}
        </p>
        <PumpOutPlugTable />
      </div>
      <div>
        <img
          src={Img}
          title={currentProduct.title}
          alt={currentProduct.title}
          className="product-img"
        />
      </div>
    </div>
  );
}

export default PumpOutPlug;
