import React from "react";
import { $, jQuery } from "jquery";

function HydroCTPackerTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
        <tr>
          <th colspan="2">CASING SIZE</th>
          <th colspan="2">CASING WEIGHT</th>
          <th colspan="2">TUBING SIZE</th>
          <th colspan="2">MAX OD</th>
          <th colspan="2">MIN ID</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>in.</td>
          <td>mm</td>
          <td>lbs/ft</td>
          <td>kg/m</td>
          <td>in.</td>
          <td>mm</td>
          <td>in.</td>
          <td>mm</td>
          <td>in.</td>
          <td>mm</td>
        </tr>
        <tr>
          <td>4<sup>1/2</sup></td>
          <td>114.3</td>
          <td>9.5-13.5</td>
          <td>14.1-20.9</td>
          <td>2 3/8 EUE</td>
          <td>60.3</td>
          <td>3.750</td>
          <td>95.25</td>
          <td>2.000</td>
          <td>50.80</td>
        </tr>
        </tbody>
      </table>
    </div>
  );
}

export default HydroCTPackerTable;
