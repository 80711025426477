import axios from 'axios'
import config from '../../config';
import {notification} from 'antd'
import { Modal } from 'antd';
import 'antd/dist/antd.css';


async function sendEmail(e) {
    console.log('submit1', document.getElementById('name').value)
    e.preventDefault();
    await axios.post(`${config.base_url}/form/sendHelpEmail`, {
        name: document.getElementById('name').value, 
        phone: document.getElementById('phone').value, 
        email: document.getElementById('email').value, 
        message: document.getElementById('message').value, 
     }).then((res) => {
        console.log("15", res)
        // Modal.success({
        //     title: "Success"
        // })
        document.getElementById("name").value= '';
        document.getElementById("phone").value= '';
        document.getElementById("email").value= '';
        document.getElementById("message").value= '';

        notification.success({
            message: 'Message Sent!',
            description:
              'Thank you. Your message has been sent.',
            onClick: () => {
              console.log('Notification Clicked!');
            },
          });
     }).catch((err) => {
        notification.error({
            message: 'Message Failed',
            description:
              'Sorry, your message did not go through. Please try again.',
            onClick: () => {
              console.log('Notification Clicked!');
            },
          });
     })
}

function ContacForm() {
  return (
    <div>
        {/* <Modal title="Test" visible={true}>
            STH
        </Modal> */}
        <div className="contact-form-container" id="contact">
            <div className="form-grid">
                <div>
                    <h3 className="main-subhead mb20">
                        Get in <span className="bold">Touch</span>
                    </h3>
                    <form id='form'>                                 
                        <div className="form-top">
                            <input type="text" id="name" placeholder="Full Name" required />
                            <input type="text" id="phone" placeholder="Phone Number" className="no-margin"  required />
                        </div>
                        <input type="text" id="email" placeholder="Email Address" required />
                        <textarea id="message" placeholder="Message"></textarea>
                        <button className="main-btn" id="submit" onClick={sendEmail}>Submit</button>
                    </form>
                </div>
                <div>
                    <div className="contact-form-info">
                        <a href="tel:5874026783">
                            <p className="mb20">587-402-6783</p>
                        </a>
                        <a href="mailto:info@maverickdownhole.com">
                            <p className="mb20">info@maverickdownhole.com</p>
                        </a>
                        <a href="https://www.google.com/maps/place/Maverick+Downhole+Technologies+Inc./@53.5026849,-113.4236858,15z/data=!4m5!3m4!1s0x0:0x27ad2a1e9de3c98!8m2!3d53.5026849!4d-113.4236858" target="_blank">
                            <p className="mb20">5744 67th Ave NW <br />
                            Edmonton, AB T6B 3P8</p>
                        </a>
                    </div>
                </div> 
            </div>
        </div>
        </div>
  );
}

export default ContacForm;
