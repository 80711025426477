import React from 'react'
import {$,jQuery} from 'jquery';

function SafetyJointTable() {
    
  return (
    <div>  
        <table id="example" class="table table-striped table-bordered" cellspacing="0" width="100%">
        <thead>
       
        </thead>
 
        <tbody>
           
          
        </tbody>
        </table>
    </div>
  )
}

export default SafetyJointTable