import React from 'react'
import {$,jQuery} from 'jquery';

function DtOnOffTable() {
    
  return (
    <div>  
        <table id="example" class="table table-striped table-bordered" cellspacing="0" width="100%">
        <thead>
        <tr class="c83">
                <td class="c80" colspan="1" rowspan="1">
                    <p class="c5"><span class="c7">Casing / Tubing Size </span></p>
                    <p class="c5"><span class="c7">(inches)</span></p>
                </td>
                <td class="c30" colspan="1" rowspan="1">
                    <p class="c5"><span class="c7">Tool OD </span></p>
                    <p class="c5"><span class="c7">(inches)</span></p>
                </td>
                <td class="c56" colspan="1" rowspan="1">
                    <p class="c5"><span class="c7">Thread Connections </span></p>
                    <p class="c5"><span class="c7">Box Up</span></p>
                </td>
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c45"><span class="c7">Part &nbsp;</span></p>
                    <p class="c5"><span class="c7">Number</span></p>
                </td>
            </tr>
        </thead>
 
        <tbody>
        <tr class="c13">
                <td class="c80" colspan="1" rowspan="2">
                    <p class="c5"><span class="c2">2-7/8 </span></p>
                </td>
                <td class="c30" colspan="1" rowspan="2">
                    <p class="c5"><span class="c2">2.250</span></p>
                </td>
                <td class="c56" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">1.900 NUE </span></p>
                </td>
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51227</span></p>
                </td>
            </tr>
            <tr class="c13">
                <td class="c56" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">1.900 EUE </span></p>
                </td>
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51228</span></p>
                </td>
            </tr>
            <tr class="c13">
                <td class="c80" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">3-1/2 </span></p>
                </td>
                <td class="c30" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">2.750 </span></p>
                </td>
                <td class="c56" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">1.900 EUE </span></p>
                </td>
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51235</span></p>
                </td>
            </tr>
            <tr class="c13">
                <td class="c80" colspan="1" rowspan="3">
                    <p class="c5"><span class="c2">4</span></p>
                </td>
                <td class="c30" colspan="1" rowspan="2">
                    <p class="c5"><span class="c2">3.250 </span></p>
                </td>
                <td class="c56" colspan="1" rowspan="2">
                    <p class="c5"><span class="c2">1.900 EUE</span></p>
                </td>
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51240</span></p>
                </td>
            </tr>
            <tr class="c13">
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51240-XBAB</span></p>
                </td>
            </tr>
            <tr class="c13">
                <td class="c30" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">3.125 </span></p>
                </td>
                <td class="c56" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">2-3/8 EUE </span></p>
                </td>
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51240-1295</span></p>
                </td>
            </tr>
            <tr class="c13">
                <td class="c80" colspan="1" rowspan="4">
                    <p class="c5"><span class="c2">4-1/2</span></p>
                </td>
                <td class="c30" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">3.437 </span></p>
                </td>
                <td class="c56" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">1.900 EUE </span></p>
                </td>
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51242</span></p>
                </td>
            </tr>
            <tr class="c13">
                <td class="c30" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">3.580 </span></p>
                </td>
                <td class="c56" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">1.900 EUE </span></p>
                </td>
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51243</span></p>
                </td>
            </tr>
            <tr class="c13">
                <td class="c30" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">3.656 </span></p>
                </td>
                <td class="c56" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">2-3/8 EUE </span></p>
                </td>
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51244</span></p>
                </td>
            </tr>
            <tr class="c13">
                <td class="c30" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">3.750 </span></p>
                </td>
                <td class="c56" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">2-3/8 EUE </span></p>
                </td>
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51245</span></p>
                </td>
            </tr>
            <tr class="c13">
                <td class="c80" colspan="1" rowspan="2">
                    <p class="c5"><span class="c2">5</span></p>
                </td>
                <td class="c30" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">3.750 </span></p>
                </td>
                <td class="c56" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">2-3/8 EUE </span></p>
                </td>
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51252</span></p>
                </td>
            </tr>
            <tr class="c13">
                <td class="c30" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">4.250 </span></p>
                </td>
                <td class="c56" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">2-3/8 EUE </span></p>
                </td>
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51250</span></p>
                </td>
            </tr>
            <tr class="c83">
                <td class="c80" colspan="1" rowspan="1">
                    <p class="c5"><span class="c7">Casing / Tubing Size </span></p>
                    <p class="c5"><span class="c7">(inches)</span></p>
                </td>
                <td class="c30" colspan="1" rowspan="1">
                    <p class="c5"><span class="c7">Tool OD </span></p>
                    <p class="c5"><span class="c7">(inches)</span></p>
                </td>
                <td class="c56" colspan="1" rowspan="1">
                    <p class="c5"><span class="c7">Thread Connections </span></p>
                    <p class="c5"><span class="c7">Box Up</span></p>
                </td>
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c45"><span class="c7">Part &nbsp;</span></p>
                    <p class="c5"><span class="c7">Number</span></p>
                </td>
            </tr>
            <tr class="c13">
                <td class="c80" colspan="1" rowspan="4">
                    <p class="c5"><span class="c2">5-1/2</span></p>
                </td>
                <td class="c30" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">4.375 </span></p>
                </td>
                <td class="c56" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">2-3/8 EUE </span></p>
                </td>
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51254</span></p>
                </td>
            </tr>
            <tr class="c13">
                <td class="c30" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">4.500 </span></p>
                </td>
                <td class="c56" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">2-3/8 EUE </span></p>
                </td>
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51255</span></p>
                </td>
            </tr>
            <tr class="c13">
                <td class="c30" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">4.406 </span></p>
                </td>
                <td class="c56" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">2-7/8 EUE </span></p>
                </td>
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51251</span></p>
                </td>
            </tr>
            <tr class="c13">
                <td class="c30" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">4.500 </span></p>
                </td>
                <td class="c56" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">2-7/8 EUE </span></p>
                </td>
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51256</span></p>
                </td>
            </tr>
            <tr class="c13">
                <td class="c80" colspan="1" rowspan="3">
                    <p class="c5"><span class="c2">6-5/8 </span></p>
                </td>
                <td class="c30" colspan="1" rowspan="3">
                    <p class="c5"><span class="c2">5.500</span></p>
                </td>
                <td class="c56" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">2-3/8 EUE </span></p>
                </td>
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51265</span></p>
                </td>
            </tr>
            <tr class="c13">
                <td class="c56" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">2-7/8 EUE </span></p>
                </td>
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51266</span></p>
                </td>
            </tr>
            <tr class="c13">
                <td class="c56" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">3-1/2 EUE </span></p>
                </td>
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51267</span></p>
                </td>
            </tr>
            <tr class="c13">
                <td class="c80" colspan="1" rowspan="4">
                    <p class="c5"><span class="c2">7</span></p>
                </td>
                <td class="c30" colspan="1" rowspan="2">
                    <p class="c5"><span class="c2">5.875</span></p>
                </td>
                <td class="c56" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">2-3/8 EUE </span></p>
                </td>
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51270</span></p>
                </td>
            </tr>
            <tr class="c13">
                <td class="c56" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">2-7/8 EUE </span></p>
                </td>
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51271</span></p>
                </td>
            </tr>
            <tr class="c13">
                <td class="c30" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">5.250 </span></p>
                </td>
                <td class="c56" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">3-1/2 EUE </span></p>
                </td>
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51268</span></p>
                </td>
            </tr>
            <tr class="c13">
                <td class="c30" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">5.875 </span></p>
                </td>
                <td class="c56" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">3-1/2 EUE </span></p>
                </td>
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51273</span></p>
                </td>
            </tr>
            <tr class="c13">
                <td class="c80" colspan="1" rowspan="3">
                    <p class="c5"><span class="c2">7-5/8 </span></p>
                </td>
                <td class="c30" colspan="1" rowspan="3">
                    <p class="c5"><span class="c2">6.375</span></p>
                </td>
                <td class="c56" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">2-7/8 EUE </span></p>
                </td>
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51275</span></p>
                </td>
            </tr>
            <tr class="c13">
                <td class="c56" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">3-1/2 EUE </span></p>
                </td>
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51276</span></p>
                </td>
            </tr>
            <tr class="c13">
                <td class="c56" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">4-1/2 EUE </span></p>
                </td>
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51277</span></p>
                </td>
            </tr>
            <tr class="c13">
                <td class="c80" colspan="1" rowspan="3">
                    <p class="c5"><span class="c2">8-5/8 </span></p>
                </td>
                <td class="c30" colspan="1" rowspan="3">
                    <p class="c5"><span class="c2">7.500</span></p>
                </td>
                <td class="c56" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">2-7/8 EUE </span></p>
                </td>
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51285</span></p>
                </td>
            </tr>
            <tr class="c13">
                <td class="c56" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">3-1/2 EUE </span></p>
                </td>
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51286</span></p>
                </td>
            </tr>
            <tr class="c13">
                <td class="c56" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">4-1/2 EUE </span></p>
                </td>
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51287</span></p>
                </td>
            </tr>
            <tr class="c13">
                <td class="c80" colspan="1" rowspan="4">
                    <p class="c5"><span class="c2">9-5/8 </span></p>
                </td>
                <td class="c30" colspan="1" rowspan="4">
                    <p class="c5"><span class="c2">8.250</span></p>
                </td>
                <td class="c56" colspan="1" rowspan="2">
                    <p class="c5"><span class="c2">2-7/8 EUE</span></p>
                </td>
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51292</span></p>
                </td>
            </tr>
            <tr class="c13">
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51295</span></p>
                </td>
            </tr>
            <tr class="c13">
                <td class="c56" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">3-1/2 EUE </span></p>
                </td>
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51296</span></p>
                </td>
            </tr>
            <tr class="c13">
                <td class="c56" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">4-1/2 EUE </span></p>
                </td>
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51297</span></p>
                </td>
            </tr>
            <tr class="c13">
                <td class="c80" colspan="1" rowspan="3">
                    <p class="c5"><span class="c2">10-3/4 </span></p>
                </td>
                <td class="c30" colspan="1" rowspan="3">
                    <p class="c5"><span class="c2">9.310</span></p>
                </td>
                <td class="c56" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">3-1/2 EUE </span></p>
                </td>
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51210</span></p>
                </td>
            </tr>
            <tr class="c13">
                <td class="c56" colspan="1" rowspan="2">
                    <p class="c5"><span class="c2">4-1/2 EUE</span></p>
                </td>
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51210D</span></p>
                </td>
            </tr>
            <tr class="c13">
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51201</span></p>
                </td>
            </tr>
            <tr class="c13">
                <td class="c80" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">11-3/4 </span></p>
                </td>
                <td class="c30" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">10.000 </span></p>
                </td>
                <td class="c56" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">2-7/8 EUE </span></p>
                </td>
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51211B</span></p>
                </td>
            </tr>
            <tr class="c13">
                <td class="c80" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">13-3/8 </span></p>
                </td>
                <td class="c30" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">11.875 </span></p>
                </td>
                <td class="c56" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">4-1/2 EUE </span></p>
                </td>
                <td class="c47" colspan="1" rowspan="1">
                    <p class="c5"><span class="c2">51213</span></p>
                </td>
            </tr>
          
        </tbody>
        </table>
    </div>
  )
}

export default DtOnOffTable