import React, { useEffect, useState } from "react";
import { getAllProductService } from "../../../services/dept";
import { Helmet } from 'react-helmet';
import JsPDF from 'jspdf';
import RSBPImg from '../../../assets/images/product-images/mechanical-packers/rsbp-single.jpg'
import RSBPSingleTable from './Tables/RSBPSingleTable'

function RetrievableSealBorePackerSingle(props) {
  const { title } = props;
  const [products, setProducts] = useState([]);
  const [currentProduct, setCurrentProduct] = useState({});

  const generatePDF = () => {

    const report = new JsPDF('portrait','pt','a4');
    report.html(document.querySelector('.product-info')).then(() => {
        report.save(`${title}.pdf`);
    });
  }

  // useEffect(() => {
  //   getAllProductService().then((loadedProducts) => {
  //     setProducts(loadedProducts);
  //     setCurrentProduct(
  //       loadedProducts.find((product) => product.title === title)
  //     );
  //   });
  // }, []);
  useEffect(() => {
    getAllProductService().then((loadedProducts) => {
      setProducts(loadedProducts);

      let formatLoaded = loadedProducts.map((elem) => {
        if (elem.title === title) {
          return {
            ...elem,

            // description: elem.description.replace(/[\\n\\n]/gm, "n"),
            description: elem.description.split('\\n'),

          };
        } else {
          return elem;
        }
      });

      setCurrentProduct(
        formatLoaded.find((product) => product.title === title)
      );
      //currentProduct.description = currentProduct.description.replace(/[\\n\\n]/gm, "n");
     //
    });
  }, []);

  // console.log(currentProduct);
  return (
    <div className="product-info">
       <Helmet>‍
        <title>{`${title} | Maverick Downhole Technologies | Edmonton, AB`}</title>
        <script type="application/ld+json">
        {JSON.stringify({
        
        })}
        </script>
        </Helmet>
      <div>
        <h3 className="main-subhead">
          {currentProduct !== {} ? currentProduct.title : null}
        </h3>
        <h4 className="smaller-head">
          {currentProduct !== {} ? currentProduct.subhead : null}
        </h4>
        <div className="spacer"></div>
        <p className="body-text">
          {currentProduct !== {} ? currentProduct.description : null}
        </p>
        {/* <button onClick={generatePDF} type="button" className="main-btn">Download PDF</button><br /><br /> */}
        <RSBPSingleTable />
      </div>
      <div>
        <img
          src={RSBPImg}
          title={currentProduct.title}
          alt={currentProduct.title}
          className="product-img"
        />
      </div>
    </div>
  );
}

export default RetrievableSealBorePackerSingle;
