import React from "react";
import { $, jQuery } from "jquery";

function BumperSubTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c59">
            <td class="c239" colspan="1" rowspan="1">
              Size (inches)
            </td>
            <td class="c94" colspan="1" rowspan="1">
              Tool OD (inches)
            </td>
            <td class="c274" colspan="1" rowspan="1">
              Tool ID (inches)
            </td>
            <td class="c179" colspan="1" rowspan="1">
              Thread Connections Box Up / Pin Down
            </td>
            <td class="c363" colspan="1" rowspan="1">
              Part Number
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="c31">
            <td class="c239" colspan="1" rowspan="1">
              3-1/2
            </td>
            <td class="c94" colspan="1" rowspan="1">
              4.750
            </td>
            <td class="c274" colspan="1" rowspan="1">
              2.50
            </td>
            <td class="c179" colspan="1" rowspan="1">
              3-1/2 IF TOOL JOINT
            </td>
            <td class="c363" colspan="1" rowspan="1">
              53335
            </td>
          </tr>
          <tr class="c31">
            <td class="c239" colspan="1" rowspan="2">
              4-1/2
            </td>
            <td class="c94" colspan="1" rowspan="1">
              6.250
            </td>
            <td class="c274" colspan="1" rowspan="1">
              3.75
            </td>
            <td class="c179" colspan="1" rowspan="1">
              4-1/2 IF TOOL JOINT
            </td>
            <td class="c363" colspan="1" rowspan="1">
              53345
            </td>
          </tr>
          <tr class="c31">
            <td class="c94" colspan="1" rowspan="1">
              6.500
            </td>
            <td class="c274" colspan="1" rowspan="1">
              3.75
            </td>
            <td class="c179" colspan="1" rowspan="1">
              4-1/2 IF TOOL JOINT
            </td>
            <td class="c363" colspan="1" rowspan="1">
              53346
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default BumperSubTable;
