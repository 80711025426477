import React from "react";
import { $, jQuery } from "jquery";

function LcHydraulicSettingToolTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c150">
            <td class="c240" colspan="1" rowspan="1">
              Tool
            </td>
            <td class="c416" colspan="1" rowspan="1">
              Setting Area (in2/Cylinder)
            </td>
            <td class="c341" colspan="1" rowspan="1">
              Tool OD (inches)
            </td>
            <td class="c365" colspan="1" rowspan="1">
              Tool ID (inches)
            </td>
            <td class="c276" colspan="1" rowspan="1">
              Thread Connection Box Up / Pin Down
            </td>
            <td class="c73" colspan="1" rowspan="1">
              Part Number
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="c59">
            <td class="c240" colspan="1" rowspan="1">
              LC-15
            </td>
            <td class="c416" colspan="1" rowspan="1">
              4.70
            </td>
            <td class="c341" colspan="1" rowspan="1">
              3.718
            </td>
            <td class="c365" colspan="1" rowspan="1">
              1.50
            </td>
            <td class="c276" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c73" colspan="1" rowspan="1">
              39415
            </td>
          </tr>
          <tr class="c59">
            <td class="c240" colspan="1" rowspan="1">
              LC-20
            </td>
            <td class="c416" colspan="1" rowspan="1">
              4.60
            </td>
            <td class="c341" colspan="1" rowspan="1">
              4.375
            </td>
            <td class="c365" colspan="1" rowspan="1">
              2.00
            </td>
            <td class="c276" colspan="1" rowspan="1">
              3-1/2 IF TOOL JOINT
            </td>
            <td class="c73" colspan="1" rowspan="1">
              39420
            </td>
          </tr>
          <tr class="c59">
            <td class="c240" colspan="1" rowspan="1">
              LC-20HD
            </td>
            <td class="c416" colspan="1" rowspan="1">
              4.60
            </td>
            <td class="c341" colspan="1" rowspan="1">
              4.375
            </td>
            <td class="c365" colspan="1" rowspan="1">
              1.75
            </td>
            <td class="c276" colspan="1" rowspan="1">
              3-1/2 IF TOOL JOINT
            </td>
            <td class="c73" colspan="1" rowspan="1">
              39420HD
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default LcHydraulicSettingToolTable;
