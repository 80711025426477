import React from "react";
import { $, jQuery } from "jquery";

function StradasnapPackerTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c165">
            <td class="c1006" colspan="2" rowspan="1">
              Casing
            </td>
            <td class="c191" colspan="1" rowspan="2">
              Recommended Hole Size (inches)
            </td>
            <td class="c89" colspan="1" rowspan="2">
              Gage OD (inches)
            </td>
            <td class="c158" colspan="1" rowspan="2">
              Max OD (inches)
            </td>
            <td class="c153" colspan="1" rowspan="2">
              Tool ID (inches)
            </td>
            <td class="c397" colspan="1" rowspan="2">
              Thread Connections Box Up / Pin Down
            </td>
            <td class="c275" colspan="1" rowspan="2">
              Part Number
            </td>
          </tr>
          <tr class="c150">
            <td class="c240" colspan="1" rowspan="1">
              Size (inches)
            </td>
            <td class="c214" colspan="1" rowspan="1">
              Weight (lbs/ft)
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="c25">
            <td class="c240" colspan="1" rowspan="1">
              4-1/2
            </td>
            <td class="c214" colspan="1" rowspan="1">
              9.5 - 13.5
            </td>
            <td class="c191" colspan="1" rowspan="1">
              3.920 - 4.090
            </td>
            <td class="c89" colspan="1" rowspan="1">
              3.750
            </td>
            <td class="c158" colspan="1" rowspan="1">
              -
            </td>
            <td class="c153" colspan="1" rowspan="1">
              1.94
            </td>
            <td class="c397" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c275" colspan="1" rowspan="1">
              64545
            </td>
          </tr>
          <tr class="c25">
            <td class="c240" colspan="1" rowspan="2">
              5
            </td>
            <td class="c214" colspan="1" rowspan="1">
              11.5 - 15.0
            </td>
            <td class="c191" colspan="1" rowspan="1">
              4.408 - 4.560
            </td>
            <td class="c89" colspan="1" rowspan="1">
              4.125
            </td>
            <td class="c158" colspan="1" rowspan="1">
              4.2201
            </td>
            <td class="c153" colspan="1" rowspan="1">
              1.94
            </td>
            <td class="c397" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c275" colspan="1" rowspan="1">
              64550
            </td>
          </tr>
          <tr class="c25">
            <td class="c214" colspan="1" rowspan="1">
              18.0 - 21.0
            </td>
            <td class="c191" colspan="1" rowspan="1">
              4.154 - 4.276
            </td>
            <td class="c89" colspan="1" rowspan="1">
              4.000
            </td>
            <td class="c158" colspan="1" rowspan="1">
              -
            </td>
            <td class="c153" colspan="1" rowspan="1">
              1.94
            </td>
            <td class="c397" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c275" colspan="1" rowspan="1">
              64552
            </td>
          </tr>
          <tr class="c25">
            <td class="c240" colspan="1" rowspan="2">
              5-1/2
            </td>
            <td class="c214" colspan="1" rowspan="2">
              14.0 - 20.0
            </td>
            <td class="c191" colspan="1" rowspan="2">
              4.778 - 5.012
            </td>
            <td class="c89" colspan="1" rowspan="2">
              4.625
            </td>
            <td class="c158" colspan="1" rowspan="1">
              -
            </td>
            <td class="c153" colspan="1" rowspan="1">
              1.94
            </td>
            <td class="c397" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c275" colspan="1" rowspan="1">
              64555
            </td>
          </tr>
          <tr class="c25">
            <td class="c158" colspan="1" rowspan="1">
              -
            </td>
            <td class="c153" colspan="1" rowspan="1">
              2.38
            </td>
            <td class="c397" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c275" colspan="1" rowspan="1">
              64556
            </td>
          </tr>
          <tr class="c25">
            <td class="c240" colspan="1" rowspan="1">
              6-5/8
            </td>
            <td class="c214" colspan="1" rowspan="1">
              24.0 - 32.0
            </td>
            <td class="c191" colspan="1" rowspan="1">
              5.675 - 5.921
            </td>
            <td class="c89" colspan="1" rowspan="1">
              5.500
            </td>
            <td class="c158" colspan="1" rowspan="1">
              -
            </td>
            <td class="c153" colspan="1" rowspan="1">
              2.50
            </td>
            <td class="c397" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c275" colspan="1" rowspan="1">
              64565
            </td>
          </tr>
          <tr class="c25">
            <td class="c240" colspan="1" rowspan="3">
              7
            </td>
            <td class="c214" colspan="1" rowspan="1">
              17.0 - 26.0
            </td>
            <td class="c191" colspan="1" rowspan="1">
              6.276 - 6.538
            </td>
            <td class="c89" colspan="1" rowspan="1">
              6.000
            </td>
            <td class="c158" colspan="1" rowspan="1">
              6.0621
            </td>
            <td class="c153" colspan="1" rowspan="1">
              2.50
            </td>
            <td class="c397" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c275" colspan="1" rowspan="1">
              64572
            </td>
          </tr>
          <tr class="c25">
            <td class="c214" colspan="1" rowspan="2">
              26.0 - 32.0
            </td>
            <td class="c191" colspan="1" rowspan="2">
              6.094 - 6.276
            </td>
            <td class="c89" colspan="1" rowspan="2">
              5.875
            </td>
            <td class="c158" colspan="1" rowspan="1">
              -
            </td>
            <td class="c153" colspan="1" rowspan="1">
              2.50
            </td>
            <td class="c397" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c275" colspan="1" rowspan="1">
              64570
            </td>
          </tr>
          <tr class="c25">
            <td class="c158" colspan="1" rowspan="1">
              -
            </td>
            <td class="c153" colspan="1" rowspan="1">
              3.00
            </td>
            <td class="c397" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c275" colspan="1" rowspan="1">
              64573
            </td>
          </tr>
          <tr class="c25">
            <td class="c240" colspan="1" rowspan="1">
              9-5/8
            </td>
            <td class="c214" colspan="1" rowspan="1">
              43.5 - 53.5
            </td>
            <td class="c191" colspan="1" rowspan="1">
              8.535 - 8.755
            </td>
            <td class="c89" colspan="1" rowspan="1">
              8.250
            </td>
            <td class="c158" colspan="1" rowspan="1">
              -
            </td>
            <td class="c153" colspan="1" rowspan="1">
              4.00
            </td>
            <td class="c397" colspan="1" rowspan="1">
              4-1/2 EUE
            </td>
            <td class="c275" colspan="1" rowspan="1">
              64595
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default StradasnapPackerTable;
