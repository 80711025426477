import React from 'react'
import {$,jQuery} from 'jquery';

function ElOnOffToolTable() {
    
  return (
    <div>  
        <table id="example" class="table table-striped table-bordered" cellspacing="0" width="100%">
        <thead>
            <tr>
                <th colSpan={2}>Tubing Size</th>
                <th colSpan={2}>Tol OD</th>
                <th colSpan={2}>Tool ID</th>
                <th>Tubing Connection</th>
            </tr>
        </thead>
 
        <tbody>
            <tr style={{backgroundColor: '#BCBEC0'}}>
                <td>in.</td>
                <td>mm</td>
               <td>in.</td>
               <td>mm</td>
               <td>in.</td>
               <td>mm</td>
               <td>in. (mm)</td>
            </tr>
            <tr>
                <td>2 <sup>3/8</sup></td>
                <td>60.3</td>
               <td>3.25</td>
               <td>82.55</td>
               <td>2.00</td>
               <td>50.80</td>
               <td>2 <sup>3/8</sup> (60.3mm)</td>
            </tr>
            <tr>
                <td>2 <sup>7/8</sup></td>
                <td>73.0</td>
               <td>3.75</td>
               <td>95.25</td>
               <td>2.50</td>
               <td>63.50</td>
               <td>2 <sup>7/8</sup> (73.0mm)</td>
            </tr>
            <tr>
                <td>3 <sup>1/2</sup></td>
                <td>88.9</td>
               <td>5.00</td>
               <td>127.00</td>
               <td>3.00</td>
               <td>76.20</td>
               <td>3 <sup>1/2</sup> (98.9mm)</td>
            </tr>
        </tbody>
        </table>
    </div>
  )
}

export default ElOnOffToolTable