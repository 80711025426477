import React from "react";
import { $, jQuery } from "jquery";

function StormValveTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c59">
            <td class="c206" colspan="1" rowspan="1">
              Size (inches)
            </td>
            <td class="c145" colspan="1" rowspan="1">
              Tool OD (inches)
            </td>
            <td class="c190" colspan="1" rowspan="1">
              Tool ID (inches)
            </td>
            <td class="c294" colspan="1" rowspan="1">
              Thread Connections Box Up / Pin Down
            </td>
            <td class="c304" colspan="1" rowspan="1">
              Part Number
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="c31">
            <td class="c206" colspan="1" rowspan="1">
              4-3/4
            </td>
            <td class="c145" colspan="1" rowspan="1">
              5.469
            </td>
            <td class="c190" colspan="1" rowspan="1">
              1.50
            </td>
            <td class="c294" colspan="1" rowspan="1">
              3-1/2 IF TOOL JOINT
            </td>
            <td class="c304" colspan="1" rowspan="1">
              68210
            </td>
          </tr>
          <tr class="c31">
            <td class="c206" colspan="1" rowspan="1">
              6-1/8
            </td>
            <td class="c145" colspan="1" rowspan="1">
              6.125
            </td>
            <td class="c190" colspan="1" rowspan="1">
              2.00
            </td>
            <td class="c294" colspan="1" rowspan="1">
              4-1/2 IF TOOL JOINT
            </td>
            <td class="c304" colspan="1" rowspan="1">
              68220
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default StormValveTable;
