import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";

function CookieConsentt() {
  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText="Got it"
        cookieName="MaverickCookie"
        style={{ background: "#2B373B" }}
        buttonStyle={{
          color: "white",
          fontSize: "13px",
          backgroundColor: "#0099cd",
        }}
        expires={150}
      >
        <p className="body-text white" style={{ margin: "0" }}>
          Our website uses cookies. By continuing, you consent to the use of
          cookies. Read our{" "}
          <Link
            to="privacy-policy"
            className="body-text"
            style={{ textDecoration: "underline" }}
          >
            privacy policy here.
          </Link>{" "}
        </p>
      </CookieConsent>
    </div>
  );
}

export default CookieConsentt;
