import React from 'react'
import {$,jQuery} from 'jquery';

function ElOnOffToolTable() {
    
  return (
    <div>  
        <table id="example" class="table table-striped table-bordered" cellspacing="0" width="100%">
        <thead>
            <tr>
                <th colSpan={2}>Casing Size</th>
                <th colSpan={2}>Max OD</th>
                <th colSpan={2}>Min ID</th>
                <th colSpan={2}>Thread Connection</th>
            </tr>
        </thead>
 
        <tbody>
            <tr style={{backgroundColor: '#BCBEC0'}}>
                <td>in.</td>
                <td>mm</td>
               <td>in.</td>
               <td>mm</td>
               <td>in.</td>
               <td>mm</td>
               <td>in.</td>
               <td>mm</td>
            </tr>
            <tr>
                <td>1.660</td>
                <td>42.2</td>
               <td>2.220</td>
               <td>56.4</td>
               <td>1.375</td>
               <td>34.9</td>
               <td>1.660 EUE</td>
               <td>42.4</td>
            </tr>
            <tr>
                <td>1.900</td>
                <td>48.3</td>
               <td>2.500</td>
               <td>63.5</td>
               <td>1.500</td>
               <td>38.1</td>
               <td>1.900 EUE</td>
               <td>48.3</td>
            </tr>
            <tr>
                <td>2 <sup>3/8</sup></td>
                <td>60.3</td>
               <td>3.062</td>
               <td>77.8</td>
               <td>2.000</td>
               <td>50.8</td>
               <td>2 <sup>3/8</sup> EUE</td>
               <td>60.3</td>
            </tr>
            <tr>
                <td>2 <sup>7/8</sup></td>
                <td>73.0</td>
               <td>3.688</td>
               <td>93.7</td>
               <td>2.500</td>
               <td>63.5</td>
               <td>2 <sup>7/8</sup> EUE</td>
               <td>73.0</td>
            </tr>
            <tr>
                <td>3 <sup>1/2</sup></td>
                <td>88.9</td>
               <td>4.500</td>
               <td>114.3</td>
               <td>3.000</td>
               <td>76.2</td>
               <td>3 <sup>1/2</sup> EUE</td>
               <td>88.9</td>
            </tr>
        </tbody>
        </table>
    </div>
  )
}

export default ElOnOffToolTable