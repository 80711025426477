import React from "react";
import { $, jQuery } from "jquery";

function BurstDiscSubTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c18">
            <td class="c39" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c11">Size</span>
              </p>
            </td>
            <td class="c39" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c11">OD</span>
              </p>
            </td>
            <td class="c41" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c11">ID</span>
              </p>
            </td>
            <td class="c20" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c11">Disc Rating Above</span>
              </p>
            </td>
            <td class="c20" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c11">Disc Rating Below</span>
              </p>
            </td>
            <td class="c20" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c11">Shear Pressure Maximum</span>
              </p>
            </td>
            <td class="c40" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c11">Temperature Rating</span>
              </p>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              in <br />mm
            </td>
            <td>
              in <br />mm
            </td>
            <td>
              in <br />mm
            </td>
            <td>
              psi <br />MPa
            </td>
            <td>
              psi <br />MPa
            </td>
            <td>
              psi <br />MPa
            </td>
            <td>
            &deg;C <br />&deg;F
            </td>
          </tr>
          <tr>
            <td>2.375<br />60.3</td>
            <td>3.300<br />83.82</td>
            <td>1.936<br />49.17</td>
            <td>12,660<br />87.3</td>
            <td>15,000<br />104.8</td>
            <td>12,660<br />87.9</td>
            <td>320<br />160</td>
          </tr>
          <tr>
            <td>2.875<br />73.0</td>
            <td>3.975<br />101.97</td>
            <td>2.377<br />60.38</td>
            <td>16,150<br />109.6</td>
            <td>15,000<br />104.8</td>
            <td>16,150<br />111.3</td>
            <td>320<br />160</td>
          </tr>
          <tr>
            <td>3.500<br />88.9</td>
            <td>4.500<br />114.3</td>
            <td>2.867<br />72.82</td>
            <td>15,900<br />109.6</td>
            <td>15,000<br />104.8</td>
            <td>15,900<br />109.6</td>
            <td>320<br />160</td>
          </tr>
          <tr>
            <td>4.500<br />114.3</td>
            <td>5.750<br />146.05</td>
            <td>3.930<br />99.82</td>
            <td>15,000<br />104.8</td>
            <td>15,000<br />104.8</td>
            <td>15,000<br />104.8</td>
            <td>320<br />160</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default BurstDiscSubTable;
