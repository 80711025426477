import React from "react";
import { $, jQuery } from "jquery";

function Es3Table() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr>
            <th colspan="2">CASING SIZE</th>
            <th colspan="2">CASIGN WEIGHT</th>
            <th colspan="2">OD OF TOOL</th>
            <th colspan="2">THREAD CONNECTION</th>
          </tr>
        </thead>
        <tbody>
        <tr>
          <td>in.</td>
          <td>mm</td>
          <td>lbs/ft</td>
          <td>kg/m</td>
          <td>in.</td>
          <td>mm</td>
          <td>in.</td>
          <td>mm</td>
        </tr>
        <tr>
          <td>4<sup>1/2</sup></td>
          <td>114.3</td>
          <td>15.1-16.6</td>
          <td>22.3-24.6</td>
          <td>3.594</td>
          <td>91.29</td>
          <td>2 3/8 EUE</td>
          <td>60.3</td>
        </tr>
        <tr>
          <td>4<sup>1/2</sup></td>
          <td>114.3</td>
          <td>9.5-13.5</td>
          <td>14.1-20.1</td>
          <td>3.750</td>
          <td>95.25</td>
          <td>2 3/8 EUE</td>
          <td>60.3</td>
        </tr>
        <tr>
          <td>5</td>
          <td>127.0</td>
          <td>18.0-20.8</td>
          <td>26.82-13.9</td>
          <td>4.000</td>
          <td>101.60</td>
          <td>2 3/8 EUE</td>
          <td>60.3</td>
        </tr>
        <tr>
          <td>5</td>
          <td>127.0</td>
          <td>11.5-15.0</td>
          <td>17.1-22.3</td>
          <td>4.125</td>
          <td>104.78</td>
          <td>2 3/8 EUE</td>
          <td>60.3</td>
        </tr>
        <tr>
          <td>5 <sub>1/2</sub></td>
          <td>139.7</td>
          <td>20.0-23.0</td>
          <td>29.8-34.2</td>
          <td>4.500</td>
          <td>114.30</td>
          <td>2 3/8 EUE</td>
          <td>60.3</td>
        </tr>
        <tr>
          <td>5 <sub>1/2</sub></td>
          <td>139.7</td>
          <td>13.0-20.0</td>
          <td>19.4-29.8</td>
          <td>4.625</td>
          <td>117.48</td>
          <td>2 3/8 EUE</td>
          <td>60.3</td>
        </tr>
        <tr>
          <td>5 <sub>1/2</sub></td>
          <td>139.7</td>
          <td>20.0-23.0</td>
          <td>29.8-34.2</td>
          <td>4.500</td>
          <td>114.30</td>
          <td>2 7/8 EUE</td>
          <td>73.0</td>
        </tr>
        <tr>
          <td>5 <sub>1/2</sub></td>
          <td>139.7</td>
          <td>15.5-20.0</td>
          <td>23.1-29.8</td>
          <td>4.625</td>
          <td>117.48</td>
          <td>2 7/8 EUE</td>
          <td>73.0</td>
        </tr>
        <tr>
          <td>5 <sub>1/2</sub></td>
          <td>139.7</td>
          <td>13.0-14.0</td>
          <td>19.2-20.8</td>
          <td>4.781</td>
          <td>121.44</td>
          <td>2 7/8 EUE</td>
          <td>73.0</td>
        </tr>
        <tr>
          <td>7</td>
          <td>177.8</td>
          <td>17.0-26.0</td>
          <td>25.3-38.7</td>
          <td>6.000</td>
          <td>152.40</td>
          <td>2 7/8 EUE</td>
          <td>73.0</td>
        </tr>
        <tr>
          <td>7</td>
          <td>177.8</td>
          <td>26.0-35.0</td>
          <td>38.7-51.8</td>
          <td>5.875</td>
          <td>149.23</td>
          <td>2 7/8 EUE</td>
          <td>73.0</td>
        </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Es3Table;
