import React from 'react'
import {$,jQuery} from 'jquery';

function WirelineAdapterKitVsiTable() {
    
  return (
    <div>  
        <table id="example" class="table table-striped table-bordered" cellspacing="0" width="100%">
        <thead>
            <tr>
                <th>Casing Size (Inches)</th>
                <th style={{backgroundColor: 'black', width: '60%' }}>Description</th>
                <th>Part Number</th>
            </tr>
        </thead>
        <tbody>
           <tr>
                <td>2-7/8</td>
                <td>For 2-7/8” VSI-XW Packer</td>
                <td>97125</td>
           </tr>
           <tr>
                <td rowSpan={2}>3-1/2</td>
                <td>For #5 Hydraulic Setting Tool</td>
                <td>97130</td>
           </tr>
           <tr>
            <td>For 3-1/2” VSI-XW Packer (12.95#)</td>
            <td>97137</td>
           </tr>
           <tr>
                <td>4</td>
                <td>For 4” VSI-XW Packer</td>
                <td>97140</td>
           </tr>
           <tr>
                <td rowSpan={3}>4-1/2</td>
                <td>For 4-1/2” VSI-XW Packer</td>
                <td>97145</td>
           </tr>
           <tr>
                <td>For 4-1/2” VSI-XW Packer (13.5-15.1#) Short Version</td>
                <td>9714-SHORT</td>
           </tr>
           <tr>
                <td>For 4-1/2” VSI-XW Packer (15.1#)</td>
                <td>97144</td>
           </tr>
           <tr>
                <td rowSpan={5}>5-1/2</td>
                <td>For 5-1/2” X 2-3/8” VSI-XW Packer*</td>
                <td>97155*</td>
           </tr>
           <tr>
                <td>For 5-1/2” X 2-7/8” VSI-XW Packer (23.0 - 26.0#)</td>
                <td>97151</td>
           </tr>
           <tr>
                <td>For 5-1/2” X 2-7/8” VSI-XW Packer (23.0 - 26.0#) *</td>
                <td>97151-1*</td>
           </tr>
           <tr>
                <td>For 5-1/2”, 6-5/8”, 7”, 7-5/8” X 2-7/8” VSI-XW Packer</td>
                <td>97156</td>
           </tr>
           <tr>
                <td>For: 5-1/2”, 7”, 7-5/8” X 2-7/8” VSI-XW Packer Short Version*</td>
                <td>97156-SHORT*</td>
           </tr>
           <tr>
                <td>7</td>
                <td>For 7” X 3-1/2” VSI-XW Packer</td>
                <td>97174</td>
           </tr>
           <tr>
                <td rowSpan={3}>9-5/8</td>
                <td>For 8-5/8” & 9-5/8” X 2-7/8” VSI-XW Packer</td>
                <td>97196-BAC</td>
           </tr>
           <tr>
                <td>For 9-5/8” X 3-1/2” VSI-XW Packer</td>
                <td>97195</td>
           </tr>
           <tr>
                <td>For 8-5/8” & 9-5/8” X 4-1/2” VSI-XW Packer</td>
                <td>97196</td>
           </tr>
           <tr>
                <td>10-3/4</td>
                <td>For 8-5/8” Through 10-3/4” X 4-1/2” VSI-XW Packer</td>
                <td>97196</td>
           </tr>
        </tbody>
        </table>
    </div>
  )
}

export default WirelineAdapterKitVsiTable