import React from "react";
import { $, jQuery } from "jquery";

function RSBPDualTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c39">
            <td class="c30" colspan="2" rowspan="1">
              <p class="c5">
                <span class="c14">Casing </span>
              </p>
            </td>
            <td class="c12" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c14">Recommended </span>
              </p>
              <p class="c5">
                <span class="c14">Hole Size </span>
              </p>
              <p class="c5">
                <span class="c14">(inches)</span>
              </p>
            </td>
            <td class="c13" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c14">Tool OD </span>
              </p>
              <p class="c5">
                <span class="c14">(inches)</span>
              </p>
            </td>
            <td class="c7" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c14">Packer </span>
              </p>
              <p class="c73">
                <span class="c14">Bore &nbsp;</span>
              </p>
              <p class="c5">
                <span class="c14">(inches)</span>
              </p>
            </td>
            <td class="c6" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c14">Upper </span>
              </p>
              <p class="c5">
                <span class="c14">Seal Bore </span>
              </p>
              <p class="c5">
                <span class="c14">(inches)</span>
              </p>
            </td>
            <td class="c27" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c14">Part </span>
              </p>
              <p class="c5">
                <span class="c14">Number</span>
              </p>
            </td>
          </tr>
          <tr class="c45">
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c70">Size </span>
                <span class="c14">(inches)</span>
              </p>
            </td>
            <td class="c35" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c14">Weight </span>
              </p>
              <p class="c5">
                <span class="c14">(lbs/ft)</span>
              </p>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="c28">
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">4-1/2 </span>
              </p>
            </td>
            <td class="c35" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">9.5 - 13.5 </span>
              </p>
            </td>
            <td class="c12" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">3.920 - 4.090 </span>
              </p>
            </td>
            <td class="c13" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">3.750 </span>
              </p>
            </td>
            <td class="c7" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">2.062 </span>
              </p>
            </td>
            <td class="c6" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">2.688 </span>
              </p>
            </td>
            <td class="c27" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">26845</span>
              </p>
            </td>
          </tr>
          <tr class="c28">
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">5 </span>
              </p>
            </td>
            <td class="c35" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">15.0 - 18.0 </span>
              </p>
            </td>
            <td class="c12" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">4.276 - 4.408 </span>
              </p>
            </td>
            <td class="c13" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">4.000 </span>
              </p>
            </td>
            <td class="c7" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">2.688 </span>
              </p>
            </td>
            <td class="c6" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">3.000 </span>
              </p>
            </td>
            <td class="c27" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">26850</span>
              </p>
            </td>
          </tr>
          <tr class="c28">
            <td class="c18" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c9">5-1/2</span>
              </p>
            </td>
            <td class="c35" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">13.0 - 20.0 </span>
              </p>
            </td>
            <td class="c12" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">4.778 - 5.044 </span>
              </p>
            </td>
            <td class="c13" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">4.625 </span>
              </p>
            </td>
            <td class="c7" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">3.000 </span>
              </p>
            </td>
            <td class="c6" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">4.000 </span>
              </p>
            </td>
            <td class="c27" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">26855</span>
              </p>
            </td>
          </tr>
          <tr class="c28">
            <td class="c35" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">20.0 - 23.0 </span>
              </p>
            </td>
            <td class="c12" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">4.670 - 4.778 </span>
              </p>
            </td>
            <td class="c13" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">4.500 </span>
              </p>
            </td>
            <td class="c7" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">3.000 </span>
              </p>
            </td>
            <td class="c6" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">4.000 </span>
              </p>
            </td>
            <td class="c27" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">26857</span>
              </p>
            </td>
          </tr>
          <tr class="c28">
            <td class="c18" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c9">7</span>
              </p>
            </td>
            <td class="c35" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">17.0 - 26.0 </span>
              </p>
            </td>
            <td class="c12" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">6.276 - 6.538 </span>
              </p>
            </td>
            <td class="c13" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">5.938 </span>
              </p>
            </td>
            <td class="c7" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">4.000 </span>
              </p>
            </td>
            <td class="c6" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">5.250 </span>
              </p>
            </td>
            <td class="c27" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">26870</span>
              </p>
            </td>
          </tr>
          <tr class="c28">
            <td class="c35" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">26.0 - 32.0 </span>
              </p>
            </td>
            <td class="c12" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">6.094 - 6.276 </span>
              </p>
            </td>
            <td class="c13" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">5.875 </span>
              </p>
            </td>
            <td class="c7" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">4.000 </span>
              </p>
            </td>
            <td class="c6" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">5.250 </span>
              </p>
            </td>
            <td class="c27" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">26871</span>
              </p>
            </td>
          </tr>
          <tr class="c28">
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">7-5/8 </span>
              </p>
            </td>
            <td class="c35" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">24.0 - 29.7 </span>
              </p>
            </td>
            <td class="c12" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">6.875 - 7.025 </span>
              </p>
            </td>
            <td class="c13" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">6.672 </span>
              </p>
            </td>
            <td class="c7" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">4.000 </span>
              </p>
            </td>
            <td class="c6" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">4.750 </span>
              </p>
            </td>
            <td class="c27" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">26875</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default RSBPDualTable;
