import React from "react";
import { $, jQuery } from "jquery";

function RSBPSingleTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c39">
            <td class="c4" colspan="2" rowspan="1">
              <p class="c5">
                <span class="c14">Casing </span>
              </p>
            </td>
            <td class="c10" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c14">Recommended </span>
              </p>
              <p class="c5">
                <span class="c14">Hole Size </span>
              </p>
              <p class="c5">
                <span class="c14">(inches)</span>
              </p>
            </td>
            <td class="c37" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c14">Tool OD </span>
              </p>
              <p class="c5">
                <span class="c14">(inches)</span>
              </p>
            </td>
            <td class="c24" colspan="1" rowspan="2">
              <p class="c78">
                <span class="c14">Packer &nbsp;</span>
              </p>
              <p class="c5">
                <span class="c14">Bore </span>
              </p>
              <p class="c5">
                <span class="c14">(inches)</span>
              </p>
            </td>
            <td class="c25" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c14">Min ID </span>
              </p>
              <p class="c5">
                <span class="c14">Thru Seals </span>
              </p>
              <p class="c5">
                <span class="c14">(inches)</span>
              </p>
            </td>
            <td class="c16" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c14">Part </span>
              </p>
              <p class="c5">
                <span class="c14">Number</span>
              </p>
            </td>
          </tr>
          <tr class="c45">
            <td class="c17" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c70">Size </span>
                <span class="c14">(inches)</span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c14">Weight </span>
              </p>
              <p class="c5">
                <span class="c14">(lbs/ft)</span>
              </p>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="c0">
            <td class="c17" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">5 </span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">15.0 - 18.0 </span>
              </p>
            </td>
            <td class="c10" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">4.276 - 4.408 </span>
              </p>
            </td>
            <td class="c37" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">4.000 </span>
              </p>
            </td>
            <td class="c24" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">2.688 </span>
              </p>
            </td>
            <td class="c25" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">1.940 </span>
              </p>
            </td>
            <td class="c16" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">26550</span>
              </p>
            </td>
          </tr>
          <tr class="c0">
            <td class="c17" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c9">5-1/2 </span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c9">13.0 - 20.0 </span>
              </p>
            </td>
            <td class="c10" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c9">4.778 - 5.044 </span>
              </p>
            </td>
            <td class="c37" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c9">4.625</span>
              </p>
            </td>
            <td class="c24" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">2.688 </span>
              </p>
            </td>
            <td class="c25" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">1.940 </span>
              </p>
            </td>
            <td class="c16" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">26055</span>
              </p>
            </td>
          </tr>
          <tr class="c0">
            <td class="c24" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">3.000 </span>
              </p>
            </td>
            <td class="c25" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">2.380 </span>
              </p>
            </td>
            <td class="c16" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">26555</span>
              </p>
            </td>
          </tr>
          <tr class="c0">
            <td class="c17" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">6-5/8 </span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">20.0 - 32.0 </span>
              </p>
            </td>
            <td class="c10" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">5.675 - 6.049 </span>
              </p>
            </td>
            <td class="c37" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">5.468 </span>
              </p>
            </td>
            <td class="c24" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">3.250 </span>
              </p>
            </td>
            <td class="c25" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">2.410 </span>
              </p>
            </td>
            <td class="c16" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">26565</span>
              </p>
            </td>
          </tr>
          <tr class="c0">
            <td class="c17" colspan="1" rowspan="7">
              <p class="c5">
                <span class="c9">7</span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c9">17.0 - 20.0 </span>
              </p>
            </td>
            <td class="c10" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c9">6.456 - 6.538 </span>
              </p>
            </td>
            <td class="c37" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c9">6.125</span>
              </p>
            </td>
            <td class="c24" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">3.000 </span>
              </p>
            </td>
            <td class="c25" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">2.380 </span>
              </p>
            </td>
            <td class="c16" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">26071</span>
              </p>
            </td>
          </tr>
          <tr class="c0">
            <td class="c24" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">4.000 </span>
              </p>
            </td>
            <td class="c25" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">3.000 </span>
              </p>
            </td>
            <td class="c16" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">26571</span>
              </p>
            </td>
          </tr>
          <tr class="c0">
            <td class="c18" colspan="1" rowspan="3">
              <p class="c5">
                <span class="c9">23.0 - 29.0 </span>
              </p>
            </td>
            <td class="c10" colspan="1" rowspan="3">
              <p class="c5">
                <span class="c9">6.184 - 6.366 </span>
              </p>
            </td>
            <td class="c37" colspan="1" rowspan="3">
              <p class="c5">
                <span class="c9">5.938</span>
              </p>
            </td>
            <td class="c24" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">3.000 </span>
              </p>
            </td>
            <td class="c25" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">2.380 </span>
              </p>
            </td>
            <td class="c16" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">26070</span>
              </p>
            </td>
          </tr>
          <tr class="c0">
            <td class="c24" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">3.250 </span>
              </p>
            </td>
            <td class="c25" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">2.420 </span>
              </p>
            </td>
            <td class="c16" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">26073</span>
              </p>
            </td>
          </tr>
          <tr class="c0">
            <td class="c24" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">4.000 </span>
              </p>
            </td>
            <td class="c25" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">3.000 </span>
              </p>
            </td>
            <td class="c16" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">26574</span>
              </p>
            </td>
          </tr>
          <tr class="c0">
            <td class="c18" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c9">32.0 - 35.0 </span>
              </p>
            </td>
            <td class="c10" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c9">6.004 - 6.094 </span>
              </p>
            </td>
            <td class="c37" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c9">5.812</span>
              </p>
            </td>
            <td class="c24" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">3.000 </span>
              </p>
            </td>
            <td class="c25" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">2.380 </span>
              </p>
            </td>
            <td class="c16" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">26072</span>
              </p>
            </td>
          </tr>
          <tr class="c0">
            <td class="c24" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">4.000 </span>
              </p>
            </td>
            <td class="c25" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">3.000 </span>
              </p>
            </td>
            <td class="c16" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">26572</span>
              </p>
            </td>
          </tr>
          <tr class="c0">
            <td class="c17" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c9">7-5/8 </span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c9">33.7 - 39.0 </span>
              </p>
            </td>
            <td class="c10" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c9">6.625 - 6.765 </span>
              </p>
            </td>
            <td class="c37" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c9">6.375</span>
              </p>
            </td>
            <td class="c24" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">3.000 </span>
              </p>
            </td>
            <td class="c25" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">2.380 </span>
              </p>
            </td>
            <td class="c16" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">26078</span>
              </p>
            </td>
          </tr>
          <tr class="c0">
            <td class="c24" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">4.000 </span>
              </p>
            </td>
            <td class="c25" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">3.000 </span>
              </p>
            </td>
            <td class="c16" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">26578</span>
              </p>
            </td>
          </tr>
          <tr class="c0">
            <td class="c17" colspan="1" rowspan="4">
              <p class="c5">
                <span class="c9">8-5/8</span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c9">24.0 - 40.0 </span>
              </p>
            </td>
            <td class="c10" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c9">7.725 - 8.097 </span>
              </p>
            </td>
            <td class="c37" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c9">7.500</span>
              </p>
            </td>
            <td class="c24" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">4.000 </span>
              </p>
            </td>
            <td class="c25" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">3.000 </span>
              </p>
            </td>
            <td class="c16" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">26085</span>
              </p>
            </td>
          </tr>
          <tr class="c0">
            <td class="c24" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">4.750 </span>
              </p>
            </td>
            <td class="c25" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">4.000 </span>
              </p>
            </td>
            <td class="c16" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">26585</span>
              </p>
            </td>
          </tr>
          <tr class="c0">
            <td class="c18" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c9">40.0 - 49.0 </span>
              </p>
            </td>
            <td class="c10" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c9">7.511 - 7.725 </span>
              </p>
            </td>
            <td class="c37" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c9">7.312</span>
              </p>
            </td>
            <td class="c24" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">4.000 </span>
              </p>
            </td>
            <td class="c25" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">3.000 </span>
              </p>
            </td>
            <td class="c16" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">26086</span>
              </p>
            </td>
          </tr>
          <tr class="c0">
            <td class="c24" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">4.750 </span>
              </p>
            </td>
            <td class="c25" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">4.000 </span>
              </p>
            </td>
            <td class="c16" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">26586</span>
              </p>
            </td>
          </tr>
          <tr class="c0">
            <td class="c17" colspan="1" rowspan="3">
              <p class="c5">
                <span class="c9">9-5/8</span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">40.0 - 47.0 </span>
              </p>
            </td>
            <td class="c10" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">8.681 - 8.835 </span>
              </p>
            </td>
            <td class="c37" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">8.500 </span>
              </p>
            </td>
            <td class="c24" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">6.000 </span>
              </p>
            </td>
            <td class="c25" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">4.750 </span>
              </p>
            </td>
            <td class="c16" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">26596</span>
              </p>
            </td>
          </tr>
          <tr class="c0">
            <td class="c18" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c9">43.5 - 53.5 </span>
              </p>
            </td>
            <td class="c10" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c9">8.535 - 8.755 </span>
              </p>
            </td>
            <td class="c37" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c9">8.250</span>
              </p>
            </td>
            <td class="c24" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">4.750 </span>
              </p>
            </td>
            <td class="c25" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">3.500 </span>
              </p>
            </td>
            <td class="c16" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">26094</span>
              </p>
            </td>
          </tr>
          <tr class="c0">
            <td class="c24" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">6.000 </span>
              </p>
            </td>
            <td class="c25" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">4.750 </span>
              </p>
            </td>
            <td class="c16" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">26595</span>
              </p>
            </td>
          </tr>
          <tr class="c0">
            <td class="c17" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">13-3/8 </span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">54.5 - 68.0 </span>
              </p>
            </td>
            <td class="c10" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">12.415 - 12.615 </span>
              </p>
            </td>
            <td class="c37" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">12.250 </span>
              </p>
            </td>
            <td class="c24" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">9.000 </span>
              </p>
            </td>
            <td class="c25" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">8.000 </span>
              </p>
            </td>
            <td class="c16" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c9">26513</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default RSBPSingleTable;
