import React from "react";
import AlphaCSS from "./alpha.css";
import AlphaLogo from "../../assets/logos/alpha.webp";
import { Helmet } from "react-helmet";

function AlphaPage() {
  return (
    <div className="alpha">
      <Helmet>
        ‍
        <title>{`Alpha Oil Tools | Maverick Downhole Technologies | Edmonton, AB`}</title>
        {/* <meta property="og:image" content={ElOnOffToolImg} /> */}
        <meta name="keywords" content="Alpha, oil tools" />
        <meta name="description" content=" Alpha Oil Tools designs and manufactures bridge plugs, cement retainers,
        packers, and accessories for the domestic US/Canada and international
        markets. Alpha Oil Tools is headquartered in Fort Worth, TX." /> 
        <script type="application/ld+json">{JSON.stringify({})}</script>
      </Helmet>
      <img
        src={AlphaLogo}
        alt="Alpha Oil Tools"
        title="Alpha Tools"
        className="alpha-logo"
      />
      <h4 className="smaller-head centre">
        Canada’s Exclusive Stocking Distributor for
      </h4>
      <h2 className="main-subhead centre">
        <span class="bold-head">Alpha Oil Tools</span>
      </h2>

      <div className="items">
        <ul className="alpha-tools">
            <li className="body-text">
            Premium Cast Iron Cement Retainers and Bridge Plugs
            </li>
            <li className="body-text">Available in 6k and 10K. 2 3/8” – 13 3/8”</li>
            <li className="body-text">Thermal option available (Inferno Series)</li>
            <li className="body-text">Alpha Mechanical and Wireline Retainers utilize proprietary premium valve reducing washouts.</li>
            <li className="body-text">Maverick/Alpha Bridge Plugs run on either HM or Wireline setting tools reducing inventories.</li>
            <li className="body-text">Alpha Wireline set only Bridge Plug for reliable cost-effective abandonments</li>
            <li className="body-text">Compatible with most manufactures setting Tools and Accessories</li>
            <li className="body-text">Maverick Setting Tools and Accessories available and stocked</li>
        </ul>
      </div>

      <div className="about-alpha">
        <h3 className="smaller-head centre">About Alpha Oil Tools</h3>
        <p className="body-text centre">
        Alpha Oil Tools designs and manufactures bridge plugs, cement retainers,
        packers, and accessories for the domestic US/Canada and international
        markets. Alpha Oil Tools is headquartered in Fort Worth, TX. <br />
        <br />
        Alpha Oil Tools has been a mainstay in the downhole tools and
        accessories sector since its founding in 1981, becoming a part of
        Weatherford International in 2005, returning to private ownership in
        December 2018 as the cornerstone of Sharp Energy Group.</p>
        <br />
        <a href="mailto:sales@maverickdownhole.com">
          <button className="main-btn">Contact Sales</button>
          <br />
          <br />
        </a>
      </div>
    </div>
  );
}

export default AlphaPage;
