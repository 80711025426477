import React from 'react'
import {$,jQuery} from 'jquery';

function ElOnOffToolTable() {
    
  return (
    <div>  
        <table id="example" class="table table-striped table-bordered" cellspacing="0" width="100%">
        <thead>
            <tr style={{backgroundColor: '#BCBEC0', color: 'black'}}>
                <th colSpan={2} style={{backgroundColor: 'white'}}></th>
                <th colSpan={4}>Type X</th>
                <th colSpan={6}>Type XN</th>
            </tr>
            <tr>
                <th colSpan={2}>Tubing OD</th>
                <th colSpan={2}>Seal Bore</th>
                <th colSpan={2}>Min OD</th>
                <th colSpan={2}>Seal Bore</th>
                <th colSpan={2}>No-GO-ID</th>
                <th colSpan={2}>Min OD</th>
            </tr>
        </thead>
 
        <tbody>
            <tr style={{backgroundColor: '#BCBEC0'}}>
                <td>in.</td>
                <td>mm</td>
               <td>in.</td>
               <td>mm</td>
               <td>in.</td>
               <td>mm</td>
               <td>in.</td>
               <td>mm</td>
               <td>in.</td>
               <td>mm</td>
               <td>in.</td>
               <td>mm</td>
            </tr>
            <tr>
                <td>1.660</td>
                <td>42.15</td>
               <td>1.187</td>
               <td>30.15</td>
               <td>1.875</td>
               <td>47.63</td>
               <td>1.187</td>
               <td>30.15</td>
               <td>1.135</td>
               <td>28.83</td>
               <td>1.875</td>
               <td>47.63</td>
            </tr>
            <tr>
                <td>1.660</td>
                <td>42.15</td>
               <td>1.250</td>
               <td>31.75</td>
               <td>1.875</td>
               <td>47.63</td>
               <td>1.250</td>
               <td>31.75</td>
               <td>1.198</td>
               <td>30.43</td>
               <td>1.875</td>
               <td>47.63</td>
            </tr>
            <tr>
                <td>1.900</td>
                <td>48.26</td>
               <td>1.437</td>
               <td>36.50</td>
               <td>2.109</td>
               <td>53.57</td>
               <td>1.437</td>
               <td>36.50</td>
               <td>1.385</td>
               <td>35.18</td>
               <td>2.109</td>
               <td>53.57</td>
            </tr>
            <tr>
                <td>1.900</td>
                <td>48.26</td>
               <td>1.500</td>
               <td>38.10</td>
               <td>2.109</td>
               <td>53.57</td>
               <td>1.500</td>
               <td>38.10</td>
               <td>1.447</td>
               <td>36.75</td>
               <td>2.109</td>
               <td>53.57</td>
            </tr>
            <tr>
                <td>2.063</td>
                <td>53.37</td>
               <td>1.563</td>
               <td>39.67</td>
               <td>2.250</td>
               <td>57.15</td>
               <td>1.562</td>
               <td>39.67</td>
               <td>1.510</td>
               <td>38.35</td>
               <td>2.250</td>
               <td>57.15</td>
            </tr>
            <tr>
                <td>2.063</td>
                <td>53.37</td>
               <td>1.625</td>
               <td>41.28</td>
               <td>2.250</td>
               <td>57.15</td>
               <td>1.625</td>
               <td>41.28</td>
               <td>1.572</td>
               <td>39.93</td>
               <td>2.250</td>
               <td>57.15</td>
            </tr>
            <tr>
                <td>2.375</td>
                <td>60.33</td>
               <td>1.781</td>
               <td>45.24</td>
               <td>2.560</td>
               <td>65.02</td>
               <td>1.781</td>
               <td>45.24</td>
               <td>1.728</td>
               <td>43.89</td>
               <td>2.560</td>
               <td>65.02</td>
            </tr>
            <tr>
                <td>2.375</td>
                <td>60.33</td>
               <td>1.812</td>
               <td>46.02</td>
               <td>2.560</td>
               <td>65.02</td>
               <td>1.812</td>
               <td>46.02</td>
               <td>1.760</td>
               <td>44.70</td>
               <td>2.560</td>
               <td>65.02</td>
            </tr>
            <tr>
                <td>2.375</td>
                <td>60.33</td>
               <td>1.875</td>
               <td>47.63</td>
               <td>2.560</td>
               <td>65.02</td>
               <td>1.875</td>
               <td>47.63</td>
               <td>1.822</td>
               <td>46.28</td>
               <td>2.560</td>
               <td>65.02</td>
            </tr>
            <tr>
                <td>2.875</td>
                <td>73.03</td>
               <td>2.250</td>
               <td>57.15</td>
               <td>3.109</td>
               <td>78.97</td>
               <td>2.250</td>
               <td>57.15</td>
               <td>2.197</td>
               <td>55.80</td>
               <td>3.109</td>
               <td>78.97</td>
            </tr>
            <tr>
                <td>2.875</td>
                <td>73.03</td>
               <td>2.312</td>
               <td>58.72</td>
               <td>3.109</td>
               <td>78.97</td>
               <td>2.312</td>
               <td>58.72</td>
               <td>2.259</td>
               <td>57.38</td>
               <td>3.109</td>
               <td>78.97</td>
            </tr>
            <tr>
                <td>3.500</td>
                <td>88.90</td>
               <td>2.750</td>
               <td>69.85</td>
               <td>3.687</td>
               <td>93.65</td>
               <td>2.750</td>
               <td>69.85</td>
               <td>2.697</td>
               <td>68.50</td>
               <td>3.687</td>
               <td>93.65</td>
            </tr>
            <tr>
                <td>3.500</td>
                <td>88.90</td>
               <td>2.812</td>
               <td>71.42</td>
               <td>3.687</td>
               <td>93.65</td>
               <td>2.812</td>
               <td>71.42</td>
               <td>2.759</td>
               <td>70.09</td>
               <td>3.687</td>
               <td>93.65</td>
            </tr>
            <tr>
                <td>4.500</td>
                <td>114.30</td>
               <td>3.750</td>
               <td>95.25</td>
               <td>5.200</td>
               <td>132.08</td>
               <td>3.750</td>
               <td>95.25</td>
               <td>3.700</td>
               <td>93.98</td>
               <td>5.200</td>
               <td>132.08</td>
            </tr>
            <tr>
                <td>4.500</td>
                <td>114.30</td>
               <td>3.812</td>
               <td>96.82</td>
               <td>5.200</td>
               <td>132.08</td>
               <td>3.812</td>
               <td>96.82</td>
               <td>3.759</td>
               <td>95.48</td>
               <td>5.200</td>
               <td>132.08</td>
            </tr>
        </tbody>
        </table>
    </div>
  )
}

export default ElOnOffToolTable