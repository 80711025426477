import React, { useEffect, useState } from "react";
import { getAllProductService } from "../../../services/dept";
import { Helmet } from 'react-helmet';
import JsPDF from 'jspdf';
import Img from '../../../assets/images/product-images/retrievable-packers/es1x.jpg'
import Es1xTable from './Tables/Es1xTable'

function ES1X(props) {

  const generatePDF = () => {

    const report = new JsPDF('portrait','pt','a4');
    report.html(document.querySelector('.product-info')).then(() => {
        report.save(`${title}.pdf`);
    });
  }

  const { title } = props;
  const [products, setProducts] = useState([]);
  const [currentProduct, setCurrentProduct] = useState({});

  useEffect(() => {
    getAllProductService().then((loadedProducts) => {
      setProducts(loadedProducts);
      setCurrentProduct(
        loadedProducts.find((product) => product.title === title)
      );
    });
  }, []);

  // console.log(currentProduct);
  return (
    <div className="product-info">
      <Helmet>‍
      <title>{`${title} | Maverick Downhole Technologies | Edmonton, AB`}</title>
      <script type="application/ld+json">
      {JSON.stringify({
      
      })}
      </script>
      </Helmet>
      <div>
        <h3 className="main-subhead">
          {currentProduct !== {} ? currentProduct.title : null}
        </h3>
        <h4 className="smaller-head">
          {currentProduct !== {} ? currentProduct.subhead : null}
        </h4>
        <div className="spacer"></div>
        <p className="body-text">
          {currentProduct !== {} ? currentProduct.description : null}
        </p>
        <button onClick={generatePDF} type="button" className="main-btn">Download PDF</button><br /><br />
        <Es1xTable />
      </div>
      <div>
        <img
          src={Img}
          title={currentProduct.title}
          alt={currentProduct.title}
          className="product-img"
        />
      </div>
    </div>
  );
}

export default ES1X;
