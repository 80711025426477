import React from "react";
import ContactForm from "../../components/ContactForm/ContactForm";
import { useEffect, useState } from "react";
import { getAllProductService } from "../../services/dept";
import { getRetrievablePackerTitlesService } from "../../services/dept";
import GeneralBtn from "../../components/ColumnBtns/GeneralBtn";
import MechanicalPackerHeader from '../../components/Headers/MechanicalPackerHeader'
import { Helmet } from 'react-helmet';
import EHCompressionPacker from '../../components/Products/RetrievablePackers/EHCompressionPacker'
import EgwCupPacker from '../../components/Products/RetrievablePackers/EgwCupPacker'
import TensionPacker from '../../components/Products/RetrievablePackers/TensionPacker'
import ES3SingleGrip from '../../components/Products/RetrievablePackers/ES3SingleGrip'
import ES4DoubleGrip from '../../components/Products/RetrievablePackers/ES4DoubleGrip'
import SnapsetCompressionSet from '../../components/Products/RetrievablePackers/SnapsetCompressionSet'
import EhiHydraulicIsolationPacker from '../../components/Products/RetrievablePackers/EhiHydraulicIsolationPacker'
import HydroCTPacker from '../../components/Products/RetrievablePackers/HydroCTPacker'
import HydroGripII from '../../components/Products/RetrievablePackers/HydroGripII'
import HydroGripIII from '../../components/Products/RetrievablePackers/HydroGripIII'
import El1InflatableSingle from '../../components/Products/RetrievablePackers/El1InflatableSingle'
import El2InflatableMulti from '../../components/Products/RetrievablePackers/El2InflatableMulti'
import ED2Dual from '../../components/Products/RetrievablePackers/ED2Dual'
import ES1X from '../../components/Products/RetrievablePackers/ES1X'
import ES1X8 from '../../components/Products/RetrievablePackers/ES1X8'
import ES1X10 from '../../components/Products/RetrievablePackers/ES1X10'
import GeneralHeader from "../../components/Headers/GeneralHeader";

const ProductController = [
  {
    title: "OH COMPRESSION PACKER",
    component: <EHCompressionPacker title="OH COMPRESSION PACKER" />,
  },
  {
    title: "EGW CUP PACKER",
    component: <EgwCupPacker title="EGW CUP PACKER" />,
  },
  {
    title: "TENSION PACKER",
    component: <TensionPacker title="TENSION PACKER" />,
  },
  {
    title: "ES-3 SINGLE GRIP COMPRESSION PACKER",
    component: <ES3SingleGrip title="ES-3 SINGLE GRIP COMPRESSION PACKER" />,
  },
  {
    title: "ES-4 DOUBLE GRIP COMPRESSION PACKER",
    component: <ES4DoubleGrip title="ES-4 DOUBLE GRIP COMPRESSION PACKER" />,
  },
  {
    title: "SNAPSET COMPRESSION SET IPACKERS",
    component: <SnapsetCompressionSet title="SNAPSET COMPRESSION SET IPACKERS" />,
  },
  {
    title: "EHI HYDRAULIC ISOLATION PACKER",
    component: <EhiHydraulicIsolationPacker title="EHI HYDRAULIC ISOLATION PACKER" />,
  },
  {
    title: "HYDRO-CT PACKER",
    component: <HydroCTPacker title="HYDRO-CT PACKER" />,
  },
  {
    title: "HYDRO-GRIP II HYDRAULIC SET PACKER",
    component: <HydroGripII title="HYDRO-GRIP II HYDRAULIC SET PACKER" />,
  },
  {
    title: "HYDRO-GRIP III HYDRAULIC SET PACKER",
    component: <HydroGripIII title="HYDRO-GRIP III HYDRAULIC SET PACKER" />,
  },
  {
    title: "INFLATABLE SINGLE SET PACKER",
    component: <El1InflatableSingle title="INFLATABLE SINGLE SET PACKER" />,
  },
  {
    title: "INFLATABLE MULTI-SET PACKER",
    component: <El2InflatableMulti title="INFLATABLE MULTI-SET PACKER" />,
  },
  {
    title: "ED-2 DUAL STRING PRODUCTION PACKER",
    component: <ED2Dual title="ED-2 DUAL STRING PRODUCTION PACKER" />,
  },
  {
    title: "ES1X-6/ES1X-6W DOUBLE GRIPPACKER",
    component: <ES1X title="ES1X-6/ES1X-6W DOUBLE GRIPPACKER" />,
  },
  {
    title: "ES1X-8/ES1X-8W DOUBLE GRIP PACKER",
    component: <ES1X8 title="ES1X-8/ES1X-8W DOUBLE GRIP PACKER" />,
  },
  {
    title: "ES1X-10/ES1X-10W DOUBLE GRIP PACKER",
    component: <ES1X10 title="ES1X-10/ES1X-10W DOUBLE GRIP PACKER" />,
  },
];

function RetrievablePacker() {
  const [retrievablePacker, setRetrievablePacker] = useState([]);
  const [currentProduct, setCurrentProduct] = useState("OH COMPRESSION PACKER");

  useEffect(() => {
    getRetrievablePackerTitlesService().then((result) => {
        setRetrievablePacker(result);
    });
  }, []);

  return (
    <div>
    <Helmet>‍
        <title>Retrievable Packers | Maverick Downhole Technologies | Edmonton, AB</title>‍
        
      </Helmet>
      <GeneralHeader title="Retrievable Packers" />
      <div className="product-section">
        <div className="product-btn-container">
          {retrievablePacker.map((retrievablePacker) => {
            return (
              <GeneralBtn
                onClick={(title) => setCurrentProduct(title)}
                title={retrievablePacker.title}
                selected={retrievablePacker.title === currentProduct}
              />
            );
          })}
        </div>
        <div>
          {!currentProduct
            ? null
            : ProductController.find(
                (product) => product.title === currentProduct
              )?.component}
        </div>
      </div>
      <ContactForm />
    </div>
  );
}

export default RetrievablePacker;
