import React from "react";
import { getAllProductService } from "../../services/dept";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function AccessoriesBtn(props) {
  const { title, onClick, selected, baseURL} = props;
  const [products, setProducts] = useState([]);
  const [currentProduct, setCurrentProduct] = useState({});
  const navigate = useNavigate(); 

  useEffect(() => {
    getAllProductService().then((loadedProducts) => {
      setProducts(loadedProducts);
      // console.log(loadedProducts.find((product) => product.title === title));
      setCurrentProduct(
        loadedProducts.find((product) => product.title === title)
      );
      // console.log(title)
    });
  }, []);
  return (
    <div>
      {selected === true ? (
        <button className="column-btn active" onClick={() => onClick(title)}>
          {currentProduct !== {} ? currentProduct?.title : null}
        </button>
      ) : (
        <button
          className="column-btn"
          onClick={() => {
            // onClick(title);
            // navigate(-1); 
            let test = title.split('/')
            let result = test[0]
            for (let len = 1; len < test.length; ++len){
              result = result + "-" + test[len]
              
            }
            navigate(`${baseURL}/${result}`, {replace: true})
            console.log(result)
            window.scrollTo(0, 0);
            // window.location.reload(); 
          }}
        >
          {currentProduct !== {} ? currentProduct?.title : null}
        </button>
      )}
    </div>
  );
}

export default AccessoriesBtn;
