import React from "react";
import { $, jQuery } from "jquery";

function PumpOutPlugTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c83">
            <td class="c71" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Tubing Size </span>
              </p>
              <p class="c5">
                <span class="c7">(inches)</span>
              </p>
            </td>
            <td class="c111" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Tool OD </span>
              </p>
              <p class="c5">
                <span class="c7">(inches)</span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Tool ID </span>
              </p>
              <p class="c5">
                <span class="c7">(inches)</span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Thread Connection </span>
              </p>
              <p class="c5">
                <span class="c7">Box Up</span>
              </p>
            </td>
            <td class="c97" colspan="1" rowspan="1">
              <p class="c189">
                <span class="c7">Part &nbsp;</span>
              </p>
              <p class="c5">
                <span class="c7">Number</span>
              </p>
            </td>
          </tr>
        </thead>

        <tbody>
          <tr class="c13">
            <td class="c71" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.050 </span>
              </p>
            </td>
            <td class="c111" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.660 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">- </span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.050 EUE </span>
              </p>
            </td>
            <td class="c97" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">59710E</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c71" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.315 </span>
              </p>
            </td>
            <td class="c111" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.900 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.20 </span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.315 EUE </span>
              </p>
            </td>
            <td class="c97" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">59713E</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c71" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c2">1.660</span>
              </p>
            </td>
            <td class="c111" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2.062 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.38 </span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.660 NUE </span>
              </p>
            </td>
            <td class="c97" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">59716N</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c111" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2.200 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.38 </span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.660 EUE </span>
              </p>
            </td>
            <td class="c97" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">59716E</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c71" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c2">1.900</span>
              </p>
            </td>
            <td class="c111" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2.500 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.50 </span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.900 EUE </span>
              </p>
            </td>
            <td class="c97" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">59719E</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c111" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2.200 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.50 </span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.900 NUE </span>
              </p>
            </td>
            <td class="c97" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">59719N</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c71" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2-3/8 </span>
              </p>
            </td>
            <td class="c111" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">3.060 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2.00 </span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2-3/8 EUE </span>
              </p>
            </td>
            <td class="c97" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">59720E</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c71" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2-7/8 </span>
              </p>
            </td>
            <td class="c111" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">3.670 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2.50 </span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2-7/8 EUE </span>
              </p>
            </td>
            <td class="c97" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">59727E</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c71" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c2">3-1/2 </span>
              </p>
            </td>
            <td class="c111" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c2">4.500</span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">3.46 </span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">3-1/2 EUE </span>
              </p>
            </td>
            <td class="c97" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">59735E</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c1" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2.88 </span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">3-1/2 EUE </span>
              </p>
            </td>
            <td class="c97" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">59736E</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c71" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c2">4-1/2 </span>
              </p>
            </td>
            <td class="c111" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c2">5.560 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c2">4.00</span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">4-1/2 EUE </span>
              </p>
            </td>
            <td class="c97" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">59745E</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">4-1/2 LTC </span>
              </p>
            </td>
            <td class="c97" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">59745LTC</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c71" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">5-1/2 </span>
              </p>
            </td>
            <td class="c111" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">6.000 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">5.13 </span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">5-1/2 LTC </span>
              </p>
            </td>
            <td class="c97" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">59755LTC</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c71" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">6-5/8 </span>
              </p>
            </td>
            <td class="c111" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">7.390 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">6.23 </span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="1">
              <p class="c219">
                <span class="c2">6-5/8 LTC </span>
              </p>
            </td>
            <td class="c97" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">59766LTC</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c71" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">7 </span>
              </p>
            </td>
            <td class="c111" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">7.650 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">6.60 </span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">7&rdquo; LTC </span>
              </p>
            </td>
            <td class="c97" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">59770LTC</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c71" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">8-5/8 </span>
              </p>
            </td>
            <td class="c111" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">9.620 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">8.18 </span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">8-5/8 LTC </span>
              </p>
            </td>
            <td class="c97" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">59786LTC</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c71" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">9-5/8 </span>
              </p>
            </td>
            <td class="c111" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">10.620 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">9.17 </span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">9-5/8 LTC </span>
              </p>
            </td>
            <td class="c97" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">59796LTC</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default PumpOutPlugTable;
