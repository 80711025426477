import React from "react";
import { $, jQuery } from "jquery";

function ProfileNippleTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c83">
            <td class="c96" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Tubing Size </span>
              </p>
              <p class="c5">
                <span class="c7">(inches)</span>
              </p>
            </td>
            <td class="c0" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Profle Size </span>
              </p>
              <p class="c5">
                <span class="c7">(inches) </span>
              </p>
            </td>
            <td class="c31" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Profles</span>
              </p>
            </td>
          </tr>
        </thead>

        <tbody>
          <tr class="c13">
            <td class="c96" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.900 </span>
              </p>
            </td>
            <td class="c0" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.500 </span>
              </p>
            </td>
            <td class="c31" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">DX DXN</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c96" colspan="1" rowspan="6">
              <p class="c5">
                <span class="c2">2-3/8</span>
              </p>
            </td>
            <td class="c0" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.437 </span>
              </p>
            </td>
            <td class="c31" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">F</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c0" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.500 </span>
              </p>
            </td>
            <td class="c31" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">F R DX DXN</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c0" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.625 </span>
              </p>
            </td>
            <td class="c31" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">F</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c0" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.781 </span>
              </p>
            </td>
            <td class="c31" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">F R</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c0" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.812 </span>
              </p>
            </td>
            <td class="c31" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">F R</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c0" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.875 </span>
              </p>
            </td>
            <td class="c31" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">F R DX DXN</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c96" colspan="1" rowspan="6">
              <p class="c5">
                <span class="c2">2-7/8</span>
              </p>
            </td>
            <td class="c0" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.812 </span>
              </p>
            </td>
            <td class="c31" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">F R</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c0" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.875 </span>
              </p>
            </td>
            <td class="c31" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">F R DX DXN</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c0" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2.130 </span>
              </p>
            </td>
            <td class="c31" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">R</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c0" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2.187 </span>
              </p>
            </td>
            <td class="c31" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">F R</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c0" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2.250 </span>
              </p>
            </td>
            <td class="c31" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">F R</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c0" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2.312 </span>
              </p>
            </td>
            <td class="c31" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">R DX DXN</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c96" colspan="1" rowspan="5">
              <p class="c5">
                <span class="c2">3-1/2</span>
              </p>
            </td>
            <td class="c0" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2.250 </span>
              </p>
            </td>
            <td class="c31" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">F</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c0" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2.312 </span>
              </p>
            </td>
            <td class="c31" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">DX</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c0" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2.562 </span>
              </p>
            </td>
            <td class="c31" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">F R</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c0" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2.750 </span>
              </p>
            </td>
            <td class="c31" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">F R DX DXN</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c0" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2.812 </span>
              </p>
            </td>
            <td class="c31" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">F R DX DXN</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default ProfileNippleTable;
