import React from "react";
import { $, jQuery } from "jquery";

function PermapakDualTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c48">
            <td class="c51" colspan="2" rowspan="1">
              <p class="c0">
                <span class="c15">Casing </span>
              </p>
            </td>
            <td class="c28" colspan="1" rowspan="2">
              <p class="c93">
                <span class="c15">Recommended Hole Size </span>
              </p>
              <p class="c0">
                <span class="c15">(inches)</span>
              </p>
            </td>
            <td class="c45" colspan="1" rowspan="2">
              <p class="c0">
                <span class="c15">Tool OD </span>
              </p>
              <p class="c0">
                <span class="c15">(inches)</span>
              </p>
            </td>
            <td class="c62" colspan="2" rowspan="1">
              <p class="c0">
                <span class="c15">Seal Bore </span>
              </p>
            </td>
            <td class="c44" colspan="1" rowspan="2">
              <p class="c0">
                <span class="c15">Min ID </span>
              </p>
              <p class="c52">
                <span class="c15">Thru Seals (inches)</span>
              </p>
            </td>
            <td class="c10" colspan="1" rowspan="2">
              <p class="c68 c82">
                <span class="c15">Part &nbsp;</span>
              </p>
              <p class="c0">
                <span class="c15">Number</span>
              </p>
            </td>
          </tr>
          <tr class="c91">
            <td class="c7" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c40">Size </span>
                <span class="c15">(inches) </span>
              </p>
            </td>
            <td class="c6" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c15">Weight </span>
              </p>
              <p class="c0">
                <span class="c15">(lbs/ft) </span>
              </p>
            </td>
            <td class="c11" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c15">Upper </span>
              </p>
              <p class="c0">
                <span class="c15">(inches)</span>
              </p>
            </td>
            <td class="c19" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c15">Lower </span>
              </p>
              <p class="c0">
                <span class="c15">(inches)</span>
              </p>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="c17">
            <td class="c7" colspan="1" rowspan="2">
              <p class="c0">
                <span class="c13">4</span>
              </p>
            </td>
            <td class="c6" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">9.5 - 11.6 </span>
              </p>
            </td>
            <td class="c28" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">3.428 - 3.548 </span>
              </p>
            </td>
            <td class="c45" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">3.281 </span>
              </p>
            </td>
            <td class="c11" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">2.688 </span>
              </p>
            </td>
            <td class="c19" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">1.750 </span>
              </p>
            </td>
            <td class="c44" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">1.750 </span>
              </p>
            </td>
            <td class="c10" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">67340</span>
              </p>
            </td>
          </tr>
          <tr class="c17">
            <td class="c6" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">11.6 - 14.8 </span>
              </p>
            </td>
            <td class="c28" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">3.240 - 3.428 </span>
              </p>
            </td>
            <td class="c45" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">3.090 </span>
              </p>
            </td>
            <td class="c11" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">2.688 </span>
              </p>
            </td>
            <td class="c19" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">1.750 </span>
              </p>
            </td>
            <td class="c44" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">1.750 </span>
              </p>
            </td>
            <td class="c10" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">67341</span>
              </p>
            </td>
          </tr>
          <tr class="c17">
            <td class="c7" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">5 </span>
              </p>
            </td>
            <td class="c6" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">15.0 - 21.0 </span>
              </p>
            </td>
            <td class="c28" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">4.154 - 4.408 </span>
              </p>
            </td>
            <td class="c45" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">3.968 </span>
              </p>
            </td>
            <td class="c11" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">3.000 </span>
              </p>
            </td>
            <td class="c19" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">2.688 </span>
              </p>
            </td>
            <td class="c44" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">2.375 </span>
              </p>
            </td>
            <td class="c10" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">67350</span>
              </p>
            </td>
          </tr>
          <tr class="c17">
            <td class="c7" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">5-1/2 </span>
              </p>
            </td>
            <td class="c6" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">23.0 - 26.0 </span>
              </p>
            </td>
            <td class="c28" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">4.548 - 4.670 </span>
              </p>
            </td>
            <td class="c45" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">4.250 </span>
              </p>
            </td>
            <td class="c11" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">3.000 </span>
              </p>
            </td>
            <td class="c19" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">2.688 </span>
              </p>
            </td>
            <td class="c44" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">2.375 </span>
              </p>
            </td>
            <td class="c10" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">67357</span>
              </p>
            </td>
          </tr>
          <tr class="c17">
            <td class="c7" colspan="1" rowspan="2">
              <p class="c0">
                <span class="c13">7</span>
              </p>
            </td>
            <td class="c6" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">23.0 - 32.0 </span>
              </p>
            </td>
            <td class="c28" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">6.094 - 6.366 </span>
              </p>
            </td>
            <td class="c45" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">5.813 </span>
              </p>
            </td>
            <td class="c11" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">4.750 </span>
              </p>
            </td>
            <td class="c19" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">4.000 </span>
              </p>
            </td>
            <td class="c44" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">3.500 </span>
              </p>
            </td>
            <td class="c10" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">67371</span>
              </p>
            </td>
          </tr>
          <tr class="c17">
            <td class="c6" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">26.0 - 29.0 </span>
              </p>
            </td>
            <td class="c28" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">6.184 - 6.276 </span>
              </p>
            </td>
            <td class="c45" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">5.900 </span>
              </p>
            </td>
            <td class="c11" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">5.250 </span>
              </p>
            </td>
            <td class="c19" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">4.375 </span>
              </p>
            </td>
            <td class="c44" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">4.250 </span>
              </p>
            </td>
            <td class="c10" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">67373</span>
              </p>
            </td>
          </tr>
          <tr class="c17">
            <td class="c7" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">7-5/8 </span>
              </p>
            </td>
            <td class="c6" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">24.0 - 39.0 </span>
              </p>
            </td>
            <td class="c28" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">6.625 - 7.025 </span>
              </p>
            </td>
            <td class="c45" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">6.250 </span>
              </p>
            </td>
            <td class="c11" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">5.250 </span>
              </p>
            </td>
            <td class="c19" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">4.000 </span>
              </p>
            </td>
            <td class="c44" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">4.250 </span>
              </p>
            </td>
            <td class="c10" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">67375</span>
              </p>
            </td>
          </tr>
          <tr class="c17">
            <td class="c7" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">9-5/8 </span>
              </p>
            </td>
            <td class="c6" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">36.0 - 53.5 </span>
              </p>
            </td>
            <td class="c28" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">8.535 - 8.921 </span>
              </p>
            </td>
            <td class="c45" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">8.125 </span>
              </p>
            </td>
            <td class="c11" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">7.000 </span>
              </p>
            </td>
            <td class="c19" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">6.000 </span>
              </p>
            </td>
            <td class="c44" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">4.750 </span>
              </p>
            </td>
            <td class="c10" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c13">67395</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default PermapakDualTable;
