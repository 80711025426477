import React, { useEffect, useState } from "react";
import { getAllProductService } from "../../../services/dept";
import WlakImg from '../../../assets/images/product-images/mechanical-packers/wlak.jpg'
import WirelineAdapterKitVsiTable from "./Tables/WirelineAdapterKitVsiTable";
import { Helmet } from 'react-helmet'

function WirelineAdapterKitVsi(props) {
  const { title } = props;
  const [products, setProducts] = useState([]);
  const [currentProduct, setCurrentProduct] = useState({});

  // useEffect(() => {
  //   getAllProductService().then((loadedProducts) => {
  //     setProducts(loadedProducts);
  //     setCurrentProduct(
  //       loadedProducts.find((product) => product.title === title)
  //     );
  //   });
  // }, []);

  useEffect(() => {
    getAllProductService().then((loadedProducts) => {
      setProducts(loadedProducts);

      let formatLoaded = loadedProducts.map((elem) => {
        if (elem.title === title) {
          return {
            ...elem,

            // description: elem.description.replace(/[\\n\\n]/gm, "n"),
            description: elem.description.split('\\n'),

          };
        } else {
          return elem;
        }
      });

      setCurrentProduct(
        formatLoaded.find((product) => product.title === title)
      );
      //currentProduct.description = currentProduct.description.replace(/[\\n\\n]/gm, "n");
     //
    });
  }, []);

  // console.log(currentProduct);
  return (
    <div className="product-info">
        <Helmet>‍
      <title>{`${title} | Maverick Downhole Technologies | Edmonton, AB`}</title>
      <script type="application/ld+json">
      {JSON.stringify({
      "@context": "https://schema.org/", 
      "@type": "Product", 
      "name": title,
      "image": {WlakImg},
      "description": currentProduct.description,
      "brand": {
        "@type": "Brand",
        "name": "Maverick Downhole Technologies"
      }
      })}
      </script>
      </Helmet>
      <div>
        <h3 className="main-subhead">
          {currentProduct !== {} ? currentProduct.title : null}
        </h3>
        <h4 className="smaller-head">
          {currentProduct !== {} ? currentProduct.subhead : null}
        </h4>
        <div className="spacer"></div>
        <p className="body-text">
          {currentProduct !== {} ? currentProduct.description : null}
        </p>
        <WirelineAdapterKitVsiTable />
      </div>
      <div>
        <img
          src={WlakImg}
          title={currentProduct.title}
          alt={currentProduct.title}
          className="product-img"
        />
      </div>
    </div>
  );
}

export default WirelineAdapterKitVsi;
