import React from "react";
import { $, jQuery } from "jquery";

function SetDownTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c165">
            <td class="c998" colspan="2" rowspan="1">
              Casing
            </td>
            <td class="c13" colspan="1" rowspan="2">
              Recommended Hole Size (inches)
            </td>
            <td class="c5" colspan="1" rowspan="2">
              Tool OD (inches)
            </td>
            <td class="c108" colspan="1" rowspan="2">
              Tool ID (inches)
            </td>
            <td class="c141" colspan="1" rowspan="2">
              Thread Connections Box Up / Pin Down
            </td>
            <td class="c95" colspan="1" rowspan="2">
              Part Number
            </td>
          </tr>
          <tr class="c150">
            <td class="c286" colspan="1" rowspan="1">
              Size (inches)
            </td>
            <td class="c12" colspan="1" rowspan="1">
              Weight (lbs/ft)
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="c25">
            <td class="c286" colspan="1" rowspan="1">
              2-7/8
            </td>
            <td class="c12" colspan="1" rowspan="1">
              6.5
            </td>
            <td class="c13" colspan="1" rowspan="1">
              2.375
            </td>
            <td class="c5" colspan="1" rowspan="1">
              2.250
            </td>
            <td class="c108" colspan="1" rowspan="1">
              1.50
            </td>
            <td class="c141" colspan="1" rowspan="1">
              1.900 NUE / 1.900 EUE
            </td>
            <td class="c95" colspan="1" rowspan="1">
              64125
            </td>
          </tr>
          <tr class="c25">
            <td class="c286" colspan="1" rowspan="1">
              4-1/2
            </td>
            <td class="c12" colspan="1" rowspan="1">
              9.5 - 13.5
            </td>
            <td class="c13" colspan="1" rowspan="1">
              3.920 - 4.090
            </td>
            <td class="c5" colspan="1" rowspan="1">
              3.750
            </td>
            <td class="c108" colspan="1" rowspan="1">
              1.94
            </td>
            <td class="c141" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c95" colspan="1" rowspan="1">
              64145
            </td>
          </tr>
          <tr class="c25">
            <td class="c286" colspan="1" rowspan="1">
              5
            </td>
            <td class="c12" colspan="1" rowspan="1">
              11.5 - 20.8
            </td>
            <td class="c13" colspan="1" rowspan="1">
              4.156 - 4.560
            </td>
            <td class="c5" colspan="1" rowspan="1">
              4.000
            </td>
            <td class="c108" colspan="1" rowspan="1">
              1.94
            </td>
            <td class="c141" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c95" colspan="1" rowspan="1">
              64150
            </td>
          </tr>
          <tr class="c25">
            <td class="c286" colspan="1" rowspan="4">
              5-1/2
            </td>
            <td class="c12" colspan="1" rowspan="3">
              13.0 - 20.0
            </td>
            <td class="c13" colspan="1" rowspan="3">
              4.778 - 5.044
            </td>
            <td class="c5" colspan="1" rowspan="3">
              4.625
            </td>
            <td class="c108" colspan="1" rowspan="1">
              1.94
            </td>
            <td class="c141" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c95" colspan="1" rowspan="1">
              64155
            </td>
          </tr>
          <tr class="c25">
            <td class="c108" colspan="1" rowspan="1">
              2.50
            </td>
            <td class="c141" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c95" colspan="1" rowspan="1">
              64156
            </td>
          </tr>
          <tr class="c25">
            <td class="c108" colspan="1" rowspan="1">
              3.00
            </td>
            <td class="c141" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c95" colspan="1" rowspan="1">
              64158
            </td>
          </tr>
          <tr class="c25">
            <td class="c12" colspan="1" rowspan="1">
              20.0 - 23.0
            </td>
            <td class="c13" colspan="1" rowspan="1">
              4.670 - 4.778
            </td>
            <td class="c5" colspan="1" rowspan="1">
              4.500
            </td>
            <td class="c108" colspan="1" rowspan="1">
              2.50
            </td>
            <td class="c141" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c95" colspan="1" rowspan="1">
              64159
            </td>
          </tr>
          <tr class="c25">
            <td class="c286" colspan="1" rowspan="4">
              7
            </td>
            <td class="c12" colspan="1" rowspan="3">
              17.0 - 26.0
            </td>
            <td class="c13" colspan="1" rowspan="3">
              6.276 - 6.538
            </td>
            <td class="c5" colspan="1" rowspan="3">
              6.000
            </td>
            <td class="c108" colspan="1" rowspan="1">
              2.00
            </td>
            <td class="c141" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c95" colspan="1" rowspan="1">
              64171
            </td>
          </tr>
          <tr class="c25">
            <td class="c108" colspan="1" rowspan="1">
              2.50
            </td>
            <td class="c141" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c95" colspan="1" rowspan="1">
              64170
            </td>
          </tr>
          <tr class="c25">
            <td class="c108" colspan="1" rowspan="1">
              3.00
            </td>
            <td class="c141" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c95" colspan="1" rowspan="1">
              64174
            </td>
          </tr>
          <tr class="c25">
            <td class="c12" colspan="1" rowspan="1">
              26.0 - 32.0
            </td>
            <td class="c13" colspan="1" rowspan="1">
              6.094 - 6.276
            </td>
            <td class="c5" colspan="1" rowspan="1">
              5.875
            </td>
            <td class="c108" colspan="1" rowspan="1">
              3.00
            </td>
            <td class="c141" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c95" colspan="1" rowspan="1">
              64173
            </td>
          </tr>
          <tr class="c25">
            <td class="c286" colspan="1" rowspan="1">
              7-5/8
            </td>
            <td class="c12" colspan="1" rowspan="1">
              33.7 - 39.0
            </td>
            <td class="c13" colspan="1" rowspan="1">
              6.625 - 6.765
            </td>
            <td class="c5" colspan="1" rowspan="1">
              5.938
            </td>
            <td class="c108" colspan="1" rowspan="1">
              2.82
            </td>
            <td class="c141" colspan="1" rowspan="1">
              2-7/8 EUE / 4-1/2 LTC
            </td>
            <td class="c95" colspan="1" rowspan="1">
              64175
            </td>
          </tr>
          <tr class="c25">
            <td class="c286" colspan="1" rowspan="1">
              8-5/8
            </td>
            <td class="c12" colspan="1" rowspan="1">
              32.0 - 40.0
            </td>
            <td class="c13" colspan="1" rowspan="1">
              7.725 - 7.921
            </td>
            <td class="c5" colspan="1" rowspan="1">
              7.500
            </td>
            <td class="c108" colspan="1" rowspan="1">
              3.00
            </td>
            <td class="c141" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c95" colspan="1" rowspan="1">
              64182
            </td>
          </tr>
          <tr class="c25">
            <td class="c286" colspan="1" rowspan="2">
              9-5/8
            </td>
            <td class="c12" colspan="1" rowspan="1">
              32.3 - 47.0
            </td>
            <td class="c13" colspan="1" rowspan="1">
              8.681 - 9.001
            </td>
            <td class="c5" colspan="1" rowspan="1">
              8.438
            </td>
            <td class="c108" colspan="1" rowspan="1">
              3.00
            </td>
            <td class="c141" colspan="1" rowspan="1">
              3-1/2 EUE
            </td>
            <td class="c95" colspan="1" rowspan="1">
              64196
            </td>
          </tr>
          <tr class="c25">
            <td class="c12" colspan="1" rowspan="1">
              40.0 - 47.0
            </td>
            <td class="c13" colspan="1" rowspan="1">
              8.681 - 8.835
            </td>
            <td class="c5" colspan="1" rowspan="1">
              8.438
            </td>
            <td class="c108" colspan="1" rowspan="1">
              4.00
            </td>
            <td class="c141" colspan="1" rowspan="1">
              4-1/2 EUE
            </td>
            <td class="c95" colspan="1" rowspan="1">
              64195
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default SetDownTable;
