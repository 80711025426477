import React from 'react'

function OtherHeader(props) {
  return (
    <div className='engineering-header'>
        <h3 className='main-subhead white'>
            {props.title}
        </h3>
    </div>
  )
}

export default OtherHeader