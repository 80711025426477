import React from 'react'
import {$,jQuery} from 'jquery';

function EcrCementRetainerTable() {
    
  return (
    <div>  
        <table id="example" class="table table-striped table-bordered" cellspacing="0" width="100%">
        <thead>
            <tr>
                <th colSpan={2}>Size</th>
                <th colSpan={2}>Weight</th>
                <th colSpan={2}>OD</th>
                <th colSpan={2}>ID</th>
                <th colSpan={2}>Connection</th>
            </tr>
        </thead>
 
        <tbody>
            <tr style={{backgroundColor: '#BCBEC0'}}>
                <td>in.</td>
                <td>mm</td>
                <td>lbs/ft</td>
                <td>kg/m</td>
                <td>in.</td>
                <td>mm</td>
                <td>in.</td>
                <td>mm</td>
                <td>in.</td>
                <td>mm</td>
            </tr>
            <tr>
                <td>4 <sup>1/2</sup></td>
                <td>114.3</td>
                <td>9.5-15.1</td>
                <td>14.1-22.5</td>
                <td>3.593</td>
                <td>91.3</td>
                <td>0.785</td>
                <td>22.2</td>
                <td>2.375</td>
                <td>60.3</td>
            </tr>
            <tr>
                <td>5</td>
                <td>127.0</td>
                <td>11.5-18.0</td>
                <td>17.1-26.8</td>
                <td>3.593</td>
                <td>91.3</td>
                <td>0.785</td>
                <td>22.2</td>
                <td>2.375</td>
                <td>60.3</td>
            </tr>
            <tr>
                <td>5 <sup>1/2</sup></td>
                <td>139.7</td>
                <td>13.0-23.0</td>
                <td>19.3-34.2</td>
                <td>4.312</td>
                <td>109.5</td>
                <td>0.785</td>
                <td>22.2</td>
                <td>2.375</td>
                <td>60.3</td>
            </tr>
            <tr>
                <td>6 <sup>5/8</sup></td>
                <td>168.3</td>
                <td>17.0-34.0</td>
                <td>25.3-50.6</td>
                <td>4.938</td>
                <td>125.4</td>
                <td>1.250</td>
                <td>31.8</td>
                <td>2.875</td>
                <td>73.0</td>
            </tr>
            <tr>
                <td>7</td>
                <td>177.8</td>
                <td>17.0-35.0</td>
                <td>25.3-52.1</td>
                <td>5.375</td>
                <td>136.5</td>
                <td>1.250</td>
                <td>31.8</td>
                <td>2.875</td>
                <td>73.0</td>
            </tr>
            <tr>
                <td>7 <sup>5/8</sup></td>
                <td>193.7</td>
                <td>20.0-39.0</td>
                <td>29.8-58.0</td>
                <td>6.312</td>
                <td>160.3</td>
                <td>1.250</td>
                <td>31.8</td>
                <td>2.875</td>
                <td>73.0</td>
            </tr>
            <tr>
                <td>8 <sup>5/8</sup></td>
                <td>219.1</td>
                <td>24.0-49.0</td>
                <td>35.7-72.9</td>
                <td>7.125</td>
                <td>181.0</td>
                <td>1.250</td>
                <td>31.8</td>
                <td>2.875</td>
                <td>73.0</td>
            </tr>
            <tr>
                <td>9 <sup>5/8</sup></td>
                <td>244.5</td>
                <td>29.3-53.5</td>
                <td>43.6-79.6</td>
                <td>8.125</td>
                <td>206.4</td>
                <td>1.250</td>
                <td>31.8</td>
                <td>2.875</td>
                <td>73.0</td>
            </tr>
        </tbody>
        </table>
    </div>
  )
}

export default EcrCementRetainerTable