import React from "react";
import { $, jQuery } from "jquery";

function PermanentHydraulicIsolationPackerTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c165">
            <td class="c484" colspan="2" rowspan="1">
              Casing
            </td>
            <td class="c441" colspan="1" rowspan="2">
              Recommended Hole Size (inches)
            </td>
            <td class="c170" colspan="1" rowspan="2">
              Tool OD (inches)
            </td>
            <td class="c110" colspan="1" rowspan="2">
              Packer &nbsp; Bore (inches)
            </td>
            <td class="c242" colspan="1" rowspan="2">
              Thread Connections Box Up / Pin Down
            </td>
            <td class="c93" colspan="1" rowspan="2">
              Part &nbsp; Number
            </td>
          </tr>
          <tr class="c150">
            <td class="c94" colspan="1" rowspan="1">
              Size (inches)
            </td>
            <td class="c37" colspan="1" rowspan="1">
              Weight (lbs/ft)
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="c25">
            <td class="c94" colspan="1" rowspan="4">
              7
            </td>
            <td class="c37" colspan="1" rowspan="2">
              17.0 - 26.0
            </td>
            <td class="c441" colspan="1" rowspan="2">
              6.276 - 6.538
            </td>
            <td class="c170" colspan="1" rowspan="2">
              6.000
            </td>
            <td class="c110" colspan="1" rowspan="2">
              4.000
            </td>
            <td class="c242" colspan="1" rowspan="1">
              4-1/2 LTC
            </td>
            <td class="c93" colspan="1" rowspan="1">
              68570
            </td>
          </tr>
          <tr class="c25">
            <td class="c242" colspan="1" rowspan="1">
              4-1/2 BTC
            </td>
            <td class="c93" colspan="1" rowspan="1">
              68570-XBLA
            </td>
          </tr>
          <tr class="c25">
            <td class="c37" colspan="1" rowspan="2">
              23.0 - 32.0
            </td>
            <td class="c441" colspan="1" rowspan="2">
              6.094 - 6.366
            </td>
            <td class="c170" colspan="1" rowspan="2">
              5.875
            </td>
            <td class="c110" colspan="1" rowspan="2">
              4.000
            </td>
            <td class="c242" colspan="1" rowspan="1">
              4-1/2 LTC
            </td>
            <td class="c93" colspan="1" rowspan="1">
              68571
            </td>
          </tr>
          <tr class="c25">
            <td class="c242" colspan="1" rowspan="1">
              4-1/2 BTC
            </td>
            <td class="c93" colspan="1" rowspan="1">
              68571-XBLA
            </td>
          </tr>
          <tr class="c25">
            <td class="c94" colspan="1" rowspan="2">
              7-5/8
            </td>
            <td class="c37" colspan="1" rowspan="2">
              24.0 - 39.0
            </td>
            <td class="c441" colspan="1" rowspan="2">
              6.625 - 7.025
            </td>
            <td class="c170" colspan="1" rowspan="2">
              6.250
            </td>
            <td class="c110" colspan="1" rowspan="2">
              4.000
            </td>
            <td class="c242" colspan="1" rowspan="1">
              4-1/2 LTC
            </td>
            <td class="c93" colspan="1" rowspan="1">
              68575
            </td>
          </tr>
          <tr class="c25">
            <td class="c242" colspan="1" rowspan="1">
              4-1/2 BTC
            </td>
            <td class="c93" colspan="1" rowspan="1">
              68575-XBLA
            </td>
          </tr>
          <tr class="c25">
            <td class="c94" colspan="1" rowspan="3">
              9-5/8
            </td>
            <td class="c37" colspan="1" rowspan="3">
              36.0 - 53.5
            </td>
            <td class="c441" colspan="1" rowspan="3">
              8.535 - 8.921
            </td>
            <td class="c170" colspan="1" rowspan="3">
              8.125
            </td>
            <td class="c110" colspan="1" rowspan="1">
              5.000
            </td>
            <td class="c242" colspan="1" rowspan="1">
              5-1/2 LTC
            </td>
            <td class="c93" colspan="1" rowspan="1">
              68595-XBCC
            </td>
          </tr>
          <tr class="c25">
            <td class="c110" colspan="1" rowspan="1">
              5.500
            </td>
            <td class="c242" colspan="1" rowspan="1">
              6-5/8 LTC
            </td>
            <td class="c93" colspan="1" rowspan="1">
              68595
            </td>
          </tr>
          <tr class="c25">
            <td class="c110" colspan="1" rowspan="1">
              6.000
            </td>
            <td class="c242" colspan="1" rowspan="1">
              7&rdquo; LTC
            </td>
            <td class="c93" colspan="1" rowspan="1">
              68595-XBCE
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default PermanentHydraulicIsolationPackerTable;
