import React from 'react'
import {$,jQuery} from 'jquery';

function EcrCementRetainerTable() {
    
  return (
    <div>  
        <table id="example" class="table table-striped table-bordered" cellspacing="0" width="100%">
        <thead>
            <tr>
                <th colSpan={2}>Casing Size</th>
                <th colSpan={2}>Casing Weight</th>
                <th colSpan={2}>Retainer OD</th>
                <th colSpan={2}>Minimum</th>
                <th colSpan={2}>Maximum</th>
            </tr>
        </thead>
 
        <tbody>
            <tr style={{backgroundColor: '#BCBEC0'}}>
                <td>in.</td>
                <td>mm</td>
                <td>lbs/ft</td>
                <td>kg/m</td>
                <td>in.</td>
                <td>mm</td>
                <td>in.</td>
                <td>mm</td>
                <td>in.</td>
                <td>mm</td>
            </tr>
            <tr>
                <td>4 <sup>1/2</sup></td>
                <td>114.3</td>
                <td>9.5-15.1</td>
                <td>14.1-22.5</td>
                <td>3.593</td>
                <td>91.26</td>
                <td>3.826</td>
                <td>97.18</td>
                <td>4.091</td>
                <td>103.89</td>
            </tr>
            <tr>
                <td>5</td>
                <td>127.0</td>
                <td>11.5-20.8</td>
                <td>17.1-31.0</td>
                <td>3.937</td>
                <td>100.00</td>
                <td>4.156</td>
                <td>105.56</td>
                <td>4.560</td>
                <td>115.82</td>
            </tr>
            <tr>
                <td>5 <sup>1/2</sup></td>
                <td>139.7</td>
                <td>13.0-23.0</td>
                <td>19.3-34.2</td>
                <td>4.312</td>
                <td>109.52</td>
                <td>4.580</td>
                <td>116.33</td>
                <td>5.044</td>
                <td>128.12</td>
            </tr>
            <tr>
                <td>5 <sup>3/4</sup></td>
                <td>146.0</td>
                <td>14.0-25.2</td>
                <td>20.8-37.5</td>
                <td>4.700</td>
                <td>119.38</td>
                <td>4.890</td>
                <td>124.21</td>
                <td>5.290</td>
                <td>134.37</td>
            </tr>
            <tr>
                <td>6 <sup>5/8</sup></td>
                <td>168.3</td>
                <td>17.0-32.0</td>
                <td>25.3-47.6</td>
                <td>5.375</td>
                <td>136.53</td>
                <td>5.595</td>
                <td>142.11</td>
                <td>6.135</td>
                <td>155.83</td>
            </tr>
            <tr>
                <td>7</td>
                <td>177.8</td>
                <td>17.0-35.0</td>
                <td>25.3-52.0</td>
                <td>5.688</td>
                <td>144.48</td>
                <td>6.004</td>
                <td>152.50</td>
                <td>6.538</td>
                <td>166.07</td>
            </tr>
            <tr>
                <td>7 <sup>5/8</sup></td>
                <td>193.7</td>
                <td>28.0-39.0</td>
                <td>29.8-58.0</td>
                <td>6.312</td>
                <td>160.32</td>
                <td>6.625</td>
                <td>168.28</td>
                <td>7.125</td>
                <td>180.98</td>
            </tr>
            <tr>
                <td>8 <sup>5/8</sup></td>
                <td>219.1</td>
                <td>24.0-49.0</td>
                <td>35.7.72.9</td>
                <td>7.125</td>
                <td>180.98</td>
                <td>7.511</td>
                <td>190.78</td>
                <td>8.097</td>
                <td>206.66</td>
            </tr>
            <tr>
                <td>9 <sup>5/8</sup></td>
                <td>244.5</td>
                <td>29.3-58.4</td>
                <td>43.6-86.9</td>
                <td>8.125</td>
                <td>206.38</td>
                <td>8.435</td>
                <td>214.25</td>
                <td>9.063</td>
                <td>230.20</td>
            </tr>
            <tr>
                <td>10 <sup>3/4</sup></td>
                <td>273.0</td>
                <td>32.8-60.7</td>
                <td>48.7-90.3</td>
                <td>9.437</td>
                <td>239.70</td>
                <td>9.660</td>
                <td>245.36</td>
                <td>10.192</td>
                <td>258.88</td>
            </tr>
            <tr>
                <td>11 <sup>3/4</sup></td>
                <td>298.5</td>
                <td>38.0-60.0</td>
                <td>56.5-89.3</td>
                <td>10.437</td>
                <td>265.10</td>
                <td>10.772</td>
                <td>273.61</td>
                <td>11.150</td>
                <td>283.21</td>
            </tr>
            <tr>
                <td>11 <sup>3/4</sup></td>
                <td>298.5</td>
                <td>60.0-83.0</td>
                <td>89.9-123.5</td>
                <td>9.937</td>
                <td>252.40</td>
                <td>10.192</td>
                <td>258.88</td>
                <td>10.772</td>
                <td>273.61</td>
            </tr>
            <tr>
                <td>13 <sup>3/8</sup></td>
                <td>339.7</td>
                <td>48.0-80.7</td>
                <td>71.4-120.0</td>
                <td>11.875</td>
                <td>301.63</td>
                <td>12.175</td>
                <td>309.25</td>
                <td>12.715</td>
                <td>322.96</td>
            </tr>
            <tr>
                <td>16</td>
                <td>406.4</td>
                <td>65.0-118.0</td>
                <td>96.7-175.7</td>
                <td>14.125</td>
                <td>358.78</td>
                <td>14.675</td>
                <td>373.10</td>
                <td>15.250</td>
                <td>387.35</td>
            </tr>
            <tr>
                <td>20</td>
                <td>508.0</td>
                <td>94.0-133.0</td>
                <td>139.8-197.8</td>
                <td>18.375</td>
                <td>466.73</td>
                <td>18.730</td>
                <td>475.74</td>
                <td>19.124</td>
                <td>485.75</td>
            </tr>
        </tbody>
        </table>
    </div>
  )
}

export default EcrCementRetainerTable