import React from "react";
import { $, jQuery } from "jquery";

function SnapsetIIPackerTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr>
            <th colSpan={2}>Casing</th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Recommended Hole Size (Inches)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Tool OD (Inches)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Tool ID (Inches)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Thread Connections Box Up / Pin Down
            </th>
            <th rowSpan={2}>Part Number</th>
          </tr>
          <tr>
            <th>Size (inches)</th>
            <th>Weight (lbs/ft)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>3-1/2</td>
            <td>7.7-10.2</td>
            <td>2.922-3.068</td>
            <td>2.781</td>
            <td>1.25</td>
            <td>1.900 EUE</td>
            <td>63635</td>
          </tr>
          <tr>
            <td rowSpan={3}>4-1/2</td>
            <td>9.5-13.5</td>
            <td>3.920-4.090</td>
            <td>3.750</td>
            <td>1.94</td>
            <td>2-3/8 EUE</td>
            <td>63645</td>
          </tr>
          <tr>
            <td>15.1</td>
            <td>3.826</td>
            <td>3.656</td>
            <td>1.94</td>
            <td>2-3/8 EUE</td>
            <td>63644</td>
          </tr>
          <tr>
            <td>18.8</td>
            <td>3.640</td>
            <td>3.437</td>
            <td>1.50</td>
            <td>1.900 EUE</td>
            <td>63646</td>
          </tr>
          <tr>
            <td rowSpan={2}>5</td>
            <td>11.5-15.0</td>
            <td>4.408-4.560</td>
            <td>4.125</td>
            <td>1.94</td>
            <td>2-3/8 EUE</td>
            <td>63650</td>
          </tr>
          <tr>
            <td>18.0-21.0</td>
            <td>4.154-4.276</td>
            <td>4.000</td>
            <td>1.94</td>
            <td>2-3/8 EUE</td>
            <td>63652</td>
          </tr>
          <tr>
            <td rowSpan={4}>5-1/2</td>
            <td rowSpan={2}>14.0-20.0</td>
            <td rowSpan={2}>4.778-5.012</td>
            <td rowSpan={2}>4.625</td>
            <td>2.00</td>
            <td>2-3/8 EUE</td>
            <td>63655</td>
          </tr>
          <tr>
            <td>2.38</td>
            <td>2-7/8 EUE</td>
            <td>63656</td>
          </tr>
          <tr>
            <td rowSpan={2}>20.0-23.0</td>
            <td rowSpan={2}>4.670-4.778</td>
            <td rowSpan={2}>4.500</td>
            <td>2.00</td>
            <td>2-3/8 EUE</td>
            <td>63657</td>
          </tr>
          <tr>
            <td>2.38</td>
            <td>2-7/8 EUE</td>
            <td>63658</td>
          </tr>
          <tr>
            <td>6-5/8</td>
            <td>24.0-32.0</td>
            <td>5.675-5.921</td>
            <td>5.500</td>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>63665</td>
          </tr>
          <tr>
            <td rowSpan={3}>7</td>
            <td rowSpan={2}>17.0-26.0</td>
            <td rowSpan={2}>6.276-6.538</td>
            <td rowSpan={2}>6.000</td>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>63672</td>
          </tr>
         <tr>
          <td>3.00</td>
          <td>3-1/2 EUE</td>
          <td>63674</td>
         </tr>
         <tr>
            <td>26.0-32.0</td>
            <td>6.094-6.276</td>
            <td>5.875</td>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>63670</td>
          </tr>
          <tr>
            <td rowSpan={2}>7-5/8</td>
            <td>24.0-29.7</td>
            <td>6.875-7.025</td>
            <td>6.672</td>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>63575</td>
          </tr>
          <tr>
            <td>33.7-39.0</td>
            <td>6.625-6.765</td>
            <td>6.453</td>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>63676</td>
          </tr>
          <tr>
            <td>9-5/8</td>
            <td>32.3-43.5</td>
            <td>8.755-9.001</td>
            <td>8.500</td>
            <td>3.00</td>
            <td>3-1/2 EUE</td>
            <td>63696</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default SnapsetIIPackerTable;
