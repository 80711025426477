import React from "react";
import { $, jQuery } from "jquery";

function Es1x8Table() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr>
            <th colspan="2">CASING SIZE</th>
            <th colspan="2">CASING WEIGHT</th>
            <th colspan="2">TUBING SIZE</th>
            <th colspan="2">MAX OD</th>
            <th colspan="2">MIN ID</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>in.</td>
            <td>mm</td>
            <td>lbs/ft</td>
            <td>kg/m</td>
            <td>in.</td>
            <td>mm</td>
            <td>in.</td>
            <td>mm</td>
            <td>in.</td>
            <td>mm</td>
          </tr>
          <tr>
            <td>
              2<sup>7/8</sup>
            </td>
            <td>73.0</td>
            <td>6.4-6.5</td>
            <td>9.5-9.7</td>
            <td>1.050 EUE</td>
            <td>26.7</td>
            <td>2.250</td>
            <td>57.15</td>
            <td>0.625</td>
            <td>15.88</td>
          </tr>
          <tr>
            <td>
              3<sup>1/2</sup>
            </td>
            <td>88.9</td>
            <td>9.2-10.2</td>
            <td>13.7-15.2</td>
            <td>1.900 EUE</td>
            <td>48.3</td>
            <td>2.781</td>
            <td>70.64</td>
            <td>1.250</td>
            <td>31.75</td>
          </tr>
          <tr>
            <td>4</td>
            <td>101.60</td>
            <td>10.4-12.9</td>
            <td>15.5-19.2</td>
            <td>1.900 EUE</td>
            <td>48.3</td>
            <td>3.187</td>
            <td>80.95</td>
            <td>1.500</td>
            <td>38.10</td>
          </tr>
          <tr>
            <td>4</td>
            <td>101.60</td>
            <td>9.5-11.0</td>
            <td>14.1-16.4</td>
            <td>1.900 EUE</td>
            <td>48.3</td>
            <td>3.250</td>
            <td>82.55</td>
            <td>1.500</td>
            <td>38.10</td>
          </tr>
          <tr>
            <td>
              4<sub>1/2</sub>
            </td>
            <td>114.30</td>
            <td>9.5-13.5</td>
            <td>14.1-20.9</td>
            <td>2 3/8 EUE</td>
            <td>60.3</td>
            <td>3.750</td>
            <td>95.25</td>
            <td>1.938</td>
            <td>49.23</td>
          </tr>
          <tr>
            <td>
              4<sub>1/2</sub>
            </td>
            <td>114.30</td>
            <td>13.5-15.1</td>
            <td>20.1-22.5</td>
            <td>2 3/8 EUE</td>
            <td>60.3</td>
            <td>3.656</td>
            <td>92.86</td>
            <td>1.938</td>
            <td>50.80</td>
          </tr>
          <tr>
            <td>
              4<sub>1/2</sub>
            </td>
            <td>114.30</td>
            <td>15.1-20.0</td>
            <td>22.5-29.8</td>
            <td>2 3/8 EUE</td>
            <td>60.3</td>
            <td>3.500</td>
            <td>88.90</td>
            <td>1.703</td>
            <td>43.26</td>
          </tr>
          <tr>
            <td>5</td>
            <td>127.00</td>
            <td>11.5-15.0</td>
            <td>17.1-22.3</td>
            <td>2 3/8 EUE</td>
            <td>60.3</td>
            <td>4.125</td>
            <td>104.78</td>
            <td>1.938</td>
            <td>49.23</td>
          </tr>
          <tr>
            <td>5</td>
            <td>127.00</td>
            <td>18.0-20.8</td>
            <td>26.8-31.0</td>
            <td>2 3/8 EUE</td>
            <td>60.3</td>
            <td>4.000</td>
            <td>101.60</td>
            <td>1.938</td>
            <td>49.23</td>
          </tr>
          <tr>
            <td>
              5<sub>1/2</sub>
            </td>
            <td>139.70</td>
            <td>14.0-20.0</td>
            <td>20.8-29.8</td>
            <td>2 3/8 EUE</td>
            <td>60.3</td>
            <td>4.625</td>
            <td>117.48</td>
            <td>1.938</td>
            <td>49.23</td>
          </tr>
          <tr>
            <td>
              5<sub>1/2</sub>
            </td>
            <td>139.70</td>
            <td>20.0-23.0</td>
            <td>29.8-34.2</td>
            <td>2 3/8 EUE</td>
            <td>60.3</td>
            <td>4.500</td>
            <td>114.30</td>
            <td>1.938</td>
            <td>49.23</td>
          </tr>
          <tr>
            <td>
              5<sub>1/2</sub>
            </td>
            <td>139.70</td>
            <td>13.0-14.0</td>
            <td>19.2-23.1</td>
            <td>2 7/8 EUE</td>
            <td>73.0</td>
            <td>4.781</td>
            <td>121.44</td>
            <td>2.375</td>
            <td>60.30</td>
          </tr>
          <tr>
            <td>
              5<sub>1/2</sub>
            </td>
            <td>139.70</td>
            <td>15.5-20.0</td>
            <td>23.1-29.8</td>
            <td>2 7/8 EUE</td>
            <td>73.0</td>
            <td>4.625</td>
            <td>117.48</td>
            <td>2.375</td>
            <td>60.30</td>
          </tr>
          <tr>
            <td>
              5<sub>1/2</sub>
            </td>
            <td>139.70</td>
            <td>20.0-23.0</td>
            <td>29.8-34.2</td>
            <td>2 7/8 EUE</td>
            <td>73.0</td>
            <td>4.500</td>
            <td>114.30</td>
            <td>2.375</td>
            <td>60.30</td>
          </tr>
          <tr>
            <td>
              5<sub>1/2</sub>
            </td>
            <td>139.70</td>
            <td>23.0-26.0</td>
            <td>34.2-38.8</td>
            <td>2 7/8 EUE</td>
            <td>73.0</td>
            <td>4.406</td>
            <td>111.91</td>
            <td>2.375</td>
            <td>60.30</td>
          </tr>
          <tr>
            <td>
              6<sub>5/8</sub>
            </td>
            <td>168.28</td>
            <td>24.0-32.0</td>
            <td>35.8-47.6</td>
            <td>2 7/8 EUE</td>
            <td>73.0</td>
            <td>5.500</td>
            <td>139.70</td>
            <td>2.500</td>
            <td>63.50</td>
          </tr>
          <tr>
            <td>
              6<sub>5/8</sub>
            </td>
            <td>168.28</td>
            <td>17.0-24.0</td>
            <td>25.3-35.7</td>
            <td>2 7/8 EUE</td>
            <td>73.0</td>
            <td>5.750</td>
            <td>146.10</td>
            <td>2.500</td>
            <td>63.50</td>
          </tr>
          <tr>
            <td>7</td>
            <td>177.80</td>
            <td>17.0-26.0</td>
            <td>25.3-38.7</td>
            <td>2 7/8 EUE</td>
            <td>73.0</td>
            <td>6.000</td>
            <td>152.40</td>
            <td>2.500</td>
            <td>63.50</td>
          </tr>
          <tr>
            <td>7</td>
            <td>177.80</td>
            <td>23.0-29.0</td>
            <td>34.2-43.2</td>
            <td>2 7/8 EUE</td>
            <td>73.0</td>
            <td>5.969</td>
            <td>151.61</td>
            <td>2.500</td>
            <td>63.50</td>
          </tr>
          <tr>
            <td>7</td>
            <td>177.80</td>
            <td>26.0-32.0</td>
            <td>38.7-47.6</td>
            <td>2 7/8 EUE</td>
            <td>73.0</td>
            <td>5.875</td>
            <td>149.23</td>
            <td>2.500</td>
            <td>63.50</td>
          </tr>
          <tr>
            <td>7</td>
            <td>177.80</td>
            <td>17.0-26.0</td>
            <td>25.3-38.7</td>
            <td>3 1/2 EUE</td>
            <td>88.9</td>
            <td>6.000</td>
            <td>152.40</td>
            <td>3.000</td>
            <td>76.20</td>
          </tr>
          <tr>
            <td>7</td>
            <td>177.80</td>
            <td>26.0-32.0</td>
            <td>38.7-47.6</td>
            <td>3 1/2 EUE</td>
            <td>88.9</td>
            <td>5.875</td>
            <td>149.23</td>
            <td>3.000</td>
            <td>63.50</td>
          </tr>
          <tr>
            <td>
              7<sub>5/8</sub>
            </td>
            <td>193.68</td>
            <td>29.7-33.7</td>
            <td>44.2-50.1</td>
            <td>2 7/8 EUE</td>
            <td>73.0</td>
            <td>6.625</td>
            <td>169.47</td>
            <td>2.500</td>
            <td>63.50</td>
          </tr>
          <tr>
            <td>
              7<sub>5/8</sub>
            </td>
            <td>193.68</td>
            <td>33.7-39.0</td>
            <td>49.1-58.0</td>
            <td>2 7/8 EUE</td>
            <td>73.0</td>
            <td>6.453</td>
            <td>163.91</td>
            <td>2.500</td>
            <td>63.50</td>
          </tr>
          <tr>
            <td>
              8<sub>5/8</sub>
            </td>
            <td>219.08</td>
            <td>24.0-40.0</td>
            <td>35.7-59.5</td>
            <td>4 1/2 EUE</td>
            <td>114.3</td>
            <td>7.750</td>
            <td>190.50</td>
            <td>4.000</td>
            <td>101.60</td>
          </tr>
          <tr>
            <td>
              8<sub>5/8</sub>
            </td>
            <td>219.08</td>
            <td>20.0-28.0</td>
            <td>29.8-59.5</td>
            <td>4 1/2 EUE</td>
            <td>114.3</td>
            <td>7.750</td>
            <td>190.50</td>
            <td>4.000</td>
            <td>101.60</td>
          </tr>
          <tr>
            <td>
              9<sub>5/8</sub>
            </td>
            <td>244.48</td>
            <td>32.3-43.5</td>
            <td>48.2-64.9</td>
            <td>4 1/2 EUE</td>
            <td>114.3</td>
            <td>8.500</td>
            <td>215.90</td>
            <td>4.000</td>
            <td>101.60</td>
          </tr>
          <tr>
            <td>
              9<sub>5/8</sub>
            </td>
            <td>244.48</td>
            <td>43.5-53.5</td>
            <td>64.9-79.8</td>
            <td>4 1/2 EUE</td>
            <td>114.3</td>
            <td>8.250</td>
            <td>209.55</td>
            <td>4.000</td>
            <td>101.60</td>
          </tr>
          <tr>
            <td>
              10<sub>3/4</sub>
            </td>
            <td>273.05</td>
            <td>32.8-51.0</td>
            <td>48.9-76.1</td>
            <td>4 1/2 EUE</td>
            <td>114.3</td>
            <td>9.625</td>
            <td>244.48</td>
            <td>4.000</td>
            <td>101.60</td>
          </tr>
          <tr>
            <td>
              10<sub>3/4</sub>
            </td>
            <td>273.05</td>
            <td>51.0-65.7</td>
            <td>78.1-98.0</td>
            <td>4 1/2 EUE</td>
            <td>114.3</td>
            <td>9.312</td>
            <td>236.52</td>
            <td>4.000</td>
            <td>101.60</td>
          </tr>
          <tr>
            <td>
              11<sub>3/4</sub>
            </td>
            <td>298.45</td>
            <td>42.0-65.0</td>
            <td>62.6-97.0</td>
            <td>4 1/2 EUE</td>
            <td>114.3</td>
            <td>10.438</td>
            <td>265.13</td>
            <td>4.000</td>
            <td>101.60</td>
          </tr>
          <tr>
            <td>
              13<sub>3/8</sub>
            </td>
            <td>339.73</td>
            <td>54.5-77.0</td>
            <td>81.3-114.8</td>
            <td>4 1/2 EUE</td>
            <td>114.3</td>
            <td>12.000</td>
            <td>304.80</td>
            <td>4.000</td>
            <td>101.60</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Es1x8Table;
