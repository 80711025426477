import React from 'react'
import ErrorPage from '../../components/Utility/ErrorPage'

function NotFoundPage() {
  return (
    <div>
        <ErrorPage />
    </div>
  )
}

export default NotFoundPage