import React from "react";
import { $, jQuery } from "jquery";

function IeThermalPackerTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr>
            <th colSpan={2}>Casing</th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Recommended Hole Size (Inches)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Gage OD (Inches)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Max OD (Inches)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Min ID (Inches)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Thread Connections Box Up / Pin Down
            </th>
            <th rowSpan={2}>Part Number</th>
          </tr>
          <tr>
            <th>Size (inches)</th>
            <th>Weight (lbs/ft)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan={2}>5</td>
            <td>11.5-15.0</td>
            <td>4.408-4.560</td>
            <td>4.125</td>
            <td>4220<sup>1</sup></td>
            <td>1.50</td>
            <td>2-3/8 EUE</td>
            <td>74150</td>
          </tr>
          <tr>
            <td>18.0-20.8</td>
            <td>4.4156-4.276</td>
            <td>4.000</td>
            <td>-</td>
            <td>1.50</td>
            <td>2-3/8 EUE</td>
            <td>74052</td>
          </tr>
          <tr>
            <td rowSpan={2}>7</td>
            <td>17.0-26.0</td>
            <td>6.276-6.538</td>
            <td>6.000</td>
            <td>6.125<sup>1</sup></td>
            <td>3.00</td>
            <td>3-1/2 EUE</td>
            <td>74170</td>
          </tr>
          <tr>
            <td>26.0-32.0</td>
            <td>6.094-6.276</td>
            <td>5.875</td>
            <td>-</td>
            <td>3.00</td>
            <td>3-1/2 EUE</td>
            <td>74171</td>
          </tr>
          <tr>
            <td>9-5/8</td>
            <td>43.5-53.5</td>
            <td>8.535-8.755</td>
            <td>8.250</td>
            <td>-</td>
            <td>4.00</td>
            <td>4-1/2 EUE</td>
            <td>74195</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default IeThermalPackerTable;
