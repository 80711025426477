import React from "react";
import { $, jQuery } from "jquery";

function Es1xTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr>
            <th colspan="2">CASING SIZE</th>
            <th colspan="2">CASING WEIGHT</th>
            <th colspan="2">TUBING SIZE</th>
            <th colspan="2">MAX OD</th>
            <th colspan="2">MIN ID</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>in.</td>
            <td>mm</td>
            <td>lbs/ft</td>
            <td>kg/m</td>
            <td>in.</td>
            <td>mm</td>
            <td>in.</td>
            <td>mm</td>
            <td>in.</td>
            <td>mm</td>
          </tr>
          <tr>
            <td>
              4<sup>1/2</sup>
            </td>
            <td>114.3</td>
            <td>9.5-13.5</td>
            <td>14.1-20.1</td>
            <td>2 3/8 EUE</td>
            <td>60.3</td>
            <td>3.750</td>
            <td>95.25</td>
            <td>1.938</td>
            <td>50.80</td>
          </tr>
          <tr>
            <td>
              5<sup>1/2</sup>
            </td>
            <td>139.7</td>
            <td>14.0-20.0</td>
            <td>19.2-29.8</td>
            <td>2 3/8 EUE</td>
            <td>60.3</td>
            <td>4.625</td>
            <td>117.44</td>
            <td>1.938</td>
            <td>50.80</td>
          </tr>
          <tr>
            <td>
              5<sup>1/2</sup>
            </td>
            <td>139.7</td>
            <td>15.5-20.0</td>
            <td>23.1-29.8</td>
            <td>2 7/8 EUE</td>
            <td>73.0</td>
            <td>4.625</td>
            <td>117.48</td>
            <td>2.500</td>
            <td>63.50</td>
          </tr>
          <tr>
            <td>7</td>
            <td>177.8</td>
            <td>26.0-32.0</td>
            <td>38.7-47.6</td>
            <td>2 7/8 EUE</td>
            <td>73.0</td>
            <td>5.875</td>
            <td>149.23</td>
            <td>2.500</td>
            <td>63.50</td>
          </tr>
          <tr>
            <td>7</td>
            <td>177.8</td>
            <td>17.0-26.0</td>
            <td>25.3-38.7</td>
            <td>2 7/8 EUE</td>
            <td>73.0</td>
            <td>6.000</td>
            <td>152.40</td>
            <td>2.500</td>
            <td>63.50</td>
          </tr>
          <tr>
            <td>7</td>
            <td>177.8</td>
            <td>17.0-23.0</td>
            <td>25.3-38.7</td>
            <td>2 7/8 EUE</td>
            <td>73.0</td>
            <td>6.250</td>
            <td>158.75</td>
            <td>2.500</td>
            <td>63.50</td>
          </tr>
          <tr>
            <td>7</td>
            <td>177.8</td>
            <td>26.0-32.0</td>
            <td>38.7-47.6</td>
            <td>3 1/2 EUE</td>
            <td>88.9</td>
            <td>5.875</td>
            <td>149.23</td>
            <td>3.000</td>
            <td>76.20</td>
          </tr>
          <tr>
            <td>7</td>
            <td>177.8</td>
            <td>17.0-26.0</td>
            <td>25.3-38.7</td>
            <td>3 1/2 EUE</td>
            <td>88.9</td>
            <td>6.000</td>
            <td>152.40</td>
            <td>3.000</td>
            <td>76.20</td>
          </tr>
          <tr>
            <td>7</td>
            <td>177.8</td>
            <td>17.0-23.0</td>
            <td>25.3-38.7</td>
            <td>3 1/2 EUE</td>
            <td>88.9</td>
            <td>6.250</td>
            <td>158.75</td>
            <td>3.000</td>
            <td>76.20</td>
          </tr>
          <tr>
            <td>
              9<sup>5/8</sup>
            </td>
            <td>244.5</td>
            <td>32.3-43.5</td>
            <td>48.1-64.7</td>
            <td>3 1/2 EUE</td>
            <td>88.9</td>
            <td>8.500</td>
            <td>215.90</td>
            <td>3.000</td>
            <td>76.20</td>
          </tr>
          <tr>
            <td>
              9<sup>5/8</sup>
            </td>
            <td>244.5</td>
            <td>40.0-53.5</td>
            <td>59.5-79.6</td>
            <td>3 1/2 EUE</td>
            <td>88.9</td>
            <td>8.250</td>
            <td>209.55</td>
            <td>3.000</td>
            <td>76.20</td>
          </tr>
          <tr>
            <td>
              11<sup>3/4</sup>
            </td>
            <td>298.5</td>
            <td>42.0-64.9</td>
            <td>62.6-96.6</td>
            <td>4 1/2 EUE</td>
            <td>114.3</td>
            <td>10.500</td>
            <td>265.13</td>
            <td>4.000</td>
            <td>101.60</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Es1xTable;
