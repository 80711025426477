import React from 'react'
import {$,jQuery} from 'jquery';

function HcAnchorCatcherTable() {
    
  return (
    <div>  
        <table id="example" class="table table-striped table-bordered" cellspacing="0" width="100%">
        <thead>
        <tr>
          <th colspan="2">CASING SIZE</th>
          <th colspan="2">CASING WEIGHT</th>
          <th colspan="2">BODY MAX OD</th>
          <th colspan="2">MIN ID</th>
          <th colspan="1">THREAD CONNECTION</th>
        </tr>
        </thead>
 
        <tbody>
        <tr style={{backgroundColor: '#BCBEC0'}}>
          <td>in.</td>
          <td>mm</td>
          <td>lbs/ft</td>
          <td>kg/m</td>
          <td>in.</td>
          <td>mm</td>
          <td>in.</td>
          <td>mm</td>
          <td>in. (mm)</td>
        </tr>
        <tr>
          <td>5 <sup>1/2</sup></td>
          <td>139.7</td>
          <td>13.0-20.0</td>
          <td>19.38-29.83</td>
          <td>4.50</td>
          <td>114.3</td>
          <td>1.990</td>
          <td>50.54</td>
          <td>2 3/8 (60.3 mm) EUE</td>
        </tr>
        <tr>
          <td>7 </td>
          <td>177.8</td>
          <td>17.0-26.0</td>
          <td>25.35-38.78</td>
          <td>6.00</td>
          <td>152.4</td>
          <td>2.440</td>
          <td>61.98</td>
          <td>2 7/8 (72.0 mm) EUE</td>
        </tr>
        <tr>
          <td>8 <sup>5/8</sup></td>
          <td>219.1</td>
          <td>20.0-36.0</td>
          <td>29.83-53.69</td>
          <td>7.63</td>
          <td>193.8</td>
          <td>3.000</td>
          <td>76.20</td>
          <td>3 1/2 (88.9 mm) EUE</td>
        </tr>
        <tr>
          <td>9 <sup>5/8</sup></td>
          <td>244.4</td>
          <td>32.0-53.5</td>
          <td>47.72-79.79</td>
          <td>8.25</td>
          <td>209.6</td>
          <td>3.000</td>
          <td>76.20</td>
          <td>3 1/2 (88.9 mm) EUE</td>
        </tr>
        </tbody>
        </table>
    </div>
  )
}

export default HcAnchorCatcherTable