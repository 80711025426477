import React from "react";
import { $, jQuery } from "jquery";

function DownholeTubingCasingSwivelTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c83">
            <td class="c93" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Tubing Size </span>
              </p>
              <p class="c5">
                <span class="c7">(inches)</span>
              </p>
            </td>
            <td class="c55" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Swivel OD </span>
              </p>
              <p class="c5">
                <span class="c7">(inches)</span>
              </p>
            </td>
            <td class="c65" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Swivel ID </span>
              </p>
              <p class="c5">
                <span class="c7">(inches)</span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Thread Connection </span>
              </p>
              <p class="c5">
                <span class="c7">Box Up / Pin Down</span>
              </p>
            </td>
            <td class="c29" colspan="1" rowspan="1">
              <p class="c214">
                <span class="c7">Part &nbsp;</span>
              </p>
              <p class="c5">
                <span class="c7">Number</span>
              </p>
            </td>
          </tr>
        </thead>

        <tbody>
          <tr class="c13">
            <td class="c93" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.900 </span>
              </p>
            </td>
            <td class="c55" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2.750 </span>
              </p>
            </td>
            <td class="c65" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.43 </span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.900 EUE / 1.900 NUE </span>
              </p>
            </td>
            <td class="c29" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">83019</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c93" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c2">2-3/8</span>
              </p>
            </td>
            <td class="c55" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">3.580 </span>
              </p>
            </td>
            <td class="c65" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2.00 </span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2-3/8 EUE </span>
              </p>
            </td>
            <td class="c29" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">83021</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c55" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">3.750 </span>
              </p>
            </td>
            <td class="c65" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2.00 </span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2-3/8 EUE </span>
              </p>
            </td>
            <td class="c29" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">83020</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c93" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2-7/8 </span>
              </p>
            </td>
            <td class="c55" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">4.400 </span>
              </p>
            </td>
            <td class="c65" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2.50 </span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2-7/8 EUE </span>
              </p>
            </td>
            <td class="c29" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">83025</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c93" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">3-1/2 </span>
              </p>
            </td>
            <td class="c55" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">5.000 </span>
              </p>
            </td>
            <td class="c65" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">3.00 </span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">3-1/2 EUE </span>
              </p>
            </td>
            <td class="c29" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">83035</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c93" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">4 </span>
              </p>
            </td>
            <td class="c55" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">4.500 </span>
              </p>
            </td>
            <td class="c65" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">3.38 </span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">4&rdquo; NUE </span>
              </p>
            </td>
            <td class="c29" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">83040</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c93" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c2">4-1/2</span>
              </p>
            </td>
            <td class="c55" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">5.750 </span>
              </p>
            </td>
            <td class="c65" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">4.00 </span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">4-1/2 LTC </span>
              </p>
            </td>
            <td class="c29" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">83045</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c55" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">6.000 </span>
              </p>
            </td>
            <td class="c65" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">4.00 </span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">4-1/2 EUE </span>
              </p>
            </td>
            <td class="c29" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">83046</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c93" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c2">5</span>
              </p>
            </td>
            <td class="c55" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">5.875 </span>
              </p>
            </td>
            <td class="c65" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">4.50 </span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">5&rdquo; LTC </span>
              </p>
            </td>
            <td class="c29" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">83051</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c55" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">6.250 </span>
              </p>
            </td>
            <td class="c65" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">4.50 </span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">5&rdquo; LTC </span>
              </p>
            </td>
            <td class="c29" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">83050</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c93" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">5-1/2 </span>
              </p>
            </td>
            <td class="c55" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">6.450 </span>
              </p>
            </td>
            <td class="c65" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">4.80 </span>
              </p>
            </td>
            <td class="c18" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">5-1/2 LTC </span>
              </p>
            </td>
            <td class="c29" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">83056</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>

      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c83">
            <td class="c186" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Casing OD </span>
              </p>
              <p class="c5">
                <span class="c7">(inches)</span>
              </p>
            </td>
            <td class="c9" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Swivel OD </span>
              </p>
              <p class="c5">
                <span class="c7">(inches)</span>
              </p>
            </td>
            <td class="c86" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Swivel ID </span>
              </p>
              <p class="c5">
                <span class="c7">(inches)</span>
              </p>
            </td>
            <td class="c10" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Thread Connection </span>
              </p>
              <p class="c5">
                <span class="c7">Box Up / Pin Down</span>
              </p>
            </td>
            <td class="c94" colspan="1" rowspan="1">
              <p class="c159">
                <span class="c7">Part &nbsp;</span>
              </p>
              <p class="c5">
                <span class="c7">Number</span>
              </p>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="c13">
            <td class="c186" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c2">7 </span>
              </p>
            </td>
            <td class="c9" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c2">8.500</span>
              </p>
            </td>
            <td class="c86" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">6.25 </span>
              </p>
            </td>
            <td class="c10" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">7&rdquo; LTC </span>
              </p>
            </td>
            <td class="c94" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">48070</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c86" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">6.50 </span>
              </p>
            </td>
            <td class="c10" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">7&rdquo; BUTTRESS </span>
              </p>
            </td>
            <td class="c94" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">48070B</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c186" colspan="1" rowspan="3">
              <p class="c5">
                <span class="c2">9-5/8 </span>
              </p>
            </td>
            <td class="c9" colspan="1" rowspan="3">
              <p class="c5">
                <span class="c2">12.000 </span>
              </p>
            </td>
            <td class="c86" colspan="1" rowspan="3">
              <p class="c5">
                <span class="c2">9.00</span>
              </p>
            </td>
            <td class="c10" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">9-5/8 LTC </span>
              </p>
            </td>
            <td class="c94" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">48095</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c10" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">9-5/8 BUTTRESS </span>
              </p>
            </td>
            <td class="c94" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">48095B</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c10" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">9-5/8 LTC </span>
              </p>
            </td>
            <td class="c94" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">48013</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default DownholeTubingCasingSwivelTable;
