import React from "react";
import { $, jQuery } from "jquery";

function HydrosetIIPackerTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
    <tr>
      <th colspan="2">CASING</th>
      <th rowspan="2">RECOMMENDED HOLE SIZE<br />(inches)</th>
      <th rowspan="2">TOOL OD<br />(inches)</th>
      <th colspan="2">LONG STRING</th>
      <th colspan="2">SHORT STRING</th>
      <th rowspan="2">Part<br />Number</th>
    </tr>
    <tr>
      <th>SIZE (inches)</th>
      <th>WEIGHT (lbs/ft)</th>
      <th>CONNECTIONS</th>
      <th>MIN ID (inches)</th>
      <th>CONNECTIONS</th>
      <th>MIN ID (inches)</th>
    </tr>
  </thead>

  <tbody>
    <tr>
      <td>5-1/2</td>
      <td>14.0-20.0</td>
      <td>4.778-5.012</td>
      <td>4.625</td>
      <td>1.900 EUE</td>
      <td>1.50</td>
      <td>1.900 NUE/1.660 EUE</td>
      <td>1.38</td>
      <td>90555-BAA-BBA</td>
    </tr>
    <tr>
      <td rowspan="8">7</td>
      <td rowspan="3">17.0-26.0</td>
      <td rowspan="3">6.276-6.538</td>
      <td rowspan="3">6.062</td>
      <td>2 3/8 EUE</td>
      <td>1.94</td>
      <td>2 3/8 EUE</td>
      <td>1.94</td>
      <td>90572-BAB</td>
    </tr>
    <tr>
      <td rowspan="2">2 7/8 EUE</td>
      <td>2.50</td>
      <td>1.900 EUE</td>
      <td>1.61</td>
      <td>90572-BAC-BAA</td>
    </tr>
    <tr>
      <td>2.50</td>
      <td>1.900 NUE</td>
      <td>1.50</td>
      <td>90572-BAC-BBA</td>
    </tr>
    <tr>
      <td rowspan="4">26.0-32.0</td>
      <td rowspan="4">6.094-6.276</td>
      <td rowspan="4">5.938</td>
      <td>2 3/8 EUE</td>
      <td>1.94</td>
      <td>2 3/8 EUE</td>
      <td>1.94</td>
      <td>90570-BAB</td>
    </tr>
    <tr>
      <td>2 7/8 EUE</td>
      <td>2.50</td>
      <td>1.900 EUE</td>
      <td>1.50</td>
      <td>90570-BAC-BBA</td>
    </tr>
    <tr>
      <td>2 7/8 NUE</td>
      <td>2.50</td>
      <td>1.900 NUE/1.900 EUE</td>
      <td>1.50</td>
      <td>90570-BBC-BBA</td>
    </tr>
    <tr>
      <td>2 7/8 EUE</td>
      <td>2.50</td>
      <td>1.900 NUE</td>
      <td>1.50</td>
      <td>90570-BAC-BAA</td>
    </tr>
    <tr>
      <td>29.0-35.0</td>
      <td>6.004-6.184</td>
      <td>5.875</td>
      <td>2 3/8 EUE</td>
      <td>1.94</td>
      <td>2 3/8 EUE</td>
      <td>1.94</td>
      <td>90571-BAB</td>
    </tr>
    <tr>
      <td>7-5/8</td>
      <td>24.0-29.7</td>
      <td>6.875-7.025</td>
      <td>6.672</td>
      <td>2 7/8 EUE</td>
      <td>2.39</td>
      <td>2 3/8 NUE/2 3/8 EUE</td>
      <td>1.94</td>
      <td>90575-BAC-BAB</td>
    </tr>
    <tr>
      <td rowspan="3">8-5/8</td>
      <td rowspan="2">24.0-32.0</td>
      <td rowspan="2">7.921-9.097</td>
      <td rowspan="2">7.688</td>
      <td>2 7/8 EUE</td>
      <td>2.39</td>
      <td>2 7/8 NUE/2 7/8 EUE</td>
      <td>2.39</td>
      <td>90585-BAC</td>
    </tr>
    <tr>
      <td>3 1/2 EUE</td>
      <td>3.00</td>
      <td>2 3/8 EUE</td>
      <td>2.00</td>
      <td>90585-BAE-BAB</td>
    </tr>
    <tr>
      <td>36.0-40.0</td>
      <td>7.725-7.825</td>
      <td>7.438</td>
      <td>2 7/8 EUE</td>
      <td>2.44</td>
      <td>2 3/8 EUE</td>
      <td>1.94</td>
      <td>90586-BAC-BAB</td>
    </tr>
    <tr>
      <td rowspan="13">9-5/8</td>
      <td rowspan="4">32.3-40.0</td>
      <td rowspan="4">8.835-9.001</td>
      <td rowspan="4">8.615</td>
      <td rowspan="3">3 1/2 EUE</td>
      <td rowspan="3">3.00</td>
      <td>1.900</td>
      <td>1.61</td>
      <td>90597-BAE-BAA</td>
    </tr>
    <tr>
      <td>2 3/8 EUE</td>
      <td>1.94</td>
      <td>90597-BAE-BAB</td>
    </tr>
    <tr>
      <td>2 7/8 EUE</td>
      <td>2.44</td>
      <td>90597-BAE-BAC</td>
    </tr>
    <tr>
      <td>4 1/2 EUE</td>
      <td>4.00</td>
      <td>1.900 EUE</td>
      <td>1.61</td>
      <td>90597-BAG-BAA</td>
    </tr>
    <tr>
      <td>32.3-47.0</td>
      <td>8.681-9.001</td>
      <td>8.500</td>
      <td>3 1/2 EUE</td>
      <td>3.00</td>
      <td>3 1/2 EUE</td>
      <td>3.00</td>
      <td>90595-BAE</td>
    </tr>
    <tr>
      <td rowspan="3">36.0-43.5</td>
      <td rowspan="3">8.755-89.21</td>
      <td rowspan="3">8.500</td>
      <td rowspan="2">3 1/2 EUE</td>
      <td rowspan="2">3.00</td>
      <td>2 3/8 EUE</td>
      <td>2.00</td>
      <td>90595-BAE-BAB</td>
    </tr>
    <tr>
      <td>2 7/8 EUE</td>
      <td>2.39</td>
      <td>90595-BAE-BAC</td>
    </tr>
    <tr>
      <td>4 1/2 EUE</td>
      <td>4.00</td>
      <td>1.900 EUE</td>
      <td>1.19</td>
      <td>0595-BAG-BAA</td>
    </tr>
    <tr>
      <td rowspan="5">47.0-53.5</td>
      <td rowspan="5">8.535-8.681</td>
      <td rowspan="5">8.312</td>
      <td rowspan="5">3 1/2 EUE</td>
      <td rowspan="5">3.00</td>
      <td>1.900 EUE</td>
      <td>1.61</td>
      <td>90596-BAE-BAA</td>
    </tr>
    <tr>
      <td>1.900 NUE</td>
      <td>1.61</td>
      <td>90596-BAE-BBA</td>
    </tr>
    <tr>
      <td>2 3/8 EUE</td>
      <td>2.00</td>
      <td>90596-BAE-BAB</td>
    </tr>
    <tr>
      <td>2 7/8 EUE</td>
      <td>2.39</td>
      <td>90596-BAE-BAC</td>
    </tr>
    <tr>
      <td>3 1/2 EUE</td>
      <td>3.00</td>
      <td>90596-BAE</td>
    </tr>
    <tr>
      <td>10-3/4</td>
      <td>51.0-65.7</td>
      <td>9.560-9.850</td>
      <td>9.312</td>
      <td>4" EUE</td>
      <td>3.50</td>
      <td>2 7/8 EUE</td>
      <td>2.39</td>
      <td>90510-BAF-BAC</td>
    </tr>
  </tbody>
      </table>
    </div>
  );
}

export default HydrosetIIPackerTable;
