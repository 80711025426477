import React from "react";
import { $, jQuery } from "jquery";

function As5PackerTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr>
            <th colSpan={2}>Casing</th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Recommended Hole Size (Inches)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Tool OD (Inches)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Tool ID (Inches)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "black" }}>
              Thread Connections Box Up / Pin Down
            </th>
            <th rowSpan={2}>Part Number</th>
          </tr>
          <tr>
            <th>Size (inches)</th>
            <th>Weight (lbs/ft)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>4-1/2</td>
            <td>9.5-13.5</td>
            <td>3.920-4.090</td>
            <td>3.750</td>
            <td>1.94</td>
            <td>2-3/8 EUE</td>
            <td>60845</td>
          </tr>
          <tr>
            <td rowSpan={4}>5-1/2</td>
            <td rowSpan={2}>14.0-20.0</td>
            <td rowSpan={2}>4.778-5.012</td>
            <td rowSpan={2}>4.625</td>
            <td>2.00</td>
            <td>2-3/8 EUE</td>
            <td>60855</td>
          </tr>
          <tr>
            <td>2.38</td>
            <td>2-7/8 EUE</td>
            <td>60856</td>
          </tr>
          <tr>
            <td rowSpan={2}>20.0-23.0</td>
            <td rowSpan={2}>4.670-4.778</td>
            <td rowSpan={2}>4.500</td>
            <td>2.00</td>
            <td>2-3/8 EUE</td>
            <td>60857</td>
          </tr>
          <tr>
            <td>2.38</td>
            <td>2-7/8 EUE</td>
            <td>60859</td>
          </tr>
          <tr>
            <td rowSpan={3}>7</td>
            <td rowSpan={2}>17.0-26.0</td>
            <td rowSpan={2}>6.276-6.538</td>
            <td rowSpan={2}>6.000</td>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>60872</td>
           </tr>
          <tr>
            <td>3.00</td>
            <td>3-1/2 EUE</td>
            <td>60874</td>
          </tr>
          <tr>
            <td>26.0-32.0</td>
            <td>6.094-6.276</td>
            <td>5.875</td>
            <td>2.50</td>
            <td>2-7/8 EUE</td>
            <td>60870</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default As5PackerTable;
