import React from "react";
import { $, jQuery } from "jquery";

function RsbRetrievingTable() {
  return (
    <div>
      <p class="smaller-head">Product Specifcations - Single Bore RSB </p>

      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c150">
            <td class="c172" colspan="1" rowspan="1">
              Packer Size (inches)
            </td>
            <td class="c309" colspan="1" rowspan="1">
              Packer Bore (inches)
            </td>
            <td class="c258" colspan="1" rowspan="1">
              Tool OD (inches)
            </td>
            <td class="c40" colspan="1" rowspan="1">
              Tool ID (inches)
            </td>
            <td class="c184" colspan="1" rowspan="1">
              Top Connection
            </td>
            <td class="c15" colspan="1" rowspan="1">
              Part Number
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="c25">
            <td class="c172" colspan="1" rowspan="1">
              5
            </td>
            <td class="c309" colspan="1" rowspan="1">
              2.688
            </td>
            <td class="c258" colspan="1" rowspan="1">
              3.500
            </td>
            <td class="c40" colspan="1" rowspan="1">
              1.00
            </td>
            <td class="c184" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c15" colspan="1" rowspan="1">
              26650
            </td>
          </tr>
          <tr class="c25">
            <td class="c172" colspan="1" rowspan="1">
              5-1/2
            </td>
            <td class="c309" colspan="1" rowspan="1">
              3.000
            </td>
            <td class="c258" colspan="1" rowspan="1">
              3.688
            </td>
            <td class="c40" colspan="1" rowspan="1">
              1.25
            </td>
            <td class="c184" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c15" colspan="1" rowspan="1">
              26655
            </td>
          </tr>
          <tr class="c25">
            <td class="c172" colspan="1" rowspan="1">
              6-5/8
            </td>
            <td class="c309" colspan="1" rowspan="1">
              3.250
            </td>
            <td class="c258" colspan="1" rowspan="1">
              4.250
            </td>
            <td class="c40" colspan="1" rowspan="1">
              1.38
            </td>
            <td class="c184" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c15" colspan="1" rowspan="1">
              26665
            </td>
          </tr>
          <tr class="c25">
            <td class="c172" colspan="1" rowspan="2">
              7
            </td>
            <td class="c309" colspan="1" rowspan="1">
              3.000
            </td>
            <td class="c258" colspan="1" rowspan="1">
              3.688
            </td>
            <td class="c40" colspan="1" rowspan="1">
              1.25
            </td>
            <td class="c184" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c15" colspan="1" rowspan="1">
              26655
            </td>
          </tr>
          <tr class="c25">
            <td class="c309" colspan="1" rowspan="1">
              4.000
            </td>
            <td class="c258" colspan="1" rowspan="1">
              5.062
            </td>
            <td class="c40" colspan="1" rowspan="1">
              1.75
            </td>
            <td class="c184" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c15" colspan="1" rowspan="1">
              26670
            </td>
          </tr>
          <tr class="c25">
            <td class="c172" colspan="1" rowspan="1">
              9-5/8
            </td>
            <td class="c309" colspan="1" rowspan="1">
              6.000
            </td>
            <td class="c258" colspan="1" rowspan="1">
              6.980
            </td>
            <td class="c40" colspan="1" rowspan="1">
              2.75
            </td>
            <td class="c184" colspan="1" rowspan="1">
              4-1/2 EUE
            </td>
            <td class="c15" colspan="1" rowspan="1">
              26696
            </td>
          </tr>
          <tr class="c25">
            <td class="c172" colspan="1" rowspan="1">
              13-3/8
            </td>
            <td class="c309" colspan="1" rowspan="1">
              9.000
            </td>
            <td class="c258" colspan="1" rowspan="1">
              9.365
            </td>
            <td class="c40" colspan="1" rowspan="1">
              5.75
            </td>
            <td class="c184" colspan="1" rowspan="1">
              7&rdquo; LTC
            </td>
            <td class="c15" colspan="1" rowspan="1">
              26613
            </td>
          </tr>
        </tbody>
      </table>


      <p class="smaller-head">Product Specifcations - Dual Bore RSB </p>

      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c150">
            <td class="c172" colspan="1" rowspan="1">
              Packer Size (inches)
            </td>
            <td class="c577" colspan="1" rowspan="1">
              Packer Bore (inches)
            </td>
            <td class="c264" colspan="1" rowspan="1">
              Tool OD (inches)
            </td>
            <td class="c40" colspan="1" rowspan="1">
              Tool ID (inches)
            </td>
            <td class="c184" colspan="1" rowspan="1">
              Top Connection
            </td>
            <td class="c545" colspan="1" rowspan="1">
              Part Number
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="c25">
            <td class="c172" colspan="1" rowspan="1">
              4-1/2
            </td>
            <td class="c577" colspan="1" rowspan="1">
              3.50
            </td>
            <td class="c264" colspan="1" rowspan="1">
              0.50
            </td>
            <td class="c40" colspan="1" rowspan="1">
              2.688 / 2.062
            </td>
            <td class="c184" colspan="1" rowspan="1">
              2-3/8 EUE
            </td>
            <td class="c545" colspan="1" rowspan="1">
              26945RT
            </td>
          </tr>
          <tr class="c25">
            <td class="c172" colspan="1" rowspan="1">
              5
            </td>
            <td class="c577" colspan="1" rowspan="1">
              3.000 / 2.688
            </td>
            <td class="c264" colspan="1" rowspan="1">
              3.688
            </td>
            <td class="c40" colspan="1" rowspan="1">
              1.00
            </td>
            <td class="c184" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c545" colspan="1" rowspan="1">
              26950RT
            </td>
          </tr>
          <tr class="c25">
            <td class="c172" colspan="1" rowspan="1">
              5-1/2
            </td>
            <td class="c577" colspan="1" rowspan="1">
              3.000
            </td>
            <td class="c264" colspan="1" rowspan="1">
              3.688
            </td>
            <td class="c40" colspan="1" rowspan="1">
              1.25
            </td>
            <td class="c184" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c545" colspan="1" rowspan="1">
              26655
            </td>
          </tr>
          <tr class="c25">
            <td class="c172" colspan="1" rowspan="1">
              7
            </td>
            <td class="c577" colspan="1" rowspan="1">
              5.250 / 4.000
            </td>
            <td class="c264" colspan="1" rowspan="1">
              5.062
            </td>
            <td class="c40" colspan="1" rowspan="1">
              1.75
            </td>
            <td class="c184" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c545" colspan="1" rowspan="1">
              26670
            </td>
          </tr>
          <tr class="c25">
            <td class="c172" colspan="1" rowspan="1">
              7-5/8
            </td>
            <td class="c577" colspan="1" rowspan="1">
              4.750 / 4.000
            </td>
            <td class="c264" colspan="1" rowspan="1">
              5.810
            </td>
            <td class="c40" colspan="1" rowspan="1">
              1.75
            </td>
            <td class="c184" colspan="1" rowspan="1">
              2-7/8 EUE
            </td>
            <td class="c545" colspan="1" rowspan="1">
              26975
            </td>
          </tr>
        </tbody>
      </table>


      <p class="smaller-head">Product Specifcations - Hydraulic Set RSB </p>

      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >

<thead>
<tr class="c150">
        <td class="c172" colspan="1" rowspan="1">
            Packer Size 
            (inches)
        </td>
        <td class="c92" colspan="1" rowspan="1">
            Packer Bore 
            (inches)
        </td>
        <td class="c264" colspan="1" rowspan="1">
            Tool OD 
            (inches)
        </td>
        <td class="c40" colspan="1" rowspan="1">
            Tool ID 
            (inches)
        </td>
        <td class="c184" colspan="1" rowspan="1">
            Top 
            Connection
        </td>
        <td class="c545" colspan="1" rowspan="1">
            Part 
            Number
        </td>
    </tr>
</thead>
<tbody>
<tr class="c25">
        <td class="c172" colspan="1" rowspan="1">
            5-1/2 
        </td>
        <td class="c92" colspan="1" rowspan="1">
            3.000 
        </td>
        <td class="c264" colspan="1" rowspan="1">
            3.688 
        </td>
        <td class="c40" colspan="1" rowspan="1">
            1.25 
        </td>
        <td class="c184" colspan="1" rowspan="1">
            2-7/8 EUE 
        </td>
        <td class="c545" colspan="1" rowspan="1">
            27655
        </td>
    </tr>
    <tr class="c25">
        <td class="c172" colspan="1" rowspan="1">
            7 
        </td>
        <td class="c92" colspan="1" rowspan="1">
            4.000 
        </td>
        <td class="c264" colspan="1" rowspan="1">
            5.062 
        </td>
        <td class="c40" colspan="1" rowspan="1">
            1.75 
        </td>
        <td class="c184" colspan="1" rowspan="1">
            2-7/8 EUE 
        </td>
        <td class="c545" colspan="1" rowspan="1">
            27670
        </td>
    </tr>
</tbody>

        </table>

    </div>
  );
}

export default RsbRetrievingTable;
