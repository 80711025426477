import React from "react";
import { $, jQuery } from "jquery";

function HydraulicSettingToolTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr>
            <th colspan="2">SIZE</th>
            <th colspan="2">SETTING TOOL SIZE</th>
            <th colspan="2">TOOL OD</th>
            <th colspan="1">THREAD CONNECTION</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>in.</td>
            <td>mm</td>
            <td>lbs/ft</td>
            <td>kg/m</td>
            <td>in.</td>
            <td>mm</td>
            <td></td>
          </tr>
          <tr>
            <td>
              1<sup>1/2</sup>
            </td>
            <td>38.1</td>
            <td># 5</td>
            <td>2.13</td>
            <td>54.10</td>
            <td>1 1/2 NPT</td>
            <td>107.95</td>
          </tr>
          <tr>
            <td>
              2<sup>3/8</sup>
            </td>
            <td>60.3</td>
            <td># 10</td>
            <td>3.63</td>
            <td>92.20</td>
            <td>2 3/8 EUE</td>
            <td>107.95</td>
          </tr>
          <tr>
            <td>
              2<sup>7/8</sup>
            </td>
            <td>73.0</td>
            <td># 20</td>
            <td>4.38</td>
            <td>111.25</td>
            <td>2 7/8 EUE</td>
            <td>107.95</td>
          </tr>
          <tr>
            <td>
              3<sup>1/2</sup>
            </td>
            <td>88.9</td>
            <td># 20</td>
            <td>4.50</td>
            <td>114.30</td>
            <td>3 1/2 EUE</td>
            <td>107.95</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default HydraulicSettingToolTable;
