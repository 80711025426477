import React from 'react'
import {$,jQuery} from 'jquery';

function Hm2BridgePlugTable() {
    
  return (
    <div>  
        <table id="example" class="table table-striped table-bordered" cellspacing="0" width="100%">
        <thead>
            <tr>
                <th colSpan={4}>Casing</th>
                <th colSpan={2} rowSpan={2}>Plug OD</th>
                <th colSpan={4}>Setting Range</th>
            </tr>
            <tr>
                <th colSpan={2}>Size</th>
                <th colSpan={2}>Weight</th>
                <th colSpan={2}>Min</th>
                <th colSpan={2}>Max</th>
            </tr>
        </thead>
 
        <tbody>
            <tr style={{backgroundColor: '#BCBEC0'}}>
                <td>in.</td>
                <td>mm</td>
                <td>lbs/ft</td>
                <td>kg/m</td>
                <td>in.</td>
                <td>mm</td>
                <td>in.</td>
                <td>mm</td>
                <td>in.</td>
                <td>mm</td>
            </tr>
            <tr>
                <td>3 <sup>1/2</sup></td>
                <td>88.9</td>
                <td>5.7-10.3</td>
                <td>8.6-15.3</td>
                <td>2.750</td>
                <td>69.85</td>
                <td>2.867</td>
                <td>72.82</td>
                <td>3.258</td>
                <td>82.75</td>
            </tr>
            <tr>
                <td>4</td>
                <td>101.6</td>
                <td>5.6-14.0</td>
                <td>8.3-20.8</td>
                <td>3.190</td>
                <td>81.03</td>
                <td>3.340</td>
                <td>84.84</td>
                <td>3.732</td>
                <td>94.79</td>
            </tr>
            <tr>
                <td>4 <sup>1/2</sup></td>
                <td>114.3</td>
                <td>9.5-16.6</td>
                <td>14.1-24.7</td>
                <td>3.562</td>
                <td>90.47</td>
                <td>3.826</td>
                <td>97.18</td>
                <td>4.090</td>
                <td>103.89</td>
            </tr>
            <tr>
                <td>5</td>
                <td>127</td>
                <td>11.5.20.8</td>
                <td>17.1-31.0</td>
                <td>3.937</td>
                <td>100.00</td>
                <td>4.154</td>
                <td>105.51</td>
                <td>4.560</td>
                <td>115.82</td>
            </tr>
            <tr>
                <td>5 <sup>1/2</sup></td>
                <td>139.7</td>
                <td>13.0-23.0</td>
                <td>19.3-34.2</td>
                <td>4.312</td>
                <td>109.52</td>
                <td>4.580</td>
                <td>116.33</td>
                <td>5.044</td>
                <td>128.12</td>
            </tr>
            <tr>
                <td>5 <sup>3/4</sup></td>
                <td>146.1</td>
                <td>14.0-25.2</td>
                <td>20.8-37.5</td>
                <td>4.669</td>
                <td>119.35</td>
                <td>4.890</td>
                <td>124.21</td>
                <td>5.290</td>
                <td>134.37</td>
            </tr>
            <tr>
                <td>6 <sup>5/8</sup></td>
                <td>168.3</td>
                <td>17.0-32.0</td>
                <td>25.3-47.6</td>
                <td>5.375</td>
                <td>136.53</td>
                <td>5.595</td>
                <td>142.11</td>
                <td>6.135</td>
                <td>155.83</td>
            </tr>
            <tr>
                <td>7</td>
                <td>177.8</td>
                <td>17.0-35.0</td>
                <td>25.3-52.1</td>
                <td>5.687</td>
                <td>144.45</td>
                <td>6.000</td>
                <td>152.40</td>
                <td>6.538</td>
                <td>166.07</td>
            </tr>
            <tr>
                <td>7 <sup>5/8</sup></td>
                <td>193.7</td>
                <td>20.0-39.0</td>
                <td>29.8-58.0</td>
                <td>6.312</td>
                <td>160.32</td>
                <td>6.625</td>
                <td>168.28</td>
                <td>7.125</td>
                <td>180.98</td>
            </tr>
            <tr>
                <td>8 <sup>5/8</sup></td>
                <td>219.1</td>
                <td>24.0-49.0</td>
                <td>35.7-72.9</td>
                <td>7.125</td>
                <td>180.98</td>
                <td>7.310</td>
                <td>185.67</td>
                <td>8.097</td>
                <td>205.66</td>
            </tr>
            <tr>
                <td>9 <sup>5/8</sup></td>
                <td>244.5</td>
                <td>29.3-58.4</td>
                <td>43.6-86.9</td>
                <td>8.175</td>
                <td>207.65</td>
                <td>8.379</td>
                <td>212.83</td>
                <td>9.063</td>
                <td>230.20</td>
            </tr>
            <tr>
                <td>10 <sup>3/4</sup></td>
                <td>273.1</td>
                <td>32.7-60.7</td>
                <td>48.6-90.3</td>
                <td>9.437</td>
                <td>239.70</td>
                <td>9.660</td>
                <td>245.36</td>
                <td>10.192</td>
                <td>258.88</td>
            </tr>
            <tr>
                <td>13 <sup>3/8</sup></td>
                <td>339.7</td>
                <td>48.0-84.5</td>
                <td>71.4-25.7</td>
                <td>11.880</td>
                <td>301.75</td>
                <td>12.175</td>
                <td>309.25</td>
                <td>12.715</td>
                <td>322.96</td>
            </tr>
        </tbody>
        </table>
    </div>
  )
}

export default Hm2BridgePlugTable