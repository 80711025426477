import AboutUs from "../components/Home/AboutUs/AboutUs";
import ReliableToolSupply from "../components/Home/ReliableToolSupply/ReliableToolSupply";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import AboutHeader from "../components/About/AboutHeader";
import AboutContent from "../components/About/AboutContent";
import {useEffect} from 'react';
import OurTeam from "../components/About/OurTeam";
import AgentsSection from "../components/Agents/AgentsSection";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <>
      <AboutHeader />
      <AboutContent />
      {/* <OurTeam /> */}
      <AgentsSection />
    </>
  );
}

export default Home;
