import React from "react";
import './certifications.css'
import LogoOne from '../../assets/logos/1.png'
import LogoTwo from '../../assets/logos/2.png'
import LogoThree from '../../assets/logos/3.png'
import { Link } from 'react-router-dom'

function CertificationsMain() {
  return (
    <div>
      <div className="certifications-container">
        <div className="main-subhead">Quality Certifications</div>

        <div className="certifications-text-container">
          <p className="body-text">
          As Canada’s Largest Stocking Distributor, Maverick is committed to
          supplying the best quality equipment, at market competitive prices and
          with a depth of inventory that allows us to help these Service Company
          clients across the Western Canada Basin and the region. All of these
          products are Certified ISO 9001:2015 and higher, and 100% Made in
          Canada and/or Made in USA.
          </p>
        </div>

        <div className="badge-grid">
          <img src={LogoOne} alt="Logo" />
          <img src={LogoTwo} alt="Logo" />
          <img src={LogoThree} alt="Logo" />
        </div>

        <div className="certifications-label">
          ISO 14310: 2008 V0
          <br />
          ISO 9001: 2015
        </div>
<br />
        <h4 className="smaller-head centre">
          Maverick Downhole Technologies is the Exclusive Canadian<br /> Stocking
          Distributor for Alpha Oil Tools.
        </h4><br />
        <Link to="/alpha-oil-tools">
          <button className="main-btn">
            Learn About Alpha Oil Tools
          </button>
        </Link>
      <br /><br />
      </div>
    </div>
  );
}

export default CertificationsMain;
