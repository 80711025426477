import React from "react";
import { $, jQuery } from "jquery";

function CrossFlowAssemblyTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c83">
            <td class="c25" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Tubing Size </span>
              </p>
              <p class="c5">
                <span class="c7">(inches)</span>
              </p>
            </td>
            <td class="c53" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Production ID </span>
              </p>
              <p class="c5">
                <span class="c7">(inches)</span>
              </p>
            </td>
            <td class="c127" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Vent ID </span>
              </p>
              <p class="c5">
                <span class="c7">(inches)</span>
              </p>
            </td>
            <td class="c96" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c7">Thread Connection </span>
              </p>
              <p class="c5">
                <span class="c7">Box Up / Pin Down</span>
              </p>
            </td>
            <td class="c40" colspan="1" rowspan="1">
              <p class="c212">
                <span class="c7">Part &nbsp;</span>
              </p>
              <p class="c5">
                <span class="c7">Number</span>
              </p>
            </td>
          </tr>
        </thead>

        <tbody>
          <tr class="c218">
            <td class="c25" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.900 </span>
              </p>
            </td>
            <td class="c53" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.19 </span>
              </p>
            </td>
            <td class="c127" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">0.62 </span>
              </p>
            </td>
            <td class="c96" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.900 NUE w/1/4&rdquo; NPT PORT </span>
              </p>
            </td>
            <td class="c40" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">67646</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c25" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2-3/8 </span>
              </p>
            </td>
            <td class="c53" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.12 </span>
              </p>
            </td>
            <td class="c127" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">0.62 </span>
              </p>
            </td>
            <td class="c96" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2-3/8 EUE </span>
              </p>
            </td>
            <td class="c40" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">67645RCF</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c25" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2-7/8 </span>
              </p>
            </td>
            <td class="c53" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.63 </span>
              </p>
            </td>
            <td class="c127" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">0.88 </span>
              </p>
            </td>
            <td class="c96" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2-7/8 EUE / 2-3/8 EUE </span>
              </p>
            </td>
            <td class="c40" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">67656</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c25" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">3-1/2 </span>
              </p>
            </td>
            <td class="c53" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2.00 </span>
              </p>
            </td>
            <td class="c127" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2.00 </span>
              </p>
            </td>
            <td class="c96" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">3-1/2 EUE </span>
              </p>
            </td>
            <td class="c40" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">67670-3500EU</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c25" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c2">4</span>
              </p>
            </td>
            <td class="c53" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">3.50 </span>
              </p>
            </td>
            <td class="c127" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2.00 </span>
              </p>
            </td>
            <td class="c96" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2-3/8 NUE / 4&rdquo; NUE </span>
              </p>
            </td>
            <td class="c40" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">67640</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c53" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2.00 </span>
              </p>
            </td>
            <td class="c127" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2.00 </span>
              </p>
            </td>
            <td class="c96" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">4&rdquo; NUE </span>
              </p>
            </td>
            <td class="c40" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">67670-4000NU</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c25" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">5 </span>
              </p>
            </td>
            <td class="c53" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2.50 </span>
              </p>
            </td>
            <td class="c127" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2.39 </span>
              </p>
            </td>
            <td class="c96" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2-3/8 EUE </span>
              </p>
            </td>
            <td class="c40" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">67650LCFV</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c25" colspan="1" rowspan="3">
              <p class="c5">
                <span class="c2">5-1/2 </span>
              </p>
            </td>
            <td class="c53" colspan="1" rowspan="3">
              <p class="c5">
                <span class="c2">1.25 </span>
              </p>
            </td>
            <td class="c127" colspan="1" rowspan="3">
              <p class="c5">
                <span class="c2">0.88 </span>
              </p>
            </td>
            <td class="c96" colspan="1" rowspan="3">
              <p class="c5">
                <span class="c2">2-7/8 EUE</span>
              </p>
            </td>
            <td class="c40" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">67657</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c40" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">67656-BBA</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c40" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">67656-XBAC</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c25" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">5-3/4 </span>
              </p>
            </td>
            <td class="c53" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2.00 </span>
              </p>
            </td>
            <td class="c127" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2.00 </span>
              </p>
            </td>
            <td class="c96" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">4&rdquo; NUE </span>
              </p>
            </td>
            <td class="c40" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">67640LCF</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c25" colspan="1" rowspan="5">
              <p class="c5">
                <span class="c2">7</span>
              </p>
            </td>
            <td class="c53" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.25 </span>
              </p>
            </td>
            <td class="c127" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">1.25 </span>
              </p>
            </td>
            <td class="c96" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">3-1/2 EUE </span>
              </p>
            </td>
            <td class="c40" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">67673</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c53" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c2">2.50 </span>
              </p>
            </td>
            <td class="c127" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c2">2.50</span>
              </p>
            </td>
            <td class="c96" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">5&rdquo; LTC </span>
              </p>
            </td>
            <td class="c40" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">67650LCF</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c96" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">2-3/8 EUE </span>
              </p>
            </td>
            <td class="c40" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">67650LCF-E</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c53" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c2">1.25 </span>
              </p>
            </td>
            <td class="c127" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c2">1.25 </span>
              </p>
            </td>
            <td class="c96" colspan="1" rowspan="2">
              <p class="c5">
                <span class="c2">3-1/2 EUE</span>
              </p>
            </td>
            <td class="c40" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">67673-E</span>
              </p>
            </td>
          </tr>
          <tr class="c13">
            <td class="c40" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">67673-XBAC</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default CrossFlowAssemblyTable;
