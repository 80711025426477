import React from "react";
import OtherHeader from '../../components/Headers/OtherHeader'
import ContactForm from '../../components/ContactForm/ContactForm'
import DlPage from "../../components/Misc/DlPage";

function Dl() {
  return (
    <div>
      <OtherHeader title="Wholesale Suppliers" />
      <DlPage />
      <ContactForm />
    </div>
  );
}

export default Dl;
