import React from "react";
import { $, jQuery } from "jquery";

function TubingSwivelTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c52">
            <td class="c41" colspan="2" rowspan="1">
              <p class="c2">
                <span class="c4">TUBING OD </span>
              </p>
            </td>
            <td class="c41" colspan="2" rowspan="1">
              <p class="c2">
                <span class="c4">OPERATING LOAD </span>
              </p>
            </td>
            <td class="c41" colspan="2" rowspan="1">
              <p class="c2">
                <span class="c4">LINE PIPE </span>
              </p>
            </td>
            <td class="c10" colspan="2" rowspan="1">
              <p class="c2">
                <span class="c4">THREAD CONNECTION</span>
              </p>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="c70">
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c20">in. </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c20">mm </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c20">lbs. </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c20">daN </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c20">in. </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c20">mm </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c20">in. </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c20">mm</span>
              </p>
            </td>
          </tr>
          <tr class="c14">
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">2 7/8 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">73.0 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">100,000 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">44,500 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">2.00 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">50.8 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">2 7/8 EUE </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">73.0</span>
              </p>
            </td>
          </tr>
          <tr class="c14">
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">3 1/2 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">88.9 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">135,000 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">60,000 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">2.00 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">50.8 </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">3 1/2 EUE </span>
              </p>
            </td>
            <td class="c1" colspan="1" rowspan="1">
              <p class="c2">
                <span class="c0">88.9</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default TubingSwivelTable;
