import React from "react";
import ContactForm from "../../components/ContactForm/ContactForm";
import { useEffect, useState } from "react";
import { getServiceToolTitlesService } from "../../services/dept";
import GeneralBtn from "../../components/ColumnBtns/GeneralBtn";
import { Helmet } from 'react-helmet';
import TubingSwivel from '../../components/Products/ServiceTools/TubingSwivel'
import GeneralHeader from '../../components/Headers/GeneralHeader'
import CasingScraper from '../../components/Products/ServiceTools/CasingScraper'
import CupTypeSelectiveTreatingAssembly from '../../components/Products/ServiceTools/CupTypeSelectiveTreatingAssembly'
import EaFluidControlValve from '../../components/Products/ServiceTools/EaFluidControlValve'
import EfvFracBallValve from '../../components/Products/ServiceTools/EfvFracBallValve'
import EhdCompressionPacker from '../../components/Products/ServiceTools/EhdCompressionPacker'
import EncUnloader from '../../components/Products/ServiceTools/EncUnloader'
import EnsEntCompressionPacker from '../../components/Products/ServiceTools/EnsEntCompressionPacker'
import EpIndexValve from '../../components/Products/ServiceTools/EpIndexValve'
import ErBypassValve from '../../components/Products/ServiceTools/ErBypassValve'
import EsuRetrievable from '../../components/Products/ServiceTools/EsuRetrievable'
import EWRCWBridgePlug from '../../components/Products/ServiceTools/EWRCWBridgePlug'
import HydraulicSettingTool from '../../components/Products/ServiceTools/HydraulicSettingTool'
//
import DltRetrievablePacker from '../../components/Products/ServiceTools/DltRetrievablePacker'
import VIIIUnloader from '../../components/Products/ServiceTools/VIIIUnloader'
import StormValve from '../../components/Products/ServiceTools/StormValve'
import BumperSub from '../../components/Products/ServiceTools/BumperSub'
import RRSafetyJoint from '../../components/Products/ServiceTools/RRSafetyJoint'
import CompressionUnloader from '../../components/Products/ServiceTools/CompressionUnloader'
import HDRetrievablePacker from '../../components/Products/ServiceTools/HDRetrievablePacker'
import AsRetrievableBridgePlug from '../../components/Products/ServiceTools/AsRetrievableBridgePlug'
import SpringLoadedRetrievingTool from '../../components/Products/ServiceTools/SpringLoadedRetrievingTool'
import WirelineAdapterKit from '../../components/Products/ServiceTools/WirelineAdapterKit'
import TypeTSqueezePacker from '../../components/Products/ServiceTools/TypeTSqueezePacker'
import ScTensionUnloader from '../../components/Products/ServiceTools/ScTensionUnloader'
import DAIIUnloader from '../../components/Products/ServiceTools/DAIIUnloader'
import TypeCPerforationWashTool from '../../components/Products/ServiceTools/TypeCPerforationWashTool'
import StradasnapPacker from '../../components/Products/ServiceTools/StradasnapPacker'
import LcHydraulicSetting from '../../components/Products/ServiceTools/LcHydraulicSetting'
import { useParams } from "react-router-dom";
import { pseudoencodeURI } from "./converter";


const ProductController = [
  {
    title: "NC UNLOADER",
    component: <EncUnloader title="NC UNLOADER"/>,
  },
  {
    title: "TUBING SWIVEL",
    component: <TubingSwivel title="TUBING SWIVEL"/>,
  },
  {
    title: "CASING SCRAPER",
    component: <CasingScraper title="CASING SCRAPER"/>,
  },
  {
    title: "WR-CW BRIDGE PLUG",
    component: <EWRCWBridgePlug title="WR-CW BRIDGE PLUG"/>,
  },
  {
    title: "BY-PASS 3-TURN VALVE",
    component: <ErBypassValve title="BY-PASS 3-TURN VALVE"/>,
  },
  {
    title: "A FLUID CONTROL VALVE",
    component: <EaFluidControlValve title="A FLUID CONTROL VALVE"/>,
  },
  {
    title: "CUP TYPE SELECTIVE TREATING ASSEMBLY",
    component: <CupTypeSelectiveTreatingAssembly title="CUP TYPE SELECTIVE TREATING ASSEMBLY"/>,
  },
  {
    title: "FV FRAC BALL VALVE",
    component: <EfvFracBallValve title="FV FRAC BALL VALVE"/>,
  },
  {
    title: "P1-J INDEX VALVE",
    component: <EpIndexValve title="P1-J INDEX VALVE"/>,
  },
  {
    title: "ENS/ENT COMPRESSION PACKER",
    component: <EnsEntCompressionPacker title="ENS/ENT COMPRESSION PACKER"/>,
  },
  {
    title: "ESU-RBP RETRIEVABLE BRIDGE PLUG",
    component: <EsuRetrievable title="ESU-RBP RETRIEVABLE BRIDGE PLUG"/>,
  },
  {
    title: "HD COMPRESSION PACKER",
    component: <EhdCompressionPacker title="HD COMPRESSION PACKER"/>,
  },
  {
    title: "BAKER 5-10-20 HYDRAULIC SETTING TOOL",
    component: <HydraulicSettingTool title="BAKER 5-10-20 HYDRAULIC SETTING TOOL"/>,
  },
  {
    title: "DLT RETRIEVABLE PACKER",
    component: <DltRetrievablePacker title="DLT RETRIEVABLE PACKER"/>,
  },
  {
    title: "V-III UNLOADER",
    component: <VIIIUnloader title="V-III UNLOADER"/>,
  },
  {
    title: "STORM VALVE",
    component: <StormValve title="STORM VALVE"/>,
  },
  {
    title: "BUMPER SUB",
    component: <BumperSub title="BUMPER SUB"/>,
  },
  {
    title: "RR SAFETY JOINT",
    component: <RRSafetyJoint title="RR SAFETY JOINT"/>,
  },
  {
    title: "COMPRESSION UNLOADER",
    component: <CompressionUnloader title="COMPRESSION UNLOADER"/>,
  },
  {
    title: "HD RETRIEVABLE PACKER",
    component: <HDRetrievablePacker title="HD RETRIEVABLE PACKER"/>,
  },
  {
    title: "AS RETRIEVABLE BRIDGE PLUG",
    component: <AsRetrievableBridgePlug title="AS RETRIEVABLE BRIDGE PLUG"/>,
  },
  {
    title: "SPRING LOADED RETRIEVING TOOL",
    component: <SpringLoadedRetrievingTool title="SPRING LOADED RETRIEVING TOOL"/>,
  },
  {
    title: "WIRELINE ADAPTER KIT",
    component: <WirelineAdapterKit title="WIRELINE ADAPTER KIT"/>,
  },
  {
    title: "TYPE T SQUEEZE PACKER",
    component: <TypeTSqueezePacker title="TYPE T SQUEEZE PACKER"/>,
  },
  {
    title: "SC TENSION UNLOADER",
    component: <ScTensionUnloader title="SC TENSION UNLOADER"/>,
  },
  {
    title: "DA-II UNLOADER",
    component: <DAIIUnloader title="DA-II UNLOADER"/>,
  },
  {
    title: "TYPE C PERFORATION WASH TOOL",
    component: <TypeCPerforationWashTool title="TYPE C PERFORATION WASH TOOL"/>,
  },
  {
    title: "STRADASNAP PACKER",
    component: <StradasnapPacker title="STRADASNAP PACKER"/>,
  },
  {
    title: "LC HYDRAULIC SETTING TOOL",
    component: <LcHydraulicSetting title="LC HYDRAULIC SETTING TOOL"/>,
  },
];

function ServiceTools() {
  const [serviceTools, setServiceTools] = useState([]);
  const [currentProduct, setCurrentProduct] = useState("TUBING SWIVEL");
  const params = useParams(); 

  useEffect(() => {
    getServiceToolTitlesService().then((result) => {
        setServiceTools(result);
        setCurrentProduct(params.subLink)
        window.scrollTo(0, 0);

    });
  }, [params]);

  return (
    <div>
    <Helmet>‍
        <title>Hydraulic Packers | Maverick Downhole Technologies | Edmonton, AB</title>‍
        
      </Helmet>
      <GeneralHeader title="Service Tools" />
      <div className="product-section">
        <div className="product-btn-container">
          {serviceTools.map((serviceTools) => {
            return (
              <GeneralBtn
                onClick={(title) => setCurrentProduct(title)}
                title={serviceTools.title}
                selected={pseudoencodeURI(serviceTools.title) === currentProduct}
                baseURL={"/products/service-tools"}
              />
            );
          })}
        </div>
        <div>
          {!currentProduct
            ? null
            : ProductController.find(
                (product) => pseudoencodeURI(product.title) === currentProduct
              )?.component}
        </div>
      </div>
      <ContactForm />
    </div>
  );
}

export default ServiceTools;
