import React from "react";
import { $, jQuery } from "jquery";

function EncUnloaderTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr>
            <th colspan="2">TUBING SIZE</th>
            <th colspan="2">MAX OD</th>
            <th colspan="2">MIN ID</th>
            <th colspan="2">THREAD CONNECTION</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>in.</td>
            <td>mm</td>
            <td>in.</td>
            <td>mm</td>
            <td>in.</td>
            <td>mm</td>
            <td>in.</td>
            <td>mm</td>
          </tr>
          <tr>
            <td>
              2<sup>3/8</sup>
            </td>
            <td>60.3</td>
            <td>3.750</td>
            <td>95.25</td>
            <td>2.000</td>
            <td>50.80</td>
            <td>2 3/8 EUE</td>
            <td>60.30</td>
          </tr>
          <tr>
            <td>
              2<sup>7/8</sup>
            </td>
            <td>73.0</td>
            <td>4.500</td>
            <td>114.30</td>
            <td>2.500</td>
            <td>63.50</td>
            <td>2 7/8 EUE</td>
            <td>73.00</td>
          </tr>
          <tr>
            <td>
              3<sup>1/2</sup>
            </td>
            <td>88.9</td>
            <td>5.500</td>
            <td>139.70</td>
            <td>3.000</td>
            <td>76.20</td>
            <td>3 1/2 EUE</td>
            <td>88.90</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default EncUnloaderTable;
