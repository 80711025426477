const productLinks = 
    {
        mechanicalPackers: '/products/mechanical-packers/TYPE%20DL-IB%20TANDEM%20TENSION%20PACKER',
        hydraulicPackers: '/products/hydraulic-packers/DLH%20PACKER',
        tubingAnchors: '/products/tubing-anchors/EC-1%20DRAG%20BLOCK%20TUBING%20ANCHOR-CATCHER',
        flowControl: '/products/flow-control/X-XN%20LANDING%20NIPPLES',
        plugRetainers: '/products/plug-retainers/HM-1B%20BRIDGE%20PLUG',
        serviceTools: '/products/service-tools/TUBING%20SWIVEL',
        accessories: '/products/accessories/ROTATIONAL%20SHEAR%20SAFETY%20JOINT',
        burstDiscs: '/products/burst-discs/BURST%20DISC%20SUB%20-%20DUAL%20HPHT',
    }

export default productLinks; 