import React from "react";
import { $, jQuery } from "jquery";

function TensionPackerTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
        <tr>
          <th colspan="6">CASING</th>
          <th colspan="6">PACKER</th>
        </tr>
        <tr>
          <th colspan="2">OD</th>
          <th colspan="2">WEIGHT</th>
          <th colspan="2">MIN ID</th>
          <th colspan="2">MAX ID</th>
          <th colspan="2">MAX OD</th>
          <th colspan="2">THREAD CONNECTION</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>in.</td>
          <td>mm</td>
          <td>lbs/ft</td>
          <td>kg/m</td>
          <td>in.</td>
          <td>mm</td>
          <td>in.</td>
          <td>mm</td>
          <td>in.</td>
          <td>mm</td>
          <td>in.</td>
          <td>mm</td>
        </tr>
        <tr>
          <td>4<sup>1/2</sup></td>
          <td>114.3</td>
          <td>9.5-13.5</td>
          <td>14.17-20.13</td>
          <td>3.920</td>
          <td>99.57</td>
          <td>4.090</td>
          <td>103.89</td>
          <td>3.771</td>
          <td>95.78</td>
          <td>2 3/8 EUE</td>
          <td>60.3</td>
        </tr>
        <tr>
          <td>5</td>
          <td>127.0</td>
          <td>15.0-18.0</td>
          <td>22.37-26.84</td>
          <td>4.276</td>
          <td>108.61</td>
          <td>4.408</td>
          <td>111.96</td>
          <td>4.125</td>
          <td>104.78</td>
          <td>2 3/8 EUE</td>
          <td>60.3</td>
        </tr>
        <tr>
          <td>5</td>
          <td>127.0</td>
          <td>11.5-15.0</td>
          <td>17.15-22.37</td>
          <td>4.408</td>
          <td>111.96</td>
          <td>4.560</td>
          <td>115.82</td>
          <td>4.250</td>
          <td>107.95</td>
          <td>2 3/8 EUE</td>
          <td>60.3</td>
        </tr>
        <tr>
          <td>5<sup>1/2</sup></td>
          <td>139.7</td>
          <td>20.0-23.0</td>
          <td>29.83-34.30</td>
          <td>4.670</td>
          <td>118.62</td>
          <td>4.788</td>
          <td>121.62</td>
          <td>4.500</td>
          <td>107.95</td>
          <td>2 3/8 EUE</td>
          <td>60.3</td>
        </tr>
        <tr>
          <td>5<sup>1/2</sup></td>
          <td>139.7</td>
          <td>15.5-20.0</td>
          <td>23.11-29.83</td>
          <td>4.778</td>
          <td>121.36</td>
          <td>4.950</td>
          <td>125.73</td>
          <td>4.641</td>
          <td>117.88</td>
          <td>2 3/8 EUE</td>
          <td>60.3</td>
        </tr>
        <tr>
          <td>5<sup>1/2</sup></td>
          <td>139.7</td>
          <td>13.0-17.0</td>
          <td>19.39-25.35</td>
          <td>4.892</td>
          <td>124.26</td>
          <td>5.044</td>
          <td>128.12</td>
          <td>4.750</td>
          <td>120.65</td>
          <td>2 3/8 EUE</td>
          <td>60.3</td>
        </tr>
        <tr>
          <td>5<sup>1/2</sup></td>
          <td>139.7</td>
          <td>13.0-15.5</td>
          <td>19.39-13.11</td>
          <td>4.950</td>
          <td>125.73</td>
          <td>5.044</td>
          <td>128.12</td>
          <td>4.781</td>
          <td>121.44</td>
          <td>2 3/8 EUE</td>
          <td>60.3</td>
        </tr>
        <tr>
          <td>7 </td>
          <td>177.8</td>
          <td>38.0</td>
          <td>56.67</td>
          <td>5.830</td>
          <td>148.08</td>
          <td>5.921</td>
          <td>150.39</td>
          <td>5.656</td>
          <td>143.66</td>
          <td>2 7/8 EUE</td>
          <td>73.0</td>
        </tr>
        <tr>
          <td>7 </td>
          <td>177.8</td>
          <td>32.0-35.0</td>
          <td>47.72-52.20</td>
          <td>6.044</td>
          <td>153.52</td>
          <td>6.094</td>
          <td>154.79</td>
          <td>5.812</td>
          <td>147.62</td>
          <td>2 7/8 EUE</td>
          <td>73.0</td>
        </tr>
        <tr>
          <td>7 </td>
          <td>177.8</td>
          <td>26.0-29.0</td>
          <td>38.78-43.25</td>
          <td>6.184</td>
          <td>157.07</td>
          <td>6.276</td>
          <td>159.41</td>
          <td>5.968</td>
          <td>151.59</td>
          <td>2 7/8 EUE</td>
          <td>73.0</td>
        </tr>
        <tr>
          <td>7 </td>
          <td>177.8</td>
          <td>20.0-26.0</td>
          <td>29.83-38.78</td>
          <td>6.276</td>
          <td>159.41</td>
          <td>6.456</td>
          <td>163.98</td>
          <td>6.078</td>
          <td>154.38</td>
          <td>2 7/8 EUE</td>
          <td>73.0</td>
        </tr>
        <tr>
          <td>7 </td>
          <td>177.8</td>
          <td>17.0-20.0</td>
          <td>25.35-29.83</td>
          <td>6.456</td>
          <td>163.98</td>
          <td>6.538</td>
          <td>166.07</td>
          <td>6.266</td>
          <td>159.16</td>
          <td>2 7/8 EUE</td>
          <td>73.0</td>
        </tr>
        </tbody>
      </table>
    </div>
  );
}

export default TensionPackerTable;
