import React from "react";
import { $, jQuery } from "jquery";

function WirelineAdapterKitPermanentTable() {
  return (
    <div>
      <table
        id="example"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr class="c310">
            <td class="c230" colspan="1" rowspan="1">
              Setting Tool Size
            </td>
            <td class="c297" colspan="1" rowspan="1">
              Setting Tool OD (inches)
            </td>
            <td class="c580" colspan="1" rowspan="1">
              External Threads
            </td>
            <td class="c255" colspan="1" rowspan="1">
              Internal Threads
            </td>
            <td class="c289" colspan="1" rowspan="1">
              Packer Sizes
            </td>
          </tr>
        </thead>
        <tbody>
           <tr class="c746">
      <td class="c230" colspan="1" rowspan="1">
          05 
      </td>
      <td class="c297" colspan="1" rowspan="1">
          1.718 
      </td>
      <td class="c580" colspan="1" rowspan="1">
          1-1/4&rdquo; UNS 
      </td>
      <td class="c255" colspan="1" rowspan="1">
          5/8&rdquo; UNF
      </td>
      <td class="c289" colspan="1" rowspan="1">
          2-3/8&rdquo; 
          2-7/8&rdquo; 
          3-1/2&rdquo;
      </td>
  </tr>
  <tr class="c746">
      <td class="c230" colspan="1" rowspan="1">
          10 
      </td>
      <td class="c297" colspan="1" rowspan="1">
          2.750 
      </td>
      <td class="c580" colspan="1" rowspan="1">
          2-1/2&rdquo; ACME 
      </td>
      <td class="c255" colspan="1" rowspan="1">
          1&rdquo; UNC
      </td>
      <td class="c289" colspan="1" rowspan="1">
          4&rdquo; 
          4-1/2&rdquo; 
          5&rdquo;
      </td>
  </tr>
  <tr class="c59">
      <td class="c230" colspan="1" rowspan="1">
          20 
      </td>
      <td class="c297" colspan="1" rowspan="1">
          3.800 
      </td>
      <td class="c580" colspan="1" rowspan="1">
          3-1/2&rdquo; STUB ACME 
      </td>
      <td class="c255" colspan="1" rowspan="1">
          2&rdquo; ACME 
      </td>
      <td class="c289" colspan="1" rowspan="1">
          5-1/2&rdquo; &amp; LARGER
      </td>
  </tr>
        </tbody>
      </table>
    </div>
  );
}

export default WirelineAdapterKitPermanentTable;
